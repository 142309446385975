<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
      v-layout
        v-flex(
            xs4
            md4
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.label"
            :label="$t(this.model.content + '.' + 'label')"
          )
        v-flex(
          xs4
          md4
        )
          v-select(
            required
            v-model="item.type"
            :items="types"
            item-value="value"
            item-text="label"
            :rules="[(v) => !!v || $t('common.required')]"
            :label="$t(this.model.content + '.' + 'type')"
          )
        v-flex(
            xs4
            md4
        )
          v-checkbox(
            :label="$t(this.model.content + '.' + 'is_only_credit')" 
            v-model="item.is_only_credit"
            :readonly="readonly"
          )
      v-layout(v-if="item.type=='CUSTOMER_FIELD_SELECT'")
        v-text-field(
          :readonly="readonly"
          required
          v-model="item.field"
          :label="$t(this.model.content + '.' + 'field')"
        )
      v-layout(v-if="item.type=='FLAG'")
        v-flex(
          xs12
          md6
        )
          v-select(v-model="flagsTo1" :items='flags' chips label='Mettre à Vrai' multiple)
        v-flex(
          xs12
          md6
        )
          v-select(v-model="flagsTo0" :items='flags' chips label='Mettre à Faux' multiple)
      //v-layout(v-if="item.type=='FLAG'")
        v-flex(
          xs12
          md6
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.flags_value"
            type="number"
            :label="$t(this.model.content + '.' + 'flags_value')"
          )
        v-flex(
          xs12
          md6
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.flags_mask"
            type="number"
            :label="$t(this.model.content + '.' + 'flags_mask')"
          )
      v-layout(v-if="item.type=='SET'")
        v-flex(
          xs12
          md6
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.int_value"
            type="number"
            :label="$t(this.model.content + '.' + 'int_value')"
          )
</template>

<script>
  import {Model} from "./imports.js"
  import CommonEdit from "../tool/CommonEdit.js"

  import NfcOperation from "../../model/nfcoperation_/NFCOperation.js"
  const nfcOperation = new NfcOperation()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      listen: false,
      flagsTo1: [],
      flagsTo0: [],
      types: [],
      flags: []
    }),
    watch: {
      "item.type": {
        handler: function(newValue) {
          if (newValue === "FLAG") {
            this.updateFromParent()
          }
        },
        // if async it would break "edited" logic
        sync: true,
      },
    }, 
    
    methods: {
      prepareItemToCreate() {
        let _self = this
        nfcOperation.get(
          {id: _self.item.parent_id}
          , function(result) {
            _self.item.parent = result.data
            _self.updateFromParent()
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
      prepareItemToEdit() {
        this.updateFromModel()
      },
      prepareItemToSend() {
        this.updateFromHMI()
      },
      updateFromParent: function() {
        this.flags = []
        for (let i = 1; i <= 8 * this.item.parent.size; i++) {
          this.flags.push(i)
        }
        this.flagsTo1 = []
        this.flagsTo0 = []
      },
      updateFromModel: function() {
        this.flags = []
        for (let i = 0; i < this.item.flags_mask.length * 8; i++) {
          this.flags.push(i+1)
        }

        this.flagsTo0 = []
        this.flagsTo1 = []

        for (let byteIndice = 0; byteIndice < this.item.flags_mask.length; byteIndice++) {
          let maskByte = this.item.flags_mask[byteIndice]
          let valueByte = this.item.flags_value[byteIndice]

          for (let bitIndice = 0; bitIndice < 8; bitIndice++) {
            let nb = byteIndice * 8 + bitIndice + 1

            if ((maskByte & 1 << bitIndice) != 0) {
              if ((valueByte & 1 << bitIndice) != 0) {
                  this.flagsTo1.push(nb)
              } else {
                  this.flagsTo0.push(nb)
              }
            }
          }
        }
      },
      updateFromHMI: function() {
        let _self = this

        _self.item.flags_value = []
        _self.item.flags_mask = []

        for (let i = 0; i < _self.item.parent.size; i++) {
          _self.item.flags_value[i] = 0
          _self.item.flags_mask[i] = 0
        }

        _self.flagsTo1.forEach(function(indice) {
          indice = indice - 1
          let i = Math.floor(indice / 8)
          let pow = indice % 8
          
          _self.item.flags_value[i] += Math.pow(2, pow)
          _self.item.flags_mask[i] += Math.pow(2, pow)
        })
        _self.flagsTo0.forEach(function(indice) {
          indice = indice - 1
          let i = Math.floor(indice / 8)
          let pow = indice % 8
          
          _self.item.flags_mask[i] += Math.pow(2, pow)
        })
      },
      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        this.types = this.model.getTypes(this)
      }
    },
  }


</script>
