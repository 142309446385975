
import loginStore from './LoginStore'
import router from '../../../router.js'
import db from './DB.js'

export default {
  data: () => ({
    loginState: loginStore.state,
  }),
  methods : {
    login() {
      loginStore.login(() => {

      // let urls  = [
      //     {
      //       page: '/hmi/basket/search?state=invoice', 
      //       right: 'is_sell' 
      //     },
      //     {
      //       page: '/hmi/plcenergy/search',      
      //       right: 'is_maint' 
      //     },
      //   ]

      //   for (let url of urls) {
      //     if (loginStore.isAllowed(url.right)) {
      //       router.push({path: url.page })
      //       return
      //     }
      //   }

        //router.push({path:"/hmi/plcenergy/search" })
        if (loginStore.isAllowed("is_sell")) {
          router.push({path:"/hmi/map" })
        } else if (loginStore.isAllowed("is_maint")) {
          router.push({path:"/hmi/mapmaint" })
        } else if (loginStore.isAllowed("is_admin")) {
          router.push({path:"/hmi/product" })
        }
        // if (loginStore.isAllowed("is_sell")) {
        //   router.push({path:"/hmi/subscribtion/search" })
        // } else {
        //   router.push({path:"/hmi/product/search" })
        // }
      })
    }
  }, 
  mounted() {
    db.logins.limit(1).each((item) => {this.loginState.user = item.login})
  },
  watch: {
  },
  props: {
  },
}
