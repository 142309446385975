var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('Map', {
    on: {
      "success": _vm.onSuccess,
      "error": _vm.onError
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }