import Model from "../Model.js"


export default class PLC extends Model {
    constructor() {
        super("plc")
    }

    defaultValues() {
        return {
            id: 0,
            com: null,
            last_batch_id: null,
            is_enabled: true,
            isRS485: false,
            isWifi: false,
        }
    }
}

