import Model from "../Model.js";


export const PRODUCT_TYPE_CARD_UNITS      = "CARD_UNITS"
export const PRODUCT_TYPE_CARD_TIME       = "CARD_TIME"
export const PRODUCT_TYPE_CARD_ACTIVATION = "CARD_ACTIVATION"
export const PRODUCT_TYPE_RELOAD_UNITS    = "RELOAD_UNITS"
export const PRODUCT_TYPE_RELOAD_TIME     = "RELOAD_TIME"
export const PRODUCT_TYPE_RELOAD_OTHER    = "RELOAD_OTHER"


export default class Product extends Model {
    constructor(content="product") {
        super(content)
    }

    defaultValues() {
        return {
            id: 0,

            is_visible: true,
            label: null,
            description: null,
            pos : 1,
            price_ht : 0,
            unit: "U",
            vat_id : null,
            
            type: "RELOAD_WITH_CREDIT",
            qty_water: 0,
            qty_elec: 0,
            nb_showers: 0,
            days_parking: 0,
            card_refund: 0,
            // sub_price_ht: 0,
            // is_subscribtion: false,
            // is_card: true,
            // card_refund: 10,
            // markers: []
        }
    }

    getTypeSubs(_self) {
        return [
            {value: "ANNUALY", label: _self.$t(this.content + '.' + 'type_sub_ANNUALY')}
            , {value: "MONTHLY", label: _self.$t(this.content + '.' + 'type_sub_MONTHLY')}
            , {value: "TRIMESTER", label: _self.$t(this.content + '.' + 'type_sub_TRIMESTER')}
        ]
    }

    getProductTypes(_self) {

        // return [
        //     {value: "CARD_WITH_CREDITS", label: "Badge avec crédits"}
        //     , {value: "ACTIVATION_CARD", label: "Badge d'activation"}
        //     , {value: "RELOAD_WITH_CREDIT", label: "Recharge pour badge avec crédits"}
        //     , {value: "RELOAD_WITHOUT_CREDIT", label: "Recharge pour badge d'activation"}
        // ]
        return [
            {value: PRODUCT_TYPE_CARD_UNITS, label: "Badge avec unités"} ,
            {value: PRODUCT_TYPE_CARD_TIME, label: "Badge avec temps"} ,
            {value: PRODUCT_TYPE_CARD_ACTIVATION, label: "Badge d'activation"} ,
            {value: PRODUCT_TYPE_RELOAD_UNITS, label: "Recharge d'unités"} ,
            {value: PRODUCT_TYPE_RELOAD_TIME, label: "Recharge de temps"} ,
            {value: PRODUCT_TYPE_RELOAD_OTHER, label: "Recharge autre"} ,
        ]

    }

}


