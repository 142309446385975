

























import { TransactionFilter } from "@/components/model/transaction/TransactionService";
import { Vue, Component, Prop } from "vue-property-decorator";


@Component
export default class TransactionFilterDialog extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  filter!: TransactionFilter

  color: string = "secondary lighten-1"
}
