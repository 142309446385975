var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.tag_headers,
      "items": _vm.value.tag_lines,
      "items-per-page": 25,
      "loading": _vm.isLoading,
      "disable-sort": "",
      "hide-default-header": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headers = _ref.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (header) {
          return _c('th', [_c('div', {
            attrs: {
              "align": header.align,
              "width": header.width
            }
          }, [_vm._v(_vm._s(_vm.$t(header.text)))])]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function fn(_ref2) {
        var items = _ref2.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', [_c('td', [_c('div', [_vm._v(_vm._s(item.label))])]), _c('td', [_c('v-btn', {
            staticClass: "linedetail  ma-2",
            attrs: {
              "disabled": item.read_write_id != null,
              "color": 'primary',
              "width": "120px"
            },
            on: {
              "click": function click($event) {
                return _vm.writeItem(item);
              }
            }
          }, [_vm._v(_vm._s(_vm.isNewCard(item) ? _vm.$t('baskettagline.create_card') : _vm.$t('baskettagline.load_card')))]), _c('v-btn', {
            staticClass: "linedetail  ma-2",
            attrs: {
              "color": 'primary'
            },
            on: {
              "click": function click($event) {
                return _vm.editNfcCard(item);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t('baskettagline.edit_card_content')))])], 1)]);
        }), 0)];
      }
    }])
  }), _c('v-overlay', {
    attrs: {
      "value": _vm.progressWrite,
      "opacity": "0.8"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "rotate": 360,
      "size": 100,
      "width": 15,
      "value": _vm.progress_value,
      "color": "teal"
    }
  }, [_vm._v(_vm._s(_vm.progress_value))])], 1), _c('EditNfcCard', {
    attrs: {
      "show": _vm.showEditNfc
    },
    on: {
      "hide": function hide($event) {
        _vm.showEditNfc = false;
      },
      "success": _vm.success,
      "error": _vm.error
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }