<template lang="pug">
  v-dialog(v-model='missingPlugin' width='50vw')
    v-card
      v-card-title.grey.lighten-4.py-4.title {{$t("nfc.no_plugin_detected")}}
      v-card-text
        div {{$t("nfc.plugin_supported_os_and_browser")}}
        div
          a(v-if="browser == 'Chrome'" href="https://chrome.google.com/webstore/detail/2ip-nfc-reader/ijgdeogfabemphplnccnjjnbjlggmbjh?hl=fr&authuser=1") {{$t("nfc.chrome_plugin")}}
        div
          a(v-if="browser == 'Firefox'" href="/2ip_nfc_reader-1.9-fx.xpi") {{$t("nfc.firefox_plugin")}}
        div
          a(v-if="platform == 'Windows'" href="/Setup_NFC_Reader_1.6.exe") {{$t("nfc.windows_native")}}
        div
          a(v-if="platform == 'Linux'" href="/2i+Process+Installateur.deb") {{$t("nfc.linux_native")}}
      v-card-actions
        v-spacer
          v-btn(color='error' @click='missingPlugin = false') {{$t("hmi.close_dialog")}}
</template>

<script>

  export default {
    components: {
    },
    props: {
    },
    computed: {
    }, 
    watch: {

    }, 
    data: () => ({
      missingPlugin: false,
      platform: null,
      browser: null,
    }),

    methods: {
      loadModel () {
        //this.model = new Model()
      },
    },     

    mounted() {
      if (navigator.platform.toLowerCase().includes("linux")) {
        this.platform = "Linux"
      } else if (navigator.platform.toLowerCase().includes("win32")) {
        this.platform = "Windows"
      }
      if (navigator.userAgent.toLowerCase().includes("chrome")) {
        this.browser = "Chrome"
      } else if (navigator.userAgent.toLowerCase().includes("firefox")) {
        this.browser = "Firefox"
      }

      //console.log(navigator)
    }
  }

</script>
