var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.item.customer != null ? _c('v-text-field', {
    staticClass: "no-border",
    attrs: {
      "readonly": true,
      "label": _vm.$t(this.model.content + '.' + 'customer')
    },
    model: {
      value: _vm.formatedCustomer,
      callback: function callback($$v) {
        _vm.formatedCustomer = $$v;
      },
      expression: "formatedCustomer"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-select', {
    staticClass: "no-border",
    attrs: {
      "items": _vm.products,
      "item-text": "label",
      "item-value": "id",
      "label": _vm.$t(this.model.content + '.' + 'product'),
      "readonly": true
    },
    model: {
      value: _vm.item.product_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "product_id", $$v);
      },
      expression: "item.product_id"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-select', {
    staticClass: "no-border",
    attrs: {
      "items": _vm.statuses,
      "item-text": "label",
      "item-value": "value",
      "label": _vm.$t(this.model.content + '.' + 'status'),
      "readonly": true
    },
    model: {
      value: _vm.item.status,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "status", $$v);
      },
      expression: "item.status"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs5": "",
      "md5": ""
    }
  }, [_c('v-select', {
    staticClass: "no-border",
    attrs: {
      "items": _vm.plcs,
      "item-text": "label",
      "item-value": "id",
      "label": _vm.$t(this.model.content + '.' + 'localisation'),
      "readonly": true
    },
    model: {
      value: _vm.item.plc_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "plc_id", $$v);
      },
      expression: "item.plc_id"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs1": "",
      "md1": ""
    }
  }, [_vm.item.nfc_card != null && !_vm.readonly && _vm.item.status == 'PROGRESS' ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t(this.model.content + '.' + 'close')))]) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [false ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.next();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t(this.model.content + '.' + 'change_localisation')))]) : _vm._e()], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'is_auto_renew'),
      "readonly": true
    },
    model: {
      value: _vm.item.is_auto_renew,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_auto_renew", $$v);
      },
      expression: "item.is_auto_renew"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.item.begin != null ? _c('v-text-field', {
    staticClass: "no-border",
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'begin'),
      "readonly": true
    },
    model: {
      value: _vm.formatedBegin,
      callback: function callback($$v) {
        _vm.formatedBegin = $$v;
      },
      expression: "formatedBegin"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.item.end != null ? _c('v-text-field', {
    staticClass: "no-border",
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'end'),
      "readonly": true
    },
    model: {
      value: _vm.formatedEnd,
      callback: function callback($$v) {
        _vm.formatedEnd = $$v;
      },
      expression: "formatedEnd"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-expansion-panels', {
    staticClass: "mt-5",
    attrs: {
      "inset": true
    }
  }, _vm._l(_vm.item.periods, function (period) {
    return _c('v-expansion-panel', {
      key: period.id
    }, [_c('v-expansion-panel-header', {
      staticClass: "ma-1 primary"
    }, [_c('v-layout', [_c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'begin') + ' : ' + _vm.formatDate(period.begin)))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'end') + ' : ' + _vm.formatDate(period.end)))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'total_ht') + ' : ' + _vm.formatCurrency(period.total_ht)))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'total_ttc') + ' : ' + _vm.formatCurrency(period.total_ttc)))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm.isRightPdf(period) ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "white",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.pdfAction(period);
        }
      }
    }, [_vm._v("picture_as_pdf")]) : _vm._e()], 1)])], 1)], 1), _c('v-expansion-panel-content', [_c('div', [_c('v-layout', [_c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.item.product.label))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'price_ht') + " : " + _vm.formatCurrency(period.price_ht)))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.$t('subscribtionperiod.' + 'price_ttc') + " : " + _vm.formatCurrency(period.price_ttc)))])])], 1)], 1), _c('div', [_c('v-layout', {
      staticClass: "primary"
    }, [_c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v("Energie")])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v(_vm._s(_vm.$t('api_consumptionvariable.' + 'plc')))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v(_vm._s(_vm.$t('api_consumptiondetail.' + 'qty')))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v(_vm._s(_vm.$t('api_consumptionvariable.' + 'price_ht')))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v(_vm._s(_vm.$t('api_consumptiondetail.' + 'total_ht')))])]), _c('v-flex', {
      attrs: {
        "xs2": "",
        "md2": ""
      }
    }, [_c('div', {
      staticClass: "ma-2"
    }, [_vm._v(_vm._s(_vm.$t('api_consumptiondetail.' + 'total_ttc')))])])], 1)], 1), _vm._l(period.consumptions, function (consumption) {
      return _c('div', {
        key: consumption.id
      }, _vm._l(consumption.details, function (detail) {
        return _c('v-layout', {
          key: detail.id
        }, [_c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(consumption.nfcinfo.label))])]), _c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(detail.plc.label))])]), _c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(detail.qty + ' ' + consumption.nfcinfo.unit))])]), _c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(_vm.formatCurrency(consumption.price_ht)))])]), _c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(_vm.formatCurrency(detail.total_ht)))])]), _c('v-flex', {
          attrs: {
            "xs2": "",
            "md2": ""
          }
        }, [_c('div', {
          staticClass: "ma-2"
        }, [_vm._v(_vm._s(_vm.formatCurrency(detail.total_ttc)))])])], 1);
      }), 1);
    })], 2)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }