









import { Component, Vue } from 'vue-property-decorator'


import Data from  '@/components/model/data/Data'
let data = new Data()

@Component
export default class extends Vue {
    async createPlugs(): Promise<void> {
        try {
            await data.createPlugs()
            this.onSuccess("Opération terminée")
        } catch (error) {
            this.onError("L'opération a échoué", error as Error)
        }
    }
}
