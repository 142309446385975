import Model from "../Model.js"


import Basket from "../basket/Basket.js"
const basketModel = new Basket()

import loginStore from '../../hmi/tool/LoginStore.js'

export default class Vat extends Model {
    constructor() {
        super("user", "api")
    }

    defaultValues () {
        return {
            id : 0,
            label : null,
            rate : 0.0,
        }
    }
    
    replaceCurrentBasket(newBasketId, callback, error) {
      this.update(
        {id:0, active_basket_id: newBasketId}
        ,function(response) {
          callback(response.data)
        }
        , function(e) {
          error(e)
        }
      )
    }

    getCurrentBasket(callback, error) {
        this.get(
            {id: 0}
            , function(response) {
              let active_basket_id = response.data.active_basket_id
              loginStore.state.active_basket_id = active_basket_id
              //console.log("active_basket_id", active_basket_id)
  
              //auto create basket
              if (active_basket_id === null) {
                //console.log("create")
                basketModel.create(
                  basketModel.defaultValues ()
                  , function(response) {
                    loginStore.state.basketQty = response.data.qty
                    callback(response.data)
                  }
                  , function(e) {
                    error(e)
                  }
                )
              //retrieve basket
              } else {
                //console.log("get")
                basketModel.get(
                  {id: active_basket_id}
                  , function(response) {
                    loginStore.state.basketQty = response.data.qty
                    callback(response.data)
                  }
                  , function(e) {
                    error(e)
                  }
                )
              }
  
            }
            , function(e) {
              error(e)
            }
          )
    }

}
