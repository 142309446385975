<template lang="pug">
v-card
  v-card-title
    span.headline {{$t(title)}}
  v-card-text
    v-stepper(
      v-model="step"
    )
      v-stepper-header
        v-stepper-step(
          :complete="step > 1" 
          :step="1"
        ) {{$t('subscribtion.step_customer')}}
        v-divider()
        v-stepper-step(
          :complete="step > 2" 
          :step="2"
        ) {{$t('subscribtion.step_period')}}
        v-divider()
        v-stepper-step(
          :complete="step > 3" 
          :step="3"
        ) {{$t('subscribtion.step_localisation')}}
        v-divider()
        v-stepper-step(
          :complete="step > 4" 
          :step="4"
        ) {{$t('subscribtion.step_payement')}}
        v-divider
        v-stepper-step(
          :complete="step > 5" 
          :step="5"
        ) {{$t('subscribtion.step_card')}}
      v-stepper-items
        // CUSTOMER
        v-stepper-content(
          :step="1"
        )
          v-container
            v-layout()
              v-flex.ma-0(xs12 md12)
                SearchCustomer(
                  ref="SearchCustomer"
                  v-model="item.customer"
                )
            v-layout()
              v-flex.ma-0(xs6 md6)
                v-select(
                  v-if="item.customer"
                  v-model="item.customer.plug_elec"
                  :label="$t('customer.' + 'plug_elec')"
                  :rules="[(v) => !!v || $t('common.required')]"
                  :items="elecPlugs"
                  item-value="value"
                  item-text="label"
                )
              v-flex(xs6 md6)
                v-select(
                  v-if="item.customer"
                  v-model="item.customer.plug_water"
                  :label="$t('customer.' + 'plug_water')"
                  :rules="[(v) => !!v || $t('common.required')]"
                  :items="waterPlugs"
                  item-value="value"
                  item-text="label"
                )

            v-layout()
              v-flex.ma-0(xs12 md12)
                div
                  v-btn(
                    color="primary"
                    :disabled="! isNextReady()"
                    @click="next()"
                    class="ma-2"
                  ) {{$t('common.next')}}
                  v-btn.error(
                    text
                    class="ma-2"
                    v-if="item.state == 'DRAFT' && item.id != null"
                    @click="cancel()"
                  ) {{$t('common.cancel')}}
        // PERIOD
        v-stepper-content(
          :step="2"
        )
          v-container
            v-layout()
              v-flex.ma-0(xs12 md12)
                v-select(
                  :items="products"
                  item-text="label"
                  item-value="id"
                  v-model="item.product_id"
                  :label="$t(this.model.content + '.' + 'product')"
                )
            v-layout()
              v-flex.ma-0(xs6 md6)
                v-menu(
                  v-model='menuDate' 
                  :close-on-content-click='false' 
                  :nudge-right='40' 
                  transition='scale-transition' 
                  offset-y
                  min-width='290px'
                )
                  template(v-slot:activator='{ on }')
                    v-text-field(
                      v-model='formatedBegin' 
                      :label="$t('subscribtion.begin')"
                      readonly
                      v-on='on'
                    )
                  v-date-picker(
                    v-model='formatedIsoBegin' 
                    @input='menuDate = false'
                  )

              v-flex.ma-0(xs6 md6)
                v-switch(
                  v-model="item.is_auto_renew"
                  :label="$t(this.model.content + '.' + 'is_auto_renew')"
                )
            v-layout()
              v-flex.ma-0(xs12 md12)
                div
                  v-btn(
                    color="primary"
                    @click="previous()"
                    :disabled="! isPreviousAllowed()"
                    class="ma-2"
                  ) {{$t('common.previous')}}
                  v-btn(
                    color="primary"
                    :disabled="! isNextReady()"
                    @click="next()"
                    class="ma-2"
                  ) {{$t('common.next')}}
                  v-btn.error(
                    text
                    class="ma-2"
                    v-if="item.state == 'DRAFT' && item.id != null"
                    @click="cancel()"
                  ) {{$t('common.cancel')}}

        //LOCALISATION
        v-stepper-content(
          :step="3"
        )
          v-container
            v-layout()
              v-flex.ma-0(xs12 md12)
                v-select(
                  :items="plcs"
                  item-text="label"
                  item-value="id"
                  v-model="item.plc_id"
                  :label="$t(this.model.content + '.' + 'localisation')"
                )
            v-layout()
              v-flex.ma-0(xs12 md12)
                div
                  v-btn(
                    color="primary"
                    @click="previous()"
                    :disabled="! isPreviousAllowed()"
                    class="ma-2"
                  ) {{$t('common.previous')}}
                  v-btn(
                    color="primary"
                    :disabled="! isNextReady()"
                    @click="next()"
                    class="ma-2"
                  ) {{$t('common.next')}}
                  v-btn.error(
                    text
                    class="ma-2"
                    v-if="item.state == 'DRAFT' && item.id != null"
                    @click="cancel()"
                  ) {{$t('common.cancel')}}
        // paymentmethod
        v-stepper-content(
          :step="4"
        )
          v-container
            v-layout()
              v-flex.ma-0(xs12 md12)
                v-select(
                  v-model="paymentmethod"
                  :items="paymentmethods"
                  item-value="value"
                  item-text="label"
                  :label="$t('paymentmethod.title')"
                )
            v-layout()
              v-flex.ma-0(xs12 md12)
                TotalBasket(v-model="this.item.basket")
            v-layout()
              v-flex.ma-0(xs12 md12)
                div
                  v-btn(
                    color="primary"
                    @click="previous()"
                    :disabled="! isPreviousAllowed()"
                    class="ma-2"
                  ) {{$t('common.previous')}}
                  v-btn(
                    color="primary"
                    :disabled="! isNextReady()"
                    @click="next()"
                    class="ma-2"
                  ) {{$t('common.next')}}
                  v-btn.error(
                    text
                    class="ma-2"
                    v-if="item.state == 'DRAFT' && item.id != null"
                    @click="cancel()"
                  ) {{$t('common.cancel')}}
        v-stepper-content(
          :step="5"
        )
          v-container
            v-layout()
              v-flex.ma-0(xs12 md12)
                TagBasket(
                  v-if="this.item.basket != null" 
                  v-model="this.item.basket"
                  @success="onSuccessWriteCard" 
                  @error="onError"
                )
            v-layout()
              v-flex.ma-0(xs12 md12)
                div
                  v-btn(
                    color="primary"
                    @click="previous()"
                    :disabled="! isPreviousAllowed()"
                    class="ma-2"
                  ) {{$t('common.previous')}}
                  v-btn(
                    color="primary"
                    :disabled="! isNextReady()"
                    @click="finish()"
                    class="ma-2"
                  ) {{$t('common.finish')}}
                  v-btn.error(
                    text
                    class="ma-2"
                    v-if="item.state == 'DRAFT' && item.id != null"
                    @click="cancel()"
                  ) {{$t('common.cancel')}}

</template>

<script>
  import SearchCustomer from '../customer/SearchCustomer.vue'

  import {Model} from "./imports.js"

  import PLCModel from "../../model/plc/PLC.js"
  const plcModel = new PLCModel()

  import paymentMethodModel from "../../model/paymentmethod/PaymentMethod.js"

  import ExpandBasketModel from "../../model/expandbasket/ExpandBasket.js"
  const expandBasketModel = new ExpandBasketModel()

  // import FormuletModel from "../../model/formule/Formule.js"
  // const formuletModel = new FormuletModel()

  //import SubscribtionModel from "../../model/subscribtion/Subscribtion.js"
  //const subscribtionModel = new SubscribtionModel()
  
  import ProductModel from "../../model/product/Product.js"
  const productModel = new ProductModel()

  import TotalBasket from "../basket/TotalBasket.vue"
  import TagBasket from '../basket/TagBasket.vue'

  import loginStore from '../tool/LoginStore'

  import Customer from "../../model/customer/Customer.js"
  const customer = new Customer()


  export default {
    components: {
       SearchCustomer
       , TotalBasket
       , TagBasket
    },
    props: {
    },
    computed: {
      formatedBegin: function() {
        return this.formatDate(this.item.begin)
      }
      , formatedIsoBegin: {
        get: function() {
          return this.formatIsoDate(this.item.begin)
        }
        , set: function(value) {
          this.item.begin = this.parseIsoDate(value)
        }
      }
    },
    watch: {
      '$route': 'fetchData(true)',
      'item.begin': function(value) {
        console.log("begin : ", this.formatDate(value))
      },
      "item.customer": function(value) {
        if (value) {
          this.elecPlugs = customer.getElecPlugs(this)
          this.waterPlugs = customer.getWaterPlugs(this)
        }
      }
    }, 
    data: () => ({
      menuDate : false
      , begin: 0
      , model: null
      , step: 1
      , item : {basket: {}}
      , title: null
      , isLoading: false
      , plcs: []
      , paymentmethods: []
      , paymentmethod: null
      , products: []

      , elecPlugs: []
      , waterPlugs: []
    }),

    methods: {
      remainsWrite: function() {
        let result = false
        this.item.basket.tag_lines.forEach(element => {
          if ( element.read_write_id == null) {
            result = true
          }
        });
        //console.log(this.basket.tag_lines, result)
        return result
      },

      fetchData: function(updateStep = false) {
        //console.log('ici')
        this.item.id = this.$route.params.id

        if (this.item.id == null || this.item.id == 0) {
          return
        }
        const _self = this
        this.model.get(
          this.item
          , function(response) {
            _self.item = response.data

            if (updateStep) {
              let step = 1
              //console.log(_self.item)
              if (_self.item.customer_id != null) {
                step ++
                if (_self.item.product_id != null) {
                  step ++
                  if (_self.item.plc_id != null) {
                    step ++
                    if (_self.item.basket_id != null) {
                      step ++
                      if (_self.item.nfc_card_id != null) {
                        step ++
                      }
                    }
                  }
                }
              }
              _self.step = step
            }
          }
          , function() {
            this.$emit('error', 'common.not_found')
          }
        )
      },

      onSuccessWriteCard(msg) {
        this.refreshBasket()
        this.onSuccess(msg)
      },
      refreshBasket() {
        expandBasketModel.getAndSet(
          this
          , this.item.basket_id
          , "basket"
          , this.item
        )
      },
      previous() {
        if (this.step == 1) {
          return
        } else if (this.step == 2) {
          this.step--
        } else if (this.step == 3) {
          this.step--
        } else if (this.step == 4) {
          this.step--
        }
      },
      isPreviousAllowed() {
        if (this.step == 1) {
          return false
        } else if (this.step == 2) {
          return true
        } else if (this.step == 3) {
          return true
        } else if (this.step == 4) {
          return true
        }

        return false
      },
      isNextReady: function() {
        if (this.step == 1) {
          return this.item.customer != null
        } else if (this.step == 2) {
          return this.item.product_id != null
        } else if (this.step == 3) {
          return this.item.plc_id != null
        } else if (this.step == 4) {
          return this.paymentmethod != null
        } else if (this.step == 5) {
          return ! this.remainsWrite()
        }

        return false
      },
      finish: function()  {
        loginStore.redirect("/hmi/subscribtion/search")
      },
      next: function() {
        if (this.step == 1) {
          if (this.item.customer != null) {
            if(this.item.begin == null) {
              this.item.begin = new Date().getTime()
              // console.log(this.item.begin, "2020-03-09T17:26:35.355203+01:00")
              // console.log( this.formatDate(this.item.begin))
            }
            customer.updateP(this.item.customer)
            .then(() => {
              this.item.customer_id = this.item.customer.id
              this.step++
            }).catch((e) => {
              this.$emit('error', 'common.created_error', e)
            })
          }
        } else if (this.step == 2) {
          if (this.item.product_id != null) {
            this.step++
          }
        } else if (this.step == 3) {
          if (this.item.plc_id != null) {
            // this.item.plc_id = this.item.plc.id
            console.log(this.item, "=>")

            if (this.item.id == null || this.item.id == 0) {
              this.model.createAndSet(
                this
                , 'item'
                , (self/*, data*/) => {
                  //console.log(data)
                  //console.log(data.basket.total_ht, data.basket.total_ttc)
                  this.$route.params.id = this.item.id
                  self.step++
                }
              )
            } else {
              this.model.updateAndSet(
                this
                , 'item'
                , (self/*, data*/) => {
                  //console.log(data)
                  //console.log(data.basket.total_ht, data.basket.total_ttc)
                  self.step++
                }
              )
            }
          }
        } else if (this.step == 4) {
          if (this.paymentmethod != null) {
            this.item.payment_method = this.paymentmethod
            this.item.action = "SET_PAYMENT_METHOD"
  
            this.model.update(
              this.item
              , (response) => {
                this.item = response.data
                this.$emit('success', 'common.updated', this.item.basket)
                this.step++
                this.item.action = null
                this.refreshBasket()
                
                //this.basket = response.data
              }
              , (e) => {
                //console.log(e)
                this.item.action = null
                this.$emit('error', 'common.created_error', e)
              })
            //this.step++


            // this.item.basket.payment_method = this.paymentmethod
            // this.item.basket.action = "SET_PAYMENT_METHOD"
            // this.item.basket.current_tag_id = null

            // expandBasketModel.update(
            //   this.item.basket
            //   , (response) => {
            //     this.item.basket = response.data
            //     this.$emit('success', 'common.updated', this.item.basket)
            //     this.step++
            //     this.item.basket.action = null
                
            //     //this.basket = response.data
            //   }
            //   , (e) => {
            //     //console.log(e)
            //     this.item.basket.action = null
            //     this.$emit('error', 'common.created_error', e)
            //   })
            // //this.step++


          }
        }
      },
      cancel: function() {

      },

      loadModel () {
        this.model = new Model()
      },
      setup() {
        //formuletModel.fillValues(this, 'formules', {filters: {types: ['ANNUAL', 'MONTHLY', 'TRIMSTER']}}, 'isLoading')
        productModel.fillValues(this, 'products', {filters: {is_subscribtion:  true}}, 'isLoading')

        this.paymentmethods = paymentMethodModel.getPaymentMethods(this)
        plcModel.fillValues(this, 'plcs', {}, 'isLoading')
      }
    },

    created () {
      this.loadModel()
    },

    mounted () {
      this.title = this.model.content + ".title"
      this.item = this.model.defaultValues()
      this.setup()
      this.fetchData(true)
    },
  }

</script>
