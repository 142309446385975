var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": "",
      "id": "edit"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 80,
      "label": _vm.$t(this.model.content + '.' + 'name')
    },
    model: {
      value: _vm.item.name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'email'),
      "required": ""
    },
    model: {
      value: _vm.item.email,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "email", $$v);
      },
      expression: "item.email"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 20,
      "label": _vm.$t(this.model.content + '.' + 'phone')
    },
    model: {
      value: _vm.item.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "phone", $$v);
      },
      expression: "item.phone"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'country')
    },
    model: {
      value: _vm.item.country,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "country", $$v);
      },
      expression: "item.country"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 20,
      "label": _vm.$t(this.model.content + '.' + 'zip')
    },
    model: {
      value: _vm.item.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "zip", $$v);
      },
      expression: "item.zip"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'city')
    },
    model: {
      value: _vm.item.city,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "city", $$v);
      },
      expression: "item.city"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'address'),
      "filled": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.item.address,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "address", $$v);
      },
      expression: "item.address"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'legal_info'),
      "filled": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.item.legal_info,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "legal_info", $$v);
      },
      expression: "item.legal_info"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'currency')
    },
    model: {
      value: _vm.item.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "currency", $$v);
      },
      expression: "item.currency"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'nb_decimals')
    },
    model: {
      value: _vm.item.nb_decimals,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "nb_decimals", $$v);
      },
      expression: "item.nb_decimals"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'month_validity')
    },
    model: {
      value: _vm.item.month_validity,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "month_validity", $$v);
      },
      expression: "item.month_validity"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))])], 1)], 1), _c('div', {
    staticClass: "ma-5"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "block": "",
      "color": "secondary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.cleanTransactions();
      }
    }
  }, [_vm._v("Nettoyer les transactions")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }