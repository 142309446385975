<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./PLCVariablePart.pug

</template>

<script >
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  import PLCRequest from "../../model/plcrequest/PLCRequest.js"
  let plcRequestModel = new PLCRequest()

  import PLCVariable from "../../model/plcvariable/PLCVariable.js"
  let plcVariableModel = new PLCVariable()

  import NfcInfo from "../../model/nfcoperation_/NFCOperation.js"
  let nfcInfotModel = new NfcInfo()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      requests: [],
      types: [],
      card_fields: [],
      nfcinfos: []
    }),
    watch: {
      "item.parent_id": function(/*value*/){
        this.fillPLCVariables()
      }
      , "item.card_uid_field_id": function(value){
        if (value == undefined) {
          this.item.card_uid_field_id = null
        }
        //console.log(this.item)
      }
      , "item.nfcinfo_id": function(value){
        if (value == undefined) {
          this.item.nfcinfo_id = null
        }
        //console.log(this.item)
      }
    }, 

    methods: {
      prepareItemToCreate: function() {
        let _self = this

        console.log(this.item.parent_id)

        if (this.item.parent_id != null) {
          plcRequestModel.get(
            {id: this.item.parent_id}
            , response => {
              _self.item.parent = response.data
              console.log("parent", _self.item.parent)
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.not_found', e)
            }
          )
        } 
      },

      loadModel: function() {
        this.model = new Model()
      },
      fillPLCVariables: function() {
        let _self = this
        let searchVariables = {filters: {parent_id: this.item.parent_id, types: ["CARD_ID_4B", "CARD_ID_7B"]}}
        plcVariableModel.list(
          1
          , 100
          , response => {
            _self.card_fields = response.data.results
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.not_found', e)
          }
          , searchVariables
        )
      },
      setup: function(){
        let _self = this

        this.types = this.model.getTypes(this)
        //console.log(this.types)
        plcRequestModel.listAll(
          response => {
            _self.requests = response.data.results
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.not_found', e)
          }
        )

        this.fillPLCVariables()

        nfcInfotModel.listAll(
          response => {
            _self.nfcinfos = response.data.results
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
    },
  }

</script>