var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "50vw"
    },
    model: {
      value: _vm.missingPlugin,
      callback: function callback($$v) {
        _vm.missingPlugin = $$v;
      },
      expression: "missingPlugin"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "grey lighten-4 py-4 title"
  }, [_vm._v(_vm._s(_vm.$t("nfc.no_plugin_detected")))]), _c('v-card-text', [_c('div', [_vm._v(_vm._s(_vm.$t("nfc.plugin_supported_os_and_browser")))]), _c('div', [_vm.browser == 'Chrome' ? _c('a', {
    attrs: {
      "href": "https://chrome.google.com/webstore/detail/2ip-nfc-reader/ijgdeogfabemphplnccnjjnbjlggmbjh?hl=fr&authuser=1"
    }
  }, [_vm._v(_vm._s(_vm.$t("nfc.chrome_plugin")))]) : _vm._e()]), _c('div', [_vm.browser == 'Firefox' ? _c('a', {
    attrs: {
      "href": "/2ip_nfc_reader-1.9-fx.xpi"
    }
  }, [_vm._v(_vm._s(_vm.$t("nfc.firefox_plugin")))]) : _vm._e()]), _c('div', [_vm.platform == 'Windows' ? _c('a', {
    attrs: {
      "href": "/Setup_NFC_Reader_1.6.exe"
    }
  }, [_vm._v(_vm._s(_vm.$t("nfc.windows_native")))]) : _vm._e()]), _c('div', [_vm.platform == 'Linux' ? _c('a', {
    attrs: {
      "href": "/2i+Process+Installateur.deb"
    }
  }, [_vm._v(_vm._s(_vm.$t("nfc.linux_native")))]) : _vm._e()])]), _c('v-card-actions', [_c('v-spacer', [_c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.missingPlugin = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.close_dialog")))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }