

















































import { TransactionFilter } from "@/components/model/transaction/TransactionService";
import { Vue, Component } from "vue-property-decorator";

import DialogSelectDate from "@/components/hmi/nfccard/DialogSelectDate.vue";

import MapFilter, { MapFilterModel } from "@/components/hmi/map/MapFilter.vue";

@Component({
    components: {
        DialogSelectDate,
        MapFilter,
    },
})
export default class TransactionFilterDialog extends Vue {
    filter: TransactionFilter = {
        isCurrent: true,
        isHistory: true,
        isWater: true,
        isElec: true,
        isNoQty: true,
        customer_id: null,
        begin: null,
        end: null,
    };

    show: boolean = false;
    resolve: ((filter: TransactionFilter | null) => void) | null = null;

    updateFilter(mapFilter: MapFilterModel): void {
        this.filter.customer = mapFilter.customer;
        this.filter.customer_id = mapFilter.customer?.id ?? null;
    }

    //- v-btn(color="primary" icon @click="showDialogSelectDate()")
    //-   v-icon mdi-pencil

    get dialogSelectDate(): DialogSelectDate {
        return this.$refs.DialogSelectDate as DialogSelectDate;
    }

    get mapFilter(): MapFilter {
        return this.$refs.MapFilter as MapFilter;
    }

    removeBeginDate(): void {
        this.filter.begin = null;
    }

    removeEndDate(): void {
        this.filter.end = null;
    }

    async pickBeginDate(): Promise<void> {
        const date:
            | string
            | null = await this.dialogSelectDate.showDialogSelectDate(
            "Date de début",
            this.filter.begin
        );
        if (date) {
            this.filter.begin = date;
            console.log("begin", this.filter.begin);
        }
    }

    async pickEndDate(): Promise<void> {
        const date:
            | string
            | null = await this.dialogSelectDate.showDialogSelectDate(
            "Date de fin",
            this.filter.end
        );
        if (date) {
            this.filter.end = date;
            console.log("end", this.filter.end);
        }
    }

    showDialog(
        currentFilter: TransactionFilter
    ): Promise<TransactionFilter | null> {
        return new Promise((resolve) => {
            this.show = true;
            this.filter = { ...currentFilter };
            this.$nextTick(() => {
                this.mapFilter.setCustomer(this.filter.customer);
            });
            this.resolve = resolve;
        });
    }

    ok(): void {
        if (this.resolve) {
            this.resolve(this.filter);
        }
        this.show = false;
    }

    cancel(): void {
        if (this.resolve) {
            this.resolve(null);
        }
        this.show = false;
    }
}
