var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "value": _vm.overlay
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('div', [_c('v-stepper', {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 1,
      "step": "1"
    }
  }, [_vm._v("Choisir un service")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 2,
      "step": "2"
    }
  }, [_vm._v("Ajouter des services")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 3,
      "step": "3"
    }
  }, [_vm._v("Payer")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 4,
      "step": "4"
    }
  }, [_vm._v("Ecrire le badge")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('div', [_c('v-select', {
    attrs: {
      "label": "Type de service",
      "items": _vm.serviceTypes,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.action,
      callback: function callback($$v) {
        _vm.action = $$v;
      },
      expression: "action"
    }
  })], 1), [_vm.ACTION_CARD_UNITS, _vm.ACTION_CARD_TIME, _vm.ACTION_CARD_ACTIVATION].includes(_vm.action) ? _c('div', [_c('v-select', {
    staticClass: "my-4",
    attrs: {
      "label": "Service",
      "items": _vm.availableCards,
      "item-value": "id",
      "item-text": "description"
    },
    model: {
      value: _vm.selectedProductId,
      callback: function callback($$v) {
        _vm.selectedProductId = $$v;
      },
      expression: "selectedProductId"
    }
  }), _c('SearchCustomer', {
    ref: "SearchCustomer",
    staticClass: "my-4",
    attrs: {
      "value": _vm.basket.customer
    },
    on: {
      "selected": _vm.setCustomer
    }
  })], 1) : _vm._e(), [_vm.ACTION_RELOAD].includes(_vm.action) ? _c('div', [_c('div', [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Badge à recharger: ")])]), _c('v-card', {
    staticClass: "pa-2 my-2",
    attrs: {
      "width": "550"
    }
  }, [_vm.readState.mode == null ? _c('v-card-text', [_c('div', [_vm._v("Le badge n'a pas été lu ")])]) : _vm._e(), _vm.readState.mode == 0 ? _c('v-card-text', [_c('div', [_vm._v("Le badge est vide ")])]) : _vm._e(), _vm.readState.mode > 0 ? _c('v-card-title', {
    staticClass: "py-0 justify-center"
  }, [_c('strong', [_vm._v(_vm._s(_vm.getCustomerShortdesc(_vm.readedCustomer)))])]) : _vm._e(), _vm.readState.mode > 0 ? _c('v-card-title', {
    staticClass: "py-0 justify-center"
  }, [[1].includes(_vm.readState.mode) ? _c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("Badge avec crédits ")]) : _vm._e(), [2].includes(_vm.readState.mode) ? _c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("Badge avec temps ")]) : _vm._e(), [3].includes(_vm.readState.mode) ? _c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("Badge d'activation ")]) : _vm._e()]) : _vm._e(), _vm.readState.mode > 0 ? _c('v-card-title', {
    staticClass: "py-0 justify-center"
  }, _vm._l(_vm.readState.id, function (e, index) {
    return _c('span', {
      key: index,
      staticClass: "subtitle-2"
    }, [index > 0 ? _c('span', [_vm._v("-")]) : _vm._e(), _c('span', [_vm._v(_vm._s(('0' + (e & 0xFF).toString(16)).slice(-2)))])]);
  }), 0) : _vm._e(), _vm.readState.mode > 0 ? _c('v-card-text', [_c('v-divider', {
    staticClass: "my-2"
  }), [1, 2].includes(_vm.readState.mode) ? _c('div', [_c('div', {
    staticClass: "py-1"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Eau:")]), _vm.readState.mode == 2 ? _c('span', [_c('span', [_vm._v(_vm._s(_vm.formatDuration(_vm.readState.nfcWater)))])]) : _c('span', [_c('span', [_vm._v(_vm._s(_vm.formatFloat(_vm.readState.nfcWater, 0, "L")))])]), _vm.readState.isWaterOnBox ? _c('span', {
    staticClass: "ml-2 caption error--text"
  }, [_vm._v("Réservé sur borne " + _vm._s(_vm.readState.box) + ": qté <= " + _vm._s(_vm.formatFloat(_vm.readState.boxWater, 0, "L")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "py-1"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Electricité:")]), _vm.readState.mode == 2 ? _c('span', [_c('span', [_vm._v(_vm._s(_vm.formatDuration(_vm.readState.nfcElec)))])]) : _c('span', [_c('span', [_vm._v(_vm._s(_vm.formatFloat(_vm.readState.nfcElec, 0, "kw/h")))])]), _vm.readState.isElecOnBox ? _c('span', {
    staticClass: "ml-2 caption error--text"
  }, [_vm._v("Réservé sur borne " + _vm._s(_vm.readState.box) + ": qté <= " + _vm._s(_vm.formatFloat(_vm.readState.boxElec, 0, "kw/h")) + " ")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "py-1"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Douches:")]), _c('strong', [_vm._v(_vm._s(_vm.formatFloat(_vm.readState.nbShowers, 0, "Unités")))])]), _c('div', {
    staticClass: "py-1"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Parking:")]), _c('strong', [_vm._v(_vm._s(_vm.readState.parkingEnd == 0 ? "" : _vm.formatDate(_vm.readState.parkingEnd)))])])], 1) : _vm._e()], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.readCard();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1) : _vm._e()]), _c('v-card-actions', [_c('v-spacer'), _vm.basket.state == 'DRAFT' && _vm.basket.id != null ? _c('v-btn', {
    staticClass: "error ma-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.cancelBasket();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.cancel')))]) : _vm._e(), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "disabled": !_vm.isStepOk(1),
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.gotoStep(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.next')))])], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-text', [_c('DetailBasket', {
    ref: "DetailBasket",
    attrs: {
      "theBasket": _vm.basket,
      "allProducts": _vm.products
    },
    on: {
      "success": _vm.success,
      "error": _vm.error
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _vm.basket.state == 'DRAFT' && _vm.basket.id != null ? _c('v-btn', {
    staticClass: "error ma-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.cancelBasket();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.cancel')))]) : _vm._e(), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.gotoStep(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.previous')))]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "disabled": _vm.basket.tag_lines.length <= 0 || _vm.basket.tag_lines[0].lines.length <= 0,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.gotoStep(3);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.next')))])], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-text', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md2": ""
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Gratuit",
      "input-value": _vm.basket.payment_method == 'FREE'
    },
    on: {
      "change": _vm.freeChanged
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md8": ""
    }
  }, [_c('v-select', {
    attrs: {
      "required": "",
      "disabled": _vm.basket.payment_method == 'FREE',
      "items": _vm.paymentmethods,
      "item-value": "value",
      "item-text": "label",
      "label": _vm.$t('paymentmethod.title')
    },
    on: {
      "change": _vm.paymentMethodChanged
    },
    model: {
      value: _vm.basket.payment_method,
      callback: function callback($$v) {
        _vm.$set(_vm.basket, "payment_method", $$v);
      },
      expression: "basket.payment_method"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('TotalVat', {
    staticClass: "pr-2",
    attrs: {
      "basket": _vm.basket
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('TotalBasket', {
    staticClass: "pl-2",
    attrs: {
      "showTitle": true
    },
    model: {
      value: _vm.basket,
      callback: function callback($$v) {
        _vm.basket = $$v;
      },
      expression: "basket"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _vm.basket.state == 'DRAFT' && _vm.basket.id != null ? _c('v-btn', {
    staticClass: "error ma-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.cancelBasket();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.cancel')))]) : _vm._e(), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.gotoStep(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.previous')))]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "disabled": _vm.basket.payment_method == null,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.gotoStep(4);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.next')))])], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_c('v-card', {
    staticClass: "elevation-0"
  }, [_vm.basket.lines_to_write > 0 ? _c('v-card-text', [_c('NFCCardContent', {
    ref: "NFCCardContent",
    attrs: {
      "nfcState": _vm.editState
    }
  })], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _vm.basket.lines_to_write > 0 && _vm.basket.id != null && _vm.isActiveBasket() ? _c('v-btn', {
    staticClass: "error ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.putAside();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('basket.put_aside')))]) : _vm._e(), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "disabled": !_vm.remainsWrite(),
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.write();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('basket.finish')))])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('CreateCustomerDialog', {
    ref: "CreateCustomerDialog",
    on: {
      "success": _vm.successCreateCustomer,
      "error": _vm.errorCreateCustomer
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "550"
    },
    model: {
      value: _vm.showCancelBasket,
      callback: function callback($$v) {
        _vm.showCancelBasket = $$v;
      },
      expression: "showCancelBasket"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t('common.cancel')))]), _c('v-card-text', [_vm._v(_vm._s(_vm.$t('basket.cancel_question')))]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.cancelDelete($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.cancel')))]), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.confirmDelete($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.delete')))]), _vm.isActiveBasket() ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.putAside($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('basket.put_aside')))]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }