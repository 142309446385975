<template lang="pug">
  include ../tool/CommonList.pug

</template>


<script>
  import {Model, EditDialog} from "./imports.js"
  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog//, ConfirmDelete
    },
    data: () => ({
      
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        this.model = new Model()
      },


      isViewAllowed() {
        return false
      },
      setup () {
        this.hidesearch = true
 
        this.headers = [
          {align: "start", width: "10", text: this.model.content + '.' + 'pos', value: 'pos', sortable: false},
          {align: "end", width: "10", text: this.model.content + '.' + 'qty', value: 'qty', sortable: false},
          {align: "start", width: "10", text: 'product.unit', value: 'unit', sortable: false},
          {align: "start", width: "100", text: this.model.content + '.' + 'product', value: 'product', sortable: false},
  
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ],

        this.columns = [
          {class: "handle", text: '::'},
          {class: "text-right", value: 'qty'},
          {class: "text-left", value: 'product.unit'},
          {class: "text-left", value: 'product.label'},

          {class: "text-right", value: 'created_date', format: this.formatDatetime},
        ]
      },
      
    },

  }

</script>
