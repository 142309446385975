<template lang="pug">
  extends ../tool/CommonDetail.pug
  block detail
    ListPLCs(:com_id="this.$route.params.id" @success="success" @error="error")
</template>

<script>
  import CommonDetail from "../tool/CommonDetail.js"

  import {Model, EditDialog} from "./imports.js"

  import ListPLCs from "../plc/ListPLCs.vue"

  export default {
    extends: CommonDetail,
    components: {
      ListPLCs, Infos: EditDialog
    },
    props: {
    },
    computed: {
    },
    watch: {

    }, 
    data: () => ({
    }),

    methods: {
      loadModel () {
        this.model = new Model()
      },
    },
  }

</script>
