<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./GatewayPart.pug

</template>

<script >
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
    }),
    watch: {
    }, 

    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){

      }
    },
  }

</script>