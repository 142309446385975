var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "1",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'qty'),
      "prefix": _vm.item.product == null ? '?' : _vm.item.product.unit
    },
    model: {
      value: _vm.item.qty,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "qty", $$v);
      },
      expression: "item.qty"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.products,
      "item-text": "label",
      "item-value": "id",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'product'),
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.product_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "product_id", $$v);
      },
      expression: "item.product_id"
    }
  })], 1)], 1)], 1)])], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }