import Model from "../Model.js"
import rest from '../../hmi/tool/rest.js'

export default class Transaction extends Model {
    constructor() {
        super("transaction")
    }

}


