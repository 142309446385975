export class WriteError extends Error {

}

const PLUGIN_MISSING_ERROR = "nfc.PLUGIN_MISSING_ERROR"
enum STATUS {
  PORT_NOT_FOUND_ERROR = "PORT_NOT_FOUND_ERROR",
  INIT = "-1",
  OK =  "0",
  INPROGRESS =  "1",
  ERROR_ACCESS_TAG =  "2",
  ERROR_TYPE_TAG =  "3",
  ERROR_SELECT_TAG =  "4",
  ERROR_COMMAND =  "5",
  ERROR_LOG_TAG =  "6",
  ERROR_READ_TAG =  "7",
  ERROR_WRITE_TAG =  "8",
  ERROR_SIZE_OVER_LIMIT =  "9",
  ERROR_SECTOR_TAG =  "10",
  ERROR_BLOCK_TAG =  "11",
  ERROR_SECTOR_SIZE =  "12",
  ERROR_BLOCK_SIZE =  "13",
  ERROR_SECTOR_TRAILER =  "14",
}

const KEY: string = "FFFFFFFFFFFF"

const DATA_SIZE = 1024

const BYTES_PER_BLOCK  = 16
const MIN_BLOCK = 0
const MAX_BLOCK = 16 * 16

// block 0
const ID_BLOCK = 0
const ID_POS = 0
const ID_SIZE = 4

// block 1

const BOX_MODE_BLOCK = 1
const BOX_MODE_POS = 1
const BOX_MODE_SIZE = 1

const IS_ELEC_ON_BOX_BLOCK = 1
const IS_ELEC_ON_BOX_POS = 2
//const IS_ELEC_ON_BOX_SIZE = 1

const IS_WATER_ON_BOX_BLOCK = 1
const IS_WATER_ON_BOX_POS = 3
//const IS_WATER_ON_BOX_SIZE = 1

const CUSTOMER_ID_BLOCK = 1
const CUSTOMER_ID_POS = 4
const CUSTOMER_ID_SIZE = 4

const NFC_ELEC_BLOCK = 1
const NFC_ELEC_POS = 4
const NFC_ELEC_SIZE = 4

const NFC_WATER_BLOCK = 1
const NFC_WATER_POS = 8
const NFC_WATER_SIZE = 4

const BOX_BLOCK = 1
const BOX_POS = 12
const BOX_SIZE = 2

const TYPE_ELEC_BLOCK = 1
const TYPE_ELEC_POS = 14
const TYPE_ELEC_SIZE = 1

const TYPE_WATER_BLOCK = 1
const TYPE_WATER_POS = 15
const TYPE_WATER_SIZE = 1

//! block 2
const GATE_ACCESS_BLOCK = 2
const GATE_ACCESS_POS = 1
const GATE_ACCESS_SIZE = 8

//! block 4
const SHOWER_ACCESS_BLOCK = 4
const SHOWER_ACCESS_POS = 1
const SHOWER_ACCESS_SIZE = 2

const SHOWER_NB_BLOCK = 4
const SHOWER_NB_POS = 3
const SHOWER_NB_SIZE = 2

//! block 5
const PARKING_ACCESS_BLOCK = 5
const PARKING_ACCESS_POS = 1
const PARKING_ACCESS_SIZE = 2

const PARKING_END_BLOCK = 5
const PARKING_END_POS = 3
const PARKING_END_SIZE = 4


// block 6
const DATE_VALIDITY_IN_S_BLOCK = 6
const DATE_VALIDITY_IN_S_POS = 0
const DATE_VALIDITY_IN_S_SIZE = 4

const MAINT_BLOCK = 6
const MAINT_POS = 4
const MAINT_SIZE = 1

// block 8
const BOX_ELEC_BLOCK = 8
const BOX_ELEC_POS = 0
const BOX_ELEC_SIZE = 4

const BOX_WATER_BLOCK = 8
const BOX_WATER_POS = 4
const BOX_WATER_SIZE = 4


// block 16
const ALWAYS_CUSTOMER_ID_BLOCK = 9
const ALWAYS_CUSTOMER_ID_POS = 0
const ALWAYS_CUSTOMER_ID_SIZE = 4


const ALL_BLOCKS: number[] = [
  ID_BLOCK,

  BOX_MODE_BLOCK,
  IS_ELEC_ON_BOX_BLOCK,
  IS_WATER_ON_BOX_BLOCK,
  CUSTOMER_ID_BLOCK,
  NFC_ELEC_BLOCK,
  NFC_WATER_BLOCK,
  BOX_BLOCK,
  TYPE_ELEC_BLOCK,
  TYPE_WATER_BLOCK,
  
  GATE_ACCESS_BLOCK,
  SHOWER_ACCESS_BLOCK,
  SHOWER_NB_BLOCK,
  PARKING_ACCESS_BLOCK,
  PARKING_END_BLOCK,

  DATE_VALIDITY_IN_S_BLOCK,
  MAINT_BLOCK,

  BOX_ELEC_BLOCK,
  BOX_WATER_BLOCK,

  ALWAYS_CUSTOMER_ID_BLOCK
]

const set: Set<number>  =  new Set(ALL_BLOCKS)
const READ_UNIQ_BLOCKS = [...set]
const READ_UNIQ_SECTORS: Set<number> = new Set(READ_UNIQ_BLOCKS.map((b) => Math.floor(b / 4)))

set.delete(ID_BLOCK)
const WRITE_UNIQ_BLOCKS = [...set]
const WRITE_UNIQ_SECTORS: Set<number> = new Set(WRITE_UNIQ_BLOCKS.map((b) => Math.floor(b / 4)))

interface Sector {
  blocks: {data: string}
}

const context: {isInit: boolean, callback: ((sectors: Sector[]) => void )| null, timeout: any} = {
  isInit: false
  , callback: null
  , timeout: null
}

export type BooleanValue = {
  value: boolean
}

export interface NfcCardModel {
  lastNfcModification: number

  key: number[] // 6 bytes

  data: (number | null)[] // 16 blocks * 4 sub blocks * 16 bytes => 1024 bytes

  id: (number | null)[] // 4 bytes

  mode: number | null
  isElecOnBox: boolean | null //1: on box, 0: on NFC
  isWaterOnBox: boolean | null //1: on box, 0: on NFC
  customerId: number | null
  nfcElec: number | null // int
  nfcWater: number | null // int
  box: number | null // unsigned short
  typeElec: number | null // unsigned char
  typeWater: number | null // unsigned char

  dateValidityInS: number | null // unsigned long: 32bits
  maint: number | null // char

  boxElec: number | null // int
  boxWater: number | null // int

  nbShowers: number | null

  showerAccess: BooleanValue[]

  parkingAccess: BooleanValue[]
  parkingEnd: number | null

  access: BooleanValue[]

  alwaysCustomerId: number | null

  // comment1: string | null
  // comment2: string | null
  // comment3: string | null
  // concatComment: string | null
}

// export interface NfcCardState {
//   readState: NfcCardModel
//   editState: NfcCardModel
// }

export class NFCCardService {

  // state: NfcCardState = {
  //   readState: this.getDefault(),
  //   editState: this.getDefault(),
  // }

  public getDefault(): NfcCardModel {
    const res: NfcCardModel = {
      lastNfcModification: 0,
      key: [0x00, 0x00, 0x00, 0x00, 0x00, 0x00],
      data: [],

      id: [],
      mode: null,
      isElecOnBox: false,
      isWaterOnBox: false,
      nfcElec: 0,
      nfcWater: 0,
      box: 0,
      typeElec: 1,
      typeWater: 1,

      dateValidityInS: null,
      maint: null,

      boxElec: 0,
      boxWater: 0,

      nbShowers: 0,
      showerAccess: [
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
      ],

      parkingAccess: [
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
        {value: false},
      ],
      parkingEnd: 0,

      access: [],
      

      customerId: null,

      alwaysCustomerId: null,
      // showerAccess: null,
      // comment1: null,
      // comment2: null,
      // comment3: null,
      // concatComment: null,
    }
    for (let i = 0; i < 64; i++) {
      res.access.push({value: false})
    }
    return res
  }

  private checkWrite(value: BooleanValue[] | (number | null)[] | /*bigint |*/ number | string | null, block: number, pos: number, size: number): void {
    if (value === null) {
      throw new WriteError(`Valeur non valide, ${block}, ${pos}, ${size}`)
    }
    if (block < MIN_BLOCK || block >= MAX_BLOCK) {
      throw new WriteError(`Block non valide, ${block}, ${pos}, ${size}`)
    }
    if (block == ID_BLOCK) {
      // throw new WriteError("Block réservé pour le fabricant")
    }
    if (block % 4 == 3) {
      throw new WriteError("Block réservé pour les droits")
    }
    if (pos < 0 || pos >= BYTES_PER_BLOCK) {
      throw new WriteError("Hors de position")
    }
    if (pos + size > BYTES_PER_BLOCK) {
      throw new WriteError("Taille limite dépassée")
    }
  }

  private writeBytes(state: NfcCardModel, value: (number | null)[], block: number, pos: number, size: number): void {
    this.checkWrite(value, block, pos, size)

    let i = 0
    for (const v of value) {
      if (v === null) {
        throw new WriteError("Impossible de traiter la valeur null")
      }
      state.data[block * BYTES_PER_BLOCK + pos + i] = v
      i++
    }
    
  }

  private writeFlags(state: NfcCardModel, value: BooleanValue[], block: number, pos: number, size: number): void {
    this.checkWrite(value, block, pos, size)

    for (let i = 0 ; i < size; i++) {
      let b: number = 0
      for (let j = 0; j < 8; j++) {
        if (value[i*8+j].value) {
          b += 1 << j
        }
      }
      state.data[block * BYTES_PER_BLOCK + pos + i] = b
    }
  }

  private writeLittleEndian(state: NfcCardModel, value: number | null, block: number, pos: number, size: number): void {
    this.checkWrite(value, block, pos, size)

    for (let i = 0; i < size; i++) {
      const b: number = (value! & (0xFF << (i * 8))) >> (i*8)
      state.data[block * BYTES_PER_BLOCK + pos + i] = b
    }
  }

  writeString(state: NfcCardModel, value: string | null, block: number, pos: number, size: number): void {
    console.log("write string ", value)
    this.checkWrite(value, block, pos, size)

    for (let i = 0; i < size; i++) {
      let b = 0
      if (i < value!.length) {
        b = value!.charCodeAt(i)
      }
      state.data[block * BYTES_PER_BLOCK + pos + i] = b
    }
  }

  private fillData(state: NfcCardModel) {
    if(
      state.id != null
      && state.id.length >= 4
      && state.id[0] != null
      && state.id[1] != null
      && state.id[2] != null
      && state.id[3] != null
    ) {

      this.writeBytes(state, state.id, ID_BLOCK, ID_POS, ID_SIZE)
    }


    this.writeBytes(state, [state.isElecOnBox ? 1 : 0], IS_ELEC_ON_BOX_BLOCK, IS_ELEC_ON_BOX_POS, 1)
    this.writeBytes(state, [state.isWaterOnBox ? 1 : 0], IS_WATER_ON_BOX_BLOCK, IS_WATER_ON_BOX_POS, 1)

    this.writeLittleEndian(state, state.mode, BOX_MODE_BLOCK, BOX_MODE_POS, BOX_MODE_SIZE)
    if (state.mode != null && [1, 2].includes(state.mode) ) {
      this.writeLittleEndian(state, state.nfcElec, NFC_ELEC_BLOCK, NFC_ELEC_POS, NFC_ELEC_SIZE)
      this.writeLittleEndian(state, state.nfcWater, NFC_WATER_BLOCK, NFC_WATER_POS, NFC_WATER_SIZE)
    } else if (state.mode != null &&  [3].includes(state.mode)) {
      this.writeLittleEndian(state, state.customerId, CUSTOMER_ID_BLOCK, CUSTOMER_ID_POS, CUSTOMER_ID_SIZE)
    }
    this.writeLittleEndian(state, state.box, BOX_BLOCK, BOX_POS, BOX_SIZE)
    this.writeLittleEndian(state, state.typeElec, TYPE_ELEC_BLOCK, TYPE_ELEC_POS, TYPE_ELEC_SIZE)
    this.writeLittleEndian(state, state.typeWater, TYPE_WATER_BLOCK, TYPE_WATER_POS, TYPE_WATER_SIZE)

    this.writeFlags(state, state.access, GATE_ACCESS_BLOCK, GATE_ACCESS_POS, GATE_ACCESS_SIZE)

    this.writeFlags(state, state.showerAccess, SHOWER_ACCESS_BLOCK, SHOWER_ACCESS_POS, SHOWER_ACCESS_SIZE)
    this.writeLittleEndian(state, state.nbShowers, SHOWER_NB_BLOCK, SHOWER_NB_POS, SHOWER_NB_SIZE)

    this.writeFlags(state, state.parkingAccess, PARKING_ACCESS_BLOCK, PARKING_ACCESS_POS, PARKING_ACCESS_SIZE)
    this.writeLittleEndian(state, state.parkingEnd, PARKING_END_BLOCK, PARKING_END_POS, PARKING_END_SIZE)

    this.writeLittleEndian(state, state.dateValidityInS, DATE_VALIDITY_IN_S_BLOCK, DATE_VALIDITY_IN_S_POS, DATE_VALIDITY_IN_S_SIZE)
    this.writeLittleEndian(state, state.maint, MAINT_BLOCK, MAINT_POS, MAINT_SIZE)

    this.writeLittleEndian(state, state.boxElec, BOX_ELEC_BLOCK, BOX_ELEC_POS, BOX_ELEC_SIZE)
    this.writeLittleEndian(state, state.boxWater, BOX_WATER_BLOCK, BOX_WATER_POS, BOX_WATER_SIZE)

    for (let i = 0; i < BYTES_PER_BLOCK; i++) {
      this.writeBytes(state, [0], ALWAYS_CUSTOMER_ID_BLOCK, i, 1)
    }
    this.writeLittleEndian(state, state.alwaysCustomerId, ALWAYS_CUSTOMER_ID_BLOCK, ALWAYS_CUSTOMER_ID_POS, ALWAYS_CUSTOMER_ID_SIZE)
  }

  async write(state: NfcCardModel, idToCheck: (number | null)[] | null, cbCheck: ((readState: NfcCardModel) => string | true) | null): Promise<void> {
    const readState: NfcCardModel = this.getDefault()
    await this.read(readState)
    if (idToCheck) {
      try {
        const id: (number|null)[] =  [... idToCheck]
        if (! readState.id || readState.id.length !=  id.length) {
          throw new WriteError("L'ID du badge NFC lu et l'ID du badge à écrire ne correspondent pas")
        }
        for (let i = 0; i < ID_SIZE; i++) {
          if (id[i] == null || id[i] != readState.id[i]) {
            throw new WriteError("L'ID du badge NFC lu et l'ID du badge à écrire ne correspondent pas")
          }
        }
      } catch(e: any) {
        throw new WriteError("L'ID du badge NFC n'a pas pu être vérifié")
      }
    }
    if (cbCheck) {
      let resCheck = cbCheck(readState)
      if (resCheck != true) {
        throw new WriteError(resCheck)
      }
    }

    // this.state.editState.dateValidityInS = Math.floor(new Date().getTime() / 1000) + 3600 * 24 * 31 * 6
    this.fillData(state)
    let writeRequest: {
      fct: string,
      key: string,
      sectors: {
        num: number, 
        blocks: {
          num: number,
          data: string
        }[]
      }[]
    } = { 
      fct: "write"
      , key: KEY
      , sectors: []
    }
    writeRequest.sectors = []
    for (const sector of WRITE_UNIQ_SECTORS) {
      writeRequest.sectors.push({num: sector, blocks: []})
    }
    for (const block of WRITE_UNIQ_BLOCKS) {
      const sector = Math.floor(block / 4)
      const blockData: number[] = state.data.slice(block * BYTES_PER_BLOCK, (block+1)* BYTES_PER_BLOCK) as number[]
      let data = ""
      for (const b of blockData) {
        data += ('0' + (b & 0xFF).toString(16)).slice(-2)
      }
      writeRequest.sectors[sector].blocks.push({num: block, data: data})
    }
    await this.writePart(writeRequest)

    try {
      await this.read(state)
    } catch (error) {
      throw new WriteError("Erreur lors de la relecture du badge")
    }

  //   const key = this.getKey(k)
  //   this.fillData(this.updatedState)
  //   return messageModule.executeWrite(async () => {
  //     await this.checkId(port, key, id)
  //     await this.writePart({
  //       port: port,
  //       key: key as number[],
  //       blocks: WRITE_UNIQ_BLOCKS // [-1]
  //     })
  //   })
  // }

  }

  async read(state: NfcCardModel): Promise<void> {
    this.cleanData(state)

    let request = { 
      fct: "read_part"
      , key: KEY
      , sectors: [
          {
              num: 0
              , blocks: [
                  {num: 0}
              ]
          }
      ]
    }
    request.sectors = []
    for (const sector of READ_UNIQ_SECTORS) {
      request.sectors.push({num: sector, blocks: []})
    }
    for (const block of READ_UNIQ_BLOCKS) {
      const sector = Math.floor(block / 4)
      request.sectors[sector].blocks.push({num: block})
    }
    const sectors = await this.readPart(state, request)
    this.fillValues(state)
  }

  public copyFromValues(src: NfcCardModel, dest: NfcCardModel): void {
    this.cleanData(src)
    console.log("object", src.dateValidityInS)
    this.fillData(src)
    this.copyFromData(src, dest)
    this.fillValues(dest)
  }

  public copyFromData(src: NfcCardModel, dest: NfcCardModel): void {
    this.cleanData(dest)
    dest.data = [...src.data]
  }

  private extractNfcError(status: STATUS): string {
    let error: string = ""
    switch (status) {
      case STATUS.INPROGRESS:
        error = "Pas de réponse"
        break
      case STATUS.ERROR_ACCESS_TAG:
        error = "Pas de badge présent"
        break
      case STATUS.ERROR_TYPE_TAG:
        error = "Mauvais type de badge"
        break
      case STATUS.ERROR_SELECT_TAG:
        error = "Impossible de sélectionner le badge"
        break
      case STATUS.ERROR_COMMAND:
        error = "Commande inconnue"
        break
      case STATUS.ERROR_LOG_TAG:
        error = "Clé non valide"
        break
      case STATUS.ERROR_READ_TAG:
        error = "Erreur de lecture"
        break
      case STATUS.ERROR_WRITE_TAG:
        error = "Erreur d'écriture"
        break
      case STATUS.ERROR_SIZE_OVER_LIMIT:
        error = "Trop de données demandées"
        break
      case STATUS.ERROR_SECTOR_TAG:
        error = "Ce secteur n'est pas valide"
        break
      case STATUS.ERROR_BLOCK_TAG:
        error = "Ce bloque n'est pas valide"
        break
      case STATUS.ERROR_SECTOR_SIZE:
        error = "Ce secteur n'est pas valide"
        break
      case STATUS.ERROR_BLOCK_SIZE:
        error = "Ce bloque n'est pas valide"
        break
      case STATUS.ERROR_SECTOR_TRAILER:
        error = "Les trailers de chaque secteur ne peuvent pas être écrits"
        break
      case STATUS.PORT_NOT_FOUND_ERROR:
        error = "Le lecteur NFC n'est pas branché" 
        break
      default:
        error = "Erreur inconnue : " + status
        break
    }
    return error
  }

  async writePart(request: any): Promise<Sector[]> {
    console.log(request)
    return new Promise((resolve, reject) => {
      this.sendNativeMessage(request)
      .then(
        (response: any) => {
          console.log("response", response)
          if (response == null) {
              reject(new Error("Le plugin n'a pas été trouvé"))
          } else if (response.fct != request.fct || response.status != "OK") {
              console.log("error", response)
              reject(new Error(this.extractNfcError(response.status)))
          } else {
              resolve(response)
          }
        }
      )
    })
  }

  async readPart(nfcCard: NfcCardModel, request: any): Promise<Sector[]> {
    return new Promise((resolve, reject) => {
      this.sendNativeMessage(request)
      .then(
        (response: any) => {
          if (response == null) {
              reject(new Error("Le plugin n'a pas été trouvé"))
          } else if (response.fct != request.fct || response.status != "OK") {
              console.log("error", response)
              reject(new Error(this.extractNfcError(response.status)))
          } else {
              for (const sector of response.sectors) {
                for (const block of sector.blocks) {
                  for (let c = 0; c < block.data.length; c += 2) {
                    nfcCard.data[block.num * BYTES_PER_BLOCK + c / 2] = parseInt(block.data.substr(c, 2), 16)
                  }
                }
              }
              resolve(response.sectors)
          }
        }
      )
    })
  }

  async sendNativeMessage(data: any) {
    return new Promise((resolve, reject) => {
      try {
        if (! context.isInit) {
          document.addEventListener(
            "ExtensionAnswerEvent",
            (e: any) => {
              if (context.timeout) {
                clearTimeout(context.timeout)
              }
              context.timeout = null
              const sData = e.target.getAttribute("data")
              try {
                document.documentElement.removeChild(e.target)
              } catch (e) {
                console.log(e)
              }
              
              var data = JSON.parse(sData)
              
              let cb = context.callback
              context.callback = null
              if (cb) {
                cb(data)
              }
            }
            , false
            //, true
          )
          context.isInit = true
        }

        if (context.callback != null) {
          data.status = "ERROR_BUSY"
          context.callback(data)
          return
        }

        context.callback = resolve

        var element = document.createElement("ExtensionRequestElement")
        element.setAttribute("data", JSON.stringify(data))
        document.documentElement.appendChild(element)

        var evt = document.createEvent("Events")
        evt.initEvent("ExtensionRequestEvent", true, false)

        context.timeout = setTimeout( 
          () => { 
            reject(new Error("Timeout"))
          }
          , 6000
        )
        element.dispatchEvent(evt)
      }
      catch(error) {
        reject(error)
      }
    })
  }


  private getBytes(state: NfcCardModel, block: number, pos: number, size: number): (number | null) [] {
    const result: (number | null)[] = []

    const start: number = (block * BYTES_PER_BLOCK) + pos
    const end: number = start + size

    for (let i = start; i < end; i++) {
      result.push(state.data[i])
    }

    return result
  }

  private getString(state: NfcCardModel, block: number, pos: number, size: number): string | null {
    let result: string = ""

    const start: number = (block * BYTES_PER_BLOCK) + pos
    const end: number = start + size

    for (let i = start; i < end; i++) {
      const v = state.data[i]
      if (v === null) {
        return null
      }
      if (v === 0) {
        return result
      }
      result += String.fromCharCode(v as number)
    }

    return result
  }

  private getFlags(state: NfcCardModel, block: number, pos: number, size: number): BooleanValue[] {
    const invRres: BooleanValue[] = []

    const res: BooleanValue[] = []

    for (let i = 0; i < size; i++) {
      const b: number | null = this.getByte(state, block, pos + i)
      if (b == null) {
        return invRres
      }

      for (let j = 0; j < 8; j++) {
        res.push({value: (b & (1 << j)) != 0})
      }
    }
    
    return res
  }

  private getIntLittleEndian(state: NfcCardModel, block: number, pos: number, size: number): number | null {
    let result: number = 0

    const start: number = (block * BYTES_PER_BLOCK) + pos
    const end: number = start + size

    for (let i = start; i < end; i++) {
      const v = state.data[i]
      if (v === null) {
        return null
      }
      result += v << (8 * (i-start))
    }

    return result
  }

  private getByte(state: NfcCardModel, block: number, pos: number): number | null {
    return state.data[(block * BYTES_PER_BLOCK) + pos]
  }

  private getBoolean(state: NfcCardModel, block: number, pos: number): boolean | null {
    const b = this.getByte(state, block, pos)
    if (b === null) {
      return null
    }

    return b != 0
  }

  public fillValues(state: NfcCardModel) {
    state.id = this.getBytes(state, ID_BLOCK, ID_POS, ID_SIZE)

    state.mode = this.getIntLittleEndian(state, BOX_MODE_BLOCK, BOX_MODE_POS, BOX_MODE_SIZE) // int
    state.isElecOnBox = this.getBoolean(state, IS_ELEC_ON_BOX_BLOCK, IS_ELEC_ON_BOX_POS) //1: on box, 0: on NFC
    state.isWaterOnBox = this.getBoolean(state, IS_WATER_ON_BOX_BLOCK, IS_WATER_ON_BOX_POS) //1: on box, 0: on NFC
    state.customerId = this.getIntLittleEndian(state, CUSTOMER_ID_BLOCK, CUSTOMER_ID_POS, CUSTOMER_ID_SIZE) // int
    state.nfcElec = this.getIntLittleEndian(state, NFC_ELEC_BLOCK, NFC_ELEC_POS, NFC_ELEC_SIZE) // int
    state.nfcWater = this.getIntLittleEndian(state, NFC_WATER_BLOCK, NFC_WATER_POS, NFC_WATER_SIZE) // int
    state.box = this.getIntLittleEndian(state, BOX_BLOCK, BOX_POS, BOX_SIZE) // unsigned short
    state.typeElec = this.getIntLittleEndian(state, TYPE_ELEC_BLOCK, TYPE_ELEC_POS, TYPE_ELEC_SIZE) // int
    state.typeWater = this.getIntLittleEndian(state, TYPE_WATER_BLOCK, TYPE_WATER_POS, TYPE_WATER_SIZE) // unsigned short

    state.access = this.getFlags(state, GATE_ACCESS_BLOCK, GATE_ACCESS_POS, GATE_ACCESS_SIZE)

    state.showerAccess = this.getFlags(state, SHOWER_ACCESS_BLOCK, SHOWER_ACCESS_POS, SHOWER_ACCESS_SIZE)
    state.nbShowers = this.getIntLittleEndian(state, SHOWER_NB_BLOCK, SHOWER_NB_POS, SHOWER_NB_SIZE) 

    state.parkingAccess = this.getFlags(state, PARKING_ACCESS_BLOCK, PARKING_ACCESS_POS, PARKING_ACCESS_SIZE)
    state.parkingEnd = this.getIntLittleEndian(state, PARKING_END_BLOCK, PARKING_END_POS, PARKING_END_SIZE)

    state.dateValidityInS = this.getIntLittleEndian(state, DATE_VALIDITY_IN_S_BLOCK, DATE_VALIDITY_IN_S_POS, DATE_VALIDITY_IN_S_SIZE) // unsigned long
    state.maint = this.getIntLittleEndian(state, MAINT_BLOCK, MAINT_POS, MAINT_SIZE)

    state.boxElec  = this.getIntLittleEndian(state, BOX_ELEC_BLOCK, BOX_ELEC_POS, BOX_ELEC_SIZE) // int
    state.boxWater = this.getIntLittleEndian(state, BOX_WATER_BLOCK, BOX_WATER_POS, BOX_WATER_SIZE) // int

    state.alwaysCustomerId = this.getIntLittleEndian(state, ALWAYS_CUSTOMER_ID_BLOCK, ALWAYS_CUSTOMER_ID_POS, ALWAYS_CUSTOMER_ID_SIZE) // int
  }


  public cleanData(state: NfcCardModel): void {
    const data: (number | null) [] = []
    for (let i = 0; i < DATA_SIZE; i++) {
      data[i] = null
    }
    state.data = data
  }

  public toZeroData(state: NfcCardModel): void {
    const data: (number | null) [] = []
    for (let i = 0; i < DATA_SIZE; i++) {
      data[i] = 0
    }
    console.log(data)
    state.data = data
    this.fillValues(state)
  }
}

export default new NFCCardService()
