  import loginStore from '../tool/LoginStore'
  import Nfc from '../../../shared/service/MifareClassic1K.js'
  let nfc = new Nfc(loginStore.state.company.key)

  import NFCBatchBlocks from "../../model/nfcbatchblocks/NFCBatchBlocks.js"
  const nfcBatchBlocksModel = new NFCBatchBlocks()

  import NFCErrorDialog from "../nfc/NFCErrorDialog.vue"

  import EditNfcCard from '../basket/EditNfcCard.vue'


  import NfcCardModel from '../../model/nfccard/NFCCard.js'
  let nfcCardModel = new NfcCardModel()

  export default {
    components: {
      //SearchCustomer
      NFCErrorDialog, EditNfcCard
    },
    computed: {
      isShown() {
        return this.show
      }
    },
    watch: {
      isShown(newValue) {
        if (newValue) {
          this.nfcBatchBlocks =  {card:{}, values:[]}
        }
      }
    },
    props: {
      show: Boolean
    },
    data: () => ({
      uid: null,
      progressRead: false,
      nfcBatchBlocks: {card:{}, values:[]},
      //blocks: [],
      isLoading: false,


      showEditNfc: false,
    }),
    methods : {
      async back() {
        try {
          let uid = await nfc.getIdP()
          
          let metas = await nfcCardModel.getMetaInfosP(this, {uid: uid})
          
          let result = await nfcBatchBlocksModel.readPartP(metas.request)
          let blocks = JSON.stringify(result.data)
          //console.log(blocks)
          
          let r = await nfcCardModel.backP({blocks: blocks})
          
          if (r.credits) {
            if (r.credits.length == 1) {
              let req  = nfc.clearBlocksRequest([1,2,4,5,6])
              console.log(req)
              await nfc.writeBlocksP(req)

              loginStore.redirect("/hmi/basket/view/" + r.credits[0] + "?readonly=true")
              this.hide()
            }
          }

          if (r.error) {
            if (r.error == "CARD_IN_USE") {
              this.error("nfc.CARD_IN_USE_ERROR")
            } else if (r.error == "CARD_UNKNOWN") {
              this.error("nfc.CARD_UNKNOWN_ERROR")
            }
          }

        } catch(error) {
          console.error(error)
          this.error("nfc.WRITE_ERROR")
        }
          //this.success()


        // .then((result) => {
        //   this.success()
        // })
        // .catch((e) => {
        //   this.error()
        // })

        // let res = await nfc.readPartP(metas.request)
        // let blocks = JSON.stringify(this.nfcBatchBlocks)
        // nfcCardModel.backP({blocks: blocks})
        // .then((result) => {
        //   this.success()
        // })
        // .catch((e) => {
        //   this.error()
        // })

        // let blocks = JSON.stringify(this.nfcBatchBlocks)
        // nfcCardModel.backP({blocks: blocks})
        // .then((result) => {
        //   this.success()
        // })
        // .catch((e) => {
        //   this.error()
        // })
      },
      hide() {
        this.$emit('hide')
      },
      editNfcCard(/*tagLine*/) {
        //this.show = false
        //this.hide()
        this.showEditNfc = true
      },

      success: function (msg) {
        this.$emit('success', msg)
      },
      error: function (msg, e) {
        this.$emit('error', msg, e)
      },
      async readNfc() {
        this.nfcBatchBlocks = {card:{}, values:[]}
        this.progressRead = true

        try {
          let uid = await nfc.getIdP()
          
          let metas = await nfcCardModel.getMetaInfosP(this, {uid: uid})
          
          nfcBatchBlocksModel.readPartP(metas.request)
          .then(
            (response) => {
              this.progressRead = false
              // console.log("### refund ###")
              // if (response.data.parent && response.data.parent.refunds) {
              //   for (let refund of response.data.parent.refunds) {
              //     console.log("* ", refund.label, refund.qty_remains, refund.total_refund / refund.qty_valued, refund.total_refund / refund.qty_valued * refund.qty_remains)
              //   }
              // }
              this.nfcBatchBlocks = response.data
              //console.log(response.data.values)
              for (const v of this.nfcBatchBlocks.values) {
                console.log(v.nfcinfo.label)
              }
              //console.log("nfcBatchBlocks", this.nfcBatchBlocks)
            }
          )
          .catch(
            (response) => {
              this.progressRead = false
              if (response == "nfc.PLUGIN_MISSING_ERROR") {
                this.$refs.NFCErrorDialog.missingPlugin = true
              } else {
                this.error(response)
              }
              //console.log('error', response)
            }
          )

        } catch(error) {
          this.progressRead = false
            this.$refs.NFCErrorDialog.missingPlugin = true
         }




        // let _self = this
        // nfcBatchBlocksModel.readAll(
        //   function(response) {
        //     _self.progressRead = false
        //     // console.log("### refund ###")
        //     // if (response.data.parent && response.data.parent.refunds) {
        //     //   for (let refund of response.data.parent.refunds) {
        //     //     console.log("* ", refund.label, refund.qty_remains, refund.total_refund / refund.qty_valued, refund.total_refund / refund.qty_valued * refund.qty_remains)
        //     //   }
        //     // }
        //     _self.nfcBatchBlocks = response.data
        //     console.log("nfcBatchBlocks", _self.nfcBatchBlocks)
        //   }
        //   , function(response) {
        //     _self.progressRead = false
        //     if (response == "nfc.PLUGIN_MISSING_ERROR") {
        //       _self.$refs.NFCErrorDialog.missingPlugin = true
        //     } else {
        //       _self.error(response)
        //     }
        //     //console.log('error', response)
        //   }
        // )

      },
      formatUid: function(uid) {
        return nfc.formatUid(uid)
      },
      formatCustomer: function(customer) {
        let res = ""

        if (customer == null) {
            res = this.$t("common.none")
        } else {
            if (customer.is_company) {
                res += customer.company_name  ? customer.company_name : ""
            } else {
                res += customer.first_name ? customer.first_name : ""
                res += " "
                res += customer.last_name ? customer.last_name : ""
            }
            res += " "
            res += customer.email ? customer.email : ""
        }

        return res

        //return nfc.formatCustomer(this, customer)
      },
      formatValue: function(value) {
        //return value
        return nfc.formatValue(value)
      },
    },


    mounted() {
      this.blocks = []

  }

}