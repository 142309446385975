
















import TransactionService, {
    TransactionFilter,
} from "@/components/model/transaction/TransactionService";

import Transactions from "@/components/hmi/customer/Transactions.vue";
import TransactionsFilterDialog from "@/components/hmi/transaction/TransactionsFilterDialog.vue";
import TransactionsFilterSummary from "@/components/hmi/transaction/TransactionsFilterSummary.vue";

import { Component, Vue } from "vue-property-decorator";

const transactionService: TransactionService = new TransactionService();

import { parse } from "json2csv";

@Component({
    components: {
        Transactions,
        TransactionsFilterDialog,
        TransactionsFilterSummary,
    },
})
export default class extends Vue {
    filter: TransactionFilter = {
        isCurrent: true,
        isHistory: true,
        isWater: true,
        isElec: true,
        isNoQty: true,
        customer_id: null,
        begin: null,
        end: null,
    };

    async exportToCsv(): Promise<void> {
        try {
            const transactions = await transactionService.listNoLimitP(
                this.filter
            );

            const opts = {
                fields: [
                    "id",
                    "begin",
                    "end",
                    "status",
                    "current_qty",
                    "remaining_qty",
                    "pos",
                    // "plc_id",
                    "plc_label",
                    "plug_label",
                    "plug_type",
					"customer_id",
                    "customer_is_company",
                    "customer_first_name",
                    "customer_last_name",
                    "customer_company_name",
                ],
            };
            const csv = parse(transactions, opts);

            transactionService.downloadBlob(
                new Blob([csv]),
                `export_transactions_${this.formatDatetime(
                    Date.now()
                ).replaceAll(" ", "_")}.csv`
            );
            // console.log("ici", csv);
        } catch (error) {
            this.onError(
                "Impossible de charger les consommations",
                error as Error
            );
        }
    }

    get transactionsFilterDialog(): TransactionsFilterDialog {
        return this.$refs.TransactionsFilterDialog as TransactionsFilterDialog;
    }

    async showFilters(): Promise<void> {
        const filter: TransactionFilter | null = await this.transactionsFilterDialog.showDialog(
            this.filter
        );

        if (filter) {
            console.log("filter changed", filter);
            this.filter = filter;
        }
    }
}
