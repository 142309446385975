<template lang="pug">
  extends ../tool/CommonDetail.pug
  block detail
    div.pa-1
      ListNfcValueModifers(:parent_id="this.$route.params.id" @success="success" @error="error")
    div.pa-1
      ListNFCValueLabels(:parent_id="this.$route.params.id" @success="success" @error="error")
</template>

<script>
  import CommonDetail from "../tool/CommonDetail.js"
  
  import {Model, EditDialog} from "./imports.js"

  // import Vat from "../../model/vat/Vat.js"
  // const vat = new Vat()

  import ListNfcValueModifers from "../nfcvaluemodifier/ListNFCValueModifiers.vue"
  import ListNFCValueLabels from "../nfcvaluelabel/ListNFCValueLabels.vue"

  export default {
    extends: CommonDetail,
    components: {
      ListNfcValueModifers, ListNFCValueLabels, Infos: EditDialog
    },
    props: {
    },
    computed: {

    }, 
    watch: {

    }, 
    data: () => ({
    }),

    methods: {
      loadModel () {
        this.model = new Model()
      },

    },


  }

</script>
