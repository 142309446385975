<template lang="pug">
v-dialog(v-model='show' persistent='' width='75vw')
  v-card
    v-card-title.headline {{$t('nfc.edit_card')}}
    v-card-text
      v-tabs(v-model="tab")
        v-tab.elevation-2(

        ) Prises
        v-tab.elevation-2(
          v-for="marker in markers"
          :key="'marker_' + marker.id"
          v-if="isDisplayed(marker)"
        ) {{marker.label}}

      v-tabs-items(v-model="tab")
        v-tab-item()
          v-container
            v-layout()
              v-flex(xs6 md6)
                v-select(
                  v-if="memory"
                  v-model="memory.plug_elec"
                  :label="$t('customer.' + 'plug_elec')"
                  :rules="[(v) => !!v || $t('common.required')]"
                  :items="elecPlugs"
                  item-value="value"
                  item-text="label"
                )
              v-flex(xs6 md6)
                v-select(
                  v-if="memory"
                  v-model="memory.plug_water"
                  :label="$t('customer.' + 'plug_water')"
                  :rules="[(v) => !!v || $t('common.required')]"
                  :items="waterPlugs"
                  item-value="value"
                  item-text="label"
                )

        v-tab-item(
          v-for="marker in markers"
          :key="'marker_detail_' + marker.id"
          v-if="isDisplayed(marker)"
        )
          v-card.elevation-2(flat)
            v-card-text
              NFCValueModifier(
                :readonly="readonly"
                v-for="(nfcvaluemodifier, index) in marker.nfcvaluemodifiers"
                :key="nfcvaluemodifier.id"
                @success="success"
                @error="error"
                :memory="memory"
                :item="getItem(nfcvaluemodifier)"
              )
              //- NFCValueModifier(
              //-   :readonly="readonly"
              //-   :item="nfcvaluemodifier"
              //-   v-for="(nfcvaluemodifier, index) in marker.nfcvaluemodifiers"
              //-   :key="marker.code + '_' + index"
              //-   @success="success"
              //-   @error="error"
              //-   :bus="bus"
              //- )
    v-card-actions
      v-spacer
      v-btn(color='primary' @click.stop='ok' v-if="memory.data.isRead") {{$t('common.ok')}}
      v-btn(color='error' @click.stop='cancel') {{$t('common.close')}}
  v-overlay(:value='writing' opacity="0.8")
    v-progress-circular(
        :rotate="360"
        :size="100"
        :width="15"
        value="50"
        color="teal"
      ) 50%
</template>

<script>
  import loginStore from '../tool/LoginStore'
  import Nfc from '../../../shared/service/MifareClassic1K.js'
  let nfc = new Nfc(loginStore.state.company.key)

  import NFCValueModifier from "../../../shared/component/NFCValueModifier.vue"

  import MarkerModel from '../../model/marker/Marker.js'
  var markerModel = new MarkerModel()

  import Customer from "../../model/customer/Customer.js"
  const customer = new Customer()

  export default {
    components: {
      NFCValueModifier
    },
    props: {
      show: Boolean
    },
    computed: {
      showed() {
        return this.show
      }
    },
    watch: {
      showed() {
        if (this.show) {
          //console.log("show")
          this.readValues()
        }
      }
    }, 
    data: () => ({
      tab: null,
      markers: [],
      readonly: false,
      memory: nfc.state,
      metas: [],
      writing: false,
      uid: null,

      elecPlugs: [],
      waterPlugs: [],
    }),
    methods: {
      getItem(markernfcvaluemodifier) {
        let nfcvaluemodifier = markernfcvaluemodifier.nfcvaluemodifier
        let nfcInfo = nfcvaluemodifier.parent

        return {
          type: nfcvaluemodifier.type, 
          block: nfcInfo.block, 
          byte: nfcInfo.byte, 
          size: nfcInfo.size, 
          label: nfcvaluemodifier.label, 
          unit: nfcInfo.unit, 
          labels: nfcInfo.labels
        }
      },
      isDisplayed(marker) {

        //console.log(this.memory.data)
        if (! this.memory.data.isRead) {
          return false
        }

        if (marker.code.endsWith('_HIDDEN')) {
          // ignorer les marqueurs cachés dont le type n'est pas celui de la carte
          if (nfc.isSubscribtion() && ! marker.code.includes('_SUBSCRIBTION_CARD_')) {
            return false
          }
          if (! nfc.isSubscribtion() && marker.code.includes('_SUBSCRIBTION_CARD_')) {
            return false
          }
          // for (var mod of marker.nfcvaluemodifiers) {
          //   console.log(mod.label, mod.parent.label, mod.parent.is_editable)
          // }
        }

        for (var nfcvaluemodifier of marker.nfcvaluemodifiers) {
          if (this.isEditable(nfcvaluemodifier)) {
            return true
          }
        }

        return false
      },
      isEditable(nfcvaluemodifier) {
        var valuemodifier = nfcvaluemodifier.nfcvaluemodifier
        return valuemodifier.parent.is_editable
      },
      success(msg) {
        this.$emit('success', msg)
      },
      error(msg, e) {
        this.$emit('error', msg, e)
      },
      ok() {
        nfc.getIdP()
        .then((uid) => {
          //console.log(uid, this.uid)
          if (uid != this.uid) {
            this.$emit('error', 'common.failed_write_uid_changed', 'wrong uid')
            this.writing = false
            return
          }
          this.$emit('hide')
          let blocksToWrite = nfc.fillData()
          //console.log(blocksToWrite)
          let writeRequest = nfc.getWriteRequest(blocksToWrite)
          //console.log(JSON.stringify(writeRequest))

          this.writing = true
          nfc.writeBlocksP(writeRequest)
          .then((result) => {
            this.writing = false
            this.$emit('success', 'common.wrote', result)
          })
          .catch((e) => {
            this.$emit('error', 'common.failed_write', e)
            this.writing = false
          })
        })
        .catch((e) => {
          this.$emit('error', 'common.failed_write', e)
          this.writing = false
        })

      },
      cancel() {
        this.$emit('hide')
      },
      readValues() {
        let sectors = []
        for (let marker of this.markers) {
          for (let nfcvaluemodifier of marker.nfcvaluemodifiers) {
            let valuemodifier = nfcvaluemodifier.nfcvaluemodifier
            let nfcInfo = valuemodifier.parent
            sectors.push(nfcInfo.block)
          }
        }
        // for uid
        sectors.push(0)
        // TODO en dur : types prises
        sectors.push(1)

        let request = nfc.getReadPartRequest(sectors)

        nfc.readPartP(request)
        .then((result) => {
          this.uid = nfc.getIdFromData(result.data)

          // console.log(result.data)
          // console.log(customer.getElecPlugs(this))
          // console.log(customer.getWaterPlugs(this))

          // this.plug_elec = nfc.state.plug_elec
          // this.plug_water = nfc.state.plug_water

          console.log(this.memory.plug_elec)
          console.log(this.memory.plug_water)


          this.elecPlugs = customer.getElecPlugs(this)
          this.waterPlugs = customer.getWaterPlugs(this)

          //console.log("uid", this.uid)
        })
        .catch((e) => {
          console.error(e)
        })
      }
    },
    mounted () {

      markerModel.listAllP({})
      .then((result) => {
        let markers = result.data.results
        let filteredMarkers = []
        let metas = []

        for (let marker of markers) {
          let copy = Object.assign({}, marker)
          copy.nfcvaluemodifiers = []
          filteredMarkers.push(copy)
          for (let nfcvaluemodifier of marker.nfcvaluemodifiers) {
            if (this.isEditable(nfcvaluemodifier)) {
              copy.nfcvaluemodifiers.push(nfcvaluemodifier)
              metas.push(this.getItem(nfcvaluemodifier))
              //console.log(nfcvaluemodifier)
            }
          }
          // if (this.isDisplayed(marker)) {
          //     let copy = Object.assign({}, marker)
          //     copy.nfcvaluemodifiers = []
          //     filteredMarkers.push(copy)
          //     for (let nfcvaluemodifier of marker.nfcvaluemodifiers) {
          //       if (this.isEditable(nfcvaluemodifier)) {
          //         copy.nfcvaluemodifiers.push(nfcvaluemodifier)
          //         metas.push(this.getItem(nfcvaluemodifier))
          //         //console.log(nfcvaluemodifier)
          //       }
          //     }
          // }
        }
        nfc.metas = metas
        this.markers = filteredMarkers
        //console.log(this.markers)
      })
      .catch((e) => {
        console.error(e)
        this.$emit("error", 'common.not_found', e)
      })
    }
  }
</script>
