import Model from "../Model.js"
import loginStore from '../../hmi/tool/LoginStore'
import Nfc from '../../../shared/service/MifareClassic1K.js'
let nfc = new Nfc(loginStore.state.company.key)

const DB_READ_ERROR = "nfc.DB_READ_ERROR"

export default class NFCBatchBlocks extends Model {
    constructor() {
        super("nfcbatchblocks")
    }

    defaultValues() {
        return {
            id: 0,

        }
    }

    readAll(callback, error) {
        let self = this

        nfc.readAll(
            function(response) {
                self.create(
                    {data: response.data}
                    , response => {
                        callback(response)
                    }
                    , e => {
                        error(DB_READ_ERROR, e)
                    }
                )
            }
            , e => {
                error(e)
            }
          )
    }
    
    readPartP(request) {
        return new Promise((successCallback, failureCallback) => {
            this.readPart(request, successCallback, failureCallback)
        })
    }

    readPart(request, callback, error) {
        let self = this

        nfc.readPart(
            request
            , response => {
                console.log(1, {data: response.data})
                self.create(
                    {data: response.data}
                    , response => {
                        console.log(2)
                        callback(response)
                    }
                    , e => {
                        error(DB_READ_ERROR, e)
                    }
                )
            }
            , e => {
                error(e)
            }
          )
    }
}


