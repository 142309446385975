<template lang="pug">
extends ../tool/CommonList.pug
block filters
  div(v-if="false")
    v-row
      v-col(cols="2")
        v-switch.mx-2(v-model="filters.state.invoice" :label="$t('basket.state_INVOICE')")
      v-col(cols="2")
        v-switch.mx-2(v-model="filters.state.draft" :label="$t('basket.state_DRAFT')")
    v-row
      v-col(cols="2")
        v-switch.mx-2(v-model="filters.lines_to_write" :label="$t('basket.card_to_write')")
  div
    div(style="display:none;")
      v-dialog(v-model='confirmToCurrentBasketDialog' width='75vw')
        v-card
          v-card-title.grey.lighten-4.py-4.title
            | {{$t("basket.to_current_basket_title")}}
          v-container.pa-1(grid-list-sm='')
            div()
                div {{$t("basket.to_current_basket_text")}}
          v-card-actions
            //v-btn(text='', color='primary') More
            v-spacer
            v-btn(color='error' @click='confirmToCurrentBasketDialog = false') {{$t("common.cancel")}}
            v-btn(color='primary', @click='replaceCurrentBasket()') {{$t("common.ok")}}
</template>


<script>


  import {Model} from "./imports.js"
  import CommonList from "../tool/CommonList.js"

  import User from "../../model/user/User.js"
  const user = new User()

  export default {
    extends: CommonList,
    components: {
      "EditDialog": null
    },
    data: () => ({
      filters: {lines_to_write: false, state: {invoice: true, draft: true, credit: true}},
      newCurrentBasketId: null,
      confirmToCurrentBasketDialog: false,
      filterHidden: false,
      disableCreate: true,
    }),
    computed: {
    },
    watch: {
      '$route': function() {
        this.updateFilters()
      }
    }, 
    methods: {
      getTitle() {
        return 'Prépaiement'
        // let state = this.$route.query.state

        // return this.model.content + '.title_' + state
      },
      updateFilters() {
        let state = this.$route.query.state

        this.title = this.getTitle()
        // this.title = this.model.content + '.title_' + state
        // console.log(this.title)

        //console.log("state", state)
        this.filters.state = {}
        this.filters.state[state] = true
      

        //console.log(this.filters)
        //console.log("filters")
      },
      editItem: function(item) {
        //this.$router.push({path:"/hmi/basket/edit/" + item.id })
        return
      },

      isViewAllowed: function(item) {
        return false
      },

      isEditAllowed: function() {
        //return this.model.isEditAllowed() && (item.state == "DRAFT" || item.lines_to_write > 0)
        return false
      },
      loadModel: function() {
        this.model = new Model()
      },
      
      isDeleteAllowed: function(item) {
        return item.state == "DRAFT"
      },
      isRightPdf(item) {
        return false
        //return item.state == "INVOICE" || item.state == "CREDIT"
      },
      pdfAction(item) {
        //console.log("pdf")
        this.model.showPdf(this, item)
      },
      isRightCurrentBasket(item) {
        return item.state == "DRAFT" ||  (item.state == "INVOICE" && item.lines_to_write > 0)
      },
      currentBasketAction(item) {
        let _self = this
        user.getCurrentBasket(
          function(data) {
            if (data.id == null || data.id == item.id) {
               _self.$router.push({path:"/hmi/basket/edit/" + item.id})
            } else {
              _self.newCurrentBasketId = item.id
              _self.confirmToCurrentBasketDialog = true
            }
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
      replaceCurrentBasket() {
        let _self = this
        user.replaceCurrentBasket(
          _self.newCurrentBasketId
          ,function() {
            user.getCurrentBasket(
              function(data) {
                //console.log("id", data)
                _self.$router.push({path:"/hmi/basket/edit/" + data.id })
              }
              , function(e) {
                _self.$emit('error', 'common.not_found', e)
              }
            )
            //_self.$router.push({path:"/hmi/basket/edit/" + _self.newCurrentBasketId})
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
      setup () {
        console.log("setup => updateFilters", this.$route.query)
        this.updateFilters()
        

        this.headers = [
          // {align: "center", width: "60", text: 'basket.number', value: 'number', sortable: false},
          //{align: "start", width: "60", text: 'basket.creator', value: 'creator', sortable: false},
          {align: "start", width: "10", text: 'basket.customer', value: 'customer', sortable: false},
          {align: "start", width: "100", text: 'basket.state', value: 'state', sortable: false},
          {align: "end", width: "100", text: 'basket.total_ht', value: 'total_ht', sortable: false},
          {align: "end", width: "100", text: 'basket.total_ttc', value: 'total_ttc', sortable: false},
  
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ]

        /*
    customer = models.ForeignKey('api.Customer', on_delete=models.DO_NOTHING)
    state = models.CharField(max_length=10) #DRAFT PAID DELIVERED
    number = models.IntegerField()
    creator = models.CharField(max_length=120)
*/

        this.columns = [
          // {class: "text-right", value: '.', format: this.formatNumber},
          //{class: "text-left", value: 'creator', format: this.formatUser},
          {class: "text-left", value: 'customer', format: this.formatCustomer},
          {class: "text-left", value: '.', format: this.getState},
          {class: "text-right", value: 'total_ht', format: this.formatCurrency},
          {class: "text-right", value: 'total_ttc', format: this.formatCurrency},

          {class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]

       this.actions = [
          // {color:"primary", icon: "shopping_cart", right: this.isRightCurrentBasket, action: this.currentBasketAction},
          // {color:"primary", icon: "picture_as_pdf", right: this.isRightPdf, action: this.pdfAction},
        ]
      },
      formatNumber: function (value) {
        //console.log(value)
        if (value.state == "DRAFT") {
          return ""
        }
        return value.number
      },
      getState: function (value) {
        const types =  [
            {value: "NEW_CREDITS", label: "Badge avec crédits"}
            , {value: "NEW_ACTIVATION", label: "Badge d'activation"}
            , {value: "RELOAD_CREDITS", label: "Recharge pour badge avec crédits"}
            , {value: "RELOAD_ACTIVATION", label: "Recharge pour badge d'activation"}
        ]
        for (const type of types)  {
          if (type.value == value.type) {
            return type.label
          }
        }
        return ""
      },
      // getState: function (value) {
      //   if (value.state == "INVOICE" && value.lines_to_write > 0) {
      //     return this.$t("basket.card_to_write")
      //   }
      //   return this.translateState(value.state)
      // },
      translateState: function (value) {
        return this.$t("basket.state_" + value)
      },
      formatCustomer: function (value) {
        let res  = ""
        if (value != null) {
          if (! value.is_company) {
            if (value.first_name != null) {
              res += " " + value.first_name
            }
            if (value.last_name != null) {
              res += " " + value.last_name
            }
          } else {
            res += " " + value.company_name
          }
        }

        return  res.trim()
        //return value.email
      },
      formatUser: function (value) {
        let res  = ""

        if (value.first_name != null) {
          res += " " + value.first_name
        }
        if (value.last_name != null) {
          res += " " + value.last_name
        }

        return  res.trim()
      },
    },

  }


</script>
