import Model from "../Model.js"


export default class Productnfcoperation extends Model {
    constructor() {
        super("productnfcvaluemodifier", "api", ["flags_to_one"])
    }

    defaultValues() {
        return {
            id: 0,
            pos: 1,
            flags_to_one: 0,
            int_value: 0,
            price_value: 0,
        }
    }


}


