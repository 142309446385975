<template lang="pug">
  extends ../tool/CommonDetail.pug
  block detail
    ListRequests(:parent_id="this.$route.params.id" @success="success" @error="error")
</template>
 
<script>
  import CommonDetail from "../tool/CommonDetail.js"
  
  import {Model, EditDialog} from "./imports.js"

  import ListRequests from "../plcrequest/ListPLCRequests.vue"

  export default {
    extends: CommonDetail,
    components: {
       ListRequests, Infos: EditDialog
    },
    props: {
    },
    computed: {
    },
    watch: {
    }, 
    data: () => ({
    }),

    methods: {
      loadModel () {
        this.model = new Model()
      },
    },
  }

</script>
