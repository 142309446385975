<template lang="pug">
div
  v-card
    v-card-title(color="rgb(255, 0, 0, 0.2)")
        span.headline {{$t(title)}}
    v-card-text
        v-form(
          ref="form"
          lazy-validation
          id="edit"
        )
          v-container.grid-list-md(fluid)
            v-layout()
              v-flex(xs12 md12)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.name"
                  :counter="80"
                  :label="$t(this.model.content + '.' + 'name')"
                )
            v-layout
              v-flex(xs12 md6)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.email"
                  :counter="120"

                  :label="$t(this.model.content + '.' + 'email')"
                  required
                )
              v-flex(xs12 md6)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.phone"
                  :counter="20"
                  :label="$t(this.model.content + '.' + 'phone')"
                )
            v-layout
              v-flex(xs12 md4)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.country"
                  :label="$t(this.model.content + '.' + 'country')"
                )
              v-flex(xs12 md4)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.zip"
                  :counter="20"
                  :label="$t(this.model.content + '.' + 'zip')"
                )
              v-flex(xs12 md4)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.city"
                  :counter="120"
                  :label="$t(this.model.content + '.' + 'city')"
                )
            v-layout
              v-flex(xs12 md12)
                v-textarea(
                  :readonly="readonly"
                  v-model="item.address"
                  :label="$t(this.model.content + '.' + 'address')"
                  filled
                  auto-grow
                )
            v-layout
              v-flex(xs12 md12)
                v-textarea(
                  :readonly="readonly"
                  v-model="item.legal_info"
                  :label="$t(this.model.content + '.' + 'legal_info')"
                  filled
                  auto-grow
                )
            v-layout
              v-flex(xs12 md6)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.currency"
                  :label="$t(this.model.content + '.' + 'currency')"
                )
              v-flex(xs12 md6)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.nb_decimals"
                  :label="$t(this.model.content + '.' + 'nb_decimals')"
                )
            v-layout
              v-flex(xs12 md6)
                v-text-field(
                  :readonly="readonly"
                  v-model="item.month_validity"
                  :label="$t(this.model.content + '.' + 'month_validity')"
                )
    v-card-actions
      v-spacer
      v-btn(
        color="primary"
        @click="save"
      ) {{item.id == 0 ? $t('common.create') : $t('common.save')}}
      //- v-btn(
      //-   color="error"
      //-   @click="show = false"
      //- ) {{$t('common.close')}}

  div.ma-5
    v-btn(small block color="secondary" outlined @click="cleanTransactions()") Nettoyer les transactions
</template>


<script >
  import loginStore from '../tool/LoginStore';
import { Model } from "./imports.js";

  export default {

    components: {
    },
    props: {
    },
    data: () => ({
      readonly: false,
      model: null,
      item: null,
      title: null,
    }),
    watch: {
    },

    methods: {
      cleanTransactions: async function() {
        try {
          const res = await this.model.cleanTransactions()
          console.log(res.data)
          this.$emit('success', res.data.nb + ' transactions fermées')
        } catch (error) {
          console.log(error)
          this.$emit('error', "L'opération a échoué", e)
        }
      },

      save: function() {
        console.log("save")
        let _self = this
        this.model.update(
          this.item
          , function(e) {
            _self.$emit('success', 'common.updated', e)
          }
          , function(e) {
            _self.$emit('error', 'common.updated_error', e)
          }
        )
      },
      fetchData: function() {
        let _self = this

        this.model.get(
          {id: loginStore.state.company.id}
          , function(result) {
            //console.log(result)
            _self.item = result.data
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )


        // let params = {parent_id: this.parent_id}
        // params.filters = this.filters
        // if (this.search != null) {
        //   params.search = this.search
        // }
        // if (this.showHidden) {
        //   params.showHidden = true
        // }

        // //console.log(params)

        // const _self = this
        // this.model.list(this.pagination.page, this.pagination.itemsPerPage
        //   , (response) => {
        //     _self.additionalFetch(response)
        //     //this.$emit('success', 'customer.edit.updated')
        //   }
        //   , e => {
        //     console.log(e)
        //     //this.$emit('error', 'customer.edit.updated_error', e)
        //   }
        //   , params
        // )
      },
    },

    created() {
      this.model = new Model()

      this.title = this.model.content + '.title'
   
      this.item = this.model.defaultValues()
    },

    mounted() {
      this.fetchData()
    },
  }

</script>
