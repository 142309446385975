var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.item.nfcvaluemodifier.type == 'CONSUMP' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "prefix": _vm.item.nfcvaluemodifier.parent.unit,
      "label": _vm.item.nfcvaluemodifier.label,
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.item.nfcvaluemodifier.parent.is_valued ? _c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "prefix": _vm.getCurrency(),
      "label": _vm.$t('productnfcvaluemodifier.price_value'),
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.price_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "price_value", $$v);
      },
      expression: "item.price_value"
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.item.nfcvaluemodifier.type == 'ADD' ? _c('div', [_c('v-layout', [!_vm.item.nfcvaluemodifier.parent.is_valued ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "label": _vm.item.nfcvaluemodifier.parent.label + ' (' + _vm.item.nfcvaluemodifier.parent.unit + ')',
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1) : _vm._e(), _vm.item.nfcvaluemodifier.parent.is_valued ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "label": _vm.item.nfcvaluemodifier.label + ' (' + _vm.item.nfcvaluemodifier.parent.unit + ')',
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1) : _vm._e(), false && _vm.item.nfcvaluemodifier.parent.is_valued ? _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "label": _vm.$t('productnfcvaluemodifier.refund') + ' (' + _vm.getCurrency() + ')',
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.price_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "price_value", $$v);
      },
      expression: "item.price_value"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.item.nfcvaluemodifier.type == 'FLAG' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.item.nfcvaluemodifier.label))])])], 1), _vm._l(_vm.getNbLines(), function (line, l) {
    return _c('v-layout', {
      key: _vm.item.id + '_' + l
    }, _vm._l(_vm.getNbFlagsInLine(l), function (flag, f) {
      return _c('v-flex', {
        key: _vm.item.id + '_' + (10 * l + f),
        attrs: {
          "xs1": "",
          "md1": ""
        }
      }, [_c('v-checkbox', {
        attrs: {
          "label": _vm.getFlagLabel(10 * l + f),
          "readonly": _vm.readonly
        },
        model: {
          value: _vm.flags_to_one[10 * l + f],
          callback: function callback($$v) {
            _vm.$set(_vm.flags_to_one, 10 * _vm.l + f, $$v);
          },
          expression: "flags_to_one[10 * l + f]"
        }
      })], 1);
    }), 1);
  })], 2) : _vm._e(), _vm.item.nfcvaluemodifier.type == 'DAY' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "label": _vm.item.nfcvaluemodifier.parent.label + ' (' + _vm.$t('nfcvaluemodifier.type_DAY') + ')',
      "readonly": _vm.readonly
    },
    on: {
      "blur": function blur($event) {
        return _vm.markDirty();
      }
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }