<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
    include FormulePart.pug
</template>

<script>
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  import Vat from "../../model/vat/Vat.js"
  const vat = new Vat()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      types: [],
      vats: [],
    }),
    watch: {
    }, 

    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        this.types = this.model.getTypes(this)

        vat.listAll(
          (response) => {
            this.vats = response.data.results
          },
          (e) => {
            console.log(e)
          }
        )
      }
    },

  }

</script>
