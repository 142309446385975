import Model from "../Model.js"


export default class MarkerNFCValueModifier extends Model {
    constructor() {
        super("markernfcvaluemodifier")
      }

    defaultValues() {
        return {
            id: 0,
            pos : 1,
            nfcvaluemodifier_id: null,
            nfcvaluemodifier: null,
        }
    }

}