import BasketTagLine from "../../model/baskettagline/BasketTagLine.js"
const basketTagLineModel = new BasketTagLine()

import EditNfcCard from './EditNfcCard.vue'

import loginStore from '../../hmi/tool/LoginStore'
import Nfc from '../../../shared/service/MifareClassic1K.js'
let nfc = new Nfc(loginStore.state.company.key)

import NFCCardModel from "../../model/nfccard/NFCCard.js"
const nfcCardModel = new NFCCardModel()

export default {
  components: {
    EditNfcCard
  }
  , props: {
    value: null,
  }
  , computed: {
  }
  , watch: {
    // value: function() {
    //   console.log("yop")
    //   this.total_lines = [this.value]
    // }
  }
  , data: () => ({
    isLoading: false
    , progress_value: 0
    , progressWrite: false
    , tag_headers: [
      {align: "start", width: "10", text: 'baskettagline.card_name', value: 'label' },
      {align: "start", width: "10", text: '', value: '' },
    ]
    , showEditNfc: false
  })
  , methods: {
    success(msg) {
      this.$emit('success', msg)
    },
    error(msg, e) {
      this.$emit('error', msg, e)
    },
    editNfcCard(/*tagLine*/) {
      this.showEditNfc = true
    },

    isNewCard(tagLine) {
      for (let line of tagLine.lines) {
        if (line.product) {
          let product = line.product
          if (product.is_card)  {
           return true
          }
        }
      }

      return false
    },
    isEmpty() {
      return new Promise((accept, reject) => {
        let req = nfc.getReadPartRequest([1,2,4,5,6])
        nfc.readPartP(req)
        .then(() => {
          accept(nfc.isEmpty())
        })
        .catch((e) => {
          reject(e)
        })
       })
    },
    isSubscribtion() {
      return new Promise((accept, reject) => {
        let req = nfc.getReadPartRequest([1])
        nfc.readPartP(req)
        .then(() => {
          accept(nfc.isSubscribtion())
        })
        .catch((e) => {
          reject(e)
        })
       })
    },

    isCustomerMatching() {
      return new Promise((accept, reject) => {
        nfc.getIdP()
        .then((uid) => {
           nfcCardModel.getMetaInfosP(this, {uid: uid})
           .then((meta) => {
            accept(meta.customer_id == this.value.customer_id)
           })
           .catch((e) => {
            reject(e)
          })
        })
        .catch((e) => {
          reject(e)
        })
      })
    },
    hasCreditOnlyModifier(tagLine) {
      for (let line of tagLine.lines) {
        if (line.product) {
          let product = line.product

          for (let productnfcvaluemodifier  of product.productnfcvaluemodifiers) {
            let value = productnfcvaluemodifier.int_value

            console.log(value, productnfcvaluemodifier.nfcvaluemodifier.label, productnfcvaluemodifier.nfcvaluemodifier.is_only_credit)
            if (value > 0 && productnfcvaluemodifier.nfcvaluemodifier) {
              let nfcvaluemodifier  = productnfcvaluemodifier.nfcvaluemodifier
              if (nfcvaluemodifier.is_only_credit) {
                return true
              }
            }
          }
        }
      }

      return false
    },
    async writeItem(tagLine) {
      console.log(tagLine)
      //console.log("isCard", await this.isSubscribtion(tagLine))

      let isNewCard = this.isNewCard(tagLine)
      if (isNewCard) {
        let isEmpty = await this.isEmpty()
        if (! isEmpty) {
          console.log("error_must_be_empty")
          this.error('nfc.error_must_be_empty')
          return
        }
      } else {
        let isCustomerMatching = await this.isCustomerMatching()
        if(! isCustomerMatching) {
          console.log("error_wrong_customer")
          this.error('nfc.error_wrong_customer')
          return
        }
        let  isSubscribtion = await this.isSubscribtion(tagLine)
        if (isSubscribtion) {
          let hasCreditOnlyModifier = this.hasCreditOnlyModifier(tagLine)
          if (hasCreditOnlyModifier) {
            this.error('nfc.error_cant_add_service_to_subscribtion')
            return
          }
        }
      }

      basketTagLineModel.writeItem(
        tagLine
        , (v) => {
          this.progressWrite = v != 100
          this.progress_value = v + "%"
          if (v == 100) {
            this.$emit('success', 'common.wrote')
          }
        }
        , () => {
          this.$emit('success', 'common.wrote')
        }
        , (e) => {
          this.progressWrite = false
          this.$emit('error', e)
        }
      )
    },
  }

  , mounted() {
  }
}
