import Model from "../Model.js"


export default class NFCOperation extends Model {
    constructor() {
        super("nfcinfo")
    }

    defaultValues() {
        return {
            id: 0,
            pos : 1,
            label: null,
            type: null,
            sector: null,
            block: null,
            byte: null,
            flag: null,
            size: null,
            ratio: null
        }
    }

    getTypes(_self) {
        let types = ["FLAG", "INT", "LOCALISATION", "TIMESTAMP"]

        let result = []

        types.forEach(element => {
            result.push({value: element, label: _self.$t(this.content + '.' + 'type_' + element)})
        });

        return result
    }


}


