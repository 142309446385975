import { getCustomerShortdesc } from '@/shared/utils';
import BasketModel, { BASKET_TYPE_CARD_ACTIVATION, BASKET_TYPE_CARD_TIME, BASKET_TYPE_CARD_UNITS, BASKET_TYPE_RELOAD_ACTIVATION, BASKET_TYPE_RELOAD_TIME, BASKET_TYPE_RELOAD_UNITS } from "../../model/basket/Basket.js";
//const paymentMethodModel = new PaymentMethod()
import CustomerModel from "../../model/customer/Customer.js";
import ExpandBasket from "../../model/expandbasket/ExpandBasket.js";
import paymentMethodModel from "../../model/paymentmethod/PaymentMethod.js";
import Product, { PRODUCT_TYPE_CARD_ACTIVATION, PRODUCT_TYPE_CARD_TIME, PRODUCT_TYPE_CARD_UNITS } from "../../model/product/Product.js";
import User from "../../model/user/User.js";
import CreateCustomerDialog from '../customer/EditCustomer.vue';
import PreviewCustomer from '../customer/PreviewCustomer.vue';
import SearchCustomer from '../customer/SearchCustomer.vue';
import nfcCardService, { WriteError } from '../nfccard/NFCCard';
import NFCCardContent from "../nfccard/NFCCardContent.vue";
import loginStore from '../tool/LoginStore';
import DetailBasket from './DetailBasket.vue';
import TagBasket from './TagBasket.vue';
import TotalBasket from './TotalBasket.vue';
import TotalVat from "./TotalVat.vue";
const user = new User()
const expandBasketModel = new ExpandBasket()

const customerModel = new CustomerModel()

const basketModel = new BasketModel()


const ACTION_CARD_UNITS = "ACTION_CARD_UNITS"
const ACTION_CARD_TIME = "ACTION_CARD_TIME"
const ACTION_CARD_ACTIVATION = "ACTION_CARD_ACTIVATION"
const ACTION_RELOAD = "ACTION_RELOAD"

// basket
// # NEW_CREDITS = "NEW_CREDITS"
// # NEW_ACTIVATION = "NEW_ACTIVATION"
// # RELOAD_CREDITS  = "RELOAD_CREDITS"
// # RELOAD_ACTIVATION  = "RELOAD_ACTIVATION"

// product
// # CARD_WITH_CREDITS       = "CARD_WITH_CREDITS"
// # ACTIVATION_CARD         = "ACTIVATION_CARD"
// # RELOAD_WITH_CREDIT      = "RELOAD_WITH_CREDIT"
// # RELOAD_WITHOUT_CREDIT   = "RELOAD_WITHOUT_CREDIT"


const productModel = new Product()



export default {
  components: {
    DetailBasket
    , SearchCustomer
    , PreviewCustomer
    , CreateCustomerDialog
    , TotalBasket
    , TotalVat
    , TagBasket
    , NFCCardContent
  },
  props: {
  },
  computed: {
    availableCards() {
      if (this.products == null) {
        return []
      }
      return this.products.filter(
        product =>  
        (this.action == ACTION_CARD_UNITS && product.type == PRODUCT_TYPE_CARD_UNITS)
        || (this.action == ACTION_CARD_TIME && product.type == PRODUCT_TYPE_CARD_TIME)
        || (this.action == ACTION_CARD_ACTIVATION && product.type == PRODUCT_TYPE_CARD_ACTIVATION)
      )
    },

    discountRateRules() {
      return [
        v => {
          try {
            var f = parseFloat(v)
            if (f < 0) {
              return this.$t('basket.error_more_than_0')
            }
            if (f > 100) {
              return this.$t('basket.error_less_than_100')
            }
          } catch(e) {
            return this.$t('common.error_numeric_required')
          }
  
          return true
        },
      ]
    }
  },
  watch: {
    // basket: function(value) {
    //   if ([BASKET_TYPE_RELOAD_UNITS, BASKET_TYPE_RELOAD_TIME, BASKET_TYPE_RELOAD_ACTIVATION].includes(this.basket.type)) {
    //     this.action = ACTION_RELOAD
    //   } else {
    //     this.action = this.basket.type
    //   }
    // },
    
    action: function() {
      // this.action = value
      this.setProductCardLine(null)
      if (this.action == ACTION_RELOAD) {
        this.readCard()
      }
    },

    selectedProductId: function(value) {
      this.basket.card_product = null;
      const foundProduct = this.products.find(p => p.id === value);
      this.setProductCardLine(foundProduct);
      // this.basket.card_product = foundProduct;
    }

    // basket: function(value){
    //   this.$refs.DetailBasket.setBasket(value)
    // },
    // "basket.customer": function(value) {
    //   if (value) {
    //     this.elecPlugs = customer.getElecPlugs(this)
    //     this.waterPlugs = customer.getWaterPlugs(this)
    //   }
    // }
  }, 
  data: () => ({
    overlay: false,
    action: ACTION_CARD_UNITS,
    elecPlugs: [],
    waterPlugs: [],
    products: [],
    readedCustomer: null,
    serviceTypes: [
      {label: "Créer un badge avec des unités", value: ACTION_CARD_UNITS}, 
      {label: "Créer un badge avec temps", value: ACTION_CARD_TIME}, 
      {label: "Créer un badge d'activation", value: ACTION_CARD_ACTIVATION}, 
      {label: "Recharger un badge", value: ACTION_RELOAD}, 
    ],
    ACTION_CARD_UNITS: ACTION_CARD_UNITS,
    ACTION_CARD_TIME: ACTION_CARD_TIME,
    ACTION_CARD_ACTIVATION: ACTION_CARD_ACTIVATION,
    ACTION_RELOAD: ACTION_RELOAD,
    selectedProductId: null,

    showCancelBasket: false,
    // showFinishBasket: false,
    //progress_value: 0,
    //progressWrite: false,
    //showSearchCustomer: false,
    paymentmethod: null,
    paymentmethods: [],
    isLoading: false,
    step: 1,
    basket: expandBasketModel.defaultValues(),
    showDiscount: false,
    // tag_headers: [
    //   {align: "start", width: "10", text: 'baskettagline.card_name', value: 'label' },
    //   {align: "start", width: "10", text: '', value: '' },
    // ],
    discount_rate: 0,
    discount_comment: "",
    discountValid: false,

    readState: nfcCardService.getDefault(),
    editState: nfcCardService.getDefault(),
  }),

  methods: {
    isStepOk(step) {
      if (step == 1) {
        if (this.action == null) {
          return false
        }
        if (this.action == ACTION_CARD_UNITS) {
          return this.basket != null
            && this.basket.card_product != null
            && this.basket.card_product.type == PRODUCT_TYPE_CARD_UNITS
            && this.basket.customer != null
            && this.basket.customer.id != null
        }
        if (this.action == ACTION_CARD_ACTIVATION) {
          return this.basket != null
            && this.basket.card_product != null
            && this.basket.card_product.type == PRODUCT_TYPE_CARD_ACTIVATION
            && this.basket.customer != null
            && this.basket.customer.id != null
        }
        if (this.action == ACTION_CARD_TIME) {
          return this.basket != null
            && this.basket.card_product != null
            && this.basket.card_product.type == PRODUCT_TYPE_CARD_TIME
            && this.basket.customer != null
            && this.basket.customer.id != null
        }
        if (this.action == ACTION_RELOAD) {
            return this.basket != null
              && this.basket.uid != null
              && this.basket.customer_id != null
              && [BASKET_TYPE_RELOAD_UNITS, BASKET_TYPE_RELOAD_TIME, BASKET_TYPE_RELOAD_ACTIVATION].includes(this.basket.type)
        }

      }
      return false
    },

    loadProducts: function() {
      productModel.list(1, 200
        , (response) => {
          this.products = response.data.results
        }
        , e => {
          console.log(e)
          this.error(e)
        }
        , {}
      )
    },

    success: function (msg) {
      this.$emit('success', msg)
      this.loadBasket()
    },
    error: function (msg, e) {
      this.$emit('error', msg, e)
    },

    cancelBasket: function() {
      this.showCancelBasket = true
      //this.$refs.ConfirmDelete.showDeleteDialog(this.basket)
      //basketModel.
    },

    confirmDelete: function() {
      let _self = this
      basketModel.delete(
        _self.basket
        ,() => {
          this.showCancelBasket = false
          this.$emit('success', 'common.deleted')
          if (_self.basket.id == loginStore.state.active_basket_id ) {
            loginStore.state.basketQty = 0
            loginStore.state.active_basket_id = null
          }
          //this.loadBasket()
          loginStore.redirect("/hmi/map")
        }
        , (e) => {
          //console.log(e)
          this.$emit('error', 'common.deleted_error', e)
          this.showCancelBasket = false
        }
      )
    },

    cancelDelete: function() {
      this.showCancelBasket = false
    },

    putAside: function() {
      let _self = this
      this.showCancelBasket = false
      user.replaceCurrentBasket(
        null
        , function() {
          loginStore.state.basketQty = 0
          loginStore.state.active_basket_id = null
          loginStore.redirect("/hmi/basket/search?state=invoice")
        }
        , function(e) {
          _self.$emit('error', 'common.failed_write', e)
        }
      )
    },

    isActiveBasket: function() {
      return this.basket.id == loginStore.state.active_basket_id
    },

    remainsWrite: function() {
      let result = false
      this.basket.tag_lines.forEach(element => {
        if ( element.read_write_id == null) {
          result = true
        }
      });
      //console.log(this.basket.tag_lines, result)
      return result
    },

    getNFCCardContent() {
      return this.$refs.NFCCardContent
    },

    async gotoStep(step) {
      this.step = step
      if (step == 4) {
        if (this.basket.state == "DRAFT" ) {
          this.basket.action = "SET_STATE_INVOICE"
          this.updateBasket()
        }
        // NEW_CREDITS
        // NEW_ACTIVATION
        // RELOAD_CREDITS
        // RELOAD_ACTIVATION
        if ([BASKET_TYPE_CARD_ACTIVATION, BASKET_TYPE_RELOAD_ACTIVATION].includes(this.basket.type)) {
          const timeToAddParkingEndS = this.basket.days_parking > 0 ? this.basket.days_parking * 24 * 3600 : 0
          const nowS = Math.floor(new Date().getTime() / 1000)

          // reload
          if (this.basket.type == BASKET_TYPE_RELOAD_ACTIVATION) {
            await nfcCardService.read(this.readState)
            nfcCardService.copyFromValues(this.readState, this.editState)

            this.editState.nbShowers += this.basket.nb_showers
            this.editState.parkingEnd = timeToAddParkingEndS > 0 ? Math.max(this.editState.parkingEnd, nowS) + timeToAddParkingEndS : this.editState.parkingEnd
          // new
          } else {
            this.editState = nfcCardService.getDefault()

            this.editState.nbShowers = this.basket.nb_showers
            this.editState.parkingEnd = timeToAddParkingEndS > 0 ? nowS + timeToAddParkingEndS : 0

            if (this.basket.nb_showers) {
              this.editState.showerAccess[0].value = true
            }
            if (this.basket.days_parking) {
              this.editState.parkingAccess[0].value = true
            }
          }
          
          this.editState.mode =  3 // Activation
          this.editState.isElecOnBox = false
          this.editState.isWaterOnBox = false
          this.editState.customerId = this.basket.customer.id
          // this.editState.nfcElec = 0
          // this.editState.nfcWater = 0
          this.editState.box = 0
          this.editState.typeElec = this.basket.customer.plug_elec
          this.editState.typeWater = this.basket.customer.plug_water
          this.editState.access[0] = 1

          this.editState.dateValidityInS = Math.floor(new Date().getTime() / 1000) + 3 * 30 * 24 * 3600
          this.editState.maint = 0
          this.editState.boxElec = 0
          this.editState.boxWater = 0
          
          this.editState.alwaysCustomerId = this.basket.customer_id

          // this.getNFCCardContent().refreshReadCustomer()
        } else if ([BASKET_TYPE_CARD_UNITS, BASKET_TYPE_RELOAD_UNITS, BASKET_TYPE_CARD_TIME, BASKET_TYPE_RELOAD_TIME].includes(this.basket.type) ) {
          const timeToAddParkingEndS = this.basket.days_parking > 0 ? this.basket.days_parking * 24 * 3600 : 0
          const nowS = Math.floor(new Date().getTime() / 1000)

          // reload
          if ([BASKET_TYPE_RELOAD_UNITS, BASKET_TYPE_RELOAD_TIME].includes(this.basket.type)) {
            await nfcCardService.read(this.readState)
            nfcCardService.copyFromValues(this.readState, this.editState)

            this.editState.nfcElec += this.basket.qty_elec
            this.editState.nfcWater += this.basket.qty_water
            this.editState.nbShowers += this.basket.nb_showers
            this.editState.parkingEnd = timeToAddParkingEndS > 0 ? nowS + timeToAddParkingEndS : 0
          //new
          } else {
            this.editState = nfcCardService.getDefault()

            this.editState.nfcElec = this.basket.qty_elec
            this.editState.nfcWater = this.basket.qty_water
            this.editState.nbShowers = this.basket.nb_showers
            this.editState.parkingEnd = timeToAddParkingEndS > 0 ? nowS + timeToAddParkingEndS : 0

            if (this.basket.nb_showers) {
              this.editState.showerAccess[0].value = true
            }
            this.editState.nbShowers = this.basket.nb_showers
            if (this.basket.days_parking) {
              this.editState.parkingAccess[0].value = true
            }
          }
          
          this.editState.mode =  [BASKET_TYPE_CARD_UNITS, BASKET_TYPE_RELOAD_UNITS].includes(this.basket.type) ? 1 : 2; // 1: compteur, 2: tps
          this.editState.isElecOnBox = false
          this.editState.isWaterOnBox = false
          //this.editState.customerId = this.basket.customer.id
          this.editState.box = 0
          this.editState.typeElec = this.basket.customer.plug_elec
          this.editState.typeWater = this.basket.customer.plug_water
          this.editState.access[0] = 1
          this.editState.parkingEnd = this.basket.days_parking > 0 ? Math.floor(new Date().getTime() / 1000) + this.basket.days_parking * 24 * 3600 : 0
          this.editState.dateValidityInS = Math.floor(new Date().getTime() / 1000) + 3 * 30 * 24 * 3600
          this.editState.maint = 0
          this.editState.boxElec = 0
          this.editState.boxWater = 0
          
          this.editState.alwaysCustomerId = this.basket.customer_id
          
          // this.getNFCCardContent().refreshReadCustomer()
        }
      }
      // if (step == 1) {
      //   this.step = step
      // } else if (step == 2) {
      //   this.step = step
      // }


      //this.step = step
      // if (step < 3) {
      //   this.step = step
      // } else if (step == 3) {
      //   this.setCustomer(step)
      //   this.paymentmethods = paymentMethodModel.getPaymentMethods(this)
      // } else if (step == 4) {
      //   this.setPaymentMethod(step)
      //   /*
      //   paymentMethod.listAll(
      //     function(response) {
      //       _self.paymentmethods = response.data.results
      //       // this.$emit('success', 'basket.customer_selected')
      //     }
      //     , function() {
      //       //this.$emit('error', 'common.not_found', e)
      //     }
      //   )*/
      // } else if (step == 5) {
      //   this.finishBasket(step)
      // }
    },


    finishBasket: function(uid) {
      this.basket.action = "CLOSE"
      this.basket.uid = uid
      basketModel.update(
        this.basket
        , () => {
          loginStore.state.basketQty = 0
          loginStore.state.active_basket_id = null
          this.$emit('success', 'common.updated', this.basket)
          // this.showFinishBasket = true
          loginStore.redirect("/hmi/map")
          this.basket.action = null
        }
        , (e) => {
          this.$emit('error', 'common.created_error', e)
          this.basket.action = null
        }
      )
    },

    async refreshReadCustomer() {
      if (! this.readState.alwaysCustomerId) {
        this.readedCustomer = null
      } else {
        const response = await customerModel.getP({id: this.readState.alwaysCustomerId})
        this.readedCustomer = response.data
      }
    },

    getCustomerShortdesc(customer, email = false) {
      return getCustomerShortdesc(customer, email)
    },

    // showPdf: function() {
    //   basketModel.showPdf(this, this.basket)
    // },

    // redirectToFill: function() {
    //   this.showFinishBasket = false
    //   loginStore.redirect("/hmi/map")
    // },

    // setPaymentMethod: function() {
    //   //console.log(this.paymentmethod)
    //   this.basket.action = "SET_PAYMENT_METHOD"

    //   basketModel.update(
    //     this.basket
    //     , (result) => {
    //       this.$emit('success', 'common.updated', this.basket)
    //       //this.loadBasket()
    //       this.basket = result.data
    //       this.basket.action = null
    //       //this.basket = response.data
    //     }
    //     , (e) => {
    //       //console.log(e)
    //       this.basket.action = null
    //       this.$emit('error', 'common.created_error', e)
    //     })
    // },

    getHex(id) {
      let res = ""

      if (id) {
        for (const part of id) {
          if (part != null) {
            res += ('0' + (part & 0xFF).toString(16)).slice(-2);
          }
        }
      }

      console.log("read", id, res)
      return res.toUpperCase()
    },

    async readCard() {
      try {
        await  nfcCardService.read(this.readState)
        
        this.basket.uid = this.getHex(this.readState.id)
        this.basket.customer_id = this.readState.alwaysCustomerId
        this.basket.qty = 1
        this.refreshReadCustomer()
        if ([1].includes(this.readState.mode)) {
          this.basket.type = BASKET_TYPE_RELOAD_UNITS
        } else if([2].includes(this.readState.mode)) {
          this.basket.type = BASKET_TYPE_RELOAD_TIME
        } else if([3].includes(this.readState.mode)) {
          this.basket.type = BASKET_TYPE_RELOAD_ACTIVATION
        }
  
        this.updateBasket()
      } catch (error) {
        this.$emit("error", "Le badge n'a pas pu être lu")
      }
    },

    setCustomer: function(customer) {
      if (customer) {
        this.basket.customer_id = customer.id
      } else {
        this.basket.customer_id = null
        this.basket.plug_elec = 1
        this.basket.plug_water = 1
      }

      this.basket.action = "SET_CUSTOMER"

      this.updateBasket()

    },

    successCreateCustomer: function(label, value) {
      this.$emit("success", label)
      this.selectedCustomer(value)
    },

    errorCreateCustomer: function(label) {
      this.$emit("error", label)
    },

    createCustomer: function() {
      //this.showSearchCustomer = false
      this.$refs.CreateCustomerDialog.create({})
    },

    selectedCustomer: function(value) {
      //this.showSearchCustomer = false
      //console.log("selected", value, this.basket)
      this.basket.customer = value
      // const _self = this
      // customer.get(
      //   this.basket.customer
      //   , function(response) {
      //     _self.basket.customer = response.data
      //     _self.$refs.PreviewCustomer.item = response.data

      //     _self.$emit('success', 'basket.customer_selected')
      //   }
      //   , function(e) {
      //     _self.$emit('error', 'common.not_found', e)
      //   }
      // )
    },

    hexToBytes(hex) {
      var res = []
      for (let c = 0; c < hex.length; c += 2) {
        res.push(parseInt(hex.substr(c, 2), 16));
      }
      return res
    },

    write() {
      this.overlay = true

      let id = null
      if (["RELOAD_ACTIVATION", "RELOAD_CREDITS"].includes(this.basket.type)) {
        id = this.hexToBytes(this.basket.uid)
      }
      let cbCheck = null
      if (["NEW_ACTIVATION", "NEW_CREDITS"].includes(this.basket.type)) {
        cbCheck = (read) => {
          console.log("cbCheck")
          if (read.mode != 0) {
            console.log("Le badge doit être vide")
            return "Le badge doit être vide"
          }

          return true
        }
      }
      console.log("id", id, this.basket.uid, cbCheck)

      nfcCardService.write(this.editState, id, cbCheck)
      .then((result) => {
        const uid = this.getHex(this.editState.id)
        this.finishBasket(uid)
        this.onSuccessWriteCard("Le badge a bien été écrit")
      }).catch((e) => {
        if (e instanceof WriteError) {
          this.$emit('error', e.message, e)
        } else  {
          this.$emit('error', "Erreur lors de l'écriture", e)
        }
      }).finally(() => {
        this.overlay = false
      })
    },

    updateBasket() {
      basketModel.update(
        this.basket
        , () => {
          this.$emit('success', 'common.updated', this.basket)
          this.loadBasket()
          this.basket.action = null
          //this.basket = response.data
        }
        , (e) => {
          //console.log(e)
          // retry to set the customer
          this.basket.action = null
          this.$emit('error', 'common.created_error', e)
        })
    },

    onSuccessWriteCard(msg) {
      this.loadBasket()
      this.onSuccess(msg)
    },

    loadBasket() {
      let _self = this
      // _self.showFinishBasket = false
      //_self.progressWrite = false

      this.basket.id = this.$route.params.id
      if (this.basket.id != undefined ) {
        expandBasketModel.get(
          {id: this.basket.id}
          , function(response) {
            // response.data.tag_lines.forEach(element => {
            //   element.written = false
            // });
          
            _self.basket = response.data
            
            _self.discount_rate = _self.basket.discount_rate * 100
            _self.discount_comment =  _self.basket.discount_comment

            _self.$nextTick(
              function() {
                if (_self.$refs.PreviewCustomer != undefined) {
                  _self.$refs.PreviewCustomer.item = _self.basket.customer
                }
              }
            )
            //console.log("=>", _self.basket)
            if (_self.step < 4 && _self.basket.state != "DRAFT") {
              _self.$nextTick(() =>  {
                _self.gotoStep(4)
              })
            }
            //console.log(_self.basket)
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )
      }
    },
    
    setProductCardLine(card) {
      let type = null;
      if (this.action === ACTION_CARD_UNITS) {
        type = BASKET_TYPE_CARD_UNITS;
      } else if (this.action === ACTION_CARD_TIME) {
        type = BASKET_TYPE_CARD_TIME;
      } else if (this.action === ACTION_CARD_ACTIVATION) {
        type = BASKET_TYPE_CARD_ACTIVATION;
      } 

      basketModel.setTagProduct(this.basket.id, {company_id: this.basket.company_id, type, product_id: card ? card.id : null})
      .then((result) => {
        console.log("data", result.data)
        this.basket = result.data
      }).catch((err) => {
        this.$emit('error', 'common.not_found', err)
      })
    },

    freeChanged(value) {
      if (value) {
        this.basket.payment_method = 'FREE'
        this.updateBasket()
      } else {
        this.basket.payment_method = 'CARD'
        this.updateBasket()
      }
    },

    paymentMethodChanged(value) {
      console.log(value)
      this.basket.payment_method = value
      this.updateBasket()
    },
    
  },


  mounted () {
    this.loadBasket()
    this.loadProducts()
    this.paymentmethods = paymentMethodModel.getPaymentMethods(this).filter(v => v.value!= 'FREE')
  },

}
