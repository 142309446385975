import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify, {VTextarea, VTooltip, VDivider, VCard, VCardTitle, VCardText, VSwitch} from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
//import colors from 'vuetify/lib/util/colors'

Vue.use(
  Vuetify
  , {
    
    // components: { VTextarea, VTooltip, VDivider, VCard, VCardTitle, VCardText, VSwitch},
  }
);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {


      },
      dark: {

      },
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'mdi',
  },
});
