import Model from "../Model.js"


export default class PLCValueLabel extends Model {
    constructor() {
        super("plcvaluelabel", "api")
      }

    defaultValues() {
        return {
            id: 0,
            value: 0,
        }
    }

}


