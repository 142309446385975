







































import { Forcage } from '@/components/model/energybox/Metrics'
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'

@Component
export default class extends Vue  {
    @Prop({
        type: Number,
        default: 500
    })
    width!: number

    @Prop({
        type: String,
    })
    title!: string

    @Prop({
        type: String,
    })
    text!: string

    @Prop({
        type: Object,
        required: true
    })
    forcage!: Forcage

    dForcage: Forcage = {... this.forcage}

    resolve:  ((forcage: Forcage) => void) | null = null

    show: boolean = false


    @Watch("forcage")
    forcageChanged() {
        this.dForcage = this.forcage
    }

    showDialog(): Promise<Forcage> {
        this.show = true
        return new Promise((resolve) => {
            this.resolve = resolve
        })
    }

    force(forcage: boolean, value: boolean): void {
        this.dForcage.forcage = forcage
        this.dForcage.value = value
        if (this.resolve) {
            this.resolve(this.dForcage)
        }
        this.show = false
    }

    // ok(): void {
    //     if (this.resolve) {
    //         this.resolve(this.dForcage)
    //     }
    //     this.show = false
    // }

    cancel(): void {
        this.show = false
    }
}

