<template lang="pug">
  extends ../tool/CommonDetail.pug
  block detail
    ListComs(:parent_id="this.$route.params.id" @success="success" @error="error")
</template>

<script>
  import CommonDetail from "../tool/CommonDetail.js"
  
  import {Model, EditDialog} from "./imports.js"

  import ListComs from "../com/ListComs.vue"

  export default {
    extends: CommonDetail,
    components: {
       ListComs, Infos: EditDialog
    },
    props: {
    },
    computed: {
    },
    watch: {
    }, 
    data: () => ({
    }),

    methods: {
      loadModel () {
        this.model = new Model()
      },
    },

  }

</script>
