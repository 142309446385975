var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-app', {
    attrs: {
      "id": "inspire"
    }
  }, [_c('v-main', [_c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs24": "",
      "sm8": "",
      "md4": ""
    }
  }, [_c('v-alert', {
    attrs: {
      "dismissible": "",
      "type": "error"
    },
    model: {
      value: _vm.loginState.error,
      callback: function callback($$v) {
        _vm.$set(_vm.loginState, "error", $$v);
      },
      expression: "loginState.error"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.errorMessage')) + " ")]), _c('v-card', {
    staticClass: "elevation-12"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.$t('login.title')))])], 1), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "prepend-icon": "person",
      "name": "user",
      "label": _vm.$t('login.user'),
      "type": "text"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login();
      }
    },
    model: {
      value: _vm.loginState.user,
      callback: function callback($$v) {
        _vm.$set(_vm.loginState, "user", $$v);
      },
      expression: "loginState.user"
    }
  }), _c('v-text-field', {
    attrs: {
      "prepend-icon": "lock",
      "name": "password",
      "label": _vm.$t('login.password'),
      "id": "password",
      "type": "password"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login();
      }
    },
    model: {
      value: _vm.loginState.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginState, "password", $$v);
      },
      expression: "loginState.password"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login();
      },
      "click": function click($event) {
        return _vm.login();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('login.action')))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }