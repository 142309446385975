import Model from "../Model.js"


export default class ExpandedSubscribtion extends Model {
    constructor() {
        super("expandsubscribtion")
      }

    defaultValues() {
        return {
            id: 0
            , customer_id: null
            , formule_id: null
            , plc_id: null
            , nfc_card_id: null
            , status: null
            , begin: null
            , end: null
        }
    }


    getStatuses(_self) {
        let statuses = ["DRAFT", "PROGRESS", "CLOSED"]

        let result = []

        statuses.forEach(element => {
            result.push({value: element, label: _self.$t(this.content + '.' + 'status_' + element)})
        })

        return result
    }

}


