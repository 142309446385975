<template lang="pug">
div
  v-card
    v-card-title
      span.headline {{$t(title)}}
    v-card-text
      v-layout
        v-flex(
            xs6
            md6
        )
          v-text-field(
            :readonly="true"
            v-if="item.customer != null"
            v-model="formatedCustomer"
            :label="$t(this.model.content + '.' + 'customer')"
            class="no-border"
          )
      v-layout
        v-flex(
            xs6
            md6
        )
          v-select(
            :items="products"
            item-text="label"
            item-value="id"
            v-model="item.product_id"
            :label="$t(this.model.content + '.' + 'product')"
            :readonly="true"
            class="no-border"
          )
        v-flex(
            xs6
            md6
        )
          v-select(
              :items="statuses"
              item-text="label"
              item-value="value"
              v-model="item.status"

              :label="$t(this.model.content + '.' + 'status')"
              :readonly="true"
              class="no-border"
          )
      v-layout
        v-flex(
          xs6 
          md6
        )
          v-layout
            v-flex(
              xs5
              md5
            )
              v-select(
                :items="plcs"
                item-text="label"
                item-value="id"
                v-model="item.plc_id"
                :label="$t(this.model.content + '.' + 'localisation')"
                :readonly="true"
                class="no-border"
              )
            v-flex(
              xs1
              md1
            )
              v-btn(
                v-if="item.nfc_card != null && ! readonly && item.status == 'PROGRESS'"
                color="primary"
                class="ma-2"
                @click="close()"
              ) {{$t(this.model.content + '.' + 'close')}}
            v-flex(
              xs6 
              md6
            )
              v-btn(
                color="primary"
                v-if="false"
                @click="next()"
                class="ma-2"
              ) {{$t(this.model.content + '.' + 'change_localisation')}}
        v-flex(
          xs6 
          md6
        )
          v-switch(
            v-model="item.is_auto_renew"
            :label="$t(this.model.content + '.' + 'is_auto_renew')"
            :readonly="true"
          )
      v-layout
        v-flex(
            xs6
            md6
        )
          v-text-field(
            v-if="item.begin != null"
            v-model="formatedBegin"
            :label="$t(this.model.content + '.' + 'begin')"
            :readonly="true"
            class="no-border"
          )
        v-flex(
            xs6
            md6
        )
          v-text-field(
            v-if="item.end != null"
            v-model="formatedEnd"
            :label="$t(this.model.content + '.' + 'end')"
            :readonly="true"
            class="no-border"
          )
  v-expansion-panels.mt-5(
    :inset="true"
  )
    v-expansion-panel(
      v-for="period in item.periods"
      :key="period.id"
    )
      v-expansion-panel-header.ma-1(class="primary")
        v-layout
          v-flex(
            xs2
            md2
          )
            div {{$t('subscribtionperiod.' + 'begin') + ' : ' + formatDate(period.begin)}}
          v-flex(
            xs2
            md2
          )
            div {{$t('subscribtionperiod.' + 'end') + ' : ' + formatDate(period.end)}}
          v-flex(
            xs2
            md2
          )
            div {{$t('subscribtionperiod.' + 'total_ht') + ' : ' + formatCurrency(period.total_ht)}}
          v-flex(
            xs2
            md2
          )
            div {{$t('subscribtionperiod.' + 'total_ttc') + ' : ' + formatCurrency(period.total_ttc)}}
          v-flex(
            xs2
            md2
          )
            div
              v-icon.mr-2(color="white" small v-if="isRightPdf(period)" @click.stop="pdfAction(period)") picture_as_pdf
      v-expansion-panel-content
        div 
          v-layout
            v-flex(
              xs2
              md2
            )
              div {{item.product.label}}
            v-flex(
              xs2
              md2
            )
              div {{$t('subscribtionperiod.' + 'price_ht') + " : " + formatCurrency(period.price_ht)}}
            v-flex(
              xs2
              md2
            )
              div {{$t('subscribtionperiod.' + 'price_ttc') + " : " + formatCurrency(period.price_ttc)}}
          
        div
          v-layout(class="primary")
            v-flex(
              xs2
              md2
            )
              div.ma-2 Energie
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{$t('api_consumptionvariable.' + 'plc')}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{$t('api_consumptiondetail.' + 'qty')}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{$t('api_consumptionvariable.' + 'price_ht')}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{$t('api_consumptiondetail.' + 'total_ht')}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{$t('api_consumptiondetail.' + 'total_ttc')}}
        div(
          v-for="consumption in period.consumptions"
          :key="consumption.id"
        )
          v-layout(
            v-for="detail in consumption.details"
            :key="detail.id"
          )
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{consumption.nfcinfo.label}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{detail.plc.label}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{detail.qty + ' ' + consumption.nfcinfo.unit}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{formatCurrency(consumption.price_ht)}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{formatCurrency(detail.total_ht)}}
            v-flex(
              xs2
              md2
            )
              div.ma-2 {{formatCurrency(detail.total_ttc)}}

</template>

<script>

  import CustomerModel from "../../model/customer/Customer.js"
  const customerModel = new CustomerModel()

  import {Model} from "./imports.js"

  import ExpandedSubscribtionModel from "../../model/subscribtion/ExpandedSubscribtion.js"
  const expandedSubscribtionModel = new ExpandedSubscribtionModel()

  // import FormuletModel from "../../model/formule/Formule.js"
  // const formuletModel = new FormuletModel()

  import ProductModel from "../../model/product/Product.js"
  const productModel = new ProductModel()

  import PLCModel from "../../model/plc/PLC.js"
  const plcModel = new PLCModel()

  import BasketModel from "../../model/basket/Basket.js"
  const basketModel = new BasketModel()

  export default {
    components: {

    },
    props: {
    },
    computed: {
      formatedBegin() {
        return this.formatDate(this.item.begin)
      },
      formatedEnd() {
        return this.formatDate(this.item.end)
      },
      formatedCustomer() {
        return customerModel.formatCustomer(this.item.customer)
      }
    },
    watch: {
      '$route': 'fetchData(true)',
    }, 
    data: () => ({
      model: null
      , item : {basket: {}}
      , title: null
      , isLoading: false
      , plcs: []
      , paymentmethods: []
      , paymentmethod: null
      , products: []
      , statuses: []
      , readonly: false
    }),

    methods: {
      close() {
        this.model.close(
          this.item
          ,() => {
            this.$emit('success', this.model.content + ".closed")
            this.fetchData()
          }
          , () => {
            this.$emit('error', 'common.not_found')
          }
        )
        //console.log(this.item)
      },
      pdfAction(period) {
        console.log(period)
        basketModel.showPdf(this, period.invoice)
      },
      isRightPdf(period) {
        return period.invoice_id != null
      },
      loadModel() {
        this.model = new Model()
      },
      // loadFormules: function() {
      //   this.isLoading = true
      //   formuletModel.listAll(
      //     (response) => {
      //       this.formules = response.data.results
      //       this.isLoading = false
      //     }
      //     , e => {
      //       console.log(e)
      //       this.isLoading = false
      //       //this.$emit('error', 'customer.edit.updated_error', e)
      //     }
      //     , {filters: {types: ['ANNUAL', 'MONTHLY', 'TRIMSTER']}}
      //   )
      // },
      setup() {
        this.statuses = this.model.getStatuses(this)
        productModel.fillValues(this, 'products', {}, 'isLoading')
        plcModel.fillValues(this, 'plcs', {}, 'isLoading')
      },
      fetchData: function() {
        this.readonly = this.$route.query.readonly

        //console.log('ici')
        this.item.id = this.$route.params.id

        if (this.item.id == null || this.item.id == 0) {
          return
        }
        const _self = this
        expandedSubscribtionModel.get(
          this.item
          , function(response) {
            console.log("data", response.data)
            _self.item = response.data
            console.log(_self.item, _self.item.periods)
          }
          , function() {
            _self.$emit('error', 'common.not_found')
          }
        )
      },
    },

    created () {
      this.loadModel()
    },

    mounted () {
      this.title = this.model.content + ".title"
      this.item = this.model.defaultValues()
      this.setup()
      this.fetchData(true)
    },
  }

</script>
