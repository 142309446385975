var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('Infos', {
    ref: "Infos",
    attrs: {
      "readonly": _vm.readonly,
      "showClose": false
    },
    on: {
      "success": _vm.successEdit,
      "error": _vm.errorEdit,
      "valueChanged": _vm.valueChanged
    }
  })], 1), _c('v-card-text')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }