var render = function () {
  var _vm$selectedEnergyBox;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('DialogBoxBooking', {
    ref: "DialogBoxBooking",
    attrs: {
      "pEnergyBox": _vm.selectedEnergyBox,
      "pCustomer": _vm.filter.customer
    },
    on: {
      "success": _vm.onSuccess,
      "error": _vm.onError
    }
  }), _c('DialogEnergyBox', {
    ref: "DialogEnergyBox",
    attrs: {
      "id": (_vm$selectedEnergyBox = _vm.selectedEnergyBox) === null || _vm$selectedEnergyBox === void 0 ? void 0 : _vm$selectedEnergyBox.id
    },
    on: {
      "error": _vm.onError,
      "success": _vm.onSuccess
    }
  }), _c('v-card', {
    staticClass: "elevation-2 ma-0 pa-2",
    staticStyle: {
      "position": "relative",
      "height": "100%"
    }
  }, [!_vm.maint ? _c('MapFilter', {
    staticClass: "opacity-9 map-filter",
    on: {
      "updateFilter": _vm.updateFilter
    }
  }) : _vm._e(), _c('v-btn', {
    staticClass: "elevation-5 refresh-btn primary lighten-1",
    attrs: {
      "color": "white",
      "large": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.update();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1), _c('GmapMap', {
    ref: "GmapMap",
    staticStyle: {
      "display": "flex",
      "flex-flow": "column",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "options": _vm.options,
      "center": _vm.center,
      "zoom": _vm.zoom
    }
  }, _vm._l(_vm.boxes, function (box) {
    return _c('GmapCustomMarker', {
      key: box.id,
      class: {
        'cursor-pointer': _vm.filter.customer
      },
      attrs: {
        "marker": {
          lat: box === null || box === void 0 ? void 0 : box.lat,
          lng: box === null || box === void 0 ? void 0 : box.lng
        }
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openBox(box);
        }
      }
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var _box$label;

          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('div', [_c('div', _vm._g({
            staticClass: "text-center"
          }, on), [_c('v-icon', {
            attrs: {
              "large": "",
              "color": _vm.getBoxColor(box)
            }
          }, [_vm._v("mdi-map-marker")]), _vm.zoom >= 19 ? _c('v-alert', {
            staticClass: "mt-1 px-2 py-1 rounded box_marker",
            attrs: {
              "outlined": ""
            }
          }, [_c('div', {
            staticClass: "text-center"
          }, [_c('div', {
            staticClass: "legend text--secondary"
          }, [_vm._v(_vm._s((_box$label = box === null || box === void 0 ? void 0 : box.label) !== null && _box$label !== void 0 ? _box$label : 0))]), _c('v-icon', {
            staticStyle: {
              "vertical-align": "baseline"
            },
            attrs: {
              "color": _vm.getWaterColor(box),
              "normal": ""
            }
          }, [_vm._v("mdi-water")]), _c('v-icon', {
            staticStyle: {
              "vertical-align": "baseline"
            },
            attrs: {
              "color": _vm.getElecColor(box),
              "normal": ""
            }
          }, [_vm._v("mdi-flash")])], 1)]) : _vm._e()], 1)])];
        }
      }], null, true)
    }, [_c('div', [_c('div', [_c('div', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(box === null || box === void 0 ? void 0 : box.label))])]), _vm._l(box === null || box === void 0 ? void 0 : box.plugs, function (plug) {
      return _c('div', [_c('strong', [_vm._v(_vm._s(plug.label))]), _c('v-icon', {
        attrs: {
          "normal": "",
          "color": "white"
        }
      }, [_vm._v(_vm._s(plug.type == 'ELEC' ? 'mdi-flash-outline' : 'mdi-water-outline'))]), plug.status != 'AVAILABLE' ? _c('strong', {
        staticClass: "warning--text"
      }, [_vm._v("Désactivé")]) : _vm._e(), plug.status == 'AVAILABLE' ? _c('strong', {
        staticClass: "ml-2"
      }, [_vm._v(_vm._s(plug.mode != 0 ? "Réservé" : "Libre"))]) : _vm._e()], 1);
    })], 2)])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }