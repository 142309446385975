













































































































import { Component, Prop, Vue } from "vue-property-decorator";

import MapFilter, { MapFilterModel } from "@/components/hmi/map/MapFilter.vue";
import DialogBoxBooking from "./DialogBoxBooking.vue";

import EnergyBox from "@/components/model/energybox/EnergyBox";
import { PaginatedResults, PLC } from "@/components/model/energybox/Metrics";
let energyBox = new EnergyBox();

import {
    getNbElecAvailable,
    getNbWaterAvailable,
    getBoxColor,
    getElecTypeLabel,
    getWaterTypeLabel,
} from "@/shared/utils";

import DialogEnergyBox from "../plcenergy/DialogEnergyBox.vue";

interface Coord {
    lat: number;
    lng: number;
}

interface BoxModel {
    id: number;
    num: number;
    coord: Coord;
}

@Component({
    components: { MapFilter, DialogBoxBooking, DialogEnergyBox },
})
export default class extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    maint!: boolean;

    markers = [];
    center = { lat: 0, lng: 0 };
    options = {
        zoomControl: false,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: [],
        clickableIcons: false,
    };
    map: any = null;
    zoom: number = 18;
    boxes: PLC[] = [];
    lastUpdateMs: number = 0;
    filter: MapFilterModel = { customer: null };
    selectedEnergyBox: PLC | null = null;
    // ="insert into api_plc (" & A$1&", "&B$1&", "&C$1&", "&D$1&", "&E$1&", "&F$1&", "&G$1&", "&H$1&", "&I$1&", "&J$1 & ") values ("&A2&", "&B2&", "&C2&", "&D2&", '"&E2&"', "&F2&", "&G2&", "&H2&", "&I2&", "&J2 & ");"
    // get boxes(): BoxModel[] {
    //     return [
    //         {id:1, num:123, coord: {lat:43.51958226907424, lng: 4.129414243474056}},
    //         {id:2, num:124, coord: {lat:43.519501917449446, lng: 4.129255740982484}},
    //         {id:3, num:125, coord: {lat:43.51942421321461, lng: 4.129095017241933}},
    //         {id:4, num:126, coord: {lat:43.51933677076212, lng: 4.128913180392989}},
    //         {id:5, num:127, coord: {lat:43.519250665804776, lng: 4.128735140556553}},
    //         {id:6, num:128, coord: {lat:43.51914915656129, lng: 4.1285472802737795}},
    //         {id:7, num:129, coord: {lat:43.51907167417482, lng: 4.12837350236365}},
    //         {id:8, num:130, coord: {lat:43.51897716435075, lng: 4.128193337388562}},
    //         {id:9, num:131, coord: {lat:43.51889794260435, lng: 4.1280285060212485}},
    //         {id:10, num:132, coord: {lat:43.518808991478124, lng: 4.127846425146007}},
    //         //{id:11, num:133, coord: {lat:43.51872860551024, lng: 4.127685841993579}},
    //     ]
    // }

    getWaterIndice(i: number): number {
        return i - 1;
    }

    getElecIndice(i: number): number {
        return i + 3;
    }

    update(): void {
        console.log("update map");
        this.fetchBoxes();
    }

    updateFilter(filter: MapFilterModel): void {
        this.filter = filter;
        console.log(this.filter);
        this.update();
    }

    mounted(): void {
        (this.$refs?.GmapMap as any).$mapPromise?.then((map: any) => {
            const google = (window as any).google;
            google.maps.event.addListener(map, "zoom_changed", () => {
                this.zoom = map.getZoom();
            });
        });
        this.update();
    }

    getDialogBoxBooking(): DialogBoxBooking {
        return this.$refs.DialogBoxBooking as DialogBoxBooking;
    }

    getDialogEnergyBox(): DialogEnergyBox {
        return this.$refs.DialogEnergyBox as DialogEnergyBox;
    }

    openBox(box: PLC): void {
        // if (! this.filter.customer) {
        //     this.onError("Vous devez d'abord choisir un client", new Error())
        //     return
        // }
        //this.$router.push('/box/' + item.id)
        this.selectedEnergyBox = box;
        if (this.maint) {
            this.getDialogEnergyBox().showDialog();
        } else {
            this.getDialogBoxBooking().showDialog();
        }
    }

    async fetchBoxes(): Promise<void> {
        console.log("refresh map 1");
        let response: PaginatedResults<PLC> = await energyBox.listMetrics(
            1,
            256
        );
        this.boxes = response.results;
        this.lastUpdateMs = Date.now();

        console.log("refresh map 2", this.boxes);
        for (const box of this.boxes) {
            this.center = { lat: box.lat, lng: box.lng };
            break;
        }
    }

    getBoxColor(box: PLC): string {
        return getBoxColor(this.lastUpdateMs, box);
    }

    getElecColor(box: PLC): string {
        if (!box) {
            return "secondary";
        }

        const nb = getNbElecAvailable(
            box,
            this.filter.customer?.plug_elec ?? null
        );

        return nb > 0 ? "primary" : "white";
    }

    getWaterColor(box: PLC): string {
        if (!box) {
            return "secondary";
        }

        const nb = getNbWaterAvailable(
            box,
            this.filter.customer?.plug_water ?? null
        );

        return nb > 0 ? "primary" : "white";
    }

    getElecTypeLabel(typeElec: number) {
        return getElecTypeLabel(typeElec);
    }

    getWaterTypeLabel(typeWater: number) {
        return getWaterTypeLabel(typeWater);
    }
}
