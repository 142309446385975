import Model from "../Model.js"
import rest from '../../hmi/tool/rest.js'

export default class Subscribtion extends Model {
    constructor() {
        super("subscribtion")
    }

    defaultValues() {
        return {
            id: 0
            , customer_id: null
            , formule_id: null
            , plc_id: null
            , nfc_card_id: null
            , status: null
            , begin: null
            , end: null
            , is_auto_renew: true
        }
    }

    close(item, callbackOk, callbackError) {
        rest.post(
            this.api_url + item.id + "/close/"
            , item
            , (response) => {
                callbackOk(response)
            }
            , e => {
                callbackError(e)
            }
        )
    }

    getStatuses(_self) {
        let statuses = ["DRAFT", "PROGRESS", "CLOSED"]

        let result = []

        statuses.forEach(element => {
            result.push({value: element, label: _self.$t(this.content + '.' + 'status_' + element)})
        })

        return result
    }
    // getTypes(_self) {
    //     let types = ["ANNUAL", "MONTHLY", "TRIMESTER"]

    //     let result = []

    //     types.forEach(element => {
    //         result.push({value: element, label: _self.$t(this.content + '.' + 'type_' + element)})
    //     });

    //     // return [
    //     //     {value: "SET", label:_self.$t(this.content + '.' + 'type_SET')}, 
    //     //     {value: "ADD", label:_self.$t(this.content + '.' + 'type_ADD')},
    //     //     {value: "FLAG", label:_self.$t(this.content + '.' + 'type_FLAG')},
    //     //     {value: "CONSUMP", label:_self.$t(this.content + '.' + 'type_CONSUMP')},
    //     // ]

    //     return result
    // }
}


