<template lang="pug">
div
  // CONSUMPTION
  div(v-if="item.nfcvaluemodifier.type == 'CONSUMP'")
    v-layout()
      v-flex(
          xs6
          md6
      )
        v-text-field.mr-4(
          :prefix="item.nfcvaluemodifier.parent.unit" 
          :label="item.nfcvaluemodifier.label" 
          v-model="item.int_value"
          @blur="markDirty()"
          :readonly="readonly"
        )
        //  :rules="[(v) => !!v || $t('common.required')]"
      v-flex(
          xs6
          md6
      )
        v-text-field.ml-4(
          v-if="item.nfcvaluemodifier.parent.is_valued"
          :prefix="getCurrency()" 
          :label="$t('productnfcvaluemodifier.price_value')" 
          v-model="item.price_value"
          @blur="markDirty()"
          :rules="[(v) => !!v || $t('common.required')]"
          :readonly="readonly"
        )
      //- v-flex(
      //-     xs6
      //-     md6
      //- )
      //-   v-text-field.mr-4(
      //-     :prefix="item.nfcvaluemodifier.parent.unit" 
      //-     :label="item.nfcvaluemodifier.label" 
      //-     v-model="item.int_value"
      //-     @blur="markDirty()"
      //-     :rules="[(v) => !!v || $t('common.required')]"
      //-   )
      //- v-flex(
      //-     xs6
      //-     md6
      //- )
      //-   v-text-field.ml-4(
      //-     v-if="item.nfcvaluemodifier.parent.is_valued"
      //-     :prefix="getCurrency()" 
      //-     :label="$t('productnfcvaluemodifier.price_value')" 
      //-     v-model="item.price_value"
      //-     @blur="markDirty()"
      //-     :rules="[(v) => !!v || $t('common.required')]"
      //-   )
  // ADD
  div(v-if="item.nfcvaluemodifier.type == 'ADD'")
    v-layout()
      // NOT valued
      v-flex(
          xs12
          md12
          v-if="! item.nfcvaluemodifier.parent.is_valued"
      )
        v-text-field.mr-4(
          :label="item.nfcvaluemodifier.parent.label + ' (' + item.nfcvaluemodifier.parent.unit + ')'" 
          v-model="item.int_value"
          @blur="markDirty()"
          :readonly="readonly"
        )
      // valued
      v-flex(
          xs12
          md12
          v-if="item.nfcvaluemodifier.parent.is_valued"
      )
        v-text-field.mr-4(
          :label="item.nfcvaluemodifier.label + ' (' + item.nfcvaluemodifier.parent.unit + ')'" 
          v-model="item.int_value"
          @blur="markDirty()"
          :readonly="readonly"
        )
      v-flex(
          xs6
          md6
          v-if="false && item.nfcvaluemodifier.parent.is_valued"
      )
        v-text-field.ml-4(
          :label="$t('productnfcvaluemodifier.refund') + ' (' + getCurrency() + ')'" 
          v-model="item.price_value"
          @blur="markDirty()"
          :readonly="readonly"
        )
  // FLAG
  div(v-if="item.nfcvaluemodifier.type == 'FLAG'")
    v-layout()
      v-flex(
        xs12
        md12
      )
        h2 {{item.nfcvaluemodifier.label}}
    v-layout(v-for="(line, l) in getNbLines()" :key="item.id + '_' + l")
      v-flex(
        xs1
        md1
        v-for="(flag, f) in getNbFlagsInLine(l)"
        :key="item.id + '_' + (10 * l + f)"
      )
        v-checkbox(
          :label="getFlagLabel(10 * l + f)" 
          v-model="flags_to_one[10 * l + f]"
          :readonly="readonly"
        )
  //DAY
  div(v-if="item.nfcvaluemodifier.type == 'DAY'")
    v-layout()
      v-flex(
          xs12
          md12
      )
        v-text-field.mr-4(
          :label="item.nfcvaluemodifier.parent.label + ' (' + $t('nfcvaluemodifier.type_DAY') + ')'" 
          v-model="item.int_value"
          @blur="markDirty()"
          :readonly="readonly"
        )
</template>

<script>
  import Model from "../../model/productnfcoperation/Productnfcoperation.js"
  let model = new Model()

  export default {
    components: {

    },
    props: {
      item : Object,
      readonly: Boolean,
      bus: Object
    },
    computed: {

    },
    watch: {
      // ne marche pas car prop
      // item: function() {
      //   // item n'est pas chargé par model mais par son composant parent, il est donc nécessaire de faire une deserialisation
      //   Object.assign(this.item, model.deserializeRow(this.item))
      //   console.log("deserializeRow")
      // },
      flags_to_one: {
        handler: function() {
          if (this.listen) {
            //console.log(this.flags_to_one)
            let temp = []
            for (let i = 0; i < this.flags_to_one.length; i++) {
              let byteIndex = Math.floor(i / 8)
              if (byteIndex >= temp.length) {
                temp.push(0)
              }
              
              if (this.flags_to_one[i]) {
                temp[byteIndex] += 1 << i % 8
              }
            }
  
            this.item.flags_to_one = temp
            console.log(temp)
            //console.log(this.item, this.item.flags_to_one)
            this.markDirty()
          }
        }
        , deep: true
        , sync: true
      },

      "item.flags_to_one": function() {
        console.log("item.flags_to_one")
      }
    }, 
    data: () => ({
      flags_to_one: [],
      listen: false, 
      dirty: false
    }),

    methods: {
      markDirty() {
        this.dirty = true
      },
      read(blocks) {
        console.log("blocks", blocks)
      },
      save()  {
        if (! this.dirty) {
          return
        }
        console.dir(this.item, { depth: null })
        // passer par model va faire la serialization / deserialisation
        model.update(
          this.item
          , (response) => {
            //Object.assign(this.item, model.deserializeRow(response.data))
            Object.assign(this.item, response.data)
            this.dirty = false
            this.$emit('success', 'common.updated', this.item)
          }
          , (e) => {
            this.$emit('error', 'common.created_error', e)
          }
        )
      },
      getFlagLabel(pos) {
        for (let label of this.item.nfcvaluemodifier.parent.labels) {
          if (label.value == pos) {
            return label.label
          }
        }

        return '' +  (pos + 1)
      },
      getNbFlagsInLine(l) {
        let remaining = this.flags_to_one.length - 10 * l
        return Math.min(10, remaining)
      },
      getNbLines() {
        return Math.ceil(this.flags_to_one.length / 10) 
      },

      getCurrency() {
        return model.getCurrency()
      },
    },

    created () {

    },

    mounted () {
      this.bus.$on('save', this.save)
      this.bus.$on('read', this.read)

      // item n'est pas chargé par model mais par son composant parent, il est donc nécessaire de faire une deserialisation
      Object.assign(this.item, model.deserializeRow(this.item))
      //console.log("deserializeRow")

      if (this.item.nfcvaluemodifier.parent.type == "FLAG") {
        let flags_to_one = []

        for (let i = 0; i < this.item.nfcvaluemodifier.parent.size * 8; i++) {
          let byte  = 0
          let byteIndex = Math.floor(i / 8)
          if (byteIndex < this.item.flags_to_one.length) {
            byte = this.item.flags_to_one[byteIndex]
          }
          //console.log(this.item.flags_to_one, i, byteIndex)

          flags_to_one.push((byte & 1 << (i % 8)) != 0)
        }

        this.flags_to_one = flags_to_one
        //console.log(this.item.flags_to_one)
        this.listen = true
        //console.log(this.item.nfcvaluemodifier.parent.label, this.flags_to_one)
      }
    },
  }

</script>
