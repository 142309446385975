var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": _vm.width,
      "persistent": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.title ? _c('div', [_vm._v(_vm._s(_vm.title))]) : _vm._e()]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "ma-2 text-center",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.force(false, false);
      }
    }
  }, [_vm._v("Ne pas Forcer")]), _c('v-divider', {
    staticClass: "ma-2"
  }), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.force(true, false);
      }
    }
  }, [_vm._v("Forcer à 0")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.force(true, true);
      }
    }
  }, [_vm._v("Forcer à 1")])], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("Annuler ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }