export default {
  components: {
  }
  , props: {
    value: null,
    showTitle: false
  }
  , computed: {
  }
  , watch: {
    // value: function() {
    //   console.log("yop")
    //   this.total_lines = [this.value]
    // }
  }
  , data: () => ({
    total_headers: [
      {align: "end", width: "2", text: 'basket.total_ht', value: 'total_ht' },
      {align: "end", width: "2", text: 'basket.total_ttc', value: 'total_ttc' },
    ]
    , total_columns: []
    , total_lines : []
  })
  , methods: {

  }

  , mounted() {
    this.total_columns = [
      {class: "text-right", value: 'total_ht', format: this.formatCurrency},
      {class: "text-right", value: 'total_ttc', format: this.formatCurrency},
    ]
  }
}
