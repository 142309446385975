var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.transactions,
      "item-key": "id",
      "options": _vm.pagination,
      "server-items-length": _vm.totalItems,
      "footer-props": {
        'items-per-page-options': [10, 25, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.customer",
      fn: function fn(_ref) {
        var _item$customer_compan, _item$customer_first_, _item$customer_last_n;

        var item = _ref.item;
        return [item.isCompany ? _c('div', [_c('span', [_vm._v(_vm._s((_item$customer_compan = item.customer_company_name) !== null && _item$customer_compan !== void 0 ? _item$customer_compan : ""))])]) : _vm._e(), !item.isCompany ? _c('div', [_c('span', [_vm._v(_vm._s((_item$customer_first_ = item.customer_first_name) !== null && _item$customer_first_ !== void 0 ? _item$customer_first_ : "") + " " + _vm._s((_item$customer_last_n = item.customer_last_name) !== null && _item$customer_last_n !== void 0 ? _item$customer_last_n : ""))])]) : _vm._e()];
      }
    }, {
      key: "item.begin",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatDatetime(item.begin)))])];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(item.end ? _vm.formatDatetime(item.end) : "/"))])];
      }
    }, {
      key: "item.plcLabel",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item.plc_label))])];
      }
    }, {
      key: "item.plugLabel",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.plug_label))])];
      }
    }, {
      key: "item.plugType",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(_vm._s(item.plug_type == "ELEC" ? "Elec" : "Eau"))])];
      }
    }, {
      key: "item.qty",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(_vm._s(item.current_qty) + " " + _vm._s(item.plug_type == "ELEC" ? "kw/h" : "L"))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }