<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include CustomerPart.pug

</template>


<script >
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"


  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      elecPlugs: [],
      waterPlugs: []
    }),
    watch: {
    },

    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      emailRule (v) {
        if (v == null || v === '') {
          return this.$i18n.t(this.model.content + '.' + 'emailRequired')
        } 
        if (! v.match(".+@.+[.].+")) {
          return this.$i18n.t(this.model.content + '.' + 'emailValid')
        }
        return true
      },

      setup: function(){
        this.elecPlugs = this.model.getElecPlugs(this)
        this.waterPlugs = this.model.getWaterPlugs(this)
      }
    },
  }

</script>
