var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "inspire"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "justify-center": ""
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "550"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t("common.delete_title")))]), _c('v-card-text', [_vm._v(_vm._s(_vm.$t("common.delete_question")))]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.confirmDelete($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.yes")))]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.cancelDelete($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.no")))])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }