import { render, staticRenderFns } from "./TotalVat.vue?vue&type=template&id=462fa8ec&lang=pug&"
import script from "./TotalVat.js?vue&type=script&lang=js&"
export * from "./TotalVat.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardTitle,VDataTable})
