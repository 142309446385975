var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-navigation-drawer', {
    attrs: {
      "clipped": _vm.$vuetify.breakpoint.lgAndUp,
      "fixed": "",
      "app": "",
      "floating": "",
      "permanent": ""
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [item.children && _vm.isAllowed(item) ? _c('v-list-group', {
      key: item.text,
      attrs: {
        "no-action": "",
        "prepend-icon": item.model ? item.icon : item['icon-alt']
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(item.text)))])];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.model,
        callback: function callback($$v) {
          _vm.$set(item, "model", $$v);
        },
        expression: "item.model"
      }
    }, _vm._l(item.children, function (child, i) {
      return _vm.isAllowed(child) ? _c('v-list-item', {
        key: i,
        on: {
          "click": function click($event) {
            return _vm.gotoPage(child);
          }
        }
      }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(child.text)))]), child.icon ? _c('v-list-item-action', [_c('v-icon', [_vm._v(_vm._s(child.icon))])], 1) : _vm._e()], 1) : _vm._e();
    }), 1) : _vm._e()];
  }), _c('v-list-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-action', [_c('v-icon', [_vm._v("logout")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('hmi.logout')))])], 1)], 1)], 2)], 1), _c('v-app-bar', {
    staticClass: "pa-2",
    attrs: {
      "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
      "app": "",
      "fixed": ""
    }
  }, [_c('img', {
    staticClass: "mb-3",
    attrs: {
      "src": "/static/img/Logo_DC-Plaisance_couleur.PNG",
      "height": "40"
    }
  }), _c('v-toolbar-title', {
    staticClass: "ml-0 pl-3"
  }), _c('v-spacer')], 1), _c('v-main', {
    staticClass: "mb-14"
  }, [_c('v-snackbar', {
    attrs: {
      "color": _vm.type,
      "multi-line": false,
      "timeout": 6000,
      "vertical": false
    },
    model: {
      value: _vm.showMsg,
      callback: function callback($$v) {
        _vm.showMsg = $$v;
      },
      expression: "showMsg"
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.msg))), _c('v-btn', {
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showMsg = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.close")))])], 1), _c('v-fade-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('router-view', {
    on: {
      "success": _vm.success,
      "error": _vm.error
    }
  })], 1), _c('v-layout', {
    staticClass: "ma-0 fab-container",
    staticStyle: {
      "z-index": "5"
    },
    attrs: {
      "top": "",
      "left": ""
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "fab": "",
      "small": "",
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.displayDialogReadNFCCard
    }
  }, [_c('v-icon', [_vm._v("crop_portrait")])], 1), _vm.hasRight('is_sell') ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "fab": "",
      "small": "",
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.basketCreate
    }
  }, [_c('v-icon', [_vm._v("shopping_cart")])], 1) : _vm._e(), _vm.hasRight('is_sell') ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "fab": "",
      "dark": "",
      "small": ""
    },
    on: {
      "click": _vm.redirectMap
    }
  }, [_c('v-icon', [_vm._v("mdi-map-outline")])], 1) : _vm._e(), _vm.hasRight('is_maint') ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "fab": "",
      "dark": "",
      "small": ""
    },
    on: {
      "click": _vm.redirectMapMaint
    }
  }, [_c('v-badge', {
    attrs: {
      "value": true,
      "color": "grey",
      "content": "?"
    }
  }, [_c('v-icon', [_vm._v("mdi-map-outline")])], 1)], 1) : _vm._e()], 1)], 1), _c('div', [_c('CardReader', {
    attrs: {
      "show": _vm.showCardReader
    },
    on: {
      "hide": function hide($event) {
        _vm.showCardReader = false;
      },
      "success": _vm.success,
      "error": _vm.error
    }
  }), _c('DialogReadNFCCard', {
    ref: "DialogReadNFCCard",
    on: {
      "success": _vm.success,
      "error": _vm.error
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }