<template lang="pug">
  include ../tool/CommonList.pug

</template>


<script>
  import {Model, EditDialog} from "./imports.js"
  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      createItem: function() {
        //console.log("create")
        //this.model.viewItem(this.model.defaultValues())
        this.model.redirect("create/0")
      },
      editItem: function(item) {
        //closed / progress / draft
        if (item.status == "DRAFT") {
          this.model.redirect("create/" + item.id)
        } else if (item.status == "PROGRESS") {
          if (this.isViewAllowed(item)) {
            this.model.viewItem(item, false)
          } else {
            this.showEditDialog = true
            // trick because dialog will not render until next tick and $refs will remain empty :(
            this.nextTick(() => {
              this.prepareEditDialog(this.$refs.EditDialog)
              this.$refs.EditDialog.edit(this.prepareValue(item))
            })
          }
        }
      },

      loadModel: function() {
        this.model = new Model()
      },
      
      isDeleteAllowed: function(item) {
        //return true
        return item.status == "DRAFT"
      },
      isEditAllowed: function(item) {
        return item.status != "CLOSED"
      },
      isViewAllowed: function(item) {
        return item.status != "DRAFT"
      },
      setup () {
        this.headers = [
          {align: "start", width: "10", text: 'subscribtion.customer', value: 'customer', sortable: false},
          {align: "center", width: "60", text: 'subscribtion.begin', value: 'begin', sortable: false},
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ],

        this.columns = [
          {class: "text-left", value: 'customer', format: this.formatCustomer},
          {class: "text-center", value: 'begin', format: this.formatDate},
          {class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]
      },
      formatCustomer: function (value) {
        let res  = ""
        if (value != null) {
          if (! value.is_company) {
            if (value.first_name != null) {
              res += " " + value.first_name
            }
            if (value.last_name != null) {
              res += " " + value.last_name
            }
          } else {
            res += " " + value.company_name
          }
        }

        return  res.trim()
        //return value.email
      },
    },

  }

</script>
