import Model from "../Model.js"


export default class PLCVariable extends Model {
    constructor() {
        super("plcvariable")
    }

    defaultValues() {
        return {
            id: 0,
            parent: null,
            type: null,
            card_uid_field_id: null,
            card_value_field_id: null,
            nfcinfo_id: null,
            prefix: '',
            suffix: '',
            offset: 0,
            ratio: 1,
        }
    }

    getTypes(_self) {
        return [
            {value: "TIMESTAMP_S", label:_self.$t(this.content + '.type_' + 'TIMESTAMP_S')}, 
            {value: "FLOAT_VALUE", label:_self.$t(this.content + '.type_' + 'FLOAT_VALUE')}, 
            {value: "INT_VALUE", label:_self.$t(this.content + '.type_' + 'INT_VALUE')}, 
            {value: "FLAG", label:_self.$t(this.content + '.type_' + 'FLAG')},
            {value: "CARD_ID_4B", label:_self.$t(this.content + '.type_' + 'CARD_ID_4B')},
            {value: "CARD_ID_7B", label:_self.$t(this.content + '.type_' + 'CARD_ID_7B')},
            {value: "SELECT", label:_self.$t(this.content + '.type_' + 'SELECT')},
            {value: "LOCALISATION", label:_self.$t(this.content + '.type_' + 'LOCALISATION')},
        ]
    }
}


