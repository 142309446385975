var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('div'), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "items": _vm.types,
      "item-value": "value",
      "item-text": "label",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'type')
    },
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'reference')
    },
    model: {
      value: _vm.item.reference,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "reference", $$v);
      },
      expression: "item.reference"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": 0,
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'price_ht'),
      "prefix": this.model.getCurrency()
    },
    model: {
      value: _vm.item.price_ht,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "price_ht", $$v);
      },
      expression: "item.price_ht"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [this.item.type === 'ANNUAL' || this.item.type === 'MONTHLY' || this.item.type === 'TRIMESTER' ? _c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "items": _vm.vats,
      "item-value": "id",
      "item-text": "label",
      "rules": [function (v) {
        return !((_this.item.type === 'ANNUAL' || _this.item.type === 'MONTHLY' || _this.item.type === 'TRIMESTER') && v == null) || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'vat')
    },
    model: {
      value: _vm.item.vat_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "vat_id", $$v);
      },
      expression: "item.vat_id"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'is_visible')
    },
    model: {
      value: _vm.item.is_visible,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_visible", $$v);
      },
      expression: "item.is_visible"
    }
  })], 1)], 1)], 1)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }