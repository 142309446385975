



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomerService, {
    Customer,
} from "@/components/model/customer/CustomerTS";
import { getElecTypeLabel, getWaterTypeLabel } from "@/shared/utils";

type DisplayableCustomer = Customer & { shortdesc?: string };

export interface MapFilterModel {
    customer: DisplayableCustomer | null;
}

const customerService = new CustomerService();

@Component
export default class extends Vue {
    items: DisplayableCustomer[] = [];
    isLoading: boolean = false;
    model: DisplayableCustomer | null = null;
    filter: MapFilterModel = { customer: null };
    search: string = "";

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    noBorder!: boolean;

    // @Prop({
    //     type: String,
    //     required: false,
    //     default: "error",
    // })
    // bgColor!: string

    @Watch("search")
    searchChanged(newValue: string): void {
        this.fetch();
    }

    @Watch("filter", { deep: true })
    updateFilterCustomer(): void {
        this.$emit("updateFilter", this.filter);
    }

    setCustomer(customer?: Customer): void {
        if (!customer) {
            this.filter.customer = null;
            return;
        }

        this.filter.customer = this.getDisplayableCustomer(customer);
    }

    clearCustomer(): void {
        this.filter.customer = null;
    }

    getDisplayableCustomer(item: Customer): DisplayableCustomer {
        const displayableCustomer: DisplayableCustomer = { ...item };
        displayableCustomer.shortdesc = `${
            item.is_company
                ? item.company_name ?? "?"
                : (item.first_name ?? "?") + " " + (item.last_name ?? "?")
        } | ${item.email ?? "?"}`;
        return displayableCustomer;
    }

    async fetch(): Promise<void> {
        this.isLoading = true;
        try {
            this.items = (
                await customerService.listP(1, 50, { search: this.search })
            ).map((item: Customer) => {
                // const displayableCustomer: DisplayableCustomer = {... item}
                // displayableCustomer.shortdesc = `${item.is_company ? item.company_name ?? "?" : (item.first_name ?? '?') + ' ' + (item.last_name ?? '?')} | ${item.email ?? '?'}`
                return this.getDisplayableCustomer(item);
            });
        } catch (error) {
            this.items = [];
            this.onError("La liste des clients n'a pas pu être chargée", error);
        } finally {
            this.isLoading = false;
        }
    }

    getElecTypeLabel(typeElec: number) {
        return getElecTypeLabel(typeElec);
    }

    getWaterTypeLabel(typeWater: number) {
        return getWaterTypeLabel(typeWater);
    }

    // getElecTypeLabel(v: number): string | undefined {
    //     return getLabel(v, typesElec)
    // }

    // getWaterTypeLabel(v: number): string | undefined {
    //     return getLabel(v, typesWater)
    // }

    mounted(): void {
        this.fetch();
    }
}
