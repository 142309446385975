




























































































































































































import {
    PLC,
    Forcage,
    typesBox,
    typesCounting,
    getLabel,
} from "@/components/model/energybox/Metrics";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import EnergyBoxPlugs from "@/components/hmi/plcenergy/EnergyBoxPlugs.vue";

import EnergyBox from "@/components/model/energybox/EnergyBox";
import { getBoxColor, getStatusColor } from "@/shared/utils";
let energyBox = new EnergyBox();

import DialogForcing from "@/components/hmi/dialog/DialogForcing.vue";

@Component({
    components: {
        EnergyBoxPlugs,
        DialogForcing,
    },
})
export default class extends Vue {
    forcage: Forcage = { forcage: false, value: false };
    titleForcage: string = "";
    overlay: boolean = false;
    lastUpdateMs: number = 0

    @Watch("box")
    boxChanged(): void {
        this.lastUpdateMs = Date.now();
    }

    @Prop({
        type: Number,
        required: false,
    })
    id?: number;

    box: PLC | null = null;

    show: boolean = false;
    tab: number | null = 2;
    // infos: number[] = [0,1,2,3,4]

    @Watch("id")
    idChanged(): void {
        this.fetchData();
    }

    update(): void {
        this.fetchData();
    }

    clickOutput(i: number): void {
        this.showForcageOutput(i);
    }

    getDialogForcing(): DialogForcing {
        return this.$refs.DialogForcing as DialogForcing;
    }

    async showForcageOutput(i: number): Promise<void> {
        const forcage = this.box?.metrics?.forcage_s_voy?.[i];
        if (forcage) {
            this.forcage = forcage;
            this.titleForcage = `Forçage sortie ${i + 1}`;
            const f: Forcage = await this.getDialogForcing().showDialog();

            if (f) {
                try {
                    // const currentForcages = this.energyBox?.metrics?.forcage_s_voy
                    // if (! currentForcages) {
                    //   return
                    // }
                    // const forcages = [... currentForcages]
                    // forcages[i] = forcage
                    if (this.box?.id) {
                        await this.forcerSortie(i, f.forcage, f.value);
                        this.onSuccess("La demande a été envoyée");
                    }
                } catch (e) {
                    this.onError(
                        "La sortie n'a pas pu être forcée",
                        e as Error
                    );
                }
            }
        }
    }

    async forcerSortie(
        num: number,
        forcage: boolean,
        value: boolean
    ): Promise<void> {
        let plcId: number | undefined = this.box?.id;
        //let customerId: number | undefined = this.customer?.id
        if (plcId) {
            try {
                this.overlay = true;
                const plc: PLC = await energyBox.forcerSortie(
                    plcId,
                    num,
                    forcage,
                    value
                );
                this.box = plc;
                this.onSuccess("Sortie modifiée");
            } catch (error: any) {
                this.box = error.plc ?? this.box;
                this.onError(
                    `La sortie n'a pas pu être modifiée. Cause: ${error?.errorMsg}`,
                    error as Error
                );
            } finally {
                this.overlay = false;
            }
        }
    }

    async fetchData(): Promise<void> {
        console.log("fetchData");
        let box: PLC | null = null;
        try {
            if (this.id) {
              // box = await energyBox.getMetrics(this.id);
              const res = await energyBox.read(this.id);
              if (! res.updated) {
                console.log(res);
                this.onError("Les valeurs ne sont peut-être pas à jour", new Error("Not updated"));
              }
              box = res.plc;
            }
        } catch (error) {
            this.onError(
                "Impossible de charger les informations de la borne",
                error as Error
            );
        } finally {
            this.box = box;
        }
    }

    getBoxColor(): string {
        return getBoxColor(this.lastUpdateMs, this.box) + "--text";
    }

    showDialog(): void {
        this.show = true;
    }

    getTypeBoxLabel(id: number): string | undefined {
        return getLabel(id, typesBox);
    }

    getTypeCountingLabel(id: number): string | undefined {
        return getLabel(id, typesCounting);
    }

    getInputColor(index: number): string {
        if (
            !this.box?.metrics ||
            !this.box?.metrics?.lesetors ||
            index >= this.box?.metrics?.lesetors?.length
        ) {
            return "warning";
        }
        return this.box?.metrics?.lesetors?.[index] ? "success" : "secondary";
    }

    getOutputColor(index: number): string {
        if (
            !this.box?.metrics ||
            !this.box?.metrics?.lesstors ||
            index >= this.box?.metrics?.lesstors?.length
        ) {
            return "warning";
        }
        return this.box?.metrics?.lesstors?.[index] ? "success" : "secondary";
    }

    getStatusColor(lastOk: string): string {
        return getStatusColor(this.lastUpdateMs, lastOk);
    }
}
