<template lang="pug">
  extends ../tool/CommonDetail.pug
  //- block detail
  //-   v-tabs(v-model="tab")
  //-     v-tab.elevation-2(
  //-       v-for="marker in item.markers"
  //-       :key="'marker_' + marker.id"
  //-       v-if="! marker.code.endsWith('_HIDDEN')"
  //-     ) {{marker.label}}

  //-     v-tab.elevation-2 {{$t(this.model.content + '.' + 'energies')}}
  //-     v-tab.elevation-2 {{$t(this.model.content + '.' + 'access')}}
  //-     v-tab.elevation-2 {{$t(this.model.content + '.' + 'parkings')}}
  //-     v-tab.elevation-2 {{$t(this.model.content + '.' + 'showers')}}

  //-   v-tabs-items(v-model="tab")
  //-     v-tab-item(
  //-       v-for="marker in item.markers"
  //-       :key="'marker_detail_' + marker.id"
  //-       v-if="! marker.code.endsWith('_HIDDEN')"
  //-     )
  //-       v-card.elevation-2(flat)
  //-         v-card-text
  //-           ProductNFCValueModifier(
  //-             :readonly="readonly"
  //-             :item="filterValues(marker.code)[index]"
  //-             v-for="(valueModifier, index) in filterValues(marker.code)"
  //-             :key="marker.code + '_' + index"
  //-             @success="success"
  //-             @error="error"
  //-           )
   
    ListProductNfcOperationsLines(:parent_id="this.$route.params.id" @success="success" @error="error" :readonly="readonly")
</template>

<script>
  import CommonDetail from "../tool/CommonDetail.js";
  
  import { PRODUCT_TYPE_CARD_ACTIVATION, PRODUCT_TYPE_CARD_TIME, PRODUCT_TYPE_CARD_UNITS, PRODUCT_TYPE_RELOAD_OTHER, PRODUCT_TYPE_RELOAD_TIME, PRODUCT_TYPE_RELOAD_UNITS } from "../../model/product/Product.js";
import { EditDialog, Model } from "./imports.js";
  // import NFCValueModifierModel from "../../model/nfcvaluemodifier/NFCValueModifier.js"
  // var nfcValueModifierModel = new NFCValueModifierModel()

  // import ProductNFCValueModifier from "../productnfcvaluemodifier/ProductNFCValueModifier.vue"


  // import Productnfcoperation from "../../model/productnfcoperation/Productnfcoperation.js"
  // var productnfcoperation = new Productnfcoperation()

  import Vat from "../../model/vat/Vat.js";
  const vat = new Vat()

  // import ListProductNfcOperationsLines from "../productnfcoperation/ListProductNFCOperations.vue"

  export default {
    extends: CommonDetail,
    components: {
      Infos: EditDialog //, ProductNFCValueModifier, ListProductNfcOperationsLines
    },
    props: {
    },
    computed: {
      time_qty_water: {
        get() {
          console.log("get time_qty_water", this.item )
          return Math.round(this.item.qty_water / 60);
        },
        set(newValue) {
          this.item.qty_water = newValue * 60;
        },
      },
      time_qty_elec: {
        get() {
          return Math.round(this.item.qty_elec / 60);
        },
        set(newValue) {
          this.item.qty_elec = newValue * 60;
        },
      },
    },
    // watch: {
    //   item: function() {
    //     console.log(this.item.markers)
    //     // let markers = []

    //     if (this.item.productnfcvaluemodifiers) {
    //       for (let productnfcvaluemodifier of this.item.productnfcvaluemodifiers) {
    //         let found = false
    //         for (let marker of markers) {
    //           if (marker == productnfcvaluemodifier.marker_code) {
    //             found = true
    //             break
    //           }
    //         }

    //         if (! found) {
    //           markers.push(productnfcvaluemodifier.marker_code)
    //         }
    //       }
    //     }

    //     this.markers = markers
    //     console.log(this.markers)
    //   }
    // }, 
    data: () => ({
      tab: null,
      PRODUCT_TYPE_CARD_UNITS: PRODUCT_TYPE_CARD_UNITS,
      PRODUCT_TYPE_CARD_TIME: PRODUCT_TYPE_CARD_TIME,
      PRODUCT_TYPE_CARD_ACTIVATION: PRODUCT_TYPE_CARD_ACTIVATION,
      PRODUCT_TYPE_RELOAD_UNITS: PRODUCT_TYPE_RELOAD_UNITS,
      PRODUCT_TYPE_RELOAD_TIME: PRODUCT_TYPE_RELOAD_TIME,
      PRODUCT_TYPE_RELOAD_OTHER: PRODUCT_TYPE_RELOAD_OTHER,
    }),

    methods: {
      // filterValues(marker) {
      //   if (! this.item || ! this.item.productnfcvaluemodifiers) {
      //     return []
      //   }
      //   let results = this.item.productnfcvaluemodifiers.filter((v) => v.marker_code == marker)

      //   return results
      // },
      loadModel () {
        this.model = new Model()
      },


      setup() {
      }
    },


  }

</script>
