var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs4": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "md4": ""
    }
  }, [_c('v-select', {
    attrs: {
      "required": "",
      "items": _vm.types,
      "item-value": "value",
      "item-text": "label",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'type')
    },
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "md4": ""
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'is_only_credit'),
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.is_only_credit,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_only_credit", $$v);
      },
      expression: "item.is_only_credit"
    }
  })], 1)], 1), _vm.item.type == 'CUSTOMER_FIELD_SELECT' ? _c('v-layout', [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "label": _vm.$t(this.model.content + '.' + 'field')
    },
    model: {
      value: _vm.item.field,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "field", $$v);
      },
      expression: "item.field"
    }
  })], 1) : _vm._e(), _vm.item.type == 'FLAG' ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.flags,
      "chips": "",
      "label": "Mettre à Vrai",
      "multiple": ""
    },
    model: {
      value: _vm.flagsTo1,
      callback: function callback($$v) {
        _vm.flagsTo1 = $$v;
      },
      expression: "flagsTo1"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.flags,
      "chips": "",
      "label": "Mettre à Faux",
      "multiple": ""
    },
    model: {
      value: _vm.flagsTo0,
      callback: function callback($$v) {
        _vm.flagsTo0 = $$v;
      },
      expression: "flagsTo0"
    }
  })], 1)], 1) : _vm._e(), _vm.item.type == 'SET' ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "label": _vm.$t(this.model.content + '.' + 'int_value')
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1)], 1) : _vm._e()], 1)])], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }