import rest from '../../hmi/tool/rest.js'
import Model from "../Model.js"


export default class NFCCard extends Model {
    constructor() {
        super("nfccard")
    }

    defaultValues() {
        return {
            id: 0
            , uid: null
        }
    }

    backP(params) {
        return new Promise((success, error) => {
            params = this.serialize(params)
            rest.post(
                this.api_url + 'back/'
                , params
                , (response) => {
                    success(JSON.parse(response.data))
                }
                , e => {
                    error("nfc.error_reading_card_meta", e)
                    //self.$emit('error', 'common.not_found', e)
                }
            )
        })
    }

    getMetaInfosP(self, params) {
        return new Promise((successCallback, failureCallback) => {
            this.getMetaInfos(self, params, successCallback, failureCallback)
        })
    }

    getMetaInfos(self, params, callback, error) {
        console.log(callback, error)
        params = this.serialize(params)
        rest.post(
            this.api_url + 'meta_infos/'
            , params
            , (response) => {
                callback(JSON.parse(response.data))
            }
            , e => {
                error("nfc.error_reading_card_meta", e)
                //self.$emit('error', 'common.not_found', e)
            }
        )
    }
}


