













































































































































import EnergyBox from  '@/components/model/energybox/EnergyBox'
import { PaginatedResults, PLC } from '@/components/model/energybox/Metrics'
let energyBox = new EnergyBox()

import {Component, Vue, Watch} from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'

import EnergyBoxPlugs from './EnergyBoxPlugs.vue'
import DialogEnergyBox from './DialogEnergyBox.vue'
import { getStatusColor} from '@/shared/utils'


//! timeout gateway
//! timeout plc
//! last_ok

//! tooltip
//! forcages

@Component({
  components: {
    EnergyBoxPlugs
    , DialogEnergyBox
  }
})
export default class extends Vue {
    lastUpdateMs: number = 0
    pagination = {
      descending: true,
      page: 1,
      itemsPerPage: 10,
    }
    headers: DataTableHeader[] = []
    plcs: PLC[] = []
    selectedEnergyBox: PLC | null = null

    getDialogEnergyBox(): DialogEnergyBox {
      return this.$refs.DialogEnergyBox as DialogEnergyBox
    }

    openDialogEnergyBox(energyBox: PLC): void {
      this.selectedEnergyBox = energyBox
      this.getDialogEnergyBox().showDialog()
    }

    async fetchData(): Promise<void> {
      let response: PaginatedResults<PLC> = await energyBox.listMetrics(1, this.pagination.itemsPerPage)
      this.plcs = response.results
      this.lastUpdateMs = Date.now()
      console.log(this.plcs[0])
    }

    // getComGatewayColor(item: PLCWithMetrics): string {
    //   let color: string = "grey"
    //   if (item.metrics && ! (item.metrics.status_com === undefined)) {
    //     color = item.metrics.status_com === 0 ? "success" :  "error"
    //   }
    //   return color
    // }

    getStatusColor(lastOk: string): string {
      return getStatusColor(this.lastUpdateMs, lastOk)
    }
      // // let color: string = "grey"
      // // if (item.metrics && ! (item.metrics.status_esclave === undefined)) {
      // //   color = item.metrics.status_esclave === 0 ? "success" :  "error"
      // // }
      // // return color
      //   if (! box?.last_metrics_ok) {
      //       return "secondary"
      //   }
      //   if (isOlderThan(box?.last_metrics_ok, 1000 * 60 * 60)) {
      //       return "error"
      //   }
      //   if (isOlderThan(box?.last_metrics_ok, 1000 * 60 * 30)) {
      //       return "warning"
      //   }

      //   return "primary"
    // }


    getColor(item: PLC, key: string, i: number): string {
      let color: string = "grey"
      if (item.metrics && key in item.metrics) {
        const values: boolean[] = (item.metrics as any)[key] 
        if (i >= 0 && i < values.length) {
          color = values[i] ? "success" : "secondary"
        }
      }

      return color
    }

    update(): void {
      this.fetchData()
    }

    mounted() {
      this.headers.push(
        {align: "start", text: 'Borne', value: 'status_esclave' },
        {align: "start", text: 'Dernière màj', value: 'last_ok' },
        {align: "start", text: 'ID réseau', value: 'slave_id' },
        {align: "start", text: 'Libellé', value: 'label' },
        //{align: "start", text: 'Passerelle', value: 'status_com' },
        {align: "start", text: 'Version', value: 'version' },
        {align: "start", text: 'ID Carte', value: 'id_carte' },
        // {align: "start", text: 'ID Emplacement', value: 'localisation' },
        {align: "start", text: 'T°', value: 'la_temperature' },
        {align: "start", text: 'Etat', value: 'state' },
        {align: "start", text: 'Actions', value: 'actions' },
      )
      this.update()
    }
  }


