import axios from 'axios'
import db from './DB.js'
import router from '../../../router.js'

//import django from './Django.js'
let django = process.env.VUE_APP_BACK_END_HOST

export default {
    state: {
        rights: [],
        token: null,
        error: false,
        user: null,
        password: null,
        active_basket_id: null,
        company: {key: 'FFFFFFFFFFFF'},
        basketQty: 0,
    },
    currency: function() {
        return this.state.company.currency
    },
    isLogged() {
        return this.state.token != null
    },
    clear() {
        this.state.rights = 0
        this.state.token = null
        this.state.error = false

    },
    formatCurrency: function (value) {
        if (value == null) {
            return ""
        }
        return parseFloat(value).toFixed(this.state.company.nb_decimals) + " " + this.state.company.currency
    },
    formatRatio: function (value) {
        if (value == null) {
            return ""
        }
        return parseFloat(value).toFixed(this.state.company.nb_decimals) + " %"
    },
    login(callback) {
        this.clear()
        let _self = this

        axios.post(django + `/api/login`, {user: _self.state.user, password: _self.state.password})
            .then(response => {
                _self.state.token = response.data.token
                _self.state.rights = response.data.rights
                _self.state.is_maint = response.data.is_maint
                _self.state.is_sell = response.data.is_sell
                _self.state.is_sell_admin = response.data.is_sell_admin
                _self.state.company = response.data.company
                _self.state.active_basket_id = response.data.active_basket_id
                _self.state.password = null
                //console.log(response.data)
                _self.state.basketQty = response.data.basketQty

                db.logins.clear()
                db.logins.bulkAdd([
                    {login: _self.state.user},
                ]);

                //console.log(response.data.user)
                //console.log(_self.state.rights)
                callback()
                /*
                if (route) {
                    router.push({path:"/hmi/basket/fill"})
                }
                */
            })
            .catch(e => {
                _self.state.error = true
                console.log(e)
            })
    },

    logout() {
        this.clear()
        this.state.user = null
        this.state.password = null

        router.push({path:"/login"})
    },
    redirect(path) {
        router.push({path:path})
    },
    isAllowed(right) {
        //console.log("rights", this.state.rights)
        //console.log(right, " ", this.state.rights.includes(right))
        // return this.state.rights.includes(right)
        return this.state[right]
    },
}
