<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
      v-layout
        v-flex(
            xs12
            md12
        )
          v-select(
              :items="nfcvaluemodifiers"
              item-text="label"
              item-value="id"
              v-model="item.nfcvaluemodifier_id"

              :rules="[(v) => !!v || $t('common.required')]"
              :label="$t(this.model.content + '.' + 'nfcvaluemodifier')"
              :readonly="readonly"
          )
</template>

<script>
  import {Model} from "./imports.js"
  import CommonEdit from "../tool/CommonEdit.js"

  import NFCValueModifier from "../../model/nfcvaluemodifier/NFCValueModifier.js"
  const nfcValueModifierModel = new NFCValueModifier()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      listen: false,
      nfcvaluemodifiers: []
    }),
    watch: {
     
    }, 
    
    methods: {
      
      loadModel: function() {
        this.model = new Model()
      },

      load: function() {
        this.isLoading = true
        nfcValueModifierModel.list(
          1
          , 100
          , (response) => {
            this.nfcvaluemodifiers = response.data.results
            this.isLoading = false
            //this.pagination.totalItems = response.data.count
            //console.log("count", response.data.count)
            //this.$emit('success', 'customer.edit.updated')
          }
          , e => {
            console.log(e)
            this.isLoading = false
            //this.$emit('error', 'customer.edit.updated_error', e)
          }
          , {parent_id: '*'}
        )
      },

      setup: function(){
        this.load()
      }
    },
  }


</script>
