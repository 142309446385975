<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include SubscribtionPart.pug
</template>

<script>
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  import SearchCustomer from '../customer/SearchCustomer.vue'
  import CreateCustomerDialog from '../customer/EditCustomer.vue'

  import FormuletModel from "../../model/formule/Formule.js"
  const formuletModel = new FormuletModel()

  export default {
    extends: CommonEdit,
    components: {
      SearchCustomer, CreateCustomerDialog
    },
    props: {
    },
    data: () => ({
      formules: []
      , statuses: []
    }),
    watch: {
    }, 

    methods: {
      redirectCreate() {
        this.model.viewItem(this.item)
      },
      checkForm() {
        if (this.item.customer == null) {
          return false
        }
        this.item.customer_id = this.item.customer.id
        
        if (this.item.formule_id == null) {
          return false
        }
        return true
      },
      loadModel: function() {
        this.model = new Model()
      },
      setup: function(){
        this.loadFormules()
        this.statuses = this.model.getStatuses(this)
      },
      loadFormules: function() {
        this.isLoading = true
        formuletModel.listAll(
          (response) => {
            this.formules = response.data.results
            this.isLoading = false
          }
          , e => {
            console.log(e)
            this.isLoading = false
            //this.$emit('error', 'customer.edit.updated_error', e)
          }
          , {filters: {types: ['ANNUAL', 'MONTHLY', 'TRIMSTER']}}
        )
      },
    },

  }

</script>
