export default {
  components: {
  },
  props: {
  },
  computed: {
    currency: function() {
      return this.model.currency()
    }
  },
  watch: {
    '$route': 'fetchData',
  }, 
  data: () => ({
    parent_id: null,
    title: "",
    item: {},
    readonly: true,
    valid: false,
  }),

  methods: {
    // validate() {
    //   console.log("validate kjljklq")
    //   this.$refs.Infos.validate(
    //     (item) => {
    //       console.log("kjljklq")
    //     this.item = item
    //   })
    // },
    valueChanged(item) {
      this.item = item
    },
    loadModel: function() {
      //must be done in sub component
      console.error("Must implements loadModel!!")
    },
    setup: function() {
      
    },
    errorEdit(msg, e) {
      this.error(msg, e)
    },
    successEdit(msg) {
      console.log("=> success")
      this.success(msg)
    },
    success: function(msg) {
      this.$emit('success', msg)
    },
    error: function(msg, e) {
      //console.log(msg, e)
      this.$emit('error', msg, e)
    },
    fetchData: function() {
      this.item.id = this.$route.params.id
      const _self = this
      //console.log(this.model)
      this.model.get(
        this.item
        , function(response) {
          //console.log("ok")
          _self.item = response.data
          _self.$refs.Infos.item = response.data
        }
        , function() {
          this.$emit('error', 'common.not_found')
        }
      )
    },

  },

  created () {
    this.loadModel()
  },

  mounted () {
    try {
      //console.log("created", this.item)
      let readonly = this.$route.query.readonly
      readonly = readonly == undefined ? true : readonly
      //this.$refs.Infos.readonly = this.$route.query.readonly
      this.readonly = readonly
      this.title = this.model.content + ".title"
      this.setup()
      this.fetchData()
    } catch (e) {
      this.error("common.not_found")
    }
  },

}
