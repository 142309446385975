var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    class: _vm.readonly ? 'no-border' : '',
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 10,
      "label": _vm.$t(this.model.content + '.' + 'code')
    },
    model: {
      value: _vm.item.code,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    class: _vm.readonly ? 'no-border' : '',
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1)], 1)], 1)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }