import Model from "../Model.js"


export default class Marker extends Model {
    constructor() {
        super("marker")
      }

    defaultValues() {
        return {
            id: 0,
            pos : 1,
            code: null,
            label: null,
            nfcvaluemodifiers: []
        }
    }

}