  import loginStore from '../tool/LoginStore'


  import User from "../../model/user/User.js"
  const user = new User()

  import CardReader from './CardReader.vue'
  import DialogReadNFCCard from '../nfccard/DialogReadNFCCard.vue'

  export default {
    components: {
      CardReader, DialogReadNFCCard
    },
    watch: {
    },
    props: {
    },
    data: () => ({
      //blocks: [],
      showMsg: false,
      type: 'info',
      msg: '',
      loginState: loginStore.state,
      drawer: null,
      clickedItem: null,
      //searchInput: "",
      foundCustomers: [],
      items: [
        { 
          icon: 'build', 
          'icon-alt': 'build', 
          text: 'hmi.settings', 
          model: false, 
          children: [
            // { icon: 'search', text: 'nfcinfo.title',        page: '/hmi/nfcinfo/search',        right: 'is_maint' },
            // { icon: 'search', text: 'marker.title',         page: '/hmi/marker/search',         right: 'is_maint' },

            { icon: 'search', text: 'gateway.title',        page: '/hmi/gateway/search',        right: 'is_maint' },
            // { icon: 'search', text: 'com.title',            page: '/hmi/com/search',            right: 'is_maint' },
            { icon: 'search', text: 'plc.title',            page: '/hmi/plc/search',            right: 'is_maint' },
            // { icon: 'search', text: 'plctype.title',        page: '/hmi/plctype/search',        right: 'is_maint' },
            // { icon: 'search', text: 'plcrequest.title',     page: '/hmi/plcrequest/search',     right: 'is_maint' },
            //{ icon: 'search', text: 'datatype.title',       page: '/hmi/datatype/search',       right: 'is_maint' },
            // { icon: 'search', text: 'plcvariable.title',    page: '/hmi/plcvariable/search',    right: 'is_maint' },

            //{ icon: 'search', text: 'plcbatchvalue.title',  page: '/hmi/plcbatchvalue/search',  right: 'is_maint' },

            { icon: 'search', text: 'plc_energy.title',      page: '/hmi/plcenergy/search',      right: 'is_maint' },

            // { icon: 'mdi-database', text: 'hmi.data',      page: '/hmi/data',      right: 'is_maint' },
            
          ]
        },
        { 
          icon: 'shopping_cart', 
          'icon-alt': 'shopping_cart', 
          text: 'hmi.sales', 
          model: false, 
          children: [
            //{ icon: 'search', text: 'paymentmethod.title', page: '/hmi/paymentmethod/search', right: 'is_sell_admin' },
            { icon: 'search', text: 'company.title',  page: '/hmi/company/edit',    right: 'is_sell_admin' },
            { icon: 'search', text: 'vat.title',      page: '/hmi/vat/search',      right: 'is_sell_admin' },
            { icon: 'search', text: 'customer.title', page: '/hmi/customer/search', right: 'is_sell' },
            { icon: 'search', text: 'product.title',  page: '/hmi/product/search',  right: 'is_sell_admin' },
            //{ icon: 'search', text: 'formule.title',  page: '/hmi/formule/search',  right: 'is_sell_admin' },
            // { icon: 'search', text: 'basket.title', page: '/hmi/basket/search', right: 'is_sell_admin' },
            // { icon: 'add', text: 'basket.title', page: '/hmi/basket/create', right: 'is_sell_admin' },
            // { icon: 'add', text: 'basket.title', page: '/hmi/basket/fill', right: 'is_sell_admin' },
            //{ icon: 'search', text: 'subscribtion.title',      page: '/hmi/subscribtion/search',      right: 'is_sell_admin' },
            // { icon: 'search', text: 'basket.state_DRAFT',      page: '/hmi/basket/search?state=draft',            right: 'is_sell' },
            { icon: 'search', text: 'Prépaiement',    page: '/hmi/basket/search?state=invoice',            right: 'is_sell' },
            { icon: 'search', text: 'Consommation',    page: '/hmi/transaction/search',            right: 'is_sell' },
            // { icon: 'search', text: 'basket.state_CREDIT',     page: '/hmi/basket/search?state=credit',            right: 'is_sell' },
          ]
        },
/*
        { icon: 'history', text: 'hmi.history', page: '/history', right: 'is_sell_admin'},
        { icon: 'settings', text: 'hmi.settings', page: '/settings', right: 'is_sell_admin'},
        */
      ],
      showCardReader: false,
      show: false,
    }),
    methods : {
      displayDialogReadNFCCard() {
        this.$refs['DialogReadNFCCard'].showDialog()
      },


      displayCard() {
        this.showCardReader = true
        this.show = true
        console.log("displayCard", this.showCardReader)
      },
      selectedCustomer(value) {
        //console.log("clicked", value)
        if (value == null) {
          return
        }
        this.$router.push("/hmi/customer/show/" + value + "/")
      },
      success: function (msg) {
        //console.log("showSnackbar", msg)
        this.type = 'success'
        this.showMsg = true
        this.msg = msg
      },
      error: function (msg, e) {
        //console.log("showSnackbar", msg)
        console.log(e)
        this.type = 'error'
        this.showMsg = true
        this.msg = msg
      },
     
      logout: function() {
        //console.log("logout")
        loginStore.logout()
      },
      isAllowed: function (item) {
        //console.log(item)
        if (item.right == null) {
          if (item.children) {
            for (var child of item.children) {
              if (this.isAllowed(child)) {
                return true
              }
            }
            // no right amon children
            return false
          } else {
            // no right and no child
            return true
          }
        }
        return loginStore.isAllowed(item.right)
      },
      hasRight: function (right) {
        return loginStore.isAllowed(right)
      },
      gotoPage : function(item) {
        this.$router.push({path: item.page})
      },
      basketCreate: function() {
        let _self = this
        user.getCurrentBasket(
          function(data) {
            _self.$router.push({path:"/hmi/basket/edit/" + data.id })
          }
          , function(e) {
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
      redirectListBasket: function() {
        this.$router.push({path:"/hmi/basket/search"})
      },
      redirectFillBasket: function() {
        this.$router.push({path:"/hmi/basket/fill"})
      },

      redirectMap: function() {
        this.$router.push({path:"/hmi/map"})
      },

      redirectMapMaint: function() {
        this.$router.push({path:"/hmi/mapmaint"})
      },
    }, 

    mounted() {
     
    }
  }

