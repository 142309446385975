// Generated by https://quicktype.io

import {Customer} from '@/components/model/customer/CustomerTS'

export interface SelectValue {
id: number | string,
label: string
}

export function getLabel(id: number | string | undefined, labels: SelectValue[]): string | undefined {
    if (id === undefined) {
        return undefined
    }
    for (const label of labels) {
        if (label.id == id) {
            return label.label
        }
    }

    return "?"
}

export const typesBox: SelectValue[] = [
    {id: 0, label: "Aucun"}, 
    {id: 1, label: "Borne Energie"}, 
    {id: 2, label: "Accès Pontons"},
    {id: 3, label: "Sanitaire"}, 
    {id: 4, label: "Jeton"},
]

export const typesCounting: SelectValue[] = [
    {id: 0, label: "Aucun"}, 
    {id: 1, label: "Compteur"}, 
    {id: 2, label: "Temps"},
]

export const typesElec: SelectValue[] = [
    {id: 0, label: "Aucun"}, 
    {id: 1, label: "Mono 16A"}, 
    {id: 2, label: "Mono 32A"},
    {id: 3, label: "Mono 63A"}, 
    {id: 4, label: "Mono 125A"},
    {id: 5, label: "Tétra 32A"}, 
    {id: 6, label: "Tétra 63A"},
    {id: 7, label: "Tétra 125A"}, 
    {id: 8, label: "Tétra 250A"},
    {id: 9, label: "Tétra 400A"}, 
]

export const typesWater: SelectValue[] = [
    {id: 0, label: "Aucun"}, 
    {id: 1, label: "Robinet"}, 
]

export const typesDelivering: SelectValue[] = [
    {id: 0, label: "Aucun"}, 
    {id: 1, label: "Compteur"}, 
    {id: 2, label: "Temps"}, 
    {id: 3, label: "Post-paiement"}, 
]

export const typesPlug: SelectValue[] = [
    {id: "WATER", label: "Eau"}, 
    {id: "ELEC", label: "Electricté"}, 
]

export interface PaginatedResults<T> {
    count: number
    page: number
    page_size: number
    results: T[]
}

export interface PLC {
    id:              number
    created_date:    string
    modified_date:   string
    is_enabled:      boolean
    localisation:    number
    slave_id:        number
    pos:             number
    label:           string
    status:          string
    com_port:        string
    gateway_label:   string
    gateway_last_ok: string
    type_code:       string
    last_ok:         string

    lat:             number
    lng:             number

    plugs:           PLCPlug[]
    metrics?:        Metrics
}

export interface PLCPlug {
    pos:             number
    label:           string
    status:          string
    type:            string
    kind:            number
    transaction:     Transaction | null
    customer:        Customer | null

    voyant:         boolean
    voyant_f:       boolean
    voyant_fv:      boolean
    relai:          boolean
    relai_f:        boolean
    relai_fv:       boolean
    tag:            string
    mode:           number
    echelle:        number
    current_qty:    number
    remaining_qty:  number
}

// export interface Customer {
//     id: number
//     is_company: boolean
//     first_name: string
//     last_name: string
//     company_name: string
//     email: string
// }

export interface Transaction {
    begin:               string
    end:                 string
    end_closing:         string
    status:              string
    prev_qty:            number
    current_qty:         number
    remaining_qty:       number
    query_qty:           number
    pos:                 string
    plc_label:           string
    plug_label:          string
    plug_type:           string
}

// export interface PLC {
//     id:            number
//     created_date:  string
//     modified_date: string
//     is_enabled:    boolean
//     localisation:  number
//     slave_id:      number
//     pos:           number
//     label:         string
//     status:        null
//     metrics:       string
//     com_port:      string
//     gateway_label: string
//     type_code:     string
//     last_ok: string
// }


// export interface PLCWithMetrics {
//     plc: PLC
//     metrics?: Metrics
// }

export interface Metrics {
    status_com?:           number
    status_esclave?:       number
    version?:              number
    id_carte?:             number
    id_localisation?:      number
    fct_carte?:            number
    fct_borne?:            number
    num_esclave_modbus?:   number
    num_acces?:            number
    les_cpts?:             number[]
    les_restants?:         number[]
    lesrelais?:            boolean[]
    lesetors?:             boolean[]
    lesstors?:             boolean[]
    voyetats?:             boolean[]
    les_voyants?:          boolean[]
    la_temperature?:       number
    etat_energies?:        EtatEnergy[]
    forcage_s_int?:        Forcage[]
    forcage_s_ext?:        Forcage[]
    forcage_s_voy?:        Forcage[]
    forcage_init_energie?: Forcage[]
    timestamp_date_heure?: number
    temp_ventilo?:         number
    temp_chauffage?:       number
    echelles_energies?:    number[]
    types_energies?:       number[]
    energie_hs?:           boolean[]
}

export interface EtatEnergy {
    id_tag:         number[]
    id_customer:    number
    id_transaction: number
    fct_energie:    number
    restant:        number
}

export interface Forcage {
    forcage: boolean
    value:   boolean
}
