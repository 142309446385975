  export default {
    components: {
    },
    computed: {
      currency: function() {
        return this.model.currency()
      }
    },
    props: {
      readonly: Boolean,
      showClose: Boolean
    },
    data: () => ({
      parent_readonly: false,
      //show: false,
      title: null,

      valid: false,
      leave: false,
      next: null,
      error: false,
      item: {},
      model : null
    }),
    watch: {
      data: {
        handler: function (/*val, oldVal*/) {
          this.edited = true
        },
        // if async it would break "edited" logic
        sync: true,
        deep: true
      },
      // item: {
      //   handler: function() {
      //     console.log("item changed", this)
      //     this.validate()
      //   },
      //   deep: true
      // },
    }, 

    methods: {
      loadModel: function() {
        //must be done in sub component
        console.error("Must implements loadModel!!")
      },

      create(item) {
        this.item = {...item, ...this.model.defaultValues()}
        this.prepareItemToCreate()
        //this.show = true
      },
      edit(item) {
        this.item = { ...item }
        this.prepareItemToEdit()
        //copy because we can cancel
        //this.show = true
      },
      prepareItemToCreate() {
      },
      prepareItemToEdit() {
      },
      prepareItemToSend() {
      },
      checkForm() {
        return this.$refs.form.validate()
      },
      redirectCreate() {

      },
      close() {
        this.$emit('error', 'common.canceled', this.item)
      },
      additionalSave() {

      },
      validate () {
        //console.log("validate", this)
        //console.log("sdsds", this.$refs.form.validate())
        if (this.checkForm()) {
          //console.log("checkForm")
          this.prepareItemToSend()
          if (this.item.id == 0) {
            this.model.create(
              this.item
              , response => {
                this.item = response.data
                this.additionalSave()
                // console.log("emit")
                // this.$emit("valueChanged", this.item)
                this.$emit('success', 'common.created', this.item)
                //this.show = false
                this.redirectCreate()
              }
              , (e) => {
                if (e.errorMessageToUse) {
                  this.$emit('error', e.errorMessageToUse, e)
                } else {
                  this.$emit('error', 'common.created_error', e)
                }
                // this.$emit('error', 'common.created_error', e)
              })
          } else {
            this.model.update(
              this.item
              , (response) => {
                this.item = response.data
                this.additionalSave()
                //this.show = false
                //console.log("success =>", this)
                //console.log("emit")
                this.$emit("valueChanged", this.item)
                this.$emit('success', 'common.updated')
              }
              , e => {
                if (e.errorMessageToUse) {
                  this.$emit('error', e.errorMessageToUse, e)
                } else {
                  this.$emit('error', 'common.updated_error', e)
                }
                // this.$emit('error', 'common.updated_error', e)
              })
          }
        }
      },

    },

    created() {
      this.loadModel()
    },

    mounted() {
      this.setup()

      this.title = this.model.content + '.title'
   
      this.item = this.model.defaultValues()
    },
  }
