<template src="../tool/CommonList.pug" lang="pug"></template>

<script>
import commonList from  './CommonList.js'
export default  commonList

//import Vue from 'vue'


// var Component = Vue.extend({
//   mixins: [commonList],
//   props: {
//     fLoadModel: Function,
//     fIsViewAllowed: Function,
//     fIsDeleteAllowed: Function,
//     fSetup: Function,
//   },
//   methods: {
//       loadModel: function() {
        
//         this.fLoadModel()
//       },

//       isViewAllowed: function() {
//         return this.fIsViewAllowed()
//       },
//       isDeleteAllowed: function() {
//         return this.fIsDeleteAllowed()
//       },
//       setup () {
//         console.log("vue")
//         this.fSetup()
//       }
//   }
// })
// const comp = new Component()
// console.log(comp)
// export default comp


</script>
