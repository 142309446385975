import Model from "../Model.js"


export default class FormuleLine extends Model {
    constructor() {
        super("formuleline")
      }

    defaultValues() {
        return {
            id: 0,
            qty: null,
            product: {id: null, label: null},
        }
    }

}


