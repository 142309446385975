import axios from 'axios'
import loginStore from './LoginStore'
//import django from './Django.js'

import AxiosError from 'axios-error'

let django = process.env.VUE_APP_BACK_END_HOST

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

export default {
    state: {

    },
    // pdf(data, callbackOk, callbackError) {
    //     var config = {
    //         headers: {'Authorization': "Token " + loginStore.state.token},

    //     }
    //     //console.log(idSrc, idDest)
    //     axios.post("http://127.0.0.1:8000/pdf", data, config)
    //         .then(response => {
    //             callbackOk(response)
    //         })
    //         .catch(e => {
    //             callbackError(e)
    //         })
    // },


    move(url, data, callbackOk, callbackError) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token},

        }
        //console.log(idSrc, idDest)
        axios.post(django + url + data.idSrc + "/move/", data, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },
    post(url, data, callbackOk, callbackError) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token},

        }
        //console.log(idSrc, idDest)
        axios.post(django + url, data, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                const err = new AxiosError(e)
                //console.log(err.response)
                callbackError( err)
            })
    },
    pdf_view(url, data, callbackOk, callbackError) {
        var config = {
            responseType: 'arraybuffer',
            headers: {'Authorization': "Token " + loginStore.state.token},
        }
        //console.log(idSrc, idDest)
        axios.post(django + url + data.id + "/pdf_view/", data, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },


    postP(url, data) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token},

        }
        //console.log(idSrc, idDest)
        return axios.post(django + url, data, config)
    },
    
    // importData(url, data) {
    //     return this.post(url + "/import_data", data)
    // },

    // exportData(url, data) {
    //     return this.post(url + "/export_data", data)
    // },
    
    create(url, data, callbackOk, callbackError) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token}
        }
        //console.log(data)
        axios.post(django + url, data, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                //console.log(callbackError)
                callbackError(e)
            }
        )
    },
    // binary(url, data, callbackOk, callbackError) {
    //     var config = {
    //         responseType: 'arraybuffer',
    //         headers: {'Authorization': "Token " + loginStore.state.token}
    //     }
    //     //console.log(data)
    //     axios.post("http://127.0.0.1:8000" + url, data, config)
    //         .then(response => {
    //             callbackOk(response)
    //         })
    //         .catch(e => {
    //             //console.log(callbackError)
    //             callbackError(e)
    //         }
    //     )
    // },
    get(url, data, callbackOk, callbackError) {
        //console.log("toto")
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token}
        }

        axios.get(django + url + data.id + "/", config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },

    getP(url, data) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token}
        }

        return axios.get(django + url, config)
    },

    list(url, page, page_size, callbackOk, callbackError, params) {
        if (params == null) {
            params = {}
        }
        // copy to not change filters
        params = {...params, page, page_size}
        // params.page = page
        // params.page_size = page_size
        
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token},
            params:params
        }

        console.log("=>", django + url, config)
        return axios.get(django + url, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },
    update(url, data, callbackOk, callbackError) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token}
        }
        //console.log("update", data)
        axios.put(django + url + data.id + "/", data, config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },
    delete(url, data, callbackOk, callbackError) {
        var config = {
            headers: {'Authorization': "Token " + loginStore.state.token}
        }
        //console.log("config", config)

        axios.delete(django + url + data.id + "/", config)
            .then(response => {
                callbackOk(response)
            })
            .catch(e => {
                callbackError(e)
            })
    },
}
