var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "max-width": "100%"
    }
  }, [_vm.showTitle ? _c('v-card-title', [_vm._v(_vm._s(_vm.$t('basket.total')))]) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.total_headers,
      "items": [_vm.value],
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headers = _ref.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (header) {
          return _c('th', [_c('div', {
            attrs: {
              "align": header.align,
              "width": header.width
            }
          }, [_vm._v(_vm._s(_vm.$t(header.text)))])]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function fn(_ref2) {
        var items = _ref2.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', _vm._l(_vm.total_columns, function (col) {
            return _c('td', {
              class: col.class
            }, [_c('div', [_vm._v(" " + _vm._s(_vm.getLabel(item, col)))])]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }