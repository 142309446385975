







import { Component, Vue } from 'vue-property-decorator'

import Map from '@/components/hmi/map/Map.vue'


@Component({
    components: {Map}
})
export default class extends Vue {
    
}
