












































































































































































































import MapFilter, { MapFilterModel } from '@/components/hmi/map/MapFilter.vue';
import { Customer } from '@/components/model/customer/CustomerTS.js';
import { getCustomerShortdesc } from '@/shared/utils';
import { Component, Vue } from 'vue-property-decorator';
import CustomerModel from "../../model/customer/Customer.js";
import nfcCardService, { NfcCardModel, WriteError } from './NFCCard';


const customerModel = new CustomerModel()


// import DialogSelectDate from "./DialogSelectDate.vue"

@Component({
  components: {MapFilter}
})
export default class extends Vue {
  showConfirmReturnCard: boolean = false
  overlay: boolean = false
  tab: number = 0
  show: boolean = false
  elecPlugs: { value: number; label: any; }[] = []
  waterPlugs: { value: number; label: any; }[] = []

  readedCustomer: Customer | null = null

  filter: MapFilterModel = {customer: null}

  readState: NfcCardModel = nfcCardService.getDefault()
  editState: NfcCardModel = nfcCardService.getDefault()

  // getDialogSelectDate(): DialogSelectDate {
  //   return this.$refs['DialogSelectDate'] as DialogSelectDate
  // }

  // async showDialogSelectDate(title: string, timestampInS: number): Promise<void> {
  //   try {
  //     this.editState.parkingEnd = await this.getDialogSelectDate().showDialogSelectDate("Date fin parking", this.editState.parkingEnd)
  //     console.log(this.editState.parkingEnd)
  //   } catch(e) {
  //     console.log(e)
  //   }
  // }

  // get formatedIsoParkingEndReaded(): string {
  //   if (this.editState.parkingEnd == null) {
  //     return ""
  //   }
  //   return this.formatIsoDate(this.editState.parkingEnd*1000)
  // }

  // set formatedIsoParkingEndReaded(value: string) {
  //   this.editState.parkingEnd = this.parseIsoDate(value) / 1000
  // }

  // get readState(): NfcCardModel {
  //   return this.nfcCardState.readState
  // }

  // get editState(): NfcCardModel {
  //   return this.nfcCardState.editState
  // }

  // updateFilter(filter: MapFilterModel): void {
  //     this.filter = filter
  //     this.editState.customerId = filter.customer?.id ?? null
  //     if (filter.customer) {
  //       this.editState.typeElec = filter.customer.plug_elec
  //     } else {
  //       this.editState.typeElec = 1
  //     }
  // }

  mounted() {
    this.elecPlugs = customerModel.getElecPlugs(this)
    this.waterPlugs = customerModel.getWaterPlugs(this)
  }

  async showDialog(): Promise<void> {
    const res: boolean = await this.read()
    if (res) {
      this.show = true
    }
  }

  
  async returnCard(): Promise<void> {
    this.showConfirmReturnCard = false
    try {
      const emptyState: NfcCardModel = nfcCardService.getDefault()
      nfcCardService.toZeroData(emptyState)
      await nfcCardService.write(emptyState, this.readState.id, null)
      this.$emit('success', 'Le badge NFC a été écrit')
    } catch(e: any) {
      if (e instanceof WriteError) {
        this.$emit('error', e.message, e)
      } else {
        this.$emit('error', "Le badge NFC n'a pas pu être écrit", e)
      }
    } finally {
      this.overlay = false
    }
  }

  async write(): Promise<void> {
    try {
      this.editState.dateValidityInS = Math.floor(new Date().getTime() / 1000) + 3 * 30 * 24 * 3600
      await nfcCardService.write(this.editState, this.readState.id, null)
      this.$emit('success', 'Le badge NFC a été écrit')
    } catch(e: any) {
      if (e instanceof WriteError) {
        this.$emit('error', e.message, e)
      } else {
        this.$emit('error', "Le badge NFC n'a pas pu être écrit", e)
      }
    } finally {
      this.overlay = false
    }
  }

  async read(): Promise<boolean> {
    this.overlay = true
    try {
      await nfcCardService.read(this.readState)
      nfcCardService.copyFromValues(this.readState, this.editState)
      console.log(this.readState.id, this.editState.id)
    } catch(e: any) {
      this.$emit('error', e.message, e)
      return false
    } finally {
      this.overlay = false
    }
    try {
      await this.refreshReadCustomer()
    } catch (e) {
      this.$emit('error', "Le client n'a pas été trouvé", e)
      return false
    }

    return true
  }

  async refreshReadCustomer() {
    if (! this.readState.alwaysCustomerId) {
      this.readedCustomer = null
    } else {
      const response = await customerModel.getP({id: this.readState.alwaysCustomerId})
      this.readedCustomer = response.data
    }
  }

  getCustomerShortdesc(customer: Customer, email: boolean = false): string | null {
    return getCustomerShortdesc(customer, email)
  }
}

