























































































































































































  import { Component, Vue, Watch } from 'vue-property-decorator';

  import CustomerService, { Customer } from '@/components/model/customer/CustomerTS';
  const customerService:  CustomerService = new CustomerService()

  import { TransactionFilter } from '@/components/model/transaction/TransactionService';
  // const transactionService:  TransactionService = new TransactionService()

  import { getCustomerShortdesc } from '@/shared/utils';

  // import Consumptions from "@/components/hmi/customer/Consumptions.vue"
  import Transactions from "@/components/hmi/customer/Transactions.vue";

  import { DataTableHeader } from 'vuetify';
import { Model } from "./imports.js";

  import BasketModel from "../../model/basket/Basket.js";
  const basketService = new BasketModel()

  @Component({
    components: {
      Transactions
    }
  })
  export default class extends Vue {
    filterCurrent: TransactionFilter = {
      isCurrent: true,
      isHistory: false,
      isWater: true,
      isElec: true,
      isNoQty: true,
      customer_id: null,
      begin: null,
      end: null,
    };
    filterHistory:  TransactionFilter = {
      isCurrent: false,
      isHistory: true,
      isWater: true,
      isElec: true,
      isNoQty: true,
      customer_id: null,
      begin: null,
      end: null,
    };

    id: number = 0
    item: Customer | null = null
    // transactions: Transaction[] = []
    tab: number = 0
    model = new Model()
    readonly: boolean = true
    elecPlugs: any[] = []
    waterPlugs: any[] = []
    valid: boolean = false
    headers: DataTableHeader[] = [
      {align: "start", text: 'Début', value: 'begin' },
      {align: "start", text: 'Statut', value: 'detailStatus' },
      {align: "start", text: 'Borne', value: 'plcLabel' },
      {align: "start", text: '#', value: 'plug' },
      // {align: "start", text: 'Consommation', value: 'qty' },
    ]
    basketHeaders: DataTableHeader[] = [
      {align: "start", width: "100", text: 'Badge', value: 'uid', sortable: false},
      {align: "start", width: "100", text: 'Type', value: 'type', sortable: false},
      {align: "end", width: "100", text: 'Total HT', value: 'total_ht', sortable: false},
      {align: "end", width: "100", text: 'Total TTC', value: 'total_ttc', sortable: false},

      {align: "center", width: "60", text: 'Date de création', value: 'created_date', sortable: false},
    ]
    baskets: any[] = []


    async sendMobileMail(): Promise<void> {
      if (! this.item) {
        return
      }
      try {
        await this.model.sendMobileMail(this.item.id)
        this.$emit('success', 'Un e-mail a été envoyé à ' + this.item.email, this.item)
      } catch (error) {
        this.$emit('error', "L'e-mail " + this.item.email + " n'a pas pu être envoyé", error)
      }
    }

    get filteredBaskets(): any[] {
      return this.baskets.filter((b) => {
        return b.state == "INVOICE"
          && this.item != null
          && b.customer_id == this.item.id
      })
    }

    getType(value: string): string {
      const types =  [
          {value: "NEW_CREDITS", label: "Badge avec crédits"}
          , {value: "NEW_ACTIVATION", label: "Badge d'activation"}
          , {value: "RELOAD_CREDITS", label: "Recharge pour badge avec crédits"}
          , {value: "RELOAD_ACTIVATION", label: "Recharge pour badge d'activation"}
      ]
      for (const type of types)  {
        if (type.value == value) {
          return type.label
        }
      }
      return ""
    }

    @Watch("$route")
    routeChanged() {
      this.fetchData()
    }

    refresh(): void {
      this.fetchData()
    }

    // get currentTransactions(): Transaction[] {
    //   return this.transactions.filter((t) => t.status == TransactionStatus.DELIVERING)
    // }

    // get historyTransactions(): Transaction[] {
    //   return this.transactions.filter((t) => t.status != TransactionStatus.DELIVERING)
    // }

    async fetchData(): Promise<void> {
      this.id = parseInt(this.$route.params.id)
      this.filterCurrent.customer_id = this.id
      this.filterHistory.customer_id = this.id
      
      try {
        this.item = await customerService.getP(this.id)
        // let transactions: Transaction[] = await transactionService.listP(1, 50, {customer_id: this.id})
        // console.log(transactions)
        // this.transactions = transactions
        
        this.baskets = (await basketService.listP(1, 250)).data.results
        console.log(this.baskets)
      } catch (error) {
        this.item = null
        // this.transactions = []
        this.onError("Impossible de charger le client", error as Error)
      }
      
    }

    getCustomerShortdesc(customer: Customer): string | null {
      return getCustomerShortdesc(customer, true)
    }

    emailRule (v: any) {
      if (v == null || v === '') {
        return this.$i18n.t(this.model.content + '.' + 'emailRequired')
      } 
      if (! v.match(".+@.+[.].+")) {
        return this.$i18n.t(this.model.content + '.' + 'emailValid')
      }
      return true
    }

    checkForm() {
      return (this.$refs.form as unknown as {validate: () => boolean}).validate()
    }

    validate () {
      console.log("validate", this)
      //console.log("sdsds", this.$refs.form.validate())
      if (this.checkForm()) {
        //console.log("checkForm")
        this.prepareItemToSend()
        if (this.item && this.item.id == 0) {
          this.model.create(
            this.item
            , (response: any) => {
              this.item = response.data
              this.additionalSave()
              // console.log("emit")
              // this.$emit("valueChanged", this.item)
              this.$emit('success', 'common.created', this.item)
              //this.show = false
              this.redirectCreate()
            }
            , (e: any) => {
              if (e.errorMessageToUse) {
                this.$emit('error', e.errorMessageToUse, e)
              } else {
                this.$emit('error', 'common.created_error', e)
              }
            })
        } else {
          this.model.update(
            this.item
            , (response: any) => {
              this.item = response.data
              this.additionalSave()
              //this.show = false
              //console.log("success =>", this)
              //console.log("emit")
              this.$emit("valueChanged", this.item)
              this.$emit('success', 'common.updated')
            }
            , (e: any) => {
                if (e.errorMessageToUse) {
                  this.$emit('error', e.errorMessageToUse, e)
                } else {
                  this.$emit('error', 'common.updated_error', e)
                }
            })
        }
      }
    }

    additionalSave() {
    }

    redirectCreate() {
      //! TODO
    }

    prepareItemToSend() {
    }

    mounted(): void {
      this.elecPlugs = this.model.getElecPlugs(this)
      this.waterPlugs = this.model.getWaterPlugs(this)

      let readonly = this.$route.query.readonly ?? true
      this.readonly = readonly ? true: false
      
      this.fetchData()
    }
  }

