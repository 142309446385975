var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t(_vm.title))), _c('v-spacer'), !_vm.hidesearch ? _c('v-text-field', {
    attrs: {
      "append-icon": "search",
      "label": _vm.$t('common.search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }) : _vm._e(), _vm.filterHidden ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [!_vm.hidesearch ? _c('v-switch', _vm._g({
          staticStyle: {
            "max-width": "70px",
            "margin-left": "25px",
            "margin-right": "25px"
          },
          attrs: {
            "color": "primary",
            "dark": ""
          },
          model: {
            value: _vm.showHidden,
            callback: function callback($$v) {
              _vm.showHidden = $$v;
            },
            expression: "showHidden"
          }
        }, on)) : _vm._e()];
      }
    }], null, false, 4067514835)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.showNotVisible')))])]) : _vm._e(), !_vm.disableCreate && _vm.isCreateAllowed() ? _c('v-btn', {
    staticClass: "mb-2",
    attrs: {
      "slot": "activator",
      "disabled": _vm.readonly,
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.createItem
    },
    slot: "activator"
  }, [_vm._v(_vm._s(_vm.$t("common.create")))]) : _vm._e(), _vm._t("additional-toolbar")], 2), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "hide-default-header": "",
      "disable-sort": "",
      "options": _vm.pagination,
      "server-items-length": _vm.totalItems,
      "footer-props": {
        'items-per-page-options': [10, 25, 50, 200]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref2) {
        var headers = _ref2.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (header) {
          return _c('th', [_c('div', {
            attrs: {
              "align": header.align,
              "width": header.width
            }
          }, [_vm._v(_vm._s(_vm.$t(header.text)))])]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function fn(_ref3) {
        var items = _ref3.items;
        return [_c('draggable', {
          attrs: {
            "handle": _vm.readonly ? 'no_handle' : '.handle',
            "tag": "tbody"
          },
          on: {
            "end": _vm.dragEnd
          },
          model: {
            value: items,
            callback: function callback($$v) {
              items = $$v;
            },
            expression: "items"
          }
        }, _vm._l(items, function (item) {
          return _c('tr', [_vm._l(_vm.columns, function (col) {
            return _c('td', {
              class: col.class
            }, [_vm._v(_vm._s(_vm.getLabel(item, col))), _vm._l(item[col.subvalue], function (detail) {
              return col.subvalue != null ? _c('div', {
                staticClass: "linedetail"
              }, [_vm._v(_vm._s(detail))]) : _vm._e();
            })], 2);
          }), _c('td', {
            staticClass: "text-left layout px-0"
          }, [_vm.isViewAllowed(item) ? _c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "color": "primary",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.viewItem(item);
              }
            }
          }, [_vm._v("visibility")]) : _vm._e(), !_vm.readonly && _vm.isEditAllowed(item) ? _c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "color": "primary",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.editItem(item);
              }
            }
          }, [_vm._v("edit")]) : _vm._e(), !_vm.readonly && _vm.isDeleteAllowed(item) ? _c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "color": "error",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item);
              }
            }
          }, [_vm._v("delete")]) : _vm._e(), _vm._l(_vm.actions, function (action) {
            return action.right(item) ? _c('v-icon', {
              key: action.icon,
              staticClass: "mr-2",
              attrs: {
                "color": action.color,
                "small": ""
              },
              on: {
                "click": function click($event) {
                  return action.action(_vm.item);
                }
              }
            }, [_vm._v(_vm._s(action.icon))]) : _vm._e();
          })], 2)], 2);
        }), 0)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-alert', {
          attrs: {
            "value": true,
            "color": "info",
            "icon": "warning"
          }
        }, [_vm._v(_vm._s(_vm.$t("common.no_data")))])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticStyle: {
      "display": "None"
    }
  }, [_c('ConfirmDelete', {
    ref: "ConfirmDelete",
    on: {
      "confirmDelete": _vm.confirmDelete,
      "cancelDelete": _vm.cancelDelete
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "None"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "75vw",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.showEditDialog,
      callback: function callback($$v) {
        _vm.showEditDialog = $$v;
      },
      expression: "showEditDialog"
    }
  }, [!_vm.disableCreate ? _c('EditDialog', {
    ref: "EditDialog",
    attrs: {
      "showClose": true
    },
    on: {
      "success": _vm.successEdit,
      "error": _vm.errorEdit
    }
  }) : _vm._e()], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }