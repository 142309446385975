import Model from "../Model.js"


export default class Com extends Model {
    constructor() {
        super("com")
    }

    defaultValues() {
        return {
            id: 0,
            parent: null,
        }
    }

    getTypes(_self) {
        return [
            {value: "RS485", label:_self.$t(this.content + '.type_' + 'RS485')}, 
            {value: "WIFI", label:_self.$t(this.content + '.type_' + 'WIFI')}, 
        ]
    }
}


