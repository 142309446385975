var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": "Numéro"
    },
    model: {
      value: _vm.item.localisation,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "localisation", $$v);
      },
      expression: "item.localisation"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.com_readonly && _vm.item.com != null ? _c('v-text-field', {
    staticClass: "no-border",
    attrs: {
      "readonly": true,
      "label": _vm.$t(this.model.content + '.' + 'com')
    },
    model: {
      value: _vm.item.com.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item.com, "label", $$v);
      },
      expression: "item.com.label"
    }
  }) : _vm._e(), !_vm.com_readonly ? _c('v-select', {
    attrs: {
      "items": _vm.coms,
      "item-text": "label",
      "item-value": "id",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'com'),
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.com_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "com_id", $$v);
      },
      expression: "item.com_id"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.types,
      "item-text": "label",
      "item-value": "id",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'type'),
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type_id", $$v);
      },
      expression: "item.type_id"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.isRS485 ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'slave_id')
    },
    model: {
      value: _vm.item.slave_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "slave_id", $$v);
      },
      expression: "item.slave_id"
    }
  }) : _vm._e(), _vm.isWifi ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'ip')
    },
    model: {
      value: _vm.item.ip,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "ip", $$v);
      },
      expression: "item.ip"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'is_enabled')
    },
    model: {
      value: _vm.item.is_enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_enabled", $$v);
      },
      expression: "item.is_enabled"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": "Latitude"
    },
    model: {
      value: _vm.item.lat,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "lat", $$v);
      },
      expression: "item.lat"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": "Longitude"
    },
    model: {
      value: _vm.item.lng,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "lng", $$v);
      },
      expression: "item.lng"
    }
  })], 1)], 1)], 1)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }