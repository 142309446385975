  import loginStore from '../tool/LoginStore'

 import User from "../../model/user/User.js"
  const user = new User()

  import Basket from "../../model/basket/Basket.js"
  const basketModel = new Basket()

  import BasketTagLine from "../../model/baskettagline/BasketTagLine.js"
  const basketTagLineModel = new BasketTagLine()

  import Product from "../../model/product/Product.js"
  const productModel = new Product()

  // import Formule from "../../model/formule/Formule.js"
  // const formuleModel = new Formule()

  
  export default {
    components: {
      
    },
    props: {
    },
    computed: {
      
    },
    watch: {
      showDialog() {
        if (this.showDialog) {
          productModel.list(1, 200
            , (response) => {
              this.productCards = response.data.results
              console.log(this.productCards)
            }
            , e => {
              console.log(e)
              this.error(e)
            }
            , {filters: {is_card:true, is_subscribtion: false}}
          )
          
        }
      }
    }, 
    data: () => ({
      basketTagLineId: null,
      productCards: [],
      productCardId: null,
      cardToAddType: "BUY_NEW",
      qties: [5, 20, 100, 500, 1000, 5000],
      baskettagline: null,

      showDialog: false,
      validateCard: false,
      card_name: null,

      tagline_id: null,
      displayChooseReloadByNew: true,
      baskettaglines: [],

      basket: Object,
      itemsWithCard: [],
      itemsWithoutCard: [],
    }),

    methods: {
      loadProducts: function(isCard, callback) {
        let products = []

        productModel.list(1, 200
          , (response) => {
            products = response.data.results
            products.forEach(element => {
              element.title = element.label
              element.truncatedText = this.truncate(element.description)
              element.text = element.description
              element.qty = 5,
              //element.unit = element.unit,
              element.actions = [
                {
                  id : 'product#' + element.id,
                  label: 'basket.add',
                }
              ]
            })
            callback(products)
          }
          , e => {
            console.log(e)
            this.error(e)
          }
          , {filters: {is_card:isCard, is_subscribtion: false}}
        )
      },
      truncate: function(text) {
        if (text  == null) {
          return null
        }
        text = text.trim()
        if (text.length > 60) {
          text = text.substring(0, 60) + "..."
        } 
        while ((text.match(/\n/g) || []).length > 2) {
          text = text.replace(new RegExp('\\n[^\\n]*$'), ' ');
        }

        return text
      },
      
      getDetailTable(details, max) {
        let res = "<table >"

        res += "<tbody>"
        let i
        for (i=0; i < details.length && i < max; i++) {
          let detail = details[i]
          res += "<tr>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += detail.qty
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += detail.product.unit
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-left'>"
          res += detail.product.label
          res += "</td>"
          res += "</tr>"
        }
        if (details.length > max) {
          res += "<tr><td>...</td></tr>"
        }
        res += "</tbody>"
        res += "</table>"
        return res
      },

      loadItemsWithCard: function() {
        this.itemsWithCard = []

        var _self = this
        _self.loadProducts(true, function(products) {
          _self.itemsWithCard = _self.itemsWithCard.concat(products)
        })

      },

      loadItemsWithoutCard: function() {
        this.itemsWithoutCard = []

        var _self = this
        _self.loadProducts(false, function(products) {
            _self.itemsWithoutCard = _self.itemsWithoutCard.concat(products)
        })
      },

      addCardLine: function() {
        let _self = this

        if (_self.$refs.cardForm.validate()) {
          if (_self.cardToAddType === "RELOAD") {
            if (this.card_name == null) {
              this.card_name = this.$t("baskettagline.new_card_name") + " " + (this.baskettaglines.length + 1)
            }

            basketTagLineModel.create(
              {parent_id: _self.basket.id, label: _self.card_name}
              , function() {
                _self.showDialog = false
                _self.loadCurrentBasket()
              }
              , function(e) {
                _self.$emit('error', 'common.not_found', e)
              }
            )
          } else if(_self.cardToAddType === "BUY_NEW") {
            this.addCardToBasket(this.productCardId)
            _self.showDialog = false
          }
        }
      },

      loadBasketTagLines: function(callback) {
        let _self = this
        basketTagLineModel.list(1, 100
          , (response) => {
            _self.baskettaglines = response.data.results
            console.log(_self.baskettaglines)
            callback()
          }
          , e => {
            console.log(e)
          }
          , {filters: {parent_id:this.basket.id}}
        )
      },

      addCardToBasket: function(id) {

        if (this.card_name == null) {
          this.card_name = this.$t("baskettagline.new_card_name") + " " + (this.baskettaglines.length + 1)
        }
        let data = {parent_id: this.basket.id, label: this.card_name, qty:1, product_id: id}

        // create BasketTagLine
        basketTagLineModel.create(
          data
          , () => {
            this.showDialog = false
            this.loadCurrentBasket()
          }
          , (e) => {
            this.$emit('error', 'common.not_found', e)
          }
        )

      },

      getBasketTagLine(id) {
        for (let basketTagLine of this.baskettaglines) {
          if (basketTagLine.id == id) {
            return basketTagLine
          }
        }

        return null
      },

      updateQty(item, value) {
        item.qty = value
      },

      addServiceToBasket(item) {
        console.log("item", item)
        let baskettagline = this.getBasketTagLine(this.basketTagLineId)
        let data = {
          parent_id: this.basket.id
          , id: this.basketTagLineId
          , label: baskettagline.label
          , pos: baskettagline.pos
          , qty:parseInt(item.qty)
          , product_id: item.id
        }

        // create BasketTagLine
        basketTagLineModel.update(
          data
          , () => {
            this.showDialog = false
            //this.loadCurrentBasket()
            user.getCurrentBasket(
              (basket) => {
                loginStore.state.basketQty = basket.qty
              }
              , (e) => {
                this.$emit('error', 'common.not_found', e)
              }
            )
    
            this.$emit('success', 'common.updated')
          }
          , (e) => {
            this.$emit('error', 'common.not_found', e)
          }
        )
      },

      showAddCardMethod: function() {
          this.card_name = this.$t("baskettagline.new_card_name") + " " + (this.baskettaglines.length + 1)
          this.showDialog = true

      },

      showServices: function() {
          this.displayChooseReloadByNew = false

          this.loadItemsWithoutCard()
      },

      loadBasket: function(basket) {
        this.basket = basket

        loginStore.state.basketQty = this.basket.qty

        this.loadBasketTagLines(() => {
          // no card in the basket
          if (this.baskettaglines.length == 0) {
            this.showAddCardMethod()
          } else {
            if (this.basket.current_tag_id === null) {
              this.basketTagLineId = this.baskettaglines[this.baskettaglines.length-1].id
            } else  {
              this.basketTagLineId = this.basket.current_tag_id
            }

          }
        })
      },

      loadCurrentBasket() {
        this.showServices()
        user.getCurrentBasket(
          (data) => {
            this.loadBasket(data)
          }
          , (e) => {
            this.$emit('error', 'common.not_found', e)
          }
        )

      },
      formatCurrency: function (value) {
        return basketModel.formatCurrency(value)
      },

    },

    mounted() {
      this.loadCurrentBasket()
      
    }
  }
