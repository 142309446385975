import Model from "../Model.js";

export default class Customer extends Model {
    constructor() {
        super("customer")
    }

    defaultValues() {
        return {
            id: 0,
            is_company: false,
            company_name: null,
            first_name: null,
            last_name: null,
            email: null,
    
            phone: null,
            country: null,
            city: null,
            zip: null,
            address: null,
            is_mobile: true,
        }
    }

    formatCustomer (value) {
        let res  = ""
        if (value != null) {
            if (! value.is_company) {
            if (value.first_name != null) {
                res += " " + value.first_name
            }
            if (value.last_name != null) {
                res += " " + value.last_name
            }
            } else {
            res += " " + value.company_name
            }
        }

        return  res.trim()
        //return value.email
    }

    getElecPlugs(_self) {
        const result = []

        for (let i = 0; i <= 9; i++ ) {
            result.push({value: i, label: _self.$t(this.content + '.' + 'PLUG_ELEC_' + i)})
        }

        return result
    }

    getWaterPlugs(_self) {
        const result = []

        for (let i = 0; i <= 1; i++ ) {
            result.push({value: i, label: _self.$t(this.content + '.' + 'PLUG_WATER_' + i)})
        }

        return result
    }

    create(data, callbackOk, callbackError) {
        return super.create(
            data, 
            callbackOk, 
            (e) => {
                if (e.response
                    && e.response.data
                    && ["CUSTOMER_EMAIL_EXISTS", "USER_EMAIL_EXISTS"].includes(e.response.data.code)) {
                        // e.errorMessageToUse = "Cet email est déjà utilisé";
                        e = {...e, errorMessageToUse: "Cet email est déjà utilisé"}
                }
                callbackError(e);
            }
        );
    }

    update(data, callbackOk, callbackError) {
        // console.log("update customer")
        return super.update(
            data, 
            callbackOk, 
            (e) => {
                if (e.response
                    && e.response.data
                    && ["CUSTOMER_EMAIL_EXISTS", "USER_EMAIL_EXISTS"].includes(e.response.data.code)) {
                        // e.errorMessageToUse = "Cet email est déjà utilisé";
                        e = {...e, errorMessageToUse: "Cet email est déjà utilisé"}
                }
                callbackError(e);
            }
        );
    }

    async sendMobileMail(customerId) {
        return super.postP(this.api_url + customerId + "/send_mobile_mail/", {})
    }
}


