var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.filter.customer ? _c('v-combobox', {
    staticClass: "rounded-xl",
    class: {
      'error': !_vm.noBorder,
      'pt-2': !_vm.noBorder,
      'px-3': !_vm.noBorder
    },
    attrs: {
      "label": "Filtre client",
      "dense": "",
      "single-line": "",
      "items": _vm.items,
      "loading": _vm.isLoading,
      "search-input": _vm.search,
      "hide-no-data": "",
      "hide-selected": "",
      "item-text": "shortdesc",
      "item-value": "id",
      "placeholder": "Chercher un client",
      "prepend-icon": "mdi-account-search-outline",
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    },
    model: {
      value: _vm.filter.customer,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "customer", $$v);
      },
      expression: "filter.customer"
    }
  }) : _vm._e(), _vm.filter.customer ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-chip', _vm._g(_vm._b({
          class: {
            'px-3': !_vm.noBorder,
            'rounded-0': _vm.noBorder,
            'pl-0': _vm.noBorder
          },
          attrs: {
            "color": "white",
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.clearCustomer();
            }
          }
        }, 'v-chip', attrs, false), on), [_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Société: " + _vm._s(_vm.filter.customer.company_name))])]) : _vm._e(), !_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Mme/M: " + _vm._s(_vm.filter.customer.first_name) + " " + _vm._s(_vm.filter.customer.last_name))])]) : _vm._e()])];
      }
    }], null, false, 1175828086)
  }, [_c('div', [_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Société: " + _vm._s(_vm.filter.customer.company_name))])]) : _vm._e(), !_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Mme/M: " + _vm._s(_vm.filter.customer.first_name) + " " + _vm._s(_vm.filter.customer.last_name))])]) : _vm._e(), _c('div', [_vm._v("Mail: " + _vm._s(_vm.filter.customer.email))]), _c('div', [_vm._v("Type de prise: " + _vm._s(_vm.getElecTypeLabel(_vm.filter.customer.plug_elec)))])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }