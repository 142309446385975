var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('input', {
    ref: "inputImportFile",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/JSON"
    },
    on: {
      "change": _vm.inputImportFileChanged
    }
  }), _c('EnhancedList', {
    scopedSlots: _vm._u([{
      key: "additional-toolbar",
      fn: function fn() {
        return [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ml-5 mb-2",
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
            }
          }])
        }, [_c('v-list', [_c('v-list-item', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _vm.exportData
          }
        }, [_vm._v(_vm._s(_vm.$t("common.export")))])], 1), _c('v-list-item', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _vm.clickFileImportSelection
          }
        }, [_vm._v(_vm._s(_vm.$t("common.import")))])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }