<template lang="pug">
  include ../tool/CommonList.pug

</template>


<script>
  import {Model, EditDialog} from "./imports.js"

  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog//, ConfirmDelete
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      isViewAllowed() {
        return false
      },
      isDeleteAllowed() {
        return true
      },
      setup () {
        //console.log("parent_id : "  + this.parent_id)
        this.hidesearch = true
 
        this.headers = [
          {align: "start", width: "10", text: this.model.content + '.' + 'pos', value: 'pos', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'label', value: 'label', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'type', value: 'type', sortable: false},
  
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ],

        this.columns = [
          {class: "handle", text: '::'},
          {class: "text-left", value: 'label'},
          {class: "text-left", value: 'type', format: this.translateType},


          {class: "text-right", value: 'created_date', format: this.formatDatetime},
        ]
      },
      translateType: function (value) {
        return this.$t(this.model.content + '.' + "type_" + value)
      },
    },

  }


</script>
