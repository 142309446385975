  export default {
    props: {
    },
    data: () => ({
      show: false,
      item: Object
    }),
    methods : {
      showDeleteDialog(item) {
        this.show = true
        this.item = item
      },
      confirmDelete () {
        //console.log("ici")
        this.show = false
        this.$emit('confirmDelete', this.item)
      },
      cancelDelete () {
        //console.log("la")
        this.show = false
        this.$emit('cancelDelete')
      },
    },
  }
