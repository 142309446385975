var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "75vw"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t('nfc.edit_card')))]), _c('v-card-text', [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    staticClass: "elevation-2"
  }, [_vm._v("Prises")]), _vm._l(_vm.markers, function (marker) {
    return _vm.isDisplayed(marker) ? _c('v-tab', {
      key: 'marker_' + marker.id,
      staticClass: "elevation-2"
    }, [_vm._v(_vm._s(marker.label))]) : _vm._e();
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-container', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.memory ? _c('v-select', {
    attrs: {
      "label": _vm.$t('customer.' + 'plug_elec'),
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.elecPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.memory.plug_elec,
      callback: function callback($$v) {
        _vm.$set(_vm.memory, "plug_elec", $$v);
      },
      expression: "memory.plug_elec"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_vm.memory ? _c('v-select', {
    attrs: {
      "label": _vm.$t('customer.' + 'plug_water'),
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.waterPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.memory.plug_water,
      callback: function callback($$v) {
        _vm.$set(_vm.memory, "plug_water", $$v);
      },
      expression: "memory.plug_water"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _vm._l(_vm.markers, function (marker) {
    return _vm.isDisplayed(marker) ? _c('v-tab-item', {
      key: 'marker_detail_' + marker.id
    }, [_c('v-card', {
      staticClass: "elevation-2",
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', _vm._l(marker.nfcvaluemodifiers, function (nfcvaluemodifier, index) {
      return _c('NFCValueModifier', {
        key: nfcvaluemodifier.id,
        attrs: {
          "readonly": _vm.readonly,
          "memory": _vm.memory,
          "item": _vm.getItem(nfcvaluemodifier)
        },
        on: {
          "success": _vm.success,
          "error": _vm.error
        }
      });
    }), 1)], 1)], 1) : _vm._e();
  })], 2)], 1), _c('v-card-actions', [_c('v-spacer'), _vm.memory.data.isRead ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.ok($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.ok')))]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.cancel($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))])], 1)], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.writing,
      "opacity": "0.8"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "rotate": 360,
      "size": 100,
      "width": 15,
      "value": "50",
      "color": "teal"
    }
  }, [_vm._v("50%")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }