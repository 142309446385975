<template lang="pug">
  include ../tool/CommonList.pug

</template>


<script>
  import {Model, EditDialog} from "./imports.js"
  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        this.model = new Model()
      },
      
      isDeleteAllowed: function() {
        return false
      },
      setup () {
        this.headers = [
          {align: "start", width: "10", text: this.model.content + "." + 'pos', value: 'pos', sortable: false},
          {align: "start", width: "100", text: this.model.content + "." + 'reference', value: 'reference', sortable: false},
          {align: "start", width: "100", text: this.model.content + "." + 'label', value: 'label', sortable: false},
          {align: "center", width: "60", text: this.model.content + "." + 'price_ht', value: 'price_ht', sortable: false},
  
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ],

        this.columns = [
          {class: "handle", text: '::'},
          {class: "text-left", value: 'reference'},
          {class: "text-left", value: 'label'},
          {class: "text-right", value: 'price_ht', format: this.formatCurrency},
          {class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]
      },
      
    },

  }

</script>
