var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('Infos', {
    ref: "Infos",
    attrs: {
      "readonly": _vm.readonly,
      "showClose": false
    },
    on: {
      "success": _vm.successEdit,
      "error": _vm.errorEdit,
      "valueChanged": _vm.valueChanged
    }
  })], 1), _c('v-card-text', [_c('div', {
    staticClass: "pa-1"
  }, [_c('ListMarkerNFCValueModifiers', {
    attrs: {
      "parent_id": this.$route.params.id
    },
    on: {
      "success": _vm.success,
      "error": _vm.error
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }