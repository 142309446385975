import Basket, { BASKET_TYPE_CARD_ACTIVATION, BASKET_TYPE_CARD_TIME, BASKET_TYPE_CARD_UNITS, BASKET_TYPE_RELOAD_ACTIVATION, BASKET_TYPE_RELOAD_TIME, BASKET_TYPE_RELOAD_UNITS } from "../../model/basket/Basket.js";
const basketModel = new Basket()

import ExpandBasket from "../../model/expandbasket/ExpandBasket.js";
const expandBasketModel = new ExpandBasket()

import BasketLine from "../../model/basketline/BasketLine.js";
const basketLineModel = new BasketLine()

import BasketTagLine from "../../model/baskettagline/BasketTagLine.js";
const basketTagLineModel = new BasketTagLine()

//import PreviewCustomer from '../customer/PreviewCustomer.vue'

import ConfirmDelete from "../tool/ConfirmDelete.vue";
import EditQty from "../tool/EditQty.vue";
import TotalBasket from "./TotalBasket.vue";
import TotalNfcQty from "./TotalNfcQty.vue";

import { PRODUCT_TYPE_RELOAD_OTHER, PRODUCT_TYPE_RELOAD_TIME, PRODUCT_TYPE_RELOAD_UNITS } from "../../model/product/Product.js";
import loginStore from '../tool/LoginStore';

// import Product from "../../model/product/Product.js"
// const productModel = new Product()

export default {
    components: {
      EditQty
      , ConfirmDelete
      /*, PreviewCustomer*/
      , TotalBasket
      , TotalNfcQty
    },
    props: {
      allProducts: Array,
      theBasket: Object
    },
    computed: {
      products() {
        return this.allProducts.filter(
          (p) =>
          (this.isActivationCard && [PRODUCT_TYPE_RELOAD_OTHER].includes(p.type))
            ||   (this.isCardWithUnits && [PRODUCT_TYPE_RELOAD_UNITS, PRODUCT_TYPE_RELOAD_OTHER].includes(p.type))
            ||   (this.isCardWithTime && [PRODUCT_TYPE_RELOAD_TIME, PRODUCT_TYPE_RELOAD_OTHER].includes(p.type))
        )
      },

      isCardWithTime() {
        return [BASKET_TYPE_CARD_TIME, BASKET_TYPE_RELOAD_TIME].includes(this.basket.type)
      },

      isCardWithUnits() {
        return [BASKET_TYPE_CARD_UNITS, BASKET_TYPE_RELOAD_UNITS].includes(this.basket.type)
      },

      isActivationCard() {
        return [BASKET_TYPE_CARD_ACTIVATION, BASKET_TYPE_RELOAD_ACTIVATION].includes(this.basket.type)
      }
    },
    watch: {
      theBasket: function() {
        this.basket = this.theBasket
      },
      basket: function() {
        this.basket.tag_lines.forEach(tag_line => {
          tag_line.lines.forEach(line => {
            //let newLine = Object.assign({}, line)
            //newLine.card_label = tag_line.label
            line.vatlines.forEach(vatline => {
              vatline.amount_diff = vatline.amount - vatline.basis
            })
            
            // if (line.vatlines.length == 1) {
            //   line.vat = line.vatlines[0].label
            //   line.vat_tooltip = this.getVatTable(line.vatlines)
            // } else if (line.vatlines.length > 1) {
            //   line.vat = "(*)"
            //   line.vat_tooltip = this.getVatTable(line.vatlines)
            // } 
            if (line.details.length > 1) {
              line.label_s = line.label + " (*)"
              line.label_s_tooltip = this.getDetailTable(line.details)
              console.log(line.label_tooltip)
            } else {
              line.label_s = line.label
            }
          });
        });
      }
    }, 
    data: () => ({
      basket: {}
      , showCustomer: false
      , basket_headers: [
        //{align: "start", width: "2", text: 'baskettagline.card_label', value: 'card_label' },
        {align: "start", width: "10", text: 'basketline.reference', value: 'reference' },
        {align: "start", width: "10", text: 'basketline.label', value: 'label_s' },
        {align: "end", width: "2", text: 'basketline.qty', value: 'qty' },
        {align: "end", width: "2", text: 'basketline.unit', value: 'unit' },
        {align: "end", width: "2", text: 'basketline.unit_price_ht', value: 'unit_price_ht' },
        {align: "end", width: "2", text: 'basketline.discounted_unit_price_ht', value: 'discounted_unit_price_ht' },
        {align: "end", width: "2", text: 'basketline.price_ht', value: 'price_ht' },
        {align: "end", width: "2", text: 'basketline.vat', value: 'vat' },
        {align: "end", width: "2", text: 'basketline.price_ttc', value: 'price_ttc' },
      ]
      , basket_columns: []
      // label   = models.CharField(max_length=120)
      // basis   = models.FloatField(default=0, validators=[MinValueValidator(0)])
      // rate    = models.FloatField(validators=[MinValueValidator(0)])
      // amount  = models.FloatField(validators=[MinValueValidator(0)])
      
      , createdCredit: null
      , dialogCreditCreated: false

      , showAdd: false
      , qtyToAdd: 1
      , selectedProduct: null
    }), 
    methods: {
      addProduct(qty, product_id) {
        let basketTagLineId = null
        if (! this.basket 
          || ! this.basket.tag_lines 
          || this.basket.tag_lines.length < 1
          || ! this.basket.tag_lines[0]) {
          return
        }
        basketTagLineId = this.basket.tag_lines[0].id

        // let data = {
        //   parent_id: this.basket.id
        //   , id: this.basketTagLineId
        //   , label: baskettagline.label
        //   , pos: baskettagline.pos
        //   , qty:parseInt(item.qty)
        //   , product_id: item.id
        // }

        // create BasketTagLine
        basketTagLineModel.addProduct(
          basketTagLineId
          , {qty, product_id}
        )
        .then((result) => {
          this.basket = result.data
          this.$emit('success', 'common.updated')
        }).catch((err) => {
          this.$emit("error", "Le produit n'a pas pu être ajouté", err)
        }).finally(() => {
          this.showAdd = false
        })
        //   data
        //   , () => {
        //     this.showDialog = false
        //     //this.loadCurrentBasket()
        //     user.getCurrentBasket(
        //       (basket) => {
        //         loginStore.state.basketQty = basket.qty
        //       }
        //       , (e) => {
        //         this.$emit('error', 'common.not_found', e)
        //       }
        //     )
    
        //     this.$emit('success', 'common.updated')
        //   }
        //   , (e) => {
        //     this.$emit('error', 'common.not_found', e)
        //   }
        // )
      },

      confirmDelete: function(item) {
        let _self = this
        if (item.lines != null) {
          //console.log("tag")
          basketTagLineModel.delete(
            item
            ,() => {
              _self.$emit('success', 'common.deleted')
              _self.fetchData()
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.deleted_error', e)
            }
          )
        } else {
          //basketLineModel
          //console.log("line")
          basketLineModel.delete(
            item
            ,() => {
              _self.$emit('success', 'common.deleted')
              _self.fetchData()
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.deleted_error', e)
            }
          )
        }
      },
      cancelDelete: function() {
        
      },
      confirmQty: function(item) {
        let _self = this
        basketLineModel.update(
          item
          ,() => {
            _self.$emit('success', 'common.updated')
            _self.fetchData()
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.updated_error', e)
          }
        )
      },
      cancelQty: function() {
        
      },
      deleteTagLine: function(tagLine) {
        //console.log("delete", tagLine)
        this.$refs.ConfirmDelete.showDeleteDialog(tagLine)
      },
      editBasketLine: function(basketLine) {
        console.log("edit", basketLine)

        this.$refs.EditQty.showDialog(basketLine)
      },
      deleteBasketLine: function(basketLine) {
        //console.log("delete", basketLine)
        this.$refs.ConfirmDelete.showDeleteDialog(basketLine)
      },
      isBasketEmpty: function() {
        if (this.basket  == null || this.basket.tag_lines == null || this.basket.tag_lines.length == 0) {
          return true
        }
        let res = true
        
        this.basket.tag_lines.forEach(tagline => {
          if (tagline.lines != null && tagline.lines.length > 0) {
            res = false
          }
        })

        return res
      },
      getDetailTable(details) {
        let res = "<table >"

        // res += "<thead>"
        // res += "<tr>"
        // res += "<th>"
        // res += this.$t('vat.basis')
        // res += "</th>"
        // res += "<th>"
        // res += this.$t('vat.code')
        // res += "</th>"
        // res += "<th>"
        // res += this.$t('vat.amount')
        // res += "</th>"
        // res += "</tr>"
        // res += "</thead>"

        res += "<tbody>"
        details.forEach(detail => {
          res += "<tr>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += detail.qty
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += detail.unit
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-left'>"
          res += detail.label
          res += "</td>"
          res += "</tr>"
        })
        res += "</tbody>"
        res += "</table>"
        return res
      },
      getVatTable(vats) {
        let res = "<table style='border-color=grey;border-width=1;'>"

        res += "<thead>"
        res += "<tr>"
        res += "<th>"
        res += this.$t('vat.basis')
        res += "</th>"
        res += "<th>"
        res += this.$t('vat.code')
        res += "</th>"
        res += "<th>"
        res += this.$t('vat.amount')
        res += "</th>"
        res += "</tr>"
        res += "</thead>"

        res += "<tbody>"
        vats.forEach(vat => {
          res += "<tr>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += this.formatCurrency(vat.basis)
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += vat.label
          res += "</td>"
          res += "<td class='ma-1 pa-1 text-right'>"
          res += this.formatCurrency(vat.amount_diff)
          res += "</td>"
          res += "</tr>"
        })
        res += "</tbody>"
        res += "</table>"
        return res
      },
      setBasket(basket) {
        this.basket = basket
      },
      translatePaymentMethod(payement) {
        return basketModel.translatePaymentMethod(this, payement)
      },

      displayShowAdd: function() {
        this.showAdd = true
        this.qtyToAdd = 1
      },

      fetchData: function() {
        //let _self = this
        
        this.basket.id = this.$route.params.id

        if (this.basket.id != undefined ) {
          this.showCustomer = true
          expandBasketModel.get(
            this.basket
            , (response) => {
              this.basket = response.data
              //console.log("basket_orig", this.basket.basket_orig)
              // this.title = this.$t("basket.state_" + this.basket.state) + (this.basket.state != "DRAFT" ?  " " + this.basket.number : "")
              // if (this.basket.state == "CREDIT") {
              //   this.title +=  " (" + this.$t("basket.state_" + this.basket.basket_orig.state) + " " + this.basket.basket_orig.number + ")"
              // }
              
              if (loginStore.state.active_basket_id == this.basket.id) {
                loginStore.state.basketQty = this.basket.qty
              }
              this.$nextTick(() => {
                if (this.$refs.PreviewCustomer != undefined) {
                  this.$refs.PreviewCustomer.item = this.basket.customer
                }
              })
            } 
            , function(e) {
              console.log("ko", e)
            }
          )
        } else {
          this.showCustomer = false
        }

        // const _self = this
        // this.model.get(
        //   this.item
        //   , function(response) {
        //     _self.item = response.data
        //     //console.log("ok", _self.item)
        //   } 
        //   , function(e) {
        //     console.log("ko", e)
        //   }
        // )
      },

      // loadProducts: function() {
      //   productModel.list(1, 200
      //     , (response) => {
      //       this.allProducts = response.data.results
      //     }
      //     , e => {
      //       console.log(e)
      //     }
      //     , {}
      //   )
      // },

      // createCredit() {
      //   expandBasketModel.creditP(this.basket)
      //   .then((data) => {
      //     //console.log(result)
      //     this.basket = data
      //     this.$emit('success', "basket.credit_created")
      //   })
      //   .catch(e => {
      //     if (e.response && e.response.data) {
      //       let data = e.response.data
      //       if (data.error == "NOTHING_TO_CREDIT") {
      //         this.$emit('error', "basket.error_creating_credit_zero")
      //         return
      //       } 
      //       if (data.error == "WRONG_STATE") {
      //         this.$emit('error', "basket.error_creating_credit_not_invoice")
      //         return
      //       } 
      //     }
      //     this.$emit('error', "basket.error_creating_credit")
      //   })
      // },
      // openCredit(credit) {
      //   basketModel.showPdf(this, credit)
      //   //loginStore.redirect("/hmi/basket/view/" + credit.id + "?readonly=true")
      // },
      // pdfAction() {
      //   //console.log("pdf")
      //   basketModel.showPdf(this, this.basket)
      // },
    },

    mounted() {
      this.basket_columns = [
        //{class: "text-left", value: 'card_label'},
        {class: "text-left", value: 'reference'},
        {class: "text-left", value: 'label_s'},
        {class: "text-right", value: 'qty'},
        {class: "text-right", value: 'unit'},
        {class: "text-right", value: 'unit_price_ht', format: this.formatCurrency},
        {class: "text-right", value: 'discounted_unit_price_ht', format: this.formatCurrency},
        {class: "text-right", value: 'price_ht', format: this.formatCurrency},
        {class: "text-right", value: 'vat'},
        {class: "text-right", value: 'price_ttc', format: this.formatCurrency},
      ]

      this.fetchData()
      // this.loadProducts()
    }

    
  }
