var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "inspire"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "justify-center": ""
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "450"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t("common.change_qty_title")))]), _c('div', {
    staticClass: "ma-4"
  }, [_c('v-text-field', {
    attrs: {
      "required": "",
      "type": "number",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "min": "1",
      "prefix": _vm.item == null ? '' : _vm.item.unit
    },
    model: {
      value: _vm.item.qty,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "qty", $$v);
      },
      expression: "item.qty"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.confirmQty($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.ok")))]), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.cancelQty($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }