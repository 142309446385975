var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isRead ? _c('div', [_vm.item.type == 'CONSUMP' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "prefix": _vm.item.unit,
      "label": _vm.item.label,
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.item.type == 'ADD' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "label": _vm.item.label + ' (' + _vm.item.unit + ')',
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.item.type == 'DAY' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "label": _vm.item.label + ' (' + _vm.$t('nfcvaluemodifier.type_DAY') + ')',
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.int_value,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "int_value", $$v);
      },
      expression: "item.int_value"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.item.type == 'FLAG' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.item.label))])])], 1), _vm._l(_vm.getFlagNbLines(), function (line, l) {
    return _c('v-layout', {
      key: _vm.item.id + '_' + l
    }, _vm._l(_vm.getNbFlagsInLine(l), function (flag, f) {
      return _c('v-flex', {
        key: _vm.item.id + '_' + (_vm.itemsPerLine * l + f),
        attrs: {
          "xs1": "",
          "md1": ""
        }
      }, [_c('v-checkbox', {
        attrs: {
          "label": _vm.getFlagLabel(_vm.itemsPerLine * l + f),
          "readonly": _vm.readonly
        },
        model: {
          value: _vm.flags[_vm.itemsPerLine * l + f],
          callback: function callback($$v) {
            _vm.$set(_vm.flags, _vm.itemsPerLine * _vm.l + f, $$v);
          },
          expression: "flags[itemsPerLine * l + f]"
        }
      })], 1);
    }), 1);
  })], 2) : _vm._e(), _vm.item.type == 'CUSTOMER_FIELD_SELECT' ? _c('div', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.item.label,
      "items": _vm.labels,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.int_value,
      callback: function callback($$v) {
        _vm.int_value = $$v;
      },
      expression: "int_value"
    }
  })], 1)], 1)], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }