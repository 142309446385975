var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_vm._v("Consommations"), _c('v-spacer'), _c('TransactionsFilterSummary', {
    attrs: {
      "filter": _vm.filter
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.showFilters();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("Filtres")], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.exportToCsv();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-file-delimited-outline")])], 1)], 1), _c('Transactions', {
    attrs: {
      "filter": _vm.filter,
      "showCustomer": true
    }
  }), _c('TransactionsFilterDialog', {
    ref: "TransactionsFilterDialog"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }