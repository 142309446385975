























import {Component, Vue} from 'vue-property-decorator'


@Component
export default class extends Vue {
  show: boolean = false
  title: string = ""

  isoDate: string | null = null;

  // timestampInS: number | null = 0 


  okCb: ((isoDate: string | null) => void) | null = null

  async showDialogSelectDate(title: string, isoDate: string | null): Promise<string | null> {
    return new Promise((resolve, reject) => {
      this.title = title
      this.isoDate = isoDate
      this.show = true
      
      this.okCb = resolve
    })
  }

  ok(): void {
    this.show = false
    if (this.okCb) {
      this.okCb(this.isoDate)
    }
  }

  cancel(): void {
    this.show = false
    if (this.okCb) {
      this.okCb(null)
    }
  }

  // get formatedIsoDate(): string {
  //   if (this.timestampInS == null) {
  //     return ""
  //   }
  //   return this.formatIsoDate(this.timestampInS*1000)
  // }

  // set formatedIsoDate(value: string) {
  //   this.timestampInS = this.parseIsoDate(value) / 1000
  // }

}

