<template lang="pug">
div
  input.d-none(type='file' ref="inputImportFile" v-on:change="inputImportFileChanged" accept="application/JSON")
  EnhancedList()
    template(v-slot:additional-toolbar)
      v-menu(
        bottom
        left
      )
        template(v-slot:activator="{ on, attrs }")
          v-btn.ml-5.mb-2(
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            icon
          ) 
            v-icon mdi-dots-vertical
        v-list
          v-list-item()
            v-btn(color="primary" dark @click="exportData") {{$t("common.export")}}
          v-list-item()
            v-btn(color="primary" dark @click="clickFileImportSelection") {{$t("common.import")}}
</template>


<script > 
  import Vue from 'vue'
  import {Model, EditDialog} from "./imports.js"

  import commonList from "../tool/CommonList.vue"

  const model = new Model()

  var EnhancedList = Vue.extend({
    mixins: [commonList],
    components: {
      EditDialog
    },
    data: () => ({

    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        //console.log("iiiiici")
        this.model = model
      },

      isViewAllowed: function() {
        return true
      },
      isDeleteAllowed: function() {
        return true
      },
      setup () {
        this.headers = [
          {align: "start", width: "10", text: this.model.content + '.' + 'pos', value: 'pos', sortable: false},
          {align: "start", width: "100", text: this.model.content + '.' + 'label', value: 'label', sortable: false},
          {align: "start", width: "30", text: this.model.content + '.' + 'code', value: 'code', sortable: false},
          {align: "start", width: "30", text: this.model.content + '.' + 'type', value: 'type', sortable: false},
          {align: "end", width: "60", text: this.model.content + '.' + 'block', value: 'block', sortable: false},
          {align: "end", width: "60", text: this.model.content + '.' + 'byte', value: 'byte', sortable: false},
          {align: "end", width: "60", text: this.model.content + '.' + 'size', value: 'size', sortable: false},
          {align: "end", width: "60", text: this.model.content + '.' + 'ratio', value: 'sizratioe', sortable: false},
  
          {align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "30", text: 'common.actions', value: 'name', sortable: false }
        ]

      this.columns = [
          {class: "handle", text: '::'},
          {class: "text-left", value: 'label'},
          {class: "text-left", value: 'code'},
          {class: "text-left", value: 'type', format: this.translateType},
          {class: "text-right", value: 'block'},
          {class: "text-right", value: 'byte'},
          {class: "text-right", value: 'size'},
          {class: "text-right", value: 'ratio'},
          
          {class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]
      
      },
      translateType: function (value) {
        return this.$t(this.model.content + '.' + "type_" + value)
      },
    }
  })

  export default {
    //extends: CommonList,
    components: {
      EnhancedList
    },
    data: () => ({
      importFile: null
    }),
    computed: {
    },
    watch: {
      importFile: function(newValue) {
        if (newValue) {
          this.importData()
        }
      }
    },
    methods: {
      inputImportFileChanged(e) {
        const files = e.target.files
        console.log(files.length)
        if (files.length > 0) {
          this.importFile = files[0]
          //console.log(this.importFile)
        } else {
          this.importFile = null
        }
      },

      exportData: function() {
        console.log("export")
        model.exportData()
          .then(() => {
            this.onSuccess('common.export_success')
          }).catch((err) => {
            this.onError('common.export_error', err)
          })
      },
      importData: function() {
        model.importData(this.importFile)
          .then(() => {
            this.onSuccess('common.import_success')
            this.clearImportFile()
          }).catch((err) => {
            this.onError('common.import_error', err)
            this.clearImportFile()
            
          })
      },
      clearImportFile: function() {
        // pour pouvoir recharger le même fichier
        this.$refs.inputImportFile.value = ''
      },
      clickFileImportSelection: function() {
        this.$refs.inputImportFile.click()
      },
    },
  }

</script>
