import CustomerJS from './Customer'

export interface Customer {
    id: number
    is_company: boolean
    first_name: string
    last_name: string
    company_name: string
    email: string

    phone: string
    country: string
    city: string
    zip: string
    address: string
    plug_elec: number
    plug_water: number
}

export default class extends CustomerJS {
    constructor() {
        super()
    }

    listP(page: number, page_size: number, params: {search: string}): Promise<Customer[]> {
        return new Promise((resolve, reject) => {
            super.listP(page, page_size, params)
            .then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getP(id: number): Promise<Customer> {
        return new Promise((resolve, reject) => {
            super.getP({id})
            .then((result) => {
                resolve(result.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

