import { SelectValue } from '../energybox/Metrics'
import SubscribtionModel from './Subscribtion'

export interface SubscribtionFilter {
    customer_id?: number
}


export enum SubscribtionType {
    MONTHLY = "MONTHLY"
    , TRIMSTER = "TRIMSTER"
    , ANNUALLY = "ANNUALY"
}
export const labelsSubscribtionType: SelectValue[] = [
    {id: SubscribtionType.MONTHLY, label: "Mensuel"}, 
    {id: SubscribtionType.TRIMSTER, label: "Trimsetriel"}, 
    {id: SubscribtionType.ANNUALLY, label: "Annuel"}, 
]

export enum SubscribtionStatus {
    CLOSED = "CLOSED"
    , PROGRESS = "PROGRESS"
    //, DRAFT = "DRAFT"
}
export const labelsSubscribtionStatus: SelectValue[] = [
    {id: SubscribtionStatus.CLOSED, label: "Clos"}, 
    {id: SubscribtionStatus.PROGRESS, label: "En cours"}, 
    //{id: SubscribtionStatus.DRAFT, label: "Brouillon"}, 
]

export interface Subscribtion {
    id:            number;
    customer:      number;
    created_date:  string;
    modified_date: string;
    begin:         string;
    end:           null;
    type:          SubscribtionType;
    status:        SubscribtionStatus;
    is_auto_renew: boolean;
    periods:       SubscribtionPeriod[];
}

export interface SubscribtionPeriod {
    id:            number;
    consumptions:  ConsumptionVariable[];
    created_date:  string;
    modified_date: string;
    begin:         string;
    end:           string;
    status:        string;
    is_current:    boolean;
    parent:        number;
    invoice:       number | null;
}

export enum ConsumptionVariableType {
    ELEC = "ELEC"
    , WATER = "WATER"
}
export const labelsConsumptionVariableType: SelectValue[] = [
    {id: ConsumptionVariableType.ELEC, label: "Electricité"}, 
    {id: ConsumptionVariableType.WATER, label: "Eau"}, 
]

export interface ConsumptionVariable {
    id:            number;
    details:       ConsumptionDetail[];
    created_date:  string;
    modified_date: string;
    type:          ConsumptionVariableType;
    parent:        number;
}

export interface ConsumptionDetail {
    id:            number;
    created_date:  string;
    modified_date: string;
    qty:           number;
    prev_qty:      number;
    num:           number;
    status:        ConsumptionDetailStatus;
    parent:        number;
    plc:           PLC;
}

export interface PLC {
    label: string
}

export enum ConsumptionDetailStatus {
    WAITING     = "WAITING"
    , PROGRESS  = "PROGRESS"
    , CLOSED    = "CLOSED"
}

export const labelsConsumptionDetailStatus: SelectValue[] = [
    {id: ConsumptionDetailStatus.WAITING, label: "En attente"}, 
    {id: ConsumptionDetailStatus.PROGRESS, label: "En cours"}, 
    {id: ConsumptionDetailStatus.CLOSED, label: "Terminé"}, 
]
// export const labelsConsumptionDetailStatus: SelectValue[] = Object.keys(ConsumptionDetailStatus)
//     .map((key) => {return {id: key, label: "ConsumptionDetailStatus" + "." + key}})


export default class SubscribtionService extends SubscribtionModel {
    constructor() {
        super()
    }

    listP(page: number, page_size: number, params: SubscribtionFilter): Promise<Subscribtion[]> {
        return new Promise((resolve, reject) => {
            super.postP(this.api_url, {page, page_size, ...params})
            .then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
