import Model from "../Model.js"
import rest from '../../hmi/tool/rest.js'


export default class Basket extends Model {
    constructor() {
        super("expandbasket")
      }

    defaultValues() {
        return {
            tag_lines:[]
            , vats:[]
            , customer: {
                id: null
            },

        }
    }

    getPaymentMethods(_self) {
        return [
            {value: "CARD", label:_self.$t(this.content + '.payment_method_' + 'CARD')}, 
            {value: "CHECK", label:_self.$t(this.content + '.payment_method_' + 'CHECK')},
            {value: "CASH", label:_self.$t(this.content + '.payment_method_' + 'CASH')},
        ]
    }

    creditP(params) {
        return new Promise((success, error) => {
            params = this.serialize(params)
            rest.post(
                this.api_url + params.id + "/create_credit/"
                , params
                , (response) => {
                    console.log(response.data)
                    success(response.data)
                }
                , e => {
                    //console.log("rr", e.response)
                    error(e)
                    //self.$emit('error', 'common.not_found', e)
                }
            )
        })
    }

}


