














































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'

import { EtatEnergy, Metrics, PLC, PLCPlug} from '@/components/model/energybox/Metrics'

import {Customer} from '@/components/model/customer/CustomerTS'
import {getElecTypeLabel, getWaterTypeLabel, getCustomerShortdesc, getStatusColor} from '@/shared/utils'
import EnergyBox from '@/components/model/energybox/EnergyBox'

const energyBox = new EnergyBox()

// import { EtatEnergy, getLabel, PLCWithMetrics, typesElec, typesWater } from '@/components/model/energybox/Metrics'

@Component
export default class extends Vue {
  @Watch("energyBox")
  energyBoxChanged() {
    this.lastUpdate = Date.now()
  }

  @Prop({
    type: Object,
    required: false,
  })
  pEnergyBox?: PLC

  @Prop({
    type: Object,
    required: false,
  })
  pCustomer?: Customer

  lastUpdate: number = 0
  energyBox?: PLC = this.pEnergyBox
  customer?: Customer = this.pCustomer
  metrics: Metrics | null = null

  show: boolean = false
  overlay: boolean = false

  @Watch("pCustomer")
  onPCustomerChanged(): void {
    this.customer = this.pCustomer
  }

  @Watch("pEnergyBox")
  onPEnergyBox(): void {
    if (this.pEnergyBox) {
      this.energyBox = this.pEnergyBox
    }
  }

  async fetchData(): Promise<void> {
    if (this.energyBox) {
      try {
        const res = await energyBox.read(this.energyBox.id)
        if (! res.updated) {
          console.log(res);
          this.onError("Les valeurs ne sont peut-être pas à jour", new Error("Not updated"));
        }
        this.energyBox = res.plc
        this.metrics = res.metrics
      } catch (error: any) {
          console.log("catch")
          this.metrics = null
          this.onError(`Impossible de lire les valeurs`, error as Error)
      }
      // const plc: PLC  = await energyBox.getMetrics(this.energyBox.id)
      // this.energyBox = plc
    }
  }

  update() {
    this.fetchData()
  }

  showDialog(): void {
    this.show = true
  }

  getStatusColor(lastOk: string): string {
    return getStatusColor(this.lastUpdate, lastOk)
  }

  async lock(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    let customerId: number | undefined = this.customer?.id
    if (plcId && customerId) {
      try {
        this.overlay = true
        console.log("try start")
        const plc: PLC = await energyBox.lock(plcId, num, customerId)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet est réservé")
        console.log("try end")
      } catch (error: any) {
        console.log("catch")
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être réservé. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        console.log("finally")
        this.overlay = false
      }
    }
  }

  async unlock(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.unlock(plcId, num)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet est déreservé")
      } catch (error: any) {
        console.log(error)
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être déreservé. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async start(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.start(plcId, num)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet est en marche")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être mis en marche. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async stop(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.stop(plcId, num)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet a été coupé")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être coupé. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }


  getPlugTypeLabel(plug: PLCPlug): string | undefined {
    if (plug.type == "ELEC") {
      return getElecTypeLabel(plug.kind)
    }
    if (plug.type == "WATER") {
      return getWaterTypeLabel(plug.kind)
    }

    return ""
  }

  getElecTypeLabel(typeElec: number): string | undefined {
      return getElecTypeLabel(typeElec)
  }

  getCustomerShortdesc(customer: Customer, email: boolean = false): string | null {
    return getCustomerShortdesc(customer, email)
  }

  connexion: WebSocket | null = null
  
  connect(): void {
    this.disconnect()
    this.connexion = new WebSocket(`ws://localhost:8000/ws/plc/39/listen/?gateway_token=aabbccddeeffgghh2i`)
    this.connexion.onopen = (event) => {
      console.log("open")
    }
    this.connexion.onclose = (event) => {
      console.log("close")
    }
    this.connexion.onmessage = (event) => {
      // console.log("msg", event.data)
      this.metrics = JSON.parse(event.data)
    }
  }

  disconnect(): void {
    if (this.connexion) {
      this.connexion.close()
      this.connexion = null
    }
  }

  created(): void {
    this.connect()
  }

  beforeRouteLeave(to: any, from: any, next: any): void {
    console.log("cleaning")
    this.disconnect()
    next()
  }
}


