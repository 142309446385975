import Model from "../Model.js";


export default class Company extends Model {
    constructor() {
        super("company", "api")
    }

    defaultValues() {
        return {
            id              : 0
            , name          : null
            , currency      : null
            , nb_decimals   : null
            , email         : null
            , phone         : null
            , country       : null
            , city          : null
            , zip           : null
            , address       : null
            , legal_info    : null
            , month_validity : null
            //, key           : null
        }
    }


    cleanTransactions() {
        return this.postP(this.api_url + "clean_transactions/")
    }
}


