import Model from "../Model.js"
import loginStore from '../../hmi/tool/LoginStore'
import Nfc from '../../../shared/service/MifareClassic1K.js'
let nfc = new Nfc(loginStore.state.company.key)

import NFCCardModel from "../nfccard/NFCCard.js"
const nfcCardModel = new NFCCardModel()

import NFCBatchBlocks from "../nfcbatchblocks/NFCBatchBlocks.js"
const nfcBatchBlocksModel = new NFCBatchBlocks()

import rest from '../../hmi/tool/rest.js'

export default class BasketLine extends Model {
    constructor() {
        super("baskettagline")
    }


    defaultValue() {
        return {
            id: 0,
            pos: 0,
            label: ""
        }
    }

    async addProduct(id, tagProduct) {
      const data = this.serialize(tagProduct)
      return new Promise((resolve, reject) => {
          rest.post(
              this.api_url + id + "/add_product/"
              , data
              , response => {
                  resolve(response)
              }
              , (e) => {
                  reject(e)
              }
          )
      })
  }

    async writeItem(tagline, progress, callback, error) {
  
      progress(5)

      try {
        let uid = await nfc.getIdP()
        console.log("======>", uid)
        let card = await nfcCardModel.getMetaInfosP(tagline, {uid: uid})
        console.log("card.request : ", card.request)
        let response = await nfcBatchBlocksModel.readPartP(card.request)

        progress(25)

        console.log("response", response)
        response = await this.updateP({
            id: tagline.id
            //, parent_id : _self.value.id
            , read_id: response.data.id
            , step: "READ"
          }
        )

        progress(30)

        let writeRequest = { 
          fct: "write"
          //, key: "FFFFFFFFFFFF"
          , sectors: []
        }
        for (let block of response.data.blocks) {
          if (block.block == 1) {
            console.log("block => ", block)
          }
          writeRequest.sectors.push({
              num: Math.floor(block.block / 4)
              , blocks: [
                  {num: block.block, data: block.data}
                ]
            }
          )
        }
        await nfc.writeBlocksP(writeRequest)

        progress(60)

        let reReadResponse = await nfcBatchBlocksModel.readPartP(card.request)

        progress(90)

        await this.updateP(
          {
            id: tagline.id
            //, parent_id : _self.value.id
            , read_write_id: reReadResponse.data.id
            , step: "WRITE"
          }
        )

        progress(100)

      } catch (e) {
        error(e)
      }

    }
    
}


