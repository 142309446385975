<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
      v-layout
        v-flex(
            xs12
            md6
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.label"
            :label="$t(this.model.content + '.' + 'label')"
          )
        v-flex(
          xs12
          md6
        )
          v-text-field(
            :readonly="readonly"
            
            v-model="item.description"
            :label="$t(this.model.content + '.' + 'description')"
          )
      v-layout()
        v-flex(
          xs12
          md6
        )
          v-text-field(
            :readonly="readonly"
            required
            v-model="item.value"
            type="number"
            :label="$t(this.model.content + '.' + 'value')"
          )
</template>

<script>
  import {Model} from "./imports.js"
  import CommonEdit from "../tool/CommonEdit.js"


  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({

    }),
    watch: {

    }, 
    
    methods: {
      
      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        
      }
    },
  }


</script>
