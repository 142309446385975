import { BASKET_TYPE_CARD_TIME, BASKET_TYPE_RELOAD_TIME } from "../../model/basket/Basket.js";

export default {
  components: {
  }
  , props: {
    value: null,
  }
  , computed: {
  }
  , watch: {
  }
  , data: () => ({
    total_headers: [
      {align: "end", width: "2", text: 'Eau', value: 'qty_water' },
      {align: "end", width: "2", text: 'Electricité', value: 'qty_elec' },
      {align: "end", width: "2", text: 'Douches', value: 'nb_showers' },
      {align: "end", width: "2", text: 'Parking', value: 'days_parking' },
    ],
    BASKET_TYPE_CARD_TIME: BASKET_TYPE_CARD_TIME,
    BASKET_TYPE_RELOAD_TIME: BASKET_TYPE_RELOAD_TIME,

  })
  , methods: {

  }

  , mounted() {
    this.total_columns = [
      {class: "text-right", value: 'total_ht', format: this.formatCurrency},
      {class: "text-right", value: 'total_ttc', format: this.formatCurrency},
    ]
  }
}
