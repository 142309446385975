<template lang="pug">
  include ../tool/CommonList.pug


</template>


<script >
  import {Model, EditDialog} from "./imports.js"
  
  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog
    },
    props: {
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        this.model = new Model()
      },
      isViewAllowed: function() {
        return true
      },
      isDeleteAllowed: function() {
        return true
      },

      formatRequest: function(item) {
        return item.parent.label + ' : ' + item.label
      },

      translateType: function(value) {
        return this.$t(this.model.content + "." + "type_" + value)
      },


      setup () {
        this.headers = [
          //{align: "start",  width: "10",  text: this.model.content + '.' + 'pos',          value: 'pos',          sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'parent',       value: 'parent.label', sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'type',         value: 'type',         sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'label',        value: 'label',        sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'code',         value: 'code',         sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'byte',         value: 'byte',         sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'size',         value: 'size',         sortable: false},
          //{align: "start",  width: "100", text: this.model.content + '.' + 'prefix',       value: 'prefix',       sortable: false},
          //{align: "start",  width: "100", text: this.model.content + '.' + 'suffix',       value: 'suffix',       sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'offset',       value: 'offset',       sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'ratio',        value: 'ratio',        sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'signed',       value: 'signed',       sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'bigendian',    value: 'bigendian',        sortable: false},

          //{align: "center", width: "10",  text: 'common.created_date',                     value: 'created_date', sortable: false},
          {align: "center", width: "10",  text: 'common.actions',                          value: 'name',         sortable: false }
        ]
 
       this.columns = [
          //{class: "handle", text: '::'},
          {class: "text-left",   value: 'parent', format: this.formatRequest},
          {class: "text-left",   value: 'type', format: this.translateType},
          {class: "text-left",   value: 'label'},
          {class: "text-left",   value: 'code'},
          {class: "text-left",   value: 'byte'},
          {class: "text-left",   value: 'size'},
          //{class: "text-left",   value: 'prefix'},
          //{class: "text-left",   value: 'suffix'},
          {class: "text-left",   value: 'offset'},
          {class: "text-left",   value: 'ratio'},
          {class: "text-left",   value: 'signed'},
          {class: "text-left",   value: 'bigendian'},
          //{class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]
      },
    },
  }

</script>
