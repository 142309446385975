
































































































import { Forcage, getLabel, PLC, PLCPlug, typesElec, typesWater } from '@/components/model/energybox/Metrics'

import { getElecTypeLabel, getWaterTypeLabel, getCustomerShortdesc} from '@/shared/utils'

import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'

import DialogForcing from '@/components/hmi/dialog/DialogForcing.vue'

import EnergyBox from  '@/components/model/energybox/EnergyBox'
import { Customer } from '@/components/model/customer/CustomerTS'
let energyBox = new EnergyBox()

@Component({
  components: {DialogForcing}
})
export default class extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  pEnergyBox!: PLC

  energyBox: PLC | null = this.pEnergyBox

  @Watch("pEnergyBox")
  onPEnergyBox(): void {
    if (this.pEnergyBox) {
      this.energyBox = this.pEnergyBox
    }
  }

  headers: DataTableHeader[] = []
  customers: any[] = []
  forcage: Forcage = {forcage: false, value: false}
  titleForcage: string = ""

  overlay: boolean = false

  getDialogForcing(): DialogForcing {
    return this.$refs.DialogForcing as DialogForcing
  }

  // getCustomerLabel(index: number): string | undefined {
  //   const idCustomer = this.energyBox?.metrics?.etat_energies?.[index]?.id_customer
  //   if (! idCustomer) {
  //     return undefined
  //   }

  //   const customer = this.customers.get(idCustomer)
  //   if (! customer) {
  //     return "Inconnu"
  //   }

  //   return customer.email
  // }

  async showForcageRelais(i: number): Promise<void> {
    const forcage = this.energyBox?.metrics?.forcage_s_ext?.[i]
    if (forcage) {
      this.forcage = forcage 
      this.titleForcage = `Forçage relais ${i+1}`
      const f: Forcage = await this.getDialogForcing().showDialog()

      if (f) {
        try {
          // const currentForcages = this.energyBox?.metrics?.forcage_s_ext
          // if (! currentForcages) {
          //   return
          // }
          // const forcages = [... currentForcages]
          // forcages[i] = forcage
          await this.forcerRelai(i, f.forcage, f.value)
          this.onSuccess("La demande a été envoyée")
        } catch (e) {
          this.onError("Le relais n'a pas pu être forcé", e as Error)
        }
      }
    }
  }

  async showForcageVoyant(i: number): Promise<void> {
    const forcage = this.energyBox?.metrics?.forcage_s_voy?.[i]
    if (forcage) {
      this.forcage = forcage 
      this.titleForcage = `Forçage voyant ${i+1}`
      const f: Forcage = await this.getDialogForcing().showDialog()

      if (f) {
        try {
          // const currentForcages = this.energyBox?.metrics?.forcage_s_voy
          // if (! currentForcages) {
          //   return
          // }
          // const forcages = [... currentForcages]
          // forcages[i] = forcage
          await this.forcerVoyant(i, f.forcage, f.value)
          this.onSuccess("La demande a été envoyée")
        } catch (e) {
          this.onError("Le voyant n'a pas pu être forcé", e as Error)
        }
      }
    }
  }

  // async showForcageInit(i: number): Promise<void> {
  //   const forcage = this.energyBox?.metrics?.forcage_init_energie?.[i]
  //   if (forcage) {
  //     this.forcage = forcage 
  //     this.titleForcage = `Réinitialisation ${i+1}`
  //     const f: Forcage = await this.getDialogForcing().showDialog()

  //     if (f) {
  //       try {
  //         // const currentForcages = this.energyBox?.metrics?.forcage_init_energie
  //         // if (! currentForcages) {
  //         //   return
  //         // }
  //         // const forcages = [... currentForcages]
  //         // forcages[i] = forcage
  //         await this.forcerInit(i, f.forcage, f.value)
  //         this.onSuccess("La demande a été envoyée")
  //       } catch (e) {
  //         this.onError("La réinitialisaion n'a pas pu être faite", e as Error)
  //       }
  //     }
  //   }
  // }

  getElecTypeLabel(index: number): string | undefined {
    return getLabel(this.energyBox?.metrics?.types_energies?.[index], typesElec)
  }

  getWaterTypeLabel(index: number): string | undefined {
    return getLabel(this.energyBox?.metrics?.types_energies?.[index], typesWater)
  }

  getLightColor(index: number): string {
    if (! this.energyBox?.metrics || ! this.energyBox.metrics.les_voyants || index >= this.energyBox.metrics.les_voyants.length) {
      return "warning"
    }
    return this.energyBox.metrics.les_voyants[index] ? "success" : "secondary"
  }

  getRelayColor(index: number): string {
    if (! this.energyBox?.metrics || ! this.energyBox.metrics.lesrelais || index >= this.energyBox.metrics.lesrelais.length) {
      return "warning"
    }
    return this.energyBox.metrics.lesrelais[index] ? "success" : "secondary"
  }

  getCustomerShortdesc(customer: Customer, email: boolean = false): string | null {
    return getCustomerShortdesc(customer, email)
  }

  getPlugTypeLabel(plug: PLCPlug): string | undefined {
    if (plug.type == "ELEC") {
      return getElecTypeLabel(plug.kind)
    }
    if (plug.type == "WATER") {
      return getWaterTypeLabel(plug.kind)
    }

    return ""
  }

  mounted() {
    this.headers.push(
      {align: "start", text: 'fct_energie', value: 'fct_energie' },
    )
  }


  async start(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.start(plcId, num)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet est en marche")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être mis en marche. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async stop(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.stop(plcId, num)
        this.energyBox = plc
        this.onSuccess("La prise ou le robinet a été coupé")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise ou le robinet n'a pas pu être coupé. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async forcerVoyant(num: number, forcage: boolean, value: boolean): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.forcerVoyant(plcId, num, forcage, value)
        this.energyBox = plc
        this.onSuccess("Voyant modifié")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`Le voyant n'a pas pu être modifié. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async forcerRelai(num: number, forcage: boolean, value: boolean): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.forcerRelai(plcId, num, forcage, value)
        this.energyBox = plc
        this.onSuccess("Relai modifié")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`Le relai n'a pas pu être modifié. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }

  async forcerInit(num: number): Promise<void> {
    let plcId: number | undefined = this.energyBox?.id
    //let customerId: number | undefined = this.customer?.id
    if (plcId) {
      try {
        this.overlay = true
        const plc: PLC = await energyBox.forcerInit(plcId, num, true, true)
        this.energyBox = plc
        this.onSuccess("Prise réinitialisée")
      } catch (error: any) {
        this.energyBox = error.plc ?? this.energyBox
        this.onError(`La prise n'a pas pu être réinitialisée. Cause: ${error?.errorMsg}`, error as Error)
      } finally {
        this.overlay = false
      }
    }
  }
}


