var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('NFCErrorDialog', {
    ref: "NFCErrorDialog"
  }), _c('EditNfcCard', {
    attrs: {
      "show": _vm.showEditNfc
    },
    on: {
      "hide": function hide($event) {
        _vm.showEditNfc = false;
      },
      "success": _vm.success,
      "error": _vm.error
    }
  }), _c('v-dialog', {
    attrs: {
      "width": "50vw"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "grey lighten-4 py-4 title"
  }, [_vm._v(_vm._s(_vm.$t("hmi.read_card")))]), _c('v-container', {
    staticClass: "pa-1",
    attrs: {
      "grid-list-sm": ""
    }
  }, [_vm.progressRead ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.$t("hmi.reading_card")))]), _c('div', [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)]) : _vm._e(), !_vm.progressRead && _vm.nfcBatchBlocks.values.length == 0 ? _c('div', [_vm._v(_vm._s(_vm.$t("hmi.read_card_instruction")))]) : _vm._e(), !_vm.progressRead && _vm.nfcBatchBlocks.values.length > 0 ? _c('div', [_vm.nfcBatchBlocks.values[0].int_value == 0 ? _c('div', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "text-h3 error--text"
  }, [_vm._v(_vm._s(_vm.$t("hmi.card_empty")))])]) : _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.card_number")))])]), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.formatUid(_vm.nfcBatchBlocks.parent.uid)))])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.customer")))])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.formatCustomer(_vm.nfcBatchBlocks.customer)) + " " + _vm._s(_vm.nfcBatchBlocks.status))])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "ma-5"
  })], 1)], 1), _vm._l(_vm.nfcBatchBlocks.values, function (value) {
    return _c('v-row', {
      key: value.id,
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('div', {
      staticClass: "pa-0",
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_vm._v(_vm._s(value.nfcinfo.label))])]), _c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('div', {
      staticClass: "pa-0",
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_vm._v(_vm._s(_vm.formatValue(value)))])])], 1);
  })], 2)]) : _vm._e()]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(_vm._s(_vm.$t('hmi.card_back')))]), _c('v-btn', {
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": function click($event) {
        return _vm.editNfcCard();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('hmi.card_edit')))]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.readNfc();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.read")))]), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(_vm._s(_vm.$t("hmi.close_dialog")))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }