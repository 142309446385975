<template lang="pug">
  extends ../tool/CommonDetail.pug
  block detail
    div.pa-1
      ListMarkerNFCValueModifiers(:parent_id="this.$route.params.id" @success="success" @error="error")

</template>

<script>
  import CommonDetail from "../tool/CommonDetail.js"
  
  import {Model, EditDialog} from "./imports.js"

  import ListMarkerNFCValueModifiers from "../markernfcvaluemodifier/ListMarkerNFCValueModifiers.vue"


  export default {
    extends: CommonDetail,
    components: {
      Infos: EditDialog, ListMarkerNFCValueModifiers 
    },
    props: {
    },
    computed: {

    }, 
    watch: {

    }, 
    data: () => ({
    }),

    methods: {
      loadModel () {
        this.model = new Model()
      },
    },
  }

</script>
