<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./ComPart.pug

</template>

<script >
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  import Gateway from "../../model/gateway/Gateway.js"

  let gatewayModel = new Gateway()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      gateways: [],
      types: []
    }),
    watch: {
    }, 

    methods: {
      prepareItemToCreate: function() {
        let _self = this

        //console.log(this.item)

        if (this.item.parent_id != null) {
          gatewayModel.get(
            {id: this.item.parent_id}
            , response => {
              _self.item.parent = response.data
              console.log("parent", _self.item.parent)
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.not_found', e)
            }
          )
        } 
      },

      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        let _self = this

        _self.types = _self.model.getTypes(_self)

        gatewayModel.listAll(
          response => {
            _self.gateways = response.data.results
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.not_found', e)
          }
        )
      }
    },
  }

</script>