import { PRODUCT_TYPE_CARD_ACTIVATION } from '@/components/model/product/Product';
import { PRODUCT_TYPE_CARD_ACTIVATION } from '@/components/model/product/Product';
import { PRODUCT_TYPE_CARD_ACTIVATION } from '@/components/model/product/Product';
<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./ProductPart.pug

    //- v-tabs(v-model="tab")
    //-   v-tab.elevation-2(
    //-     v-for="marker in item.markers"
    //-     :key="'marker_' + marker.id"
    //-     v-if="! marker.code.endsWith('_HIDDEN')"
    //-   ) {{marker.label}}

    //- v-tabs-items(v-model="tab")
    //-   v-tab-item(
    //-     v-for="marker in item.markers"
    //-     :key="'marker_detail_' + marker.id"
    //-     v-if="! marker.code.endsWith('_HIDDEN')"
    //-   )
    //-     v-card.elevation-2(flat)
    //-       v-card-text
    //-         ProductNFCValueModifier(
    //-           :readonly="readonly"
    //-           :item="filterValues(marker.code)[index]"
    //-           v-for="(valueModifier, index) in filterValues(marker.code)"
    //-           :key="marker.code + '_' + index"
    //-           @success="success2"
    //-           @error="error2"
    //-           :bus="bus"
    //-         )
    //ListProductNfcOperationsLines(:parent_id="this.$route.params.id"  :readonly="readonly")
</template>

<script>
  import Vue from 'vue';
import { Model } from "./imports.js";

  import CommonEdit from "../tool/CommonEdit.js";

  import Vat from "../../model/vat/Vat.js";
  const vat = new Vat()

  import ListProductNfcOperationsLines from "../productnfcoperation/ListProductNFCOperations.vue";
import ProductNFCValueModifier from "../productnfcvaluemodifier/ProductNFCValueModifier.vue";


  import { PRODUCT_TYPE_CARD_ACTIVATION, PRODUCT_TYPE_CARD_TIME, PRODUCT_TYPE_CARD_UNITS, PRODUCT_TYPE_RELOAD_OTHER, PRODUCT_TYPE_RELOAD_TIME, PRODUCT_TYPE_RELOAD_UNITS } from "../../model/product/Product.js";

  export default {
    extends: CommonEdit,
    components: {
      ProductNFCValueModifier, ListProductNfcOperationsLines
    },
    computed: {
      time_qty_water: {
        get() {
          console.log("get time_qty_water", this.item )
          return Math.round(this.item.qty_water / 60);
        },
        set(newValue) {
          this.item.qty_water = newValue * 60;
        },
      },
      time_qty_elec: {
        get() {
          return Math.round(this.item.qty_elec / 60);
        },
        set(newValue) {
          this.item.qty_elec = newValue * 60;
        },
      },
    },

    props: {
    },
    data: () => ({
      type_subs: [],
      product_types: [],
      vats: [],
      tab: null,
      bus: new Vue(),

      PRODUCT_TYPE_CARD_UNITS: PRODUCT_TYPE_CARD_UNITS,
      PRODUCT_TYPE_CARD_TIME: PRODUCT_TYPE_CARD_TIME,
      PRODUCT_TYPE_CARD_ACTIVATION: PRODUCT_TYPE_CARD_ACTIVATION,
      PRODUCT_TYPE_RELOAD_UNITS: PRODUCT_TYPE_RELOAD_UNITS,
      PRODUCT_TYPE_RELOAD_TIME: PRODUCT_TYPE_RELOAD_TIME,
      PRODUCT_TYPE_RELOAD_OTHER: PRODUCT_TYPE_RELOAD_OTHER,
    }),
    watch: {
      "item.is_subscribtion": function() {
        if (this.item.is_subscribtion) {
          this.item.is_card = true
        }
      }
    }, 

    methods: {
      additionalSave() {
        this.bus.$emit('save')
      },

      // TODO
      success2(/*msg*/) {
        //this.$emit('success', msg)
      },
      // TODO
      error2(msg) {
        this.$emit('error', msg)
      },
      // filterValues(marker) {
      //   if (! this.item || ! this.item.productnfcvaluemodifiers) {
      //     return []
      //   }
      //   let results = this.item.productnfcvaluemodifiers.filter((v) => v.marker_code == marker)

      //   return results
      // },
      priceRule: function(v) {
        let errorMsg =  this.$t('common.required')

        // console.log("v => ", v)
        if (v == undefined) {
          // console.log(1)
          return errorMsg
        }

        if (typeof(v) === "string") {
          // console.log(2)
          v = parseFloat(v)
        }

        if (isNaN(v)) {
          // console.log(3)
          return errorMsg
        }
        if (v < 0) {
          // console.log(4)
          return errorMsg
        }

        return true
      },

      loadModel() {
        this.model = new Model()
      },

      setup(){
        //this.readonly = this.$route.query.readonly
        //console.log("this.readonly", this.readonly)

        this.type_subs = this.model.getTypeSubs(this)
        this.product_types = this.model.getProductTypes(this)
        console.log("this.type_subs", this.type_subs)
        vat.listAll(
          (response) => {
            this.vats = response.data.results
          },
          (e) => {
            console.log(e)
          }
        )
      }
    },
  }

</script>