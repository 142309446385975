

  //import moment from 'moment'

  import ConfirmDelete from "./ConfirmDelete.vue"
  import draggable from "vuedraggable"

  export default {
    components: {
      ConfirmDelete, draggable
    },
    props: {
      parent_id: {
        type: String
        , default: null
      },
      readonly: {
        type: Boolean
        , default: false
      }
    },
    data: () => ({
      showEditDialog: false,
      disableCreate: false,
      hidesearch: false,
      actions: [],
      headers: [],
      columns: [],
      footers: [],
      filters: {},
      title: null,
      showHidden: false, // do the filter
      filterHidden:true, // show checkbox to perform the filter
      search: null,
      leave: false,
      pagination: {
        descending: true,
        page: 1,
        itemsPerPage: 200,
      },
      totalItems: 0,
      items: [],
      model : null
    }),
    computed: {
    },
    watch: {
      filters: {
        handler: function () {
          this.fetchData()
        }
        , deep: true
      },
      'pagination.page': function() {
        this.fetchData()
      },
      'pagination.itemsPerPage': function() {
        this.fetchData()
      },
/*
      'pagination.totalItems': function() {
        //console.log('totalItems', newVal, oldVal)
      },
      */
      
      'search': function() {
        this.fetchData()
      },
      'showHidden': function(/*newVal*/) {
        this.fetchData()
      },
    }, 

    methods: {
      setup () {
        console.log("js")
      },
      loadModel: function() {
        //must be done in sub component
        console.error("Must implements loadModel!!")
      },
      updateItems: function (data) {
        this.items = data.results
        this.totalItems = data.count
      },
      additionalFetch: function(response) {
        this.updateItems(response.data)
      },
      confirmDelete: function(item) {
        this.model.delete(
          item
          ,() => {
            this.$emit('success', 'common.deleted')
            this.fetchData()
          }
          , (e) => {
            console.log(e)
            this.$emit('error', 'common.deleted_error', e)
          }
        )
      },
      cancelDelete: function() {
        
      },
      getLabel: function(item, col) {
        let result = col.text 
        if (result != null) {
          return result
        }

        result = item
        if (col.value != null && col.value != ".") {
          var values = col.value.split(".")
          for (let value of values) {
            if (result != null) {
              result = result[value]
            } else {
              result = ""
            }
          }
        }

        if (col.format != null) {
          result = col.format(result)
        }
        return result
      },
      successEdit: function(msg) {
        this.showEditDialog = false
        this.success(msg)
      },
      errorEdit: function(msg, e) {
        this.showEditDialog = false
        this.error(msg, e)
      },
      success: function(msg) {
        this.fetchData()
        this.$emit('success', msg)
      },
      error: function(msg, e) {
        this.$emit('error', msg, e)
      },
      prepareEditDialog() {
        // console.log(this.$refs)
        // for (let comp in this.$refs) {
        //   console.log(comp)
        // }

        this.$refs.EditDialog.parent_readonly = this.parent_id != null
      },
      prepareValue(item) {
        let result = item
        if (this.parent_id != null) {
          result = {...item, ...{parent_id: this.parent_id}}
        }
        return result
      },
      createItem: function() {
        this.showEditDialog = true
        // trick because dialog will not render until next tick and $refs will remain empty :(
        this.nextTick(() => {
          // do something cool
          this.prepareEditDialog(this.$refs.EditDialog)
          this.$refs.EditDialog.create(this.prepareValue({}))
        })
      },
      editItem: function(item) {
        if (this.isViewAllowed()) {
          this.model.viewItem(item, false)
        } else {
          this.showEditDialog = true
          // trick because dialog will not render until next tick and $refs will remain empty :(
          this.nextTick(() => {
            this.prepareEditDialog(this.$refs.EditDialog)
            this.$refs.EditDialog.edit(this.prepareValue(item))
          })
        }
      },
      deleteItem: function(item) {
        this.$refs.ConfirmDelete.showDeleteDialog(item)
      },
      move: function (oldIndex, newIndex) {
        this.model.move(
          {parent_id: this.parent_id, idSrc: this.items[oldIndex].id, idDest: this.items[newIndex].id} 
          , (/*response*/) => {
            this.fetchData()
            this.$emit('success', 'common.updated')
          }
          , e => {
            console.log(e)
            this.$emit('error', 'common.updated_error', e)
          }) 
      },
      isEditAllowed: function(item) {
        return this.model.isEditAllowed(item)
      },
      isDeleteAllowed: function() {
        return this.model.isDeleteAllowed()
      },
      isCreateAllowed: function() {
        return this.model.isCreateAllowed()
      },
      isViewAllowed: function() {
        return this.model.isViewAllowed()
      },
      // formatCurrency: function (value) {
      //   return this.model.formatCurrency(value)
      // },
      // formatTemperature: function (value) {
      //   return parseFloat(value).toFixed(1) + "°C" 
      // },
      // formatDatetime: function (value) {
      //   return moment(String(value)).format(this.$t("common.datetime"))
      // },
      fetchData: function() {
        let params = {}
        params.filters = this.filters
        if (this.search != null) {
          params.search = this.search
        }
        if (this.showHidden) {
          params.showHidden = true
        }

        //console.log(params)

        const _self = this
        this.model.list(this.pagination.page, this.pagination.itemsPerPage
          , (response) => {
            _self.additionalFetch(response)
            //this.$emit('success', 'customer.edit.updated')
          }
          , e => {
            console.log(e)
            //this.$emit('error', 'customer.edit.updated_error', e)
          }
          , params
        )
      },
      viewItem: function(item) {
        this.model.viewItem(item )
      },
      dragEnd: function(e) {
        this.move(e.oldIndex, e.newIndex)
      },

      getTitle() {
        return this.model.content + '.title'
      }
    },

    created() {
      this.loadModel()
    },
 
    mounted() {
      if (this.parent_id != null) {
        this.filters.parent_id = this.parent_id
      }
      this.setup()
      
      this.title = this.getTitle()

      this.fetchData()
    }
  }
