import Model from "../Model.js"


class NfcValueModifier extends Model {



    constructor() {
        super("nfcvaluemodifier", "api", ["flags_value", "flags_mask"])
    }

    defaultValues() {
        return {
            id: 0,
            pos: 1,
            nfcinfo_id: null,
            nfcinfo: null,
            type: null,
            flags_value: [],
            flags_mask: [],
            int_value: 0,
        }
    }

    // async listDetail() {
    //     let values = (await this.listAllP()).data.results

    //     for (let value of values) {
    //         let label = value.label

    //         // mode temps/post paiement
    //         // localisation

    //         // HIDDEN
    //         if ("Actualiser date de fin de Validité", ["Mode Compteur", "Paiement Elec", "Paiement Eau"].includes(label)) {
    //             value.tags = ["HIDDEN_PRODUCT"]
    //         } 
    //         // ENERGY REFUND
    //         if (["Ajout Crédit Elec", "Ajout Crédit Eau"].includes(label)) {
    //             value.tags = ["ENERGY_PRODUCT", NfcValueModifier.PRODUCT_REFUND]
    //         } 

    //         // ACCESS
    //         if (["Accès premier Ponton badgé"].includes(label)) {
    //             value.tags = ["ACCESS_PRODUCT"]
    //         } 

    //         // PARKING
    //         if (["Accès parking 1", "Nb Jours fin de validité parking"].includes(label)) {
    //             value.tags = ["PARKING_PRODUCT"]
    //         } 

    //         // SHOWER
    //         if (["Accès sanitaire 1", "Crédit Douche"].includes(label)) {
    //             value.tags = ["SHOWER_PRODUCT"]
    //         } 
    //     }

    //     return values
    // }

    filterValues(values, section) {
        let result = []

        for (let value of values) {
            if (value.tags && value.tags.includes(section)) {
                result.push(value)
            }
        }

        return result
    }

    getTypes(_self) {
        let types = ["SET", "ADD", "FLAG", "INPUT", "CONSUMP", "HOUR", "DAY", "MONTH", "YEAR", "VALIDITY", "CUSTOMER_FIELD_SELECT"]

        let result = []

        types.forEach(element => {
            result.push({value: element, label: _self.$t(this.content + '.' + 'type_' + element)})
        });

        return result

    }
}

//NfcValueModifier.PRODUCT_REFUND = "PRODUCT_REFUND"

export default NfcValueModifier