var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "max-height": "250px",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-container', {
          staticClass: "ma-0 pa-0"
        }, [_c('v-row', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "dense": ""
          }
        }, [_c('v-col', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "cols": "11"
          }
        }, [_vm.value == null ? _c('v-text-field', {
          attrs: {
            "label": _vm.value == null ? _vm.$t('customer.search') : 'Client sélectionné',
            "readonly": _vm.readonly,
            "disabled": _vm.readonly,
            "error-messages": _vm.value == null ? 'Choisir un client' : null
          },
          on: {
            "click": function click($event) {
              _vm.showSearchResults = true;
            }
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }) : _vm._e(), _vm.value != null ? _c('v-tooltip', {
          attrs: {
            "left": "",
            "open-delay": "1200"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('div', [_c('div', [_vm._v(_vm._s(_vm.$t('customer.title')))]), _c('div', _vm._g({
                staticStyle: {
                  "color": "rgba(0, 0, 0, 0.87)"
                }
              }, on), [_vm._v(_vm._s(_vm.formatCustomer(_vm.value)))])])];
            }
          }], null, true)
        }, [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.formatCustomerTooltip(_vm.value))
          }
        })]) : _vm._e()], 1), _c('v-col', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "cols": "1"
          }
        }, [!_vm.readonly && _vm.value == null ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": _vm.createCustomer
          }
        }, [_c('v-icon', {
          attrs: {
            "medium": ""
          }
        }, [_vm._v("mdi-plus")])], 1) : _vm._e(), !_vm.readonly && _vm.value != null ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "error"
          },
          on: {
            "click": _vm.clearCustomer
          }
        }, [_c('v-icon', {
          attrs: {
            "medium": ""
          }
        }, [_vm._v("mdi-close")])], 1) : _vm._e()], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.showSearchResults,
      callback: function callback($$v) {
        _vm.showSearchResults = $$v;
      },
      expression: "showSearchResults"
    }
  }, [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "items-per-page": 25,
      "loading": _vm.isLoading,
      "hide-default-header": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref3) {
        var items = _ref3.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            on: {
              "click": function click($event) {
                return _vm.select(item);
              }
            }
          }, _vm._l(_vm.columns, function (col) {
            return _c('td', {
              class: col.class
            }, [_vm._v(_vm._s(_vm.getLabel(item, col))), _vm._l(item[col.subvalue], function (detail) {
              return col.subvalue != null ? _c('div', {
                staticClass: "linedetail"
              }, [_vm._v(_vm._s(detail))]) : _vm._e();
            })], 2);
          }), 0);
        }), 0)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-alert', {
          attrs: {
            "value": true,
            "color": "info",
            "icon": "warning"
          }
        }, [_vm._v(_vm._s(_vm.$t("common.no_data")))])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "75vw"
    },
    model: {
      value: _vm.showCreate,
      callback: function callback($$v) {
        _vm.showCreate = $$v;
      },
      expression: "showCreate"
    }
  }, [_c('CreateCustomerDialog', {
    ref: "CreateCustomerDialog",
    attrs: {
      "showClose": true
    },
    on: {
      "success": _vm.successCreateCustomer,
      "error": _vm.errorCreateCustomer
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }