var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.parent_readonly && _vm.item.parent != null ? _c('v-text-field', {
    staticClass: "no-border",
    attrs: {
      "readonly": true,
      "label": _vm.$t(this.model.content + '.' + 'parent')
    },
    model: {
      value: _vm.item.parent.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item.parent, "label", $$v);
      },
      expression: "item.parent.label"
    }
  }) : _vm._e(), !_vm.parent_readonly ? _c('v-select', {
    attrs: {
      "items": _vm.requests,
      "item-text": "label'",
      "item-value": "id",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'parent'),
      "readonly": _vm.readonly
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(_vm._s(item.parent.label + ' : ' + item.label))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(_vm._s(item.parent.label + ' : ' + item.label))])];
      }
    }], null, false, 3042331292),
    model: {
      value: _vm.item.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "parent_id", $$v);
      },
      expression: "item.parent_id"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.types,
      "item-text": "label'",
      "item-value": "value",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'type'),
      "readonly": _vm.readonly
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }]),
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "clearable": !_vm.readonly,
      "items": _vm.card_fields,
      "item-value": "id",
      "label": _vm.$t(this.model.content + '.' + 'card_uid_field'),
      "readonly": _vm.readonly
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }]),
    model: {
      value: _vm.item.card_uid_field_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "card_uid_field_id", $$v);
      },
      expression: "item.card_uid_field_id"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "clearable": !_vm.readonly,
      "items": _vm.nfcinfos,
      "item-value": "id",
      "label": _vm.$t(this.model.content + '.' + 'nfcinfo'),
      "readonly": _vm.readonly
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }]),
    model: {
      value: _vm.item.nfcinfo_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "nfcinfo_id", $$v);
      },
      expression: "item.nfcinfo_id"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'code')
    },
    model: {
      value: _vm.item.code,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "max": "255",
      "label": _vm.$t(this.model.content + '.' + 'byte')
    },
    model: {
      value: _vm.item.byte,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "byte", $$v);
      },
      expression: "item.byte"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "1",
      "max": "32",
      "label": _vm.$t(this.model.content + '.' + 'size')
    },
    model: {
      value: _vm.item.size,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "size", $$v);
      },
      expression: "item.size"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.item.type == 'INT_VALUE' ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "type": "number",
      "min": "0",
      "label": _vm.$t(this.model.content + '.' + 'offset')
    },
    model: {
      value: _vm.item.offset,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "offset", $$v);
      },
      expression: "item.offset"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.item.type == 'INT_VALUE' ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "type": "number",
      "min": "0",
      "label": _vm.$t(this.model.content + '.' + 'ratio')
    },
    model: {
      value: _vm.item.ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "ratio", $$v);
      },
      expression: "item.ratio"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.item.type == 'INT_VALUE' ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "type": "number",
      "min": "0",
      "max": "8",
      "label": _vm.$t(this.model.content + '.' + 'round')
    },
    model: {
      value: _vm.item.round,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "round", $$v);
      },
      expression: "item.round"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'signed')
    },
    model: {
      value: _vm.item.signed,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "signed", $$v);
      },
      expression: "item.signed"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'bigendian')
    },
    model: {
      value: _vm.item.bigendian,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "bigendian", $$v);
      },
      expression: "item.bigendian"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.item.type == 'INT_VALUE' ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 10,
      "label": _vm.$t(this.model.content + '.' + 'prefix')
    },
    model: {
      value: _vm.item.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "prefix", $$v);
      },
      expression: "item.prefix"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_vm.item.type == 'INT_VALUE' ? _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 10,
      "label": _vm.$t(this.model.content + '.' + 'suffix')
    },
    model: {
      value: _vm.item.suffix,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "suffix", $$v);
      },
      expression: "item.suffix"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }