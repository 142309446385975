<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./MarkerPart.pug

</template>

<script>
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"


  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      types: [],
    }),
    watch: {
    }, 

    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        //this.types = this.model.getTypes(this)
      }
    },
  }

</script>
