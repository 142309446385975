














































import { DataTableHeader } from 'vuetify'

import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import TransactionService, {Transaction, TransactionFilter} from '@/components/model/transaction/TransactionService'

const transactionService:  TransactionService = new TransactionService()


@Component
export default class extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: {}
  })
  filter!: TransactionFilter

  
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  showEnd!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  showCustomer!: boolean

  transactions: Transaction[] = [] 

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 10,
  }
  totalItems = 0


  @Watch("pagination", { deep: true })
  paginationChanged() {
    this.fetchData()
  }

  @Watch("filter", { deep: true })
  filterChanged() {
    this.fetchData()
  }


  get headers(): DataTableHeader[] {
    return this.allHeaders.filter(
      h => {
        if (h.value == 'end') {
          return this.showEnd
        }
        if (h.value == 'customer') {
          return this.showCustomer
        }
        return true
      }
    )
  }

  async fetchData(): Promise<void> {
    try {
      const data = await transactionService.listP(this.pagination.page, this.pagination.itemsPerPage, this.filter)
      this.transactions = data.results
      console.log(this.transactions)
      this.totalItems = data.count
    } catch (error) {
      this.onError("Impossible de charger les consommations", error as Error)
    }
  }

  mounted(): void {
    this.fetchData()
  }

  allHeaders: DataTableHeader[] = [
    {align: "start", text: 'Client', value: 'customer' },
    {align: "start", text: 'Début', value: 'begin' },
    {align: "start", text: 'Fin', value: 'end' },
    // {align: "start", text: 'Type', value: 'consumptionType' },
    // {align: "start", text: 'Statut', value: 'detailStatus' },
    {align: "start", text: 'Borne', value: 'plcLabel' },
    {align: "start", text: 'Type', value: 'plugType' },
    {align: "start", text: '#', value: 'plugLabel' },
    {align: "start", text: 'Consommation', value: 'qty' },

    // {align: "start", text: 'Montant H.T.', value: 'amount' },
  ]

  priceUnitWater: number = 0.004
  priceUnitElec: number = 0.2


}

