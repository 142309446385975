import Model from "../Model.js";

import rest from '../../hmi/tool/rest.js';

export const BASKET_TYPE_CARD_UNITS = "CARD_UNITS"
export const BASKET_TYPE_CARD_TIME = "CARD_TIME"
export const BASKET_TYPE_CARD_ACTIVATION = "CARD_ACTIVATION"
export const BASKET_TYPE_RELOAD_UNITS = "RELOAD_UNITS"
export const BASKET_TYPE_RELOAD_TIME = "RELOAD_TIME"
export const BASKET_TYPE_RELOAD_ACTIVATION = "RELOAD_ACTIVATION"

export default class Basket extends Model {
    constructor() {
        super("basket")
      }

    defaultValues() {
        return {
            id: 0,
            customer_id: null,
            payment_method: "CARD",
        }
    }

    async setTagProduct(id, tagProduct) {
        const data = this.serialize(tagProduct)
        return new Promise((resolve, reject) => {
            rest.post(
                this.api_url + id + "/set_tag_product/"
                , data
                , response => {
                    resolve(response)
                }
                , (e) => {
                    reject(e)
                }
            )
        })
    }

    getPaymentMethods(_self) {
        return [
            {value: "CARD", label:_self.$t(this.content + '.payment_method_' + 'CARD')}, 
            {value: "CHECK", label:_self.$t(this.content + '.payment_method_' + 'CHECK')},
            {value: "CASH", label:_self.$t(this.content + '.payment_method_' + 'CASH')},
        ]
    }

    translatePaymentMethod(_self, payement) {
        return _self.$t(this.content + '.payment_method_' + payement)
    }

    showPdf(_self, data) {
        let fileName = _self.$t("basket.report_" + data.state)  + "_" + data.number + ".pdf"
        //console.log(fileName)
        this.preparePdf(data, fileName)
    }

}


