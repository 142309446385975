export default {
  components: {
  }
  , props: {
    basket: null,
  }
  , computed: {
  }
  , watch: {
  }
  , data: () => ({
    vat_headers: [
      {align: "end", width: "2", text: 'vat.code', value: 'label' },
      {align: "end", width: "2", text: 'vat.basis', value: 'basis' },
      {align: "end", width: "2", text: 'vat.rate', value: 'rate' },
      {align: "end", width: "2", text: 'vat.amount', value: 'amount_diff' },
    ]
  })
  , methods: {

  }

  , mounted() {
  }
}
