import { SelectValue } from '../energybox/Metrics'
import TransactionModel from './Transaction'

export interface TransactionFilter {
    customer?: any 
    customer_id: number | null
    isCurrent: boolean
    isHistory: boolean
    isWater: boolean
    isElec: boolean
    isNoQty: boolean
    begin: string | null
    end: string | null
}

export enum TransactionStatus {
    DELIVERING = "DELIVERING"
    , CLOSING = "CLOSING"
    , CLOSED = "CLOSED"
}

export const labelsTransactionStatus: SelectValue[] = [
    {id: TransactionStatus.DELIVERING, label: "En cours"}, 
    {id: TransactionStatus.CLOSING, label: "Terminé (Attente de confirmation)"}, 
    {id: TransactionStatus.CLOSED, label: "Terminé"}, 
]

export interface Transaction {
    id:            number;
    created_date:  string;
    modified_date: string;
    begin:         string;
    end:           string | null;
    status:        TransactionStatus;
    
    prev_qty:       number;
    current_qty:    number;
    query_qty:      number;
    remaining_qty:  number,
    
    pos:            number;
    plc_id:         number;
    plc_label:      string;
    plug_label:     string;
    plug_type:      string;

    customer_is_company:       boolean;
    customer_first_name:       string;
    customer_last_name:        string;
    customer_company_name:     string;
}

export default class TransactionService extends TransactionModel {
    constructor() {
        super()
    }

    listP(page: number, page_size: number, params: TransactionFilter): Promise<{results: Transaction[], count: number}> {
        return new Promise((resolve, reject) => {
            super.listP(page, page_size, params)
            .then((result) => {
                resolve(result.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    listNoLimitP(params: TransactionFilter): Promise<Transaction[]> {
        return new Promise((resolve, reject) => {
            super.listP(0, 0, params)
            .then((result) => {
                resolve(result.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}


