import Model from "../Model.js"


export default class Gateway extends Model {
    constructor() {
        super("gateway")
    }

    defaultValues() {
        return {
            id: 0,
        }
    }
}


