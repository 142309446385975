import Model from "../Model.js"


export default class PLCRequest extends Model {
    constructor() {
        super("plcrequest")
    }

    defaultValues() {
        return {
            id: 0,
            parent: null,
        }
    }
}


