var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "caption mr-2"
  }, [_vm.filter.customer_id || _vm.filter.begin || _vm.filter.end || !_vm.filter.isWater || !_vm.filter.isElec || !_vm.filter.isCurrent || !_vm.filter.isHistory || !_vm.filter.isNoQty ? _c('span', [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("Filtres:")]), _vm.filter.customer_id ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_c('div', [_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Société: " + _vm._s(_vm.filter.customer.company_name))])]) : _vm._e(), !_vm.filter.customer.is_company ? _c('div', [_c('span', [_vm._v("Mme/M: " + _vm._s(_vm.filter.customer.first_name) + " " + _vm._s(_vm.filter.customer.last_name))])]) : _vm._e()])]) : _vm._e(), _vm.filter.begin ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Début: " + _vm._s(_vm.formatDate(_vm.filter.begin)))]) : _vm._e(), _vm.filter.end ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Fin: " + _vm._s(_vm.formatDate(_vm.filter.end)))]) : _vm._e(), !_vm.filter.isWater ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Eau: Non")]) : _vm._e(), !_vm.filter.isElec ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Electricité: Non")]) : _vm._e(), !_vm.filter.isCurrent ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("En cours: Non")]) : _vm._e(), !_vm.filter.isHistory ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Terminé: Non")]) : _vm._e(), !_vm.filter.isNoQty ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": _vm.color
    }
  }, [_vm._v("Qté 0: Non")]) : _vm._e()], 1) : _c('span', [_vm._v(" Aucun filtre")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }