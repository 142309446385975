import {Metrics, PLC } from "@/components/model/energybox/Metrics"
import {getLabel, typesElec, typesWater } from '@/components/model/energybox/Metrics'
import {Customer} from '@/components/model/customer/CustomerTS'

export function isOlderThan(lastUpdateMs: number, date: string | null, ms: number): boolean | null {
    if (date === null) {
        return null
    }

    return (lastUpdateMs - new Date(date).getTime()) >= ms

}

export function getNbElecAvailable(plc: PLC | null, type: number | null): number {
    return getNbAvailable(plc, 'ELEC', type)
}

export function getNbWaterAvailable(plc: PLC | null, type: number | null): number {
    return getNbAvailable(plc, 'WATER', type)
}

function getNbAvailable(plc: PLC | null, energyType: string, type: number | null): number {
    if (! plc) {
        return 0
    }

    let res: number = 0
    for (const plug of plc.plugs) {
        if (
            plug.status == "AVAILABLE"
            && plug.mode == 0
            && (type == null || plug.kind == type)
            && plug.type == energyType
        ) {
            res += 1
        }
    }

    return res
}


export function getElecTypeLabel(typeElec: number): string | undefined {
    return getLabel(typeElec, typesElec)
}

export function getWaterTypeLabel(typeWater: number): string | undefined {
    return getLabel(typeWater, typesWater)
}

const TIMEOUT_ERROR = 1000 * 60 * 2;
const TIMEOUT_WARNING = 1000 * 60 * 1;

export function getBoxColor(lastUpdateMs: number, box: PLC | null): string {
    if (! box) {
        return "secondary"
    }
    if (! box?.last_ok) {
        return "secondary"
    }
    if (isOlderThan(lastUpdateMs, box?.last_ok, TIMEOUT_ERROR)) {
        return "error"
    }
    if (isOlderThan(lastUpdateMs, box?.last_ok, TIMEOUT_WARNING)) {
        return "warning"
    }

    return "success"
}

export function getStatusColor(lastUpdateMs: number, lastOk: string): string {
    if (isOlderThan(lastUpdateMs, lastOk, TIMEOUT_ERROR)) {
        return "error"
    }
    if (isOlderThan(lastUpdateMs, lastOk, TIMEOUT_WARNING)) {
        return "warning"
    }

    return "success"
}


export function getCustomerShortdesc(customer: Customer | null, mail: boolean = false): string | null {
    if (! customer) {
        return null
    }

    let res: string = ""
    if (customer.is_company) {
        res += customer.company_name ?? '?'
    } else {
        res += customer.first_name ?? '?'
        res += " "
        res += customer.last_name ?? '?'
    }

    if (mail) {
        res += " | "
        res += customer.email ?? "?"
    }

    return res
}
