var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-1"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [[1, 2].includes(_vm.nfcState.mode) ? _c('div', {
    staticClass: "text-h4"
  }, [_vm._v("Badge avec crédits ")]) : _vm._e(), [3].includes(_vm.nfcState.mode) ? _c('div', {
    staticClass: "text-h4"
  }, [_vm._v("Badge d'activation ")]) : _vm._e(), [0].includes(_vm.nfcState.mode) ? _c('div', {
    staticClass: "text-h4"
  }, [_vm._v("Badge vierge")]) : _vm._e()]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('span', [_vm._v("Client:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.getCustomerShortdesc(_vm.readedCustomer)))])]), _c('v-card-text', [_c('v-tabs', {
    attrs: {
      "grow": "",
      "height": "32"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [[1, 2].includes(_vm.nfcState.mode) ? _c('v-tab', [_vm._v("Crédits")]) : _vm._e(), _c('v-tab', [_vm._v("Sanitaires")]), _c('v-tab', [_vm._v("Parkings")]), _c('v-tab', [_vm._v("Accès")]), _c('v-tab', [_vm._v("Badge")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [[1, 2].includes(_vm.nfcState.mode) ? _c('v-tab-item', [_c('v-container', {
    attrs: {
      "dense": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "readonly": true,
      "label": "Mode",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": [{
        'value': 1,
        'label': 'Compteur'
      }, {
        'value': 2,
        'label': 'Temps'
      }],
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.nfcState.mode,
      callback: function callback($$v) {
        _vm.$set(_vm.nfcState, "mode", $$v);
      },
      expression: "nfcState.mode"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_vm.nfcState.mode == 2 ? _c('span', [_c('span', [_vm._v("Temps Eau:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.formatDuration(_vm.nfcState.nfcWater)))])]) : _c('span', [_c('span', [_vm._v("Unités Eau:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.nfcState.nfcWater) + " L")])]), _vm.nfcState.isWaterOnBox ? _c('div', [_c('div', {
    staticClass: "error--text"
  }, [_vm._v("Réservé sur borne N°" + _vm._s(_vm.nfcState.box) + ": qté <= " + _vm._s(_vm.nfcState.boxWater) + "L")])]) : _vm._e()]), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Type de robinet",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.waterPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.nfcState.typeWater,
      callback: function callback($$v) {
        _vm.$set(_vm.nfcState, "typeWater", $$v);
      },
      expression: "nfcState.typeWater"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_vm.nfcState.mode == 2 ? _c('span', [_c('span', [_vm._v("Temps Elec:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.formatDuration(_vm.nfcState.nfcElec)))])]) : _c('span', [_c('span', [_vm._v("Unité Elec:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.nfcState.nfcElec) + " kw/h")])]), _vm.nfcState.isWaterOnBox ? _c('div', [_c('div', {
    staticClass: "error--text"
  }, [_vm._v("Réservé sur borne N°" + _vm._s(_vm.nfcState.box) + ": qté <= " + _vm._s(_vm.nfcState.boxElec) + "kw/h")])]) : _vm._e()]), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Type de prise",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.elecPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.nfcState.typeElec,
      callback: function callback($$v) {
        _vm.$set(_vm.nfcState, "typeElec", $$v);
      },
      expression: "nfcState.typeElec"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-tab-item', [_c('v-container', {
    attrs: {
      "dense": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('span', [_vm._v("Crédit douches:")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.nfcState.nbShowers) + " Unités")])])], 1), _vm._l(2, function (_, line) {
    return _c('v-row', {
      key: line,
      attrs: {
        "dense": ""
      }
    }, _vm._l(_vm.nfcState.showerAccess, function (item, index) {
      return index >= line * 10 && index < (line + 1) * 10 ? _c('v-col', {
        key: index
      }, [_c('v-checkbox', {
        attrs: {
          "dense": "",
          "label": (index + 1).toString()
        },
        model: {
          value: item.value,
          callback: function callback($$v) {
            _vm.$set(item, "value", $$v);
          },
          expression: "item.value"
        }
      })], 1) : _vm._e();
    }), 1);
  })], 2)], 1), _c('v-tab-item', [_c('v-container', {
    attrs: {
      "dense": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('span', [_vm._v("Date fin parking: ")]), _c('strong', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.nfcState.parkingEnd * 1000)))])])], 1), _vm._l(2, function (_, line) {
    return _c('v-row', {
      key: line,
      attrs: {
        "dense": ""
      }
    }, _vm._l(_vm.nfcState.parkingAccess, function (item, index) {
      return index >= line * 10 && index < (line + 1) * 10 ? _c('v-col', {
        key: index
      }, [_c('v-checkbox', {
        attrs: {
          "dense": "",
          "label": (index + 1).toString()
        },
        model: {
          value: item.value,
          callback: function callback($$v) {
            _vm.$set(item, "value", $$v);
          },
          expression: "item.value"
        }
      })], 1) : _vm._e();
    }), 1);
  })], 2)], 1), _c('v-tab-item', [_c('v-container', {
    attrs: {
      "dense": "",
      "fluid": ""
    }
  }, _vm._l(7, function (_, line) {
    return _c('v-row', {
      key: line,
      attrs: {
        "dense": ""
      }
    }, _vm._l(_vm.nfcState.access, function (item, index) {
      return index >= line * 10 && index < (line + 1) * 10 ? _c('v-col', {
        key: index
      }, [_c('v-checkbox', {
        attrs: {
          "dense": "",
          "label": index == 0 ? '*' : index.toString()
        },
        model: {
          value: item.value,
          callback: function callback($$v) {
            _vm.$set(item, "value", $$v);
          },
          expression: "item.value"
        }
      })], 1) : _vm._e();
    }), 1);
  }), 1)], 1), _c('v-tab-item', [_c('v-container', {
    attrs: {
      "dense": "",
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "col-3"
  }, [_c('div', [_vm._v("N° Badge NFC:")])]), _c('v-col', _vm._l(_vm.nfcState.id, function (e, index) {
    return _c('strong', {
      key: index
    }, [index > 0 ? _c('span', [_vm._v("-")]) : _vm._e(), _c('span', [_vm._v(_vm._s(('0' + (e & 0xFF).toString(16)).slice(-2)))])]);
  }), 0)], 1), _c('v-row', [_c('v-col', {
    staticClass: "col-3"
  }, [_c('div', [_vm._v("Date fin validité: ")])]), _c('v-col', [_c('div', {
    class: new Date().getTime() < _vm.nfcState.dateValidityInS * 1000 ? 'success--text' : 'error--text'
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.nfcState.dateValidityInS * 1000)))])])], 1)], 1)], 1)], 1)], 1), _c('v-card-actions')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }