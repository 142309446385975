<template lang="pug">
div(v-if="isRead")
  // CONSUMPTION
  div(v-if="item.type == 'CONSUMP'")
    v-layout()
      v-flex(
        xs12
        md12
      )
        v-text-field.mr-4(
          :prefix="item.unit" 
          :label="item.label" 
          v-model="item.int_value"
          
          :readonly="readonly"
        )

  // ADD
  div(v-if="item.type == 'ADD'")
    v-layout()
      // NOT valued
      v-flex(
        xs12
        md12
      )
        v-text-field.mr-4(
          :label="item.label + ' (' + item.unit + ')'" 
          v-model="item.int_value"
          
          :readonly="readonly"
        )
  //DAY
  div(v-if="item.type == 'DAY'")
    v-layout()
      v-flex(
        xs12
        md12
      )
        v-text-field.mr-4(
          :label="item.label + ' (' + $t('nfcvaluemodifier.type_DAY') + ')'" 
          v-model="item.int_value"
          
          :readonly="readonly"
        )
  // FLAG
  div(v-if="item.type == 'FLAG'")
    v-layout()
      v-flex(
        xs12
        md12
      )
        h2 {{item.label}}
    v-layout(v-for="(line, l) in getFlagNbLines()" :key="item.id + '_' + l")
      v-flex(
        xs1
        md1
        v-for="(flag, f) in getNbFlagsInLine(l)"
        :key="item.id + '_' + (itemsPerLine * l + f)"
      )
        v-checkbox(
          :label="getFlagLabel(itemsPerLine * l + f)" 
          v-model="flags[itemsPerLine * l + f]"
          :readonly="readonly"
        )
  // CUSTOMER_FIELD_SELECT
  div(v-if="item.type == 'CUSTOMER_FIELD_SELECT'")
    v-layout()
      v-flex(
        xs12
        md12
      )
        v-select(
          :readonly="readonly"
          :label="item.label"

          v-model="int_value"
          :items="labels"
          item-value="value"
          item-text="label"
        )
</template>

<script>

  // import Model from "../../model/productnfcoperation/Productnfcoperation.js"
  // let model = new Model()

  export default {
    components: {

    },
    props: {
      readonly: Boolean,
      memory: Object,
      item: Object,
    },
    computed: {
      isRead() {
        return this.memory.data.isRead
      } 
    },
    watch: {
      isRead: {
        handler: function() {
          //console.log(this.memory.data.isRead)
          if (this.isRead) {
            //console.log("isRead")
            this.init()
          }
        },
        deep: false
      },

      int_value: function(newValue) {
        this.memory.data.blocks[this.item.block].values[this.item.byte] = newValue
      }
    }, 
    data: () => ({
      flags: [],
      itemsPerLine: 10,
      int_value: 0,
      labels: [],
    }),

    methods: {
      init() {
        //this.item.int_value = 1
        if (this.item.type == "CUSTOMER_FIELD_SELECT") {
          this.int_value = this.memory.data.blocks[this.item.block].values[this.item.byte]
          this.labels = this.item.labels

          // .int_value_ = 1
          // this.item.labels = [{value:1, label:"test_1"}]
          // this.item.int_value = 
          
          // console.log(this.item.int_value, this.item.labels, )
        } else if (this.item.type == "FLAG") {
          this.flags = this.getFlags()
          // console.log(`%c item flag`, 'color: blue;', this.item, this.flags)
          // console.table(this.flags)
          //console.log("flags", this.flags)
        }
      },

      getFlags() {
        //console.log(this.memory.data.blocks[this.item.block].values[this.item.byte])
        //console.log(this.memory.data)
        //console.log(`%c item flag`, 'color: blue;', this.item, this.memory.data.blocks[this.item.block].values[this.item.byte])

        return this.memory.data.blocks[this.item.block].values[this.item.byte]
      },

      getFlagNbLines() {
        if (! this.flags) {
          return 0
        }
        let result = Math.ceil(this.flags.length / this.itemsPerLine) 

        //console.log("getFlagNbLines", result)
        return result
      },

      getNbFlagsInLine(l) {
        if (! this.flags) {
          return 0
        }
        let remaining = this.flags.length - this.itemsPerLine * l
        
        let result = Math.min(this.itemsPerLine, remaining)
        //console.log("getNbFlagsInLine", result)
        return result
      },

      getFlagLabel(pos) {
        for (let label of this.item.labels) {
          if (label.value == pos) {
            return label.label
          }
        }

        return '' +  (pos + 1)
      },
    },

    created () {

    },

    mounted () {
      if (this.isRead) {
        //console.log("isRead")
        this.init()
      }
    },
  }

</script>
