<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
      v-layout
        v-flex(
          xs12
          md12
        )
          v-select(
            required
            v-model="item.nfcvaluemodifier_id"
            :items="nfcvaluemodifiers"
            item-value="id"
            item-text="label"
            :rules="[(v) => !!v || $t('common.required')]"
            :label="$t(this.model.content + '.' + 'nfcvaluemodifier')"
          )

</template>

<script>
  import {Model} from "./imports.js"
  import CommonEdit from "../tool/CommonEdit.js"

  import NFCValueModifier from "../../model/nfcvaluemodifier/NFCValueModifier.js"
  const nfcValueModifier = new NFCValueModifier()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      nfcvaluemodifiers: []
    }),
    watch: {
    }, 
    
    methods: {
      prepareItemToCreate() {
      },

      loadModel: function() {
        this.model = new Model()
      },

      setup: async function(){
        this.nfcvaluemodifiers = (await nfcValueModifier.listAllP()).data.results
        console.log(this.nfcvaluemodifiers)
      }
    },
  }


</script>
