var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "readonly": _vm.readonly,
      "row": ""
    },
    model: {
      value: _vm.item.is_company,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_company", $$v);
      },
      expression: "item.is_company"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'person'),
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t(this.model.content + '.' + 'company'),
      "value": true
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Appli smartphone",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.item.is_mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_mobile", $$v);
      },
      expression: "item.is_mobile"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 240,
      "label": _vm.$t(this.model.content + '.' + 'ship_name')
    },
    model: {
      value: _vm.item.ship_name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "ship_name", $$v);
      },
      expression: "item.ship_name"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'plug_elec'),
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.elecPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.item.plug_elec,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "plug_elec", $$v);
      },
      expression: "item.plug_elec"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'plug_water'),
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "items": _vm.waterPlugs,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.item.plug_water,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "plug_water", $$v);
      },
      expression: "item.plug_water"
    }
  })], 1)], 1), _vm.item.is_company ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 80,
      "label": _vm.$t(this.model.content + '.' + 'company_name')
    },
    model: {
      value: _vm.item.company_name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "company_name", $$v);
      },
      expression: "item.company_name"
    }
  })], 1)], 1) : _vm._e(), !_vm.item.is_company ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 80,
      "label": _vm.$t(this.model.content + '.' + 'first_name')
    },
    model: {
      value: _vm.item.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "first_name", $$v);
      },
      expression: "item.first_name"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 80,
      "label": _vm.$t(this.model.content + '.' + 'last_name')
    },
    model: {
      value: _vm.item.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "last_name", $$v);
      },
      expression: "item.last_name"
    }
  })], 1)], 1) : _vm._e(), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 120,
      "rules": [_vm.emailRule],
      "label": _vm.$t(this.model.content + '.' + 'email'),
      "required": ""
    },
    model: {
      value: _vm.item.email,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "email", $$v);
      },
      expression: "item.email"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 20,
      "label": _vm.$t(this.model.content + '.' + 'phone')
    },
    model: {
      value: _vm.item.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "phone", $$v);
      },
      expression: "item.phone"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'country')
    },
    model: {
      value: _vm.item.country,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "country", $$v);
      },
      expression: "item.country"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 20,
      "label": _vm.$t(this.model.content + '.' + 'zip')
    },
    model: {
      value: _vm.item.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "zip", $$v);
      },
      expression: "item.zip"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'city')
    },
    model: {
      value: _vm.item.city,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "city", $$v);
      },
      expression: "item.city"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'address'),
      "filled": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.item.address,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "address", $$v);
      },
      expression: "item.address"
    }
  })], 1)], 1)], 1)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }