import Model from "../Model.js"


export default class BasketLine extends Model {
    constructor() {
        super("basketline")
      }


    defaultValue() {
        return {
            id: 0,
            qty: 1,
            product: {id: null, label: null},
            formule: {id: null, label: null},
        }
    }


}


