<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    div
      v-layout
          v-flex(
              xs12
              md12
          )
            v-text-field(
                :readonly="readonly"
                required
                v-model="item.qty"
                type="number"
                min="1"
                :rules="[(v) => !!v || $t('common.required')]"
                :label="$t(this.model.content + '.' + 'qty')"
                :prefix="item.product == null ? '?' : item.product.unit"
            ) 
      v-layout
          v-flex(
              xs12
              md12
          )
            v-select(
                :items="products"
                item-text="label"
                item-value="id"
                v-model="item.product_id"

                :rules="[(v) => !!v || $t('common.required')]"
                :label="$t(this.model.content + '.' + 'product')"
                :readonly="readonly"
            )


</template>

<script>
    import {Model} from "./imports.js"
    import CommonEdit from "../tool/CommonEdit.js"
    import ProductModel from "../../model/product/Product.js"
    const productModel = new ProductModel()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      products: [],
    }),
    watch: {
      "item.product_id": function(newValue) {
        //console.log("item.product.id", newValue)
        for (let product of this.products) {
          if (product.id === newValue) {
            this.item.product = product
            //console.log(this.item.product)
          }
        }
      }
    }, 
    
    methods: {
      loadModel: function() {
          this.model = new Model()
      },


      loadProducts: function(/*product*/) {
        //console.log(product)
        this.isLoading = true
        productModel.listAll(
          (response) => {
            //console.log(response.data.results)
            this.products = response.data.results
            this.isLoading = false
            //this.pagination.totalItems = response.data.count
            //console.log("count", response.data.count)
            //this.$emit('success', 'customer.edit.updated')
          }
          , e => {
            console.log(e)
            this.isLoading = false
            //this.$emit('error', 'customer.edit.updated_error', e)
          }
          /*, {search: product}*/
        )
      },

      setup: function(){
        this.loadProducts()
      }
    },
  }

</script>
