import Model from "../Model.js"


export default class Vat extends Model {
    constructor() {
        super("vat")
      }

    defaultValues () {
        return {
            id : 0,
            label : null,
            rate : 0.0,
        }
    }
}


