





































































































































































import MapFilter, { MapFilterModel } from '@/components/hmi/map/MapFilter.vue';
import { Customer } from '@/components/model/customer/CustomerTS.js';
import { getCustomerShortdesc } from '@/shared/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomerModel from "../../model/customer/Customer.js";
import nfcCardService, { NfcCardModel, WriteError } from './NFCCard';


const customerModel = new CustomerModel()


// import DialogSelectDate from "./DialogSelectDate.vue"

@Component({
  components: {MapFilter}
})
export default class extends Vue {

  @Prop({
    type: Object
    , required: true
  })
  nfcState!: NfcCardModel

  @Watch("nfcState.alwaysCustomerId")
  customerChanged() {
    this.refreshReadCustomer()
  }

  overlay: boolean = false
  tab: number = 0
  show: boolean = false
  elecPlugs: { value: number; label: any; }[] = []
  waterPlugs: { value: number; label: any; }[] = []

  readedCustomer: Customer | null = null

  filter: MapFilterModel = {customer: null}

  mounted() {
    this.elecPlugs = customerModel.getElecPlugs(this)
    this.waterPlugs = customerModel.getWaterPlugs(this)
  }

  async write(state: NfcCardModel, idToCheck: number[] | null = null): Promise<void> {
    try {
      await nfcCardService.write(state, idToCheck, null)
      this.$emit('success', 'Le badge NFC a été écrit')
    } catch(e: any) {
      if (e instanceof WriteError) {
        this.$emit('error', e.message, e)
      } else {
        this.$emit('error', "Le badge NFC n'a pas pu être écrit", e)
      }
    } finally {
      this.overlay = false
    }
  }

  async refreshReadCustomer() {
    console.log("refreshReadCustomer", this.nfcState.alwaysCustomerId)
    if (! this.nfcState.alwaysCustomerId) {
      this.readedCustomer = null
    } else {
      const response = await customerModel.getP({id: this.nfcState.alwaysCustomerId})
      this.readedCustomer = response.data
    }
  }

  getCustomerShortdesc(customer: Customer, email: boolean = false): string | null {
    return getCustomerShortdesc(customer, email)
  }


}

