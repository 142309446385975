
import Product from "./Product.js"

export default class ExpandProduct extends Product {
    constructor() {
        super()
        this.api_url = "/" + this.app + "/" + "expandproduct" + "s/"
      }


    //   defaultValues() {
    //     return {
    //         id: 0,

    //         is_visible: true,
    //         label: null,
    //         description: null,
    //         pos : 1,
    //         price_ht : 0,
    //         type: null,
    //         unit: "U",
    //         vat_id : null,
    //         is_subscribtion: false,
    //         is_card: true,
    //         card_refund: 10,
    //         markers: []
    //     }
    // }

}


