import Model from "../Model.js"


class PaymentMethod extends Model {
    constructor() {
        super("paymentmethod")
      }

    defaultValues() {
        return {
            id: 0,
            pos : 1,
            type: null,
            label: null,
            is_visible: null,
        }
    }

    getPaymentMethods(_self) {
        return [
            {value: "FREE", label: "Gratuit"}, 
            {value: "CARD", label:_self.$t(this.content + '.type_' + 'CARD')}, 
            {value: "CHECK", label:_self.$t(this.content + '.type_' + 'CHECK')},
            {value: "CASH", label:_self.$t(this.content + '.type_' + 'CASH')},
        ]
    }
}


export default new PaymentMethod()
