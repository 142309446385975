import moment from 'moment'


const PLUGIN_MISSING_ERROR = "nfc.PLUGIN_MISSING_ERROR"

const TYPE_FLAG                   = "FLAG"
const TYPE_CUSTOMER_FIELD_SELECT  = "CUSTOMER_FIELD_SELECT"

const UNREAD_BLOCK  = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
const ZERO_BLOCK    = "00000000000000000000000000000000"
const BLOCK_SIZE = 16

const NB_SECTORS = 16
const NB_BLOCK_PER_SECTOR = 4

// class Block {
//   // bytes = null
//   // hex = null

//   constructor() {
//     this.bytes = null
//     this.hex = null
//   }
// }


class MifareClassic1K {
  
  constructor(key) {
    this.key = key
    this.metas = null
    
    this.state = {
      callback: null
      , isInit: false
      , timeout: null
      , data: {isRead:false, blocks: []}

      , plug_elec: 0
      , plug_water: 0
    }
  }

  isSubscribtion() {
    let blockHex = this.state.data.blocks[1].hex
    let mode = blockHex.substring(2, 4)

    return mode == "03"
  }

  isEmpty() {
    console.log(this.state.data.blocks)
    for (let block of this.state.data.blocks) {
      if (block.hex != UNREAD_BLOCK && block.hex != ZERO_BLOCK) {
        return false
      }
    }

    return true
  }

  getKey() {
    return this.key
  }

  translate(s) {
    if (s == "common.none") {
      return "Aucun"
    }
    return s
  }

  fillData() {
    // TODO en dur
    if ( this.state.data.blocks.length > 1) {
      let bytes = this.state.data.blocks[1].bytes
      if ( bytes.length >= 16) {
        bytes[14] = this.state.plug_elec
        bytes[15] = this.state.plug_water
      }
    }

    if (this.metas) {
      for (let meta of this.metas) {
        let editedBlock = this.state.data.blocks[meta.block]
        let values = editedBlock.values[meta.byte]
        //no values
        if (values == null) {
          continue
        }
        if (meta.type == TYPE_CUSTOMER_FIELD_SELECT) {
          //console.log(meta.label, values)
          editedBlock.bytes[meta.byte]  = values
        } else if (meta.type == TYPE_FLAG) {
          // console.log("********")
          // console.log(values)
          //console.log("before", editedBlock.bytes)
          // for (let byteIndex = meta.byte; byteIndex < editedBlock ) {

          // }
          //let temp = []
          
          for (let i = 0; i < values.length; i++) {
            let byteIndex = Math.floor(i / 8)
            if (i % 8 == 0) {
              editedBlock.bytes[meta.byte + byteIndex]  = 0
            }
            if (values[i]) {
              editedBlock.bytes[meta.byte + byteIndex] += 1 << i % 8
            }
          }
        }
      }

      let editedBlocks = []
      //console.log("generate modifications")
      let blockNum = 0
      for (let block of this.state.data.blocks) {
        //console.log(blockNum, block)
        if (block.bytes) {
          let hex = this.bytesToHex(block.bytes)
          if (hex != block.hex) {
            //console.log(blockNum, block.hex, "=>", hex)
            block.hex = hex
            editedBlocks.push(blockNum)
          }
        }
        blockNum++
      }
      for (let i = 0; i < NB_SECTORS * NB_BLOCK_PER_SECTOR; i++) {
        if (i == 0 || i % 4 == 3) {
          editedBlocks
        }
      }

      //can 't write at 0 and % 4 = 3
      editedBlocks = editedBlocks.filter((e) => e != 0 && e % 4 != 3)
      

      return editedBlocks
    }

    return []
  }

  fillValues(data) {
    let newBlocks = []
  
    let blockLength = BLOCK_SIZE *2
    for (let i = 0; i + blockLength - 1 < data.length; i += blockLength) {
      let blockString = data.slice(i, i + blockLength)
      let bytes = null
      if (blockString != UNREAD_BLOCK) {
        bytes = Buffer.from(blockString, 'hex')
      }
      // console.log(blockString)
      // console.log(bytes)
      let block = {hex: blockString, bytes: bytes, values: null}
      block.values = []
      for (let i = 0; i < BLOCK_SIZE; i++) {
        block.values.push(null)
      }
      newBlocks.push(block)
    }
    
    if (this.metas) {
      for (let meta of this.metas) {
        //console.log(meta)
        let newBlock = newBlocks[meta.block]
        //no data
        if (newBlock.bytes == null) {
          continue
        }
        //console.log('meta', newBlock, meta)
        if (meta.type == TYPE_CUSTOMER_FIELD_SELECT) {
          let byte = newBlock.bytes[meta.byte]
          //console.log("%c yop", "color:blue;", meta.label, byte)
          newBlock.values[meta.byte] = byte
        } else if (meta.type == TYPE_FLAG) {
          //console.log("FLAG")
          newBlock.values[meta.byte] = []
          //console.log(newBlock.hex, newBlock.bytes)
          for (let i = 0; i < 8 * meta.size; i++) {
            let byteIndex = Math.floor(i / 8)
            let byte = newBlock.bytes[meta.byte + byteIndex]
            newBlock.values[meta.byte].push((byte & 1 << (i % 8)) != 0)
          }

          // let values = newBlock.values
          
          // if (values == null) {
          //   newBlock.values = []
          //   for (let i = 0; i < BLOCK_SIZE; i++) {
          //     newBlock.values.push(null)
          //   }

          //   newBlock.values[meta.byte] = []
          //   //console.log(newBlock.hex, newBlock.bytes)
          //   for (let i = 0; i < 8 * meta.size; i++) {
          //     let byteIndex = Math.floor(i / 8)
          //     let byte = newBlock.bytes[meta.byte + byteIndex]
          //     newBlock.values[meta.byte].push((byte & 1 << (i % 8)) != 0)
          //   }
          // }
        }
      }
      // for (let newBlock of newBlocks) {

      //   if (bytes != null) {
      //     for (let b of bytes) {
      //       block.
      //     }
      //   }
      // }
    }

  
    this.state.data.blocks = newBlocks


    // TODO en dur
    if (this.state.data.blocks.length > 1) {
      let blockHex = this.state.data.blocks[1].hex

      console.log("blockHex => ", blockHex)

      let start = 14 * 2
      let end = start + 1 * 2
      this.state.plug_elec = parseInt(blockHex.substring(start, end))
  
      start = 15 * 2
      end = start + 1 * 2
      this.state.plug_water = parseInt(blockHex.substring(start, end))
    }
  }

  sortBlocks(blocks) {
    // unique
    blocks = [...new Set(blocks)]
    // sort
    blocks.sort((v1, v2) => v1 - v2)

    return blocks
  }

  clearBlocksRequest(blocks) {
    blocks = this.sortBlocks(blocks)

    let requestSectors = []

    for (let block of blocks) {
      requestSectors.push({
        num: Math.floor(block / 4)
        , blocks: [
            {num: block, data: ZERO_BLOCK}
          ]
      })
    }

    let request = { 
      fct: "write"
      , key: this.getKey()
      , sectors: requestSectors
    }

    return request
  }


  getWriteRequest(blocks) {
    blocks = this.sortBlocks(blocks)

    let requestSectors = []

    for (let block of blocks) {
      requestSectors.push({
        num: Math.floor(block / 4)
        , blocks: [
            {num: block, data: this.state.data.blocks[block].hex}
          ]
      })
    }

    let request = { 
      fct: "write"
      , key: this.getKey()
      , sectors: requestSectors
    }

    return request
  }

  //les blocks en position absolue
  getReadPartRequest(blocks) {
    blocks = this.sortBlocks(blocks)

    let requestSectors = []

    for (let blockNum of blocks) {
      let sectorNum = Math.floor(blockNum / 4)
      let sector = this.findSector(requestSectors, sectorNum)
      if (! sector) {
        sector = {num: sectorNum, blocks: []}
        requestSectors.push(sector)
      }
      sector.blocks.push({num: blockNum})
    }

    let request = { 
      fct: "read_part"
      , key: this.getKey()
      , sectors: requestSectors
    }

    return request
  }


  findSector(sectors, num) {
    for (let sector of sectors) {
      if (sector.num == num) {
        return sector
      }
    }

    return null
  }

  sendNativeMessage(request, callback) {
      this.sendNativeMessageGeneric(request, callback)
  }


  sendNativeMessageGeneric(data, callback) {
      //console.log("sendNativeMessageGeneric")
      
      if (! this.isInit) {
          //console.log("yep")
          document.addEventListener(
              "ExtensionAnswerEvent"
            ,  (e) => {
                  //console.log("dsdsds")
                  clearTimeout(this.timeout)
                  this.timeout = null
                  
                  var sData = e.target.getAttribute("data")
                  //console.log(e.target)
                  if(document.documentElement.contains(e.target)) {
                    document.documentElement.removeChild(e.target)
                  }
                  
                  var data = JSON.parse(sData);
                  
                  let cb = window.callback
                  //console.log("cb1", cb)
                  //console.log("1=>", cb)
                  window.callback = null
                  //console.log("cb2", cb)
                  if (cb != null) {
                    cb(data)
                  }
                }
              , false
              , true
          )
          this.isInit = true
      }
      if (window.callback != null) {
          data.status = "ERROR_BUSY"
          callback(data)
          return
      }
      try {
          window.callback = callback

          var element = document.createElement("ExtensionRequestElement")
          element.setAttribute("data", JSON.stringify(data))
          document.documentElement.appendChild(element)

          var evt = document.createEvent("Events")
          evt.initEvent("ExtensionRequestEvent", true, false)

          this.timeout = setTimeout(
              () => { 
                  let cb = window.callback
                  //console.log("cb2", cb)
                  //console.log("1=>", cb)
                  window.callback = null
                  //console.log("cb3", cb)
                  cb(null)
              }
              , 10000
          )
          element.dispatchEvent(evt)
      }
      catch(error) {
          let cb = window.callback
          //console.log("cb4", cb)
          window.callback = null
          //console.log("cb5", cb)
          cb(null)
      }
  }

  writeBlocksP(write_request) {
      return new Promise((successCallback, failureCallback) => {
          this.writeBlocks(write_request, successCallback, failureCallback)
      })
  }

  writeBlocks(write_request, callback, error) {
      let key = this.getKey()
      write_request.key = key

      this.sendNativeMessage(write_request, (response) => {
          if (response == null) {
              error(PLUGIN_MISSING_ERROR)
          } else if (response.fct != write_request.fct || response.status != "OK") {
              console.log("error", write_request, response)
              if (response.status == "NAT") {
                  error(PLUGIN_MISSING_ERROR)
              } else {
                  error("nfc." + response.status)
              }
          } else {
              callback(response)
          }
      })
  }

  getIdP() {
      return new Promise((successCallback, failureCallback) => {
          this.getId( successCallback, failureCallback)
      })
  }

  getId(callback, error) {
      let idRequest = this.getReadPartRequest([0])
      // let idRequest = { 
      //     fct: "read_part"
      //     , key: key
      //     , sectors: [
      //         {
      //             num: 0
      //             , blocks: [
      //                 {num: 0}
      //             ]
      //         }
      //     ]
      // }
      this.sendNativeMessage(idRequest, (response) => {
          if (response == null) {
              error(PLUGIN_MISSING_ERROR)
          } else if (response.fct != idRequest.fct || response.status != "OK") {
              console.log("error")
              error("nfc." + response.status)
          } else {
              let data = response.sectors[0].blocks[0].data
              // prendre le max, la taille de l'uid est paramétré par la companie
              let id = this.getIdFromData(data)
              callback(id)
          }
      })
  }

  getIdFromData(data) {
    return data.substring(0,8)
    //return data.substring(0,14)
  }

  readAll(callback, error) {
      console.log("readAll")
      let key = this.getKey()
      let read_request = {fct: "read_all", key: key}

      this.sendNativeMessage(read_request, (response) => {
          console.log("response all", response)
          if (response == null) {
              error(PLUGIN_MISSING_ERROR)
          } else if (response.fct != read_request.fct || response.status != "OK") {
              console.log("error")
              if (response.status == "NAT") {
                  error(PLUGIN_MISSING_ERROR)
              } else {
                  error("nfc." + response.status)
              }
          } else {
              console.log("ok")
              callback(response)
          }
      })
  }

  getDataBlock(b, sectors) {
      for (let i = 0; i < sectors.length; i++) {
          let sector = sectors[i]
          for (let j = 0; j < sector.blocks.length; j++) {
              let block = sector.blocks[j]
              if (block.num === b) {
                  return block.data
              }

          }
      }

      return UNREAD_BLOCK
  }

  readPartP(request)  {
      return new Promise((resolve, reject) => {
          this.readPart(request, resolve, reject)
      })
  }

  readPart(request, callback, error) {
    this.state.data.isRead = false
    //console.log("readPart")
    let key = this.getKey()
    request.key = key

    this.sendNativeMessage(request, (response) => {
        //console.log("response part", response)
        if (response == null) {
            error(PLUGIN_MISSING_ERROR)
        } else if (response.fct != request.fct || response.status != "OK") {
            console.log("error")
            if (response.status == "NAT") {
                error(PLUGIN_MISSING_ERROR)
            } else {
                error("nfc." + response.status)
            }
        } else {
            let data = ""
            for (let b = 0; b < 64; b++) {
                data += this.getDataBlock(b, response.sectors)
            }
            response.data = data
            this.fillValues(data)
            this.state.data.isRead = true
            callback(response)
        }
    })
  }


  formatUid(uid) {
      let res = ""

      for (let i = 0; i < uid.length; i++) {
          if (res.length > 0 && i % 2 == 0) {
              res += " "
          }
          res += uid[i]
      }

      return res
  }

  formatValue(value) {
      let empty = this.translate("common.none")
      let res = empty
      //console.log(value.nfcinfo.type)

      // if (! value.nfcinfo) {
      //   return res
      // }

      //console.log(value, value.nfcinfo)
      if (value.nfcinfo.code == "CARD_MODE") {
          if (value.int_value == 1) {
              return "Compteur"
          } else if (value.int_value == 2) {
              return "Temps"
          } else if (value.int_value == 3) {
              return "Post-paiement"
          }
      }

      // console.log(value.nfcinfo.type)
      // if(value.type == TYPE_CUSTOMER_FIELD_SELECT) {
      //   return "ssq"
      // }

      if(value.nfcinfo.type == "INT" || value.nfcinfo.type == "LOCALISATION") {
        res = value.int_value * value.nfcinfo.ratio
        for (const label of value.nfcinfo.labels) {
          if (label.value == res) {
            res = label.label
          }
        }
      } else if (value.nfcinfo.type == "FLAG") {
          
          let bytes = this.hexToBytes(value.flags_value)
          //bytes = this.hexToBytes("03FF")
          for (let i = 0; i < bytes.length; i++ ) {
              let b  = bytes[i]
              for(let j = 0; j < 8; j++) {
                  if ((b & 1 << j) != 0) {
                      if (res != empty) {
                          res += ", "
                      } else {
                          res = ""
                      }
                      res += (i * 8 + j + 1)
                  }
              }
          }
      } else if (value.nfcinfo.type == "TIMESTAMP") {
          let date = moment(new Date(value.int_value*1000))
          console.log(value)
          res = date.format("DD/MM/YYYY HH:mm:ss")
      }
      return res
  }

  hexToBytes(hex) {
      for (var bytes = [], c = 0; c < hex.length; c += 2)
          bytes.push(parseInt(hex.substr(c, 2), 16));
      return bytes
  }

  bytesToHex(bytes) {
    for (var hex = [], i = 0; i < bytes.length; i++) {
        var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
        hex.push((current >>> 4).toString(16));
        hex.push((current & 0xF).toString(16));
    }
    return hex.join("");
  }
}


export default MifareClassic1K
