<template lang="pug">
div
  include ../tool/CommonList.pug
    //- slave_id
    template(v-slot:item.slave_id="{item}")
      div() {{item.plc?.com_type == "RS485" ? item.plc?.slave_id : item.plc?.ip}}


</template>


<script >
  import {Model, EditDialog} from "./imports.js"
  
  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog
    },
    props: {
      com_id: {
        type: String
        , default: ''
      }
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      formatIdNetwork(item) {
        console.log(item)
        if (item.com.type == "RS485") {
          return item.slave_id
        }
        return item.ip
      },

      prepareValue(item) {
        let result = item
        
        if (this.com_id != '') {
          result = {...item, ...{com_id: this.com_id}}
        }

        //console.log(result)
        return result
      },

      prepareEditDialog(dialog) {
        //console.log(dialog.com_readonly)
        dialog.com_readonly = this.com_id != ''
        //console.log(dialog.com_readonly)
      },
      loadModel: function() {
        this.model = new Model()
      },
      isViewAllowed: function() {
        return false
      },
      isDeleteAllowed: function() {
        return true
      },

      setup () {
        if (this.com_id != '') {
          this.filters.com_id = this.com_id
        }

        this.headers = [
          {align: "start",  width: "10",  text: this.model.content + '.' + 'pos',           value: 'pos',          sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'com',           value: 'com',          sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'type',          value: 'type',         sortable: false},
          // {align: "start",  width: "100", text: this.model.content + '.' + 'uid',           value: 'uid',          sortable: false},
          {align: "start",  width: "100", text: 'Id réseau',      value: 'slave_id',     sortable: false},

          {align: "start",  width: "100", text: this.model.content + '.' + 'label',         value: 'label',        sortable: false},
          //{align: "start",  width: "100", text: this.model.content + '.' + 'status',        value: 'status',       sortable: false},
          //{align: "start",  width: "100", text: this.model.content + '.' + 'temperature',   value: 'temperature',  sortable: false},
          {align: "start",  width: "100", text: this.model.content + '.' + 'is_enabled',    value: 'is_enabled',  sortable: false},

          {align: "center", width: "10",  text: 'common.created_date',                      value: 'created_date', sortable: false},
          {align: "center", width: "10",  text: 'common.actions',                           value: 'name',         sortable: false }
        ]
 
       this.columns = [
          {class: "handle", text: '::'},
          
          {class: "text-left",   value: 'com.label'},
          {class: "text-left",   value: 'type.label'},
          // {class: "text-left",   value: 'uid'},
          {class: "text-left",     format: this.formatIdNetwork},
          {class: "text-left",   value: 'label'},
          //{class: "text-left",   value: 'status'},
          //{class: "text-left",   value: 'temperature',  format: this.formatTemperature},
          {class: "text-left",   value: 'is_enabled'},
          
          {class: "text-center", value: 'created_date', format: this.formatDatetime},
        ]
      },
    },
  }

</script>
