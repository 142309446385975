import Model from "../Model.js"


export default class Formule extends Model {
    constructor() {
        super("formule")
      }

    defaultValues() {
        return {
            id: 0,
            price: null,
            label: null,
            is_visible: true,
            pos : 1,
            unit: null,
            price_ht: 0,
            vat_id: null
        }
    }

    getTypes(_self) {
        let types = ["PRE_PAYED", "ANNUAL", "MONTHLY", "TRIMESTER"]

        let result = []

        types.forEach(element => {
            result.push({value: element, label: _self.$t(this.content + '.' + 'type_' + element)})
        });

        // return [
        //     {value: "SET", label:_self.$t(this.content + '.' + 'type_SET')}, 
        //     {value: "ADD", label:_self.$t(this.content + '.' + 'type_ADD')},
        //     {value: "FLAG", label:_self.$t(this.content + '.' + 'type_FLAG')},
        //     {value: "CONSUMP", label:_self.$t(this.content + '.' + 'type_CONSUMP')},
        // ]

        return result
    }
}


