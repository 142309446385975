import Vue from 'vue'
import VueRouter from 'vue-router'

import loginStore from './components/hmi/tool/LoginStore.js'
import Login from './components/hmi/tool/Login.vue'

import Hmi from './components/hmi/hmi/Hmi.vue'


import EditCompany from './components/hmi/company/EditCompany.vue'

import ListCustomers from './components/hmi/customer/ListCustomers.vue'
import DetailCustomer from './components/hmi/customer/DetailCustomer.vue'

//import ListPaymentMethods from './components/hmi/paymentmethod/ListPaymentMethods.vue'
import ListNFCOperations from './components/hmi/nfcoperation/ListNFCOperations.vue'
import DetailNFCOperation from './components/hmi/nfcoperation/DetailNFCOperation.vue'
//import ListNFCValueModifier from './components/hmi/nfcvaluemodifier/ListNFCValueModifier.vue'

import ListProducts from './components/hmi/product/ListProducts.vue'
import DetailProduct from './components/hmi/product/DetailProduct.vue'

import ListFormules from './components/hmi/formule/ListFormules.vue'
import DetailFormule from './components/hmi/formule/DetailFormule.vue'

import ListSubscribtions from './components/hmi/subscribtion/ListSubscribtions.vue'
import CreateSubscribtion from './components/hmi/subscribtion/CreateSubscribtion.vue'
import DetailSubscribtion from './components/hmi/subscribtion/DetailSubscribtion.vue'

import ListMarkers from './components/hmi/marker/ListMarkers.vue'
import DetailMarker from './components/hmi/marker/DetailMarker.vue'

import ListGateways from './components/hmi/gateway/ListGateways.vue'
import DetailGateway from './components/hmi/gateway/DetailGateway.vue'


import ListComs from './components/hmi/com/ListComs.vue'
import DetailCom from './components/hmi/com/DetailCom.vue'
import ListPLCs from './components/hmi/plc/ListPLCs.vue'
//import DetailPLC from './components/hmi/plc/DetailPLC.vue'

import ListPLCTypes from './components/hmi/plctype/ListPLCTypes.vue'
import DetailPLCType from './components/hmi/plctype/DetailPLCType.vue'

import ListPLCRequests from './components/hmi/plcrequest/ListPLCRequests.vue'
import DetailPLCRequest from './components/hmi/plcrequest/DetailPLCRequest.vue'

import ListPLCVariables from './components/hmi/plcvariable/ListPLCVariables.vue'
import DetailPLCVariable from './components/hmi/plcvariable/DetailPLCVariable.vue'

import ListExpandPLCs from './components/hmi/plcenergy/ListPLCEnergy.vue'
// import DetailPLCEnergy from './components/hmi/plcenergy/DetailPLCEnergy.vue'


/*
import ListDataTypes from './components/hmi/datatype/ListDataTypes.vue'



import ListFlagLabels from './components/hmi/flaglabel/ListFlagLabels.vue'

import ListPLCBatchValues from './components/hmi/plcbatchvalue/ListPLCBatchValues.vue'

import ListPLCValues from './components/hmi/plcvalue/ListPLCValues.vue'
*/

import ListTransactions from './components/hmi/transaction/ListTransactions.vue'

import ListBaskets from './components/hmi/basket/ListBaskets.vue'
import CreateBasket from './components/hmi/basket/CreateBasket.vue'
import FillBasket from './components/hmi/basket/FillBasket.vue'
import DetailBasket from './components/hmi/basket/DetailBasket.vue'

import ListVATs from './components/hmi/vat/ListVATs.vue'

import MapMaint from './components/hmi/map/MapMaint.vue'
import MapSell from './components/hmi/map/MapSell.vue'

import Data from './components/hmi/data/Data.vue'

Vue.use(VueRouter)

function checkAuthent(to, from, next) {
  if (loginStore.isLogged()) {
    next()
    return
  }
  next('/login')
}

var routes =  [
  {
    path: '/login',
    component: Login
  },
  {
    beforeEnter: checkAuthent,
    path: '/hmi',
    component: Hmi,
    children: [
      /*
      {
        path: 'paymentmethod/search',
        component: ListPaymentMethods
      },*/
      {
        path: 'data',
        component: Data
      },

      {
        path: 'mapmaint',
        component: MapMaint
      },

      {
        path: 'map',
        component: MapSell
      },
      {
        path: 'customer/search',
        component: ListCustomers
      },
      {
        path: 'customer/view/:id',
        component: DetailCustomer
      },
      {
        path: 'nfcinfo/search',
        component: ListNFCOperations
      },
      // {
      //   path: 'nfcvaluemodifier/search',
      //   component: ListNFCValueModifier
      // },
      {
        path: 'vat/search',
        component: ListVATs
      },
      {
        path: 'product/search',
        component: ListProducts
      },
      {
        path: 'product/view/:id',
        component: DetailProduct
      },
      {
        path: 'nfcinfo/view/:id',
        component: DetailNFCOperation
      },
      {
        path: 'formule/search',
        component: ListFormules
      },
      {
        path: 'formule/view/:id',
        component: DetailFormule
      },
      
      {
        path: 'subscribtion/search',
        component: ListSubscribtions
      },
      
      {
        path: 'subscribtion/create/:id',
        component: CreateSubscribtion
      },

      {
        path: 'subscribtion/view/:id',
        component: DetailSubscribtion
      },

      {
        path: 'marker/search',
        component: ListMarkers
      },
      {
        path: 'marker/view/:id',
        component: DetailMarker
      },

      {
        path: 'gateway/search',
        component: ListGateways
      },
      {
        path: 'gateway/view/:id',
        component: DetailGateway
      },

      {
        path: 'com/search',
        component: ListComs
      },
      {
        path: 'com/view/:id',
        component: DetailCom
      },
      
      {
        path: 'plc/search',
        component: ListPLCs
      },

      
      {
        path: 'plctype/search',
        component: ListPLCTypes
      },
      {
        path: 'plctype/view/:id',
        component: DetailPLCType
      },
      
      
      {
        path: 'plcrequest/search',
        component: ListPLCRequests
      },
      {
        path: 'plcrequest/view/:id',
        component: DetailPLCRequest
      },


      {
        path: 'plcvariable/search',
        component: ListPLCVariables
      },
      {
        path: 'plcvariable/view/:id',
        component: DetailPLCVariable
      },
      

      {
        path: 'plcenergy/search',
        component: ListExpandPLCs
      },
      // {
      //   path: 'plcenergy/view/:id',
      //   component: DetailPLCEnergy
      // },

      /*
        {
          path: 'plc/view/:id',
          component: DetailPLC
        },

      {
        path: 'plcrequest/view/:id',
        component: DetailPLCRequest
      },

      {
        path: 'datatype/search',
        component: ListDataTypes
      },




      {
        path: 'flaglabel/search',
        component: ListFlagLabels
      },
      {
        path: 'plcbatchvalue/search',
        component: ListPLCBatchValues
      },
      {
        path: 'plcvalue/search',
        component: ListPLCValues
      },
*/
      {
        path: 'transaction/search',
        component: ListTransactions
      },


      {
        path: 'basket/search',
        component: ListBaskets
      },
      {
        path: 'basket/edit/:id',
        component: CreateBasket
      },
      {
        path: 'basket/fill',
        component: FillBasket
      },
      {
        path: 'basket/detail',
        component: DetailBasket
      },
      {
        path: 'basket/view/:id',
        component: DetailBasket
      },
      {
        path: 'company/edit',
        component: EditCompany
      },
    ]
  },
  
  { 
    beforeEnter: checkAuthent,
    path: '*', 
    redirect: '/login' 
  },
];

export default new VueRouter({
  mode: 'history',
  routes
})
//global.router = router


