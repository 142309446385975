<template lang="pug">

v-container.grid-list-md
  include CustomerPart.pug

</template>


<script>
import {Model} from "./imports.js"

export default {

  components: {
  },
  props: {
  },
  data: () => ({
    readonly: true,
    item: {}
  }),
  watch: {
  },

  methods: {
    emailRule: function() {
      return true
    }
  },

  created() {
    this.model = new Model()
  }
}


</script>
