var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "elevation-0"
  }, [_c('v-card-title', {
    attrs: {
      "color": "rgb(255, 0, 0, 0.2)"
    }
  }, [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t(_vm.title)))])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', {
    staticClass: "grid-list-md",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'reference')
    },
    model: {
      value: _vm.item.reference,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "reference", $$v);
      },
      expression: "item.reference"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 120,
      "label": _vm.$t(this.model.content + '.' + 'label')
    },
    model: {
      value: _vm.item.label,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "label", $$v);
      },
      expression: "item.label"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "counter": 512,
      "label": _vm.$t(this.model.content + '.' + 'description'),
      "rows": "1"
    },
    model: {
      value: _vm.item.description,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "description", $$v);
      },
      expression: "item.description"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.product_types,
      "item-value": "value",
      "item-text": "label"
    },
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [[_vm.PRODUCT_TYPE_CARD_UNITS, _vm.PRODUCT_TYPE_CARD_TIME, _vm.PRODUCT_TYPE_CARD_ACTIVATION].includes(_vm.item.type) ? _c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "label": _vm.$t(this.model.content + '.' + 'card_refund'),
      "prefix": _vm.model.getCurrency()
    },
    model: {
      value: _vm.item.card_refund,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "card_refund", $$v);
      },
      expression: "item.card_refund"
    }
  }) : _vm._e()], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "label": _vm.item.is_subscribtion ? _vm.$t(this.model.content + '.' + 'register_ht') : _vm.$t(this.model.content + '.' + 'price_ht'),
      "prefix": this.model.getCurrency()
    },
    model: {
      value: _vm.item.price_ht,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "price_ht", $$v);
      },
      expression: "item.price_ht"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "counter": 10,
      "label": _vm.$t(this.model.content + '.' + 'unit')
    },
    model: {
      value: _vm.item.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "unit", $$v);
      },
      expression: "item.unit"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-select', {
    staticClass: "mr-4",
    attrs: {
      "readonly": _vm.readonly,
      "required": "",
      "items": _vm.vats,
      "item-value": "id",
      "item-text": "label",
      "rules": [function (v) {
        return !!v || _vm.$t('common.required');
      }],
      "label": _vm.$t(this.model.content + '.' + 'vat')
    },
    model: {
      value: _vm.item.vat_id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "vat_id", $$v);
      },
      expression: "item.vat_id"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-switch', {
    staticClass: "ml-4",
    attrs: {
      "readonly": _vm.readonly,
      "label": _vm.$t(this.model.content + '.' + 'is_visible')
    },
    model: {
      value: _vm.item.is_visible,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "is_visible", $$v);
      },
      expression: "item.is_visible"
    }
  })], 1)], 1), [_vm.PRODUCT_TYPE_CARD_UNITS, _vm.PRODUCT_TYPE_RELOAD_UNITS, _vm.PRODUCT_TYPE_CARD_TIME, _vm.PRODUCT_TYPE_RELOAD_TIME].includes(_vm.item.type) ? _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [[_vm.PRODUCT_TYPE_CARD_UNITS, _vm.PRODUCT_TYPE_RELOAD_UNITS].includes(_vm.item.type) ? _c('v-text-field', {
    attrs: {
      "label": "Eau",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "L"
    },
    model: {
      value: _vm.item.qty_water,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "qty_water", $$v);
      },
      expression: "item.qty_water"
    }
  }) : _vm._e(), [_vm.PRODUCT_TYPE_CARD_TIME, _vm.PRODUCT_TYPE_RELOAD_TIME].includes(_vm.item.type) ? _c('div', [_c('v-text-field', {
    attrs: {
      "label": "Eau",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "min"
    },
    model: {
      value: _vm.time_qty_water,
      callback: function callback($$v) {
        _vm.time_qty_water = $$v;
      },
      expression: "time_qty_water"
    }
  })], 1) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [[_vm.PRODUCT_TYPE_CARD_UNITS, _vm.PRODUCT_TYPE_RELOAD_UNITS].includes(_vm.item.type) ? _c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "label": "Electricité",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "kw/h"
    },
    model: {
      value: _vm.item.qty_elec,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "qty_elec", $$v);
      },
      expression: "item.qty_elec"
    }
  }) : _vm._e(), [_vm.PRODUCT_TYPE_CARD_TIME, _vm.PRODUCT_TYPE_RELOAD_TIME].includes(_vm.item.type) ? _c('div', [_c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "label": "Electricité",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "min"
    },
    model: {
      value: _vm.time_qty_elec,
      callback: function callback($$v) {
        _vm.time_qty_elec = $$v;
      },
      expression: "time_qty_elec"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Douches",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "U"
    },
    model: {
      value: _vm.item.nb_showers,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "nb_showers", $$v);
      },
      expression: "item.nb_showers"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "ml-4",
    attrs: {
      "label": "Jours de parking",
      "readonly": _vm.readonly,
      "required": "",
      "type": "number",
      "min": "0",
      "rules": [_vm.priceRule],
      "prefix": "J"
    },
    model: {
      value: _vm.item.days_parking,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "days_parking", $$v);
      },
      expression: "item.days_parking"
    }
  })], 1)], 1), _vm._t("additional_fields")], 2)], 1)], 1), !_vm.readonly ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(_vm._s(_vm.item.id == 0 ? _vm.$t('common.create') : _vm.$t('common.save')))]), _vm.showClose ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('common.close')))]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }