import rest from '../hmi/tool/rest.js'
import loginStore from '../hmi/tool/LoginStore.js'
import router from '../../router.js'

export default class Model {
    // app: string
    // content: any
    // api_url: string
    // path_hmi: string
    // binaryFields: never[]
    
    constructor(content, app = "api", binaryFields = []) {
        this.app = app
        this.content = content
        this.api_url = "/" + this.app + "/" + this.content + "s/"
        this.path_hmi = "/hmi/" + this.content + "/"
        this.binaryFields = binaryFields
    }

    serialize(row) {
        let result = Object.assign({}, row)

        //console.log(this.binaryFields)
        this.binaryFields.forEach(binaryField => {
            let value = result[binaryField]
            if (value == null || value.length == 0) {
                result[binaryField] = ""
            } else {
                result[binaryField] = this.bytesToHex(value)
            }
            //console.log("serialize", binaryField, value, result[binaryField])
        })
        return result
    }

    deserializeArray(data) {
        let rows = []
        data.forEach(row => {
            rows.push(this.deserializeRow(row))
        })

        return rows
    }

    deserializeRow(row) {
        let result = Object.assign({}, row)
        //console.log(this.binaryFields)
        this.binaryFields.forEach(binaryField => {
            //console.log('deserialize', binaryField, row[binaryField])
            let value = result[binaryField]
            if (value == null || value.length == 0) {
                result[binaryField] = []
            } else  {
                result[binaryField] = this.hexToBytes(value)
            }
        })
        //console.log("deserialize", result)
        return result
    }

    // Convert a hex string to a byte array
    hexToBytes(hex) {
        for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
        return bytes;
    }

    // Convert a byte array to a hex string
    bytesToHex(bytes) {
        for (var hex = [], i = 0; i < bytes.length; i++) {
            var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
            hex.push((current >>> 4).toString(16));
            hex.push((current & 0xF).toString(16));
        }
        return hex.join("");
    }

    getCurrency() {
        return loginStore.currency()
    }
    formatCurrency(value) {
        return loginStore.formatCurrency(value)
    }
    formatRatio(value) {
        return loginStore.formatRatio(value)
    }

    defaultValues() {
    }

    isEditAllowed() {
        // return loginStore.isAllowed(this.app + ".change_" + this.content)
        return true
    }

    isDeleteAllowed() {
        // return loginStore.isAllowed(this.app + ".delete_" + this.content)
        return true
    }

    isCreateAllowed() {
        // return loginStore.isAllowed(this.app + ".add_" + this.content)
        return true
    }

    isViewAllowed() {
        // return loginStore.isAllowed(this.app + ".view_" + this.content)
        return true
    }
    viewItem(item, readonly=true) {
        //console.log("readonly", readonly)
        router.push({path: this.path_hmi + "view/" + item.id, query: {readonly: readonly} })
    }

    redirect(path) {
        //loginStore.redirect(path)
        console.log({path: this.path_hmi + path })
        router.push({path: this.path_hmi + path })
    }

    pdf_view(params, callbackOk, callbackError) {
        params = this.serialize(params)
        rest.pdf_view(
            this.api_url
            , params
            , (response) => {
                callbackOk(response)
            }
            , e => {
                callbackError(e)
            }
        )
    }

    downloadBlob (blob, filename) {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        
        document.body.appendChild(link);
        link.click()
        document.body.removeChild(link);
    }

    preparePdf(data, fileName) {
        let _self = this
        this.pdf_view(
            data
            , function(response) {
                console.log(response)
                console.log(response.data.headers)
                _self.downloadBlob(new Blob([response.data], {type: "application/octet-stream"}), fileName)
                // let blob = new Blob([response.data], { type: 'application/pdf' }),
                // url = window.URL.createObjectURL(blob)
                // window.open(url) 
            }
            , function(e) {
                console.log(e)
            }
        )
    }

    postP(url, params) {
        return rest.postP(
            url
            , params
        )
    }
    
    exportData(params) {
        params = this.serialize(params)
        
        return new Promise((resolve, reject) => {
            rest.postP(
                this.api_url + 'export_data/'
                , params
            )
                .then((result) => {
                    //console.log(result)
                    this.downloadBlob(new Blob([JSON.stringify(result.data, null, "\t")]), "export.json")
                    resolve(result)
                }).catch((err) => {
                    reject(err)
                })
        })
    }

    importData(file) {
        console.log(file)
        var form = new FormData()
        form.append("file", file)

        return rest.postP(
            this.api_url + 'import_data/'
            , form
        )

        // params = this.serialize(params)
        // return rest.postP(
        //     this.api_url + 'import_data/'
        //     , params
        // )
    }

    move(params, callbackOk, callbackError) {
        params = this.serialize(params)
        rest.move(
            this.api_url
            , params
            , (response) => {
                callbackOk(response)
            }
            , e => {
                callbackError(e)
            }
        )
    }

    listP(page, page_size, params) {
        return new Promise((successCallback, failureCallback) => {
            this.list(page, page_size, successCallback, failureCallback, params)
        })
    }

    list(page, page_size, callbackOk, callbackError, params) {
        //console.log("list", params)
        //console.log("start list", this.api_url)
        let _self = this
        return rest.list(
            this.api_url
            , page
            , page_size
            , (response) => {
                if (response.data && response.data.results) {
                    let results = _self.deserializeArray(response.data.results)
                    response.data.results = results
                }
                //console.log("end list", this.api_url)
                callbackOk(response)
            }
            , e => {
                callbackError(e)
            }
            , params
        )
    }

    listAllP(params) {
        return new Promise((successCallback, failureCallback) => {
            this.listAll( successCallback, failureCallback, params)
        })
    }

    listAll(callbackOk, callbackError, params) {
        this.list(1, 200, callbackOk, callbackError, params)
    }

    getDetailUrl(id) {
        return this.api_url + id + "/"
    }

    getP(params) {
        return new Promise((successCallback, failureCallback) => {
            this.get(params, successCallback, failureCallback)
        })
    }

    get(params, callbackOk, callbackError) {
        let _self = this
        rest.get(
            this.api_url
            , params
            , function(response) {
                let data = _self.deserializeRow(response.data)
                response.data = data
                callbackOk(response)
            } 
            , function(e) {
                callbackError(e)
            }
        )
    }

    delete(data, callbackOk, callbackError) {
        rest.delete(this.api_url, data
            , () => {
                callbackOk()
            }
            , e => {
                callbackError(e)
            }
        )
    }

    update(data, callbackOk, callbackError) {
        data = this.serialize(data)
        rest.update(
            this.api_url
            , data
            , (response) => {
                callbackOk(response)
            }
            , e => {
                callbackError(e)
            }
        )
    }

    updateP(data) {
        return new Promise((successCallback, failureCallback) => {
            this.update(data, successCallback, failureCallback)
        })
    }

    updateAndSet(self, field, callback = null) {
        //console.log("=>", self[field])
        this.update(
            self[field]
            , response => {
                //console.log("ici")
                self[field] = response.data
                self.$emit('success', 'common.updated', response.data)
                if (callback != null) {
                    callback(self, response.data)
                }
            }
            , e => {
                self.$emit('error', 'common.updated_error', e)
            }
        )
    }
    
    create(data, callbackOk, callbackError) {
        data = this.serialize(data)
        rest.create(
            this.api_url
            , data
            , response => {
                callbackOk(response)
            }
            , (e) => {
                callbackError(e)
            })
    }

    fillValues(self, field, params={}, loadingField=null) {
        if (loadingField != null) {
            self[loadingField] = true
        }
        this.listAll(
            (response) => {
                //console.log(response.data.results)
                self[field] = response.data.results
                if (loadingField != null) {
                    self[loadingField] = false
                }
            }
            , e => {
                //console.log(e)
                if (loadingField != null) {
                    self[loadingField] = false
                }
                self.$emit('error', 'common.not_found', e)
            }
            , params
        )
    }

    createAndSet(self, field, callback = null) {
        console.log("=>", self[field])
        this.create(
            self[field]
            , response => {
                //console.log("ici")
                self[field] = response.data
                self.$emit('success', 'common.created', response.data)
                if (callback != null) {
                    callback(self, response.data)
                }
            }
            , e => {
                self.$emit('error', 'common.created_error', e)
            }
        )
    }

    getAndSet(self, id, field, obj = null, callback = null) {
        if (obj == null) {
            obj = self
        }
        this.get(
            {id: id}
            , function(response) {
                if (callback == null) {
                    obj[field] = response.data

                } else {
                    callback(response)
                }
            }
            , function(e) {
                self.$emit('error', 'common.not_found', e)
            }
        )
    }
}
