
import rest from '../../hmi/tool/rest.js'

export default class EnergyBox {
    constructor() {
    }

    async createPlugs(): Promise<void> {
        return new Promise((resolve, reject) => {
            rest.postP("/api/datas/create_plugs")
            .then((result) => {
                resolve()
            }).catch((err) => {
                reject(err)
            })
        })
    }
}