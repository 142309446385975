<template lang="pug">
  include ../tool/CommonList.pug


</template>

<script>
  import {Model, EditDialog} from "./imports.js"

  import CommonList from "../tool/CommonList.js"

  export default {
    extends: CommonList,
    components: {
      EditDialog
    },
    data: () => ({
      filterHidden: false, 
    }),
    computed: {
    },
    watch: {
    }, 
    methods: {
      loadModel: function() {
        this.model = new Model()
      },

      isViewAllowed: function() {
        return true
      },
      isDeleteAllowed: function() {
        return false
      },
      setup () {
        this.headers = [
          {align: "start", width: "10", text: this.model.content + '.' + 'email', value: 'email', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'first_name', value: 'first_name', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'last_name', value: 'last_name', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'company_name', value: 'company_name', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'country', value: 'country', sortable: false},
          {align: "start", width: "10", text: this.model.content + '.' + 'city', value: 'city', sortable: false},

          //{align: "center", width: "60", text: 'common.created_date', value: 'created_date', sortable: false},
          {align: "center", width: "10", text: 'common.actions', value: 'name', sortable: false }
        ]

      this.columns = [
          {class: "text-left", value: 'email'},
          {class: "text-left", value: 'first_name'},
          {class: "text-left", value: 'last_name'},
          {class: "text-left", value: 'company_name'},
          {class: "text-left", value: 'country'},
          {class: "text-left", value: 'city'},
          //{class: "text-right", value: 'created_date', format: this.formatDatetime},
        ]

      },
    },

  }

</script>
