import Model from "../Model.js"


export default class PLCType extends Model {
    constructor() {
        super("plctype")
    }

    defaultValues() {
        return {
            id: 0,
        }
    }
}
