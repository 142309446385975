import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router.js'
import i18n from './i18n.js'
import loginStore from './components/hmi/tool/LoginStore'

import moment from 'moment'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import { getLabel, SelectValue } from './components/model/energybox/Metrics'
Vue.component('GmapCustomMarker', GmapCustomMarker)
import TreeView from "vue-json-tree-view"


import {labelsConsumptionVariableType, labelsConsumptionDetailStatus, labelsSubscribtionType, labelsSubscribtionStatus, SubscribtionType, SubscribtionStatus, ConsumptionVariableType, ConsumptionDetailStatus} from '@/components/model/subscribtion/SubscribtionService'
import {labelsTransactionStatus} from '@/components/model/transaction/TransactionService'

i18n.locale = 'fr'

Vue.use(TreeView)

Vue.prototype.$SubscribtionType = SubscribtionType
Vue.prototype.$SubscribtionStatus = SubscribtionStatus
Vue.prototype.$ConsumptionVariableType = ConsumptionVariableType
Vue.prototype.$ConsumptionDetailStatus = ConsumptionDetailStatus

Vue.prototype.$labelsSubscribtionType = labelsSubscribtionType
Vue.prototype.$labelsSubscribtionStatus = labelsSubscribtionStatus
Vue.prototype.$labelsConsumptionVariableType = labelsConsumptionVariableType
Vue.prototype.$labelsConsumptionDetailStatus = labelsConsumptionDetailStatus
Vue.prototype.$labelsTransactionStatus = labelsTransactionStatus

// Vue.prototype.$labelsConsumptionVariableType = labelsConsumptionVariableType
// Vue.prototype.$labelsConsumptionDetailStatus = labelsConsumptionDetailStatus

Vue.mixin(
    {
        methods: {
          formatDuration: function (durationInSeconds) {
            const MINUTE_IN_S: number  = 60;
            const HOUR_IN_S: number  = 60 * MINUTE_IN_S;
            const DAY_IN_S: number  = 24 * HOUR_IN_S;


            let days = Math.round(durationInSeconds / DAY_IN_S);
            let hours =  Math.round((durationInSeconds % DAY_IN_S) / HOUR_IN_S);
            let minutes = Math.round((durationInSeconds % HOUR_IN_S) / MINUTE_IN_S);
            // let seconds = durationInSeconds % MINUTE_IN_S;
            
            let res = "";
            if (days > 0) {
              res += days + "J";
            }
            if (res.length > 0 || hours > 0) {
              if (res.length > 0) {
                res += " ";
              }
              res += hours + "H";
            }

            if (res.length > 0) {
              res += " ";
            }
            res += minutes + "min";

            return res;
          },
          $l(id: string | number | undefined, labels: SelectValue[]): string | undefined {
            return getLabel(id, labels)
          },

          nextTick(cb) {
            Vue.nextTick(cb)
          },
          // $evaluate(p) {
          //   console.log(p)
          //   return eval('this.' + p)
          // },
          formatBoolean: function(v) {
            return v ? this.$t("common.true") : this.$t("common.false")
          },
          formatDatetime: function (ts) {
            if (ts == null) {
              return null
            }
            return moment(ts).format(this.$t("common.datetime") as string)
          },
          formatDate: function (ts) {
            if (ts == null) {
              return null
            }
            return moment(ts).format(this.$t("common.date") as string)
          },
          formatIsoDate: function (ts) {
            if (ts == null) {
              return null
            }
            return moment(ts).format('YYYY-MM-DD')
          },
          parseIsoDate: function(str) {
            if (str == null) {
              return null
            }

            return moment.utc(str, 'YYYY-MM-DD').valueOf()
          },
          // formatDatetime: function (value) {
          //   return moment(String(value)).format(this.$t("common.datetime"))
          // },
          // formatDate: function (value) {
          //   if (value == null) {
          //     return null
          //   }
          //   return moment(String(value)).format(this.$t("common.date"))
          // },
          // parseDate: function (str) {
          //   if (str == null) {
          //     return null
          //   }
          //   return moment(str, this.$t("common.date")).toDate()
          // },
          // parseIsoDate: function (str) {
          //   if (str == null) {
          //     return null
          //   }
          //   return Date.parse(str)
          // },
          formatCurrency: function (value) {
              return loginStore.formatCurrency(value)
          }

          , formatFloat: function (value, nbDecimal, unit="") {
            if (isNaN(value)) {
              return "?" + (unit ? " " + unit : "")
            }
            return value.toFixed(nbDecimal) + (unit ? " " + unit : "")
          }

          //ratio
          , formatRatio: function (value) {
              return loginStore.formatRatio(value)
          }
          , onSuccess: function(msg) {
            //this.fetchData()
            this.$emit('success', msg)
          }
          , onError: function(msg, e) {
            this.$emit('error', msg, e)
          }

          , getLabel: function(item, col) {
              let result = col.text 
              if (result != null) {
                return result
              }
      
              result = item
              if (col.value != null && col.value != ".") {
                var values = col.value.split(".")
                for (let value of values) {
                  if (result != null) {
                    result = result[value]
                  } else {
                    result = ""
                  }
                }
              }
      
              if (col.format != null) {
                result = col.format(result)
              }
              return result
            },
        }
    }
)


Vue.config.productionTip = false

import * as VueGoogleMaps from 'gmap-vue'

//console.log("VueGoogleMaps", VueGoogleMaps)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUVfehwMbqiwm4HElOZjOMky6qw8GeU6g',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
})


new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
