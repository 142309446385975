var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "ma-2 pa-2"
  }, [_c('v-card-title', [_vm._v("Données ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createPlugs();
      }
    }
  }, [_vm._v("Créer les prises")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }