  export default {
    props: {
    },
    data: () => ({
      show: false,
      item: Object
    }),
    methods : {
      showDialog(item) {
        this.show = true
        this.item = {...item}
      },
      confirmQty () {
        //console.log("ici")
        this.show = false
        this.$emit('confirmQty', this.item)
      },
      cancelQty () {
        //console.log("la")
        this.show = false
        this.$emit('cancelQty')
      },
    },
  }
