
import Model from "../Model.js"
import { Metrics, PaginatedResults, PLC } from "./Metrics.js"
import rest from '../../hmi/tool/rest.js'


const V_STATUS_KO_WRONG_SEQ: string = "KO_WRONG_SEQ"
const V_STATUS_KO_GATEWAY_NOT_CONNECTED: string = "GATEWAY_NOT_CONNECTED"
const V_STATUS_KO_TIMEOUT: string = "TIMEOUT"
const V_STATUS_KO_UNKNOWN_ACTION: string = "KO_UNKNOWN_ACTION"
const V_STATUS_KO_UNKNOWN_COM_TYPE: string = "KO_UNKNOWN_COM_TYPE"
const V_STATUS_KO_CONNECT_TO_COM: string = "KO_CONNECT_TO_COM"
const V_STATUS_KO_READ_MODBUS: string = "KO_READ_MODBUS"
const V_STATUS_KO_WRITE_MODBUS: string = "KO_WRITE_MODBUS"
const V_STATUS_KO_UNSUPPORTED_PLC_VERSION: string = "KO_UNSUPPORTED_PLC_VERSION"
const V_STATUS_KO_WRONG_PLC_TYPE: string = "KO_WRONG_PLC_TYPE"
const V_STATUS_KO_INVALID_PLUG_POS: string = "KO_INVALID_PLUG_POS"
const V_STATUS_KO_DISABLED_PLUG: string = "KO_DISABLED_PLUG"
const V_STATUS_KO_IN_USE_PLUG: string = "KO_IN_USE_PLUG"
const V_STATUS_KO_PLUG_NOT_LOCKED: string = "KO_PLUG_NOT_LOCKED"
const V_STATUS_KO_PLUG_NOT_UNLOCKED: string = "KO_PLUG_NOT_UNLOCKED"
const V_STATUS_KO_PLUG_NOT_STARTED: string = "KO_PLUG_NOT_STARTED"
const V_STATUS_KO_PLUG_NOT_STOPED: string = "KO_PLUG_NOT_STOPED"
const V_STATUS_KO_NOT_FORCED: string = "KO_NOT_FORCED"
const V_STATUS_KO_WIFI_REQ_NUM: string = "KO_WIFI_REQ_NUM"
const V_STATUS_KO_WIFI_TIMEOUT: string = "KO_WIFI_TIMEOUT"


// async function list(page_num: number, page_size: number): string {
    
// }
export default class EnergyBox extends Model {
    constructor() {
        super("energybox")
    }

    defaultValues() {
        return {
            id: 0,
        }
    }

    handleError(e: any): void {
        if (! e || ! e.status) {
            return
        }
        
        if (e.status == V_STATUS_KO_WRONG_SEQ) {
            e.errorMsg = `Erreur de communication avec la passerelle `
        } else if (e.status == V_STATUS_KO_GATEWAY_NOT_CONNECTED) {
            e.errorMsg = `La passerelle est déconnectée `
        } else if (e.status == V_STATUS_KO_TIMEOUT) {
            e.errorMsg = `Délais de communication avec la passerelle dépassé `
        } else if (e.status == V_STATUS_KO_UNKNOWN_ACTION) {
            e.errorMsg = `Action non reconnue par la passerelle `
        } else if (e.status == V_STATUS_KO_UNKNOWN_COM_TYPE) {
            e.errorMsg = `Type de communication non reconnu `
        } else if (e.status == V_STATUS_KO_CONNECT_TO_COM) {
            e.errorMsg = `La communication n'a pas pu être établie `
        } else if (e.status == V_STATUS_KO_READ_MODBUS) {
            e.errorMsg = `Erreur de lecture `
        } else if (e.status == V_STATUS_KO_WRITE_MODBUS) {
            e.errorMsg = `Erreur d'écriture `
        } else if (e.status == V_STATUS_KO_UNSUPPORTED_PLC_VERSION) {
            e.errorMsg = `Version de la carte de commande non reconnue `
        } else if (e.status == V_STATUS_KO_WRONG_PLC_TYPE) {
            e.errorMsg = `Type de carte de commande non reconnu `
        } else if (e.status == V_STATUS_KO_INVALID_PLUG_POS) {
            e.errorMsg = `N° de prise ou de robinet non reconnu `
        } else if (e.status == V_STATUS_KO_DISABLED_PLUG) {
            e.errorMsg = `Prise ou robinet désactivé `
        } else if (e.status == V_STATUS_KO_IN_USE_PLUG) {
            e.errorMsg = `Prise ou robinet en cours d'utilisation `
        } else if (e.status == V_STATUS_KO_PLUG_NOT_LOCKED) {
            e.errorMsg = `La prise ou le robinet n'a pas pu être attribué `
        } else if (e.status == V_STATUS_KO_PLUG_NOT_UNLOCKED) {
            e.errorMsg = `La prise ou le robinet n'a pas pu être libéré `
        } else if (e.status == V_STATUS_KO_PLUG_NOT_STARTED) {
            e.errorMsg = `La prise ou le robinet n'a pas pu être mis en marche `
        } else if (e.status == V_STATUS_KO_PLUG_NOT_STOPED) {
            e.errorMsg = `La prise ou le robinet n'a pas pu être arrêté `
        } else if (e.status == V_STATUS_KO_NOT_FORCED) {
            e.errorMsg = `La prise ou le robinet n'a pas pu être forcé `
        } else if (e.status == V_STATUS_KO_WIFI_REQ_NUM) {
            e.errorMsg = `Erreur de communication avec la passerelle `
        } else if (e.status == V_STATUS_KO_WIFI_TIMEOUT) {
            e.errorMsg = `Délais de communication avec le wifi dépassé `
        }

        console.log("ici", e.status, e.errorMsg)
    }

    async listMetrics(page: number, page_size: number): Promise<PaginatedResults<PLC>> {
        let result = await this.listP(page, page_size)
        let data = result.data
        return result.data
    }

    // async listMetrics(page: number, page_size: number): Promise<PaginatedResults<PLCWithMetrics>> {
    //     let result = await this.listP(page, page_size)
    //     let data = result.data

    //     let plcWithMetricsArray: PLCWithMetrics[] = []
    //     for (let plc of data.results) {
    //         let metrics: Metrics | undefined = undefined

    //         if (plc.metrics.trim()) {
    //             metrics = JSON.parse(plc.metrics)
    //         }
    //         plcWithMetricsArray.push({plc, metrics})
    //     }
    //     return {
    //         count: data.count,
    //         page: data.page,
    //         page_size: data.page_size,
    //         results: plcWithMetricsArray
    //     }
    // }

    async getMetrics(id: number): Promise<PLC> {
        let result = await this.getP({id})
        return result.data
    }

    // async getMetrics(id: number): Promise<PLCWithMetrics> {
    //     let result = await this.getP({id})
    //     let plc = result.data
    //     return {plc, metrics: JSON.parse(plc.metrics)}
    // }

    async forcerSortie(id: number, pos: number, forcage: boolean, value: boolean): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'forcer_sortie/', {pos, forcage, value})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async forcerRelai(id: number, pos: number, forcage: boolean, value: boolean): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'forcer_relai/', {pos, forcage, value})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async forcerVoyant(id: number, pos: number, forcage: boolean, value: boolean): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'forcer_voyant/', {pos, forcage, value})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async forcerInit(id: number, pos: number, forcage: boolean, value: boolean): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'forcer_init/', {pos, forcage, value})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async lock(id: number, num: number, customer_id: number): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'lock/', {num, customer_id})
            .then((result) => {
                console.log(result)
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async unlock(id: number, num: number, customer_id?: number): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'unlock/', {num, "customer_id": customer_id})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async start(id: number, num: number, customer_id?: number): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'start/', {num, customer_id})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async stop(id: number, num: number, customer_id?: number): Promise<PLC> {
        return new Promise((resolve, reject) => {
            rest.postP(this.getDetailUrl(id) + 'stop/', {num, customer_id})
            .then((result) => {
                resolve(result.data.plc)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }

    async read(id: number): Promise<{metrics: Metrics, plc: PLC, updated: boolean}> {
        return new Promise((resolve, reject) => {
            rest.getP(this.getDetailUrl(id) + 'read/')
            .then((result) => {
                resolve(result.data)
            }).catch((err) => {
                const e = err.response?.data
                this.handleError(e)
                reject(e)
            })
        })
    }
}

// Generated by https://quicktype.io