var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "max-width": "100%"
    }
  }, [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.total_headers,
      "items": [_vm.value],
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "item.qty_water",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [[_vm.BASKET_TYPE_CARD_TIME, _vm.BASKET_TYPE_RELOAD_TIME].includes(item.type) ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.formatDuration(item.qty_water)))])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.formatFloat(item.qty_water, 0, "L")))])])];
      }
    }, {
      key: "item.qty_elec",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [[_vm.BASKET_TYPE_CARD_TIME, _vm.BASKET_TYPE_RELOAD_TIME].includes(item.type) ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.formatDuration(item.qty_elec)))])]) : _c('div', [_c('div', [_vm._v(_vm._s(_vm.formatFloat(item.qty_elec, 0, "kw/h")))])])];
      }
    }, {
      key: "item.nb_showers",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatFloat(item.nb_showers, 0, "U")))])];
      }
    }, {
      key: "item.days_parking",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatFloat(item.days_parking, 0, "J")))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }