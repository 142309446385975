var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "max-width": "100%"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('vat.title')))]), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.vat_headers,
      "items": _vm.basket.vats,
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headers = _ref.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (header, index) {
          return _c('th', {
            key: index
          }, [_c('div', {
            attrs: {
              "align": header.align,
              "width": header.width
            }
          }, [_vm._v(_vm._s(_vm.$t(header.text)))])]);
        }), 0)])];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "item.basis",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatCurrency(item.basis)))])];
      }
    }, {
      key: "item.rate",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRatio(item.rate)))])];
      }
    }, {
      key: "item.amount_diff",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatCurrency(item.amount - item.basis)))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }