<template lang="pug">
  extends ../tool/CommonEdit.pug
  block detail
    include ./PLCPart.pug

</template>

<script >
  import {Model} from "./imports.js"

  import CommonEdit from "../tool/CommonEdit.js"

  import Com from "../../model/com/Com.js"

  import PLCType from "../../model/plctype/PLCType.js"

  let plcTypeModel = new PLCType()

  let comModel = new Com()

  export default {
    extends: CommonEdit,
    components: {
    },
    props: {
    },
    data: () => ({
      coms: [],
      types: [],
      com_readonly: false,
    }),
    watch: {
    }, 
    computed: {
      isRS485: function() {
        for (const com of this.coms) {
          if (com.id == this.item.com_id){
            return com.type == 'RS485'
          }
        }

        return  false
      },

      isWifi: function() {
        for (const com of this.coms) {
          if (com.id == this.item.com_id){
            return com.type == 'WIFI'
          }
        }

        return  false
      },
    },
    methods: {
      

      loadModel: function() {
        this.model = new Model()
      },

      setup: function(){
        let _self = this

        plcTypeModel.listAll(
          response => {
            _self.types = response.data.results
          }
          , (e) => {
            console.log(e)
            _self.$emit('error', 'common.not_found', e)
          }
        )
      },
      prepareItemToEdit: function() {
        let _self = this
        comModel.listAll(
            response => {
              _self.coms = response.data.results
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.not_found', e)
            }
          )
      },
      prepareItemToCreate: function() {
        let _self = this

        //console.log(this.item)

        if (this.item.com_id != null) {
          comModel.get(
            {id: this.item.com_id}
            , response => {
              _self.item.com = response.data
              console.log(_self.item.com)
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.not_found', e)
            }
          )
        } else {
          comModel.listAll(
            response => {
              _self.coms = response.data.results
            }
            , (e) => {
              console.log(e)
              _self.$emit('error', 'common.not_found', e)
            }
          )
        }
      }
    },
  }

</script>