<template lang="pug">
div()
  v-menu(
    v-model="showSearchResults"
    max-height="250px"
    offset-y
  )
    template(v-slot:activator="{ on }")
      v-container.ma-0.pa-0
        v-row.ma-0.pa-0(dense)
          v-col.ma-0.pa-0(cols="11")
            v-text-field(
              v-if="value == null"
              v-model="search"
              :label="value == null ? $t('customer.search') : 'Client sélectionné'"
              @click="showSearchResults = true"
              :readonly="readonly"
              :disabled="readonly"
              :error-messages="value == null ?  'Choisir un client' : null"
            )
            v-tooltip(
              left
              v-if="value != null"
              open-delay="1200"
            )
              template(v-slot:activator="{ on }")
                div
                  div {{$t('customer.title')}}
                  div(v-on="on" style="color: rgba(0, 0, 0, 0.87);") {{formatCustomer(value)}}
              div(v-html="formatCustomerTooltip(value)") 
          v-col.ma-0.pa-0(cols="1")
            v-btn(
              icon
              color="primary"
              v-if="! readonly && value == null"
              @click="createCustomer"
            )
              v-icon(medium) mdi-plus
            v-btn(
              icon
              color="error"
              v-if="! readonly && value != null"
              @click="clearCustomer"
            )
              v-icon(medium) mdi-close
    v-data-table(
      :headers="headers"
      :items="items"
      :items-per-page="25"
      class="elevation-1"
      :loading="isLoading"
      hide-default-header
      hide-default-footer
    )
      //detail
      template(v-slot:body='{ items }')
        tbody()
          tr(v-for='item in items' @click="select(item)")
            //declared columns
            td(v-for="col in columns" :class="col.class") {{getLabel(item, col)}}
              div(v-if="col.subvalue != null" v-for="detail in item[col.subvalue]" class="linedetail") {{detail}}
      // no data
      template(v-slot:no-data)
        v-alert(
          :value="true"
          color="info"
          icon="warning"
        ) {{$t("common.no_data")}}
  div(style="display:none;")
    v-dialog(v-model="showCreate"  persistent width='75vw')
      CreateCustomerDialog(:showClose="true" ref="CreateCustomerDialog" @success="successCreateCustomer" @error="errorCreateCustomer")
</template>


<script>
import {Model} from "./imports.js"
import CreateCustomerDialog from '../customer/EditCustomer.vue'

export default {

  components: {
    CreateCustomerDialog
  },
  props: {
    value: null,
    readonly: Boolean,
  },
  data: () => ({
    showSearchResults: false
    , search: null
    , isLoading: false
    , model: Object
    , headers: []
    , items: []
    , showCreate: false
  }),
  watch: {
    search: function(value) {
      let _self = this
      this.isLoading = true
      this.model.list(1, 20
        , (response) => {
          _self.items = response.data.results
          _self.isLoading = false
        }
        , e => {
          console.log(e)
          _self.isLoading = false
        }
        , {search: value}
      )
    },
  },

  methods: {
    successCreateCustomer: function(label, value) {
      this.showCreate = false
      this.$emit("success", label)
      this.select(value)
    },
    errorCreateCustomer: function(label) {
      this.showCreate = false
      this.$emit("error", label)
    },
    createCustomer: function() {
      console.log("createCustomer", this.$refs.CreateCustomerDialog)
      this.showCreate = true
      this.$refs.CreateCustomerDialog.create({})
    },
    clearCustomer: function() {
      console.log('clearCustomer')
      this.select(null) 
      this.search = null
    },
    select: function (item) {
      this.search = this.formatCustomer(this.item)
      this.$emit("selected", item)
    },
    getLabel: function(item, col) {
      let result = col.text 
      if (result != null) {
        return result
      }

      if (col.value != null) {
        var values = col.value.split(".")
        result = item
        for (let value of values) {
          result = result[value]
        }

        if (col.format != null) {
          result = col.format(result)
        }
      } else {
        result = col.format(item)
      }

      return result
    },
    labelCustomer: function (value) {
      let res  = ""
      if (value != null) {
        if (! value.is_company) {
          if (value.first_name != null) {
            res += " " + value.first_name
          }
          if (value.last_name != null) {
            res += " " + value.last_name
          }
        } else {
          res += " " + value.company_name
        }
      }

      return  res.trim()
    },
    formatCustomer: function (value) {
      let res  = ""
      if (value != null) {
        if (! value.is_company) {
          if (value.first_name != null) {
            res += " " + value.first_name
          }
          if (value.last_name != null) {
            res += " " + value.last_name
          }
        } else {
          res += " " + value.company_name
        }
        if (value.city != null) {
          res += ", " + value.city
        }
        if (value.country != null) {
          res += ", " + value.country
        }
        if (value.email != null) {
          res += ", " + value.email
        }
      }

      return  res.trim()
    },
    formatCustomerTooltip: function (value) {
      let res = "<table >"

      res += "<tbody>"

      if (value.is_company) {
        if (value.company_name != null) {
          res += "<tr>"

          res += "<td class='ma-1 pa-1 text-left'>"
          res += value.company_name
          res += "</td>"

          res += "</tr>"
        }
      } else {
        res += "<tr>"

        res += "<td class='ma-1 pa-1 text-left'>"
        if (value.first_name != null) {
          res += value.first_name
        }
        if (value.last_name != null) {
          res += " " + value.last_name
        }
        res += "</td>"

        res += "</tr>"
      }
      if (value.address != null) {
        res += "<tr>"
        res += "<td class='ma-1 pa-1 text-left'>"
        res += " " + value.address
        res += "</td>"
        res += "</tr>"
      }

      res += "<tr>"
      res += "<td class='ma-1 pa-1 text-left'>"
      if (value.zip != null) {
        res += " " + value.zip
      }
      if (value.city != null) {
        res += " " + value.city
      }
      if (value.country != null) {
        res += " " + value.country
      }
      res += "</td>"
      res += "</tr>"

      if (value.email != null) {
        res += "<tr>"
        res += "<td class='ma-1 pa-1 text-left'>"
        res += value.email
        res += "</td>"
        res += "</tr>"
      }

      res += "</tbody>"
      res += "</table>"
      return res
    },
  },
  created() {
    this.model = new Model()

    this.headers = [
      {align: "start", width: "2", text: '', value: 'name', sortable: false },

      {align: "start", width: "10", text: this.model.content + '.' + 'first_name', value: 'first_name', sortable: false},
      {align: "start", width: "10", text: this.model.content + '.' + 'city', value: 'city', sortable: false},
      {align: "start", width: "10", text: this.model.content + '.' + 'country', value: 'country', sortable: false},
      {align: "start", width: "10", text: this.model.content + '.' + 'email', value: 'email', sortable: false},
    ]

    this.columns = [
      {class: "text-left", value: null, format: this.labelCustomer},
      {class: "text-left", value: 'city'},
      {class: "text-left", value: 'country'},
      {class: "text-left", value: 'email'},
    ]

    this.search = ""
  }
}



</script>